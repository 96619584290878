import React, { Component } from "react";
import FormErrors from "../FormErrors";
import Validate from "../../utils/FormValidation";
import { Auth } from "aws-amplify";

class ForgotPassword extends Component {
  state = {
    email: "",
    errors: {
      cognito: null,
      blankfield: false,
    },
  };

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
      },
    });
  };

  forgotPasswordHandler = async (event) => {
    event.preventDefault();

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error },
      });
    }

    // AWS Cognito integration here
    try {
      await Auth.forgotPassword(this.state.email);
      this.props.history.push("/forgotpasswordverification");
    } catch (error) {
      // console.log(error);
    }
  };

  onInputChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  render() {
    return (
      <section className="section auth">
        <div className="forgotCont">
          Forgot your password?
          <p>
            Please enter the email address associated with your account and
            we'll email you a password reset link.
          </p>
          <FormErrors formerrors={this.state.errors} />
          <form onSubmit={this.forgotPasswordHandler}>
            <input
              style={{ width: "20vw" }}
              type="email"
              className="inputInfos"
              id="email"
              aria-describedby="emailHelp"
              placeholder="Enter email"
              value={this.state.email}
              onChange={this.onInputChange}
            />

            <p></p>

            <button className="buttonsTournament is-success">Submit</button>
          </form>
        </div>
      </section>
    );
  }
}

export default ForgotPassword;
