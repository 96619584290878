//const ipBackend = "https://tradeasgame-dev.eu-4.evennode.com";
//const ipBackend = "https://tradeasgame.eu-4.evennode.com";
//const ipBackend = "http://localhost:3000";
export const ipBackend =
  process.env.NODE_ENV === "development"
    ? "https://tradeasgame.eu-4.evennode.com"
    : "https://tradeasgame.eu-4.evennode.com";

// http://localhost:3000
// https://f0bvh92lm8.execute-api.eu-west-3.amazonaws.com
export const apiURL =
  process.env.NODE_ENV === "development"
    ? "https://f0bvh92lm8.execute-api.eu-west-3.amazonaws.com"
    : "https://f0bvh92lm8.execute-api.eu-west-3.amazonaws.com";

// "wss://tradeasgame-dev.eu-4.evennode.com:3001"
// wss://cmtag.eu-4.evennode.com
export const webSocketUrl =
  process.env.NODE_ENV === "development"
    ? "https://chattag.eu-4.evennode.com"
    : "https://chattag.eu-4.evennode.com";
