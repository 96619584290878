import * as React from "react";

class Iframe extends React.Component {
  render() {
    return (
      <div className="estSecondaire">
        <iframe
          id="swingswiss"
          title="SwingSwiss"
          width="100%"
          height="100%"
          src=""
        ></iframe>
      </div>
    );
  }
}

export default Iframe;
