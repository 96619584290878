//import {io} from 'socket.io-client';
import { w3cwebsocket as W3CWebSocket } from "websocket";

//const socket = io('wss://streamer.cryptocompare.com',{transports: ['websocket'], upgrade: false});
//const socket = new W3CWebSocket("wss://ftx.com/ws/");
const socket = new W3CWebSocket("wss://stream.bybit.com/v5/public/linear");

//const channelToSubscription = new Map();
//var interval = new Map();

var subscriberHolder = {
  subscribeUID: "",
  resolution: "",
  lastDailyBar: "",
  channelName: "",
  handler: null,
};

socket.onopen = () => {
  //console.log("[socket] Connected");
};

socket.onclose = () => {
  //console.log("[socket] Closed");
};

socket.onerror = () => {
  //console.log("[socket] Error");
};

socket.onmessage = (message) => {
  
  var msg = message.data;
  var data = JSON.parse(msg);

  if (data.type === "delta" && data.data.markPrice) {
    var time = data.ts;
    var market = data.data.symbol;
    var price = data.data.markPrice;

    //console.log('[socket] Update market:'+  market +': Price: ' + price + ', Time: '+time);


    if (
      subscriberHolder.channelName === undefined ||
      subscriberHolder.channelName !== market.toLowerCase()
    ) { 
      
      return;
    }

    
	//console.log("WS TIME:"+time);
	//console.log("NX TIME:"+getNextBarTime(subscriberHolder.lastDailyBar.time, subscriberHolder.resolution));

    const nextDailyBarTime = getNextBarTime(
      subscriberHolder.lastDailyBar.time,
      subscriberHolder.resolution
    );

    if(data.data.markPrice === undefined || typeof time !== 'number') {
      alert("Incorrect data received from bybit, please restart the browser :<");
      return;
    }

    let bar;
    if (time >= nextDailyBarTime) {
      bar = {
        time: nextDailyBarTime,
        open: price,
        high: price,
        low: price,
        close: price,
      };
      //console.log("[socket] Generate new bar", bar);
    } else {
      bar = {
        time: subscriberHolder.lastDailyBar.time,
        open: subscriberHolder.lastDailyBar.open,
        high: Math.max(subscriberHolder.lastDailyBar.high, price),
        low: Math.min(subscriberHolder.lastDailyBar.low, price),
        close: price,
      };
      //console.log('[socket] Update the latest bar by price', price);
    }
    subscriberHolder.lastDailyBar = bar;
    subscriberHolder.handler(bar);

    // send data to every subscriber of that symbol
    //subscriptionItem.handlers.forEach(handler => handler.callback(bar));

    
  }
};

function getNextBarTime(barTime, resolution) {

  if (resolution === "1") {
    return barTime + 60000;
  } else if (resolution === "3") {
    return barTime + 180000;
  } else if (resolution === "5") {
    return barTime + 300000;
  } else if (resolution === "15") {
    return barTime + 900000;
  } else if (resolution === "30") {
    return barTime + 1800000;
  } else if (resolution === "60") {
    return barTime + 3600000;
  } else if (resolution === "120") {
    return barTime + 7200000;
  } else if (resolution === "240") {
    return barTime + 14400000;
  } else if (resolution === "1D") {
    return barTime + 86400000;
  } 
}

export function subscribeOnStream(
  symbolInfo,
  resolution,
  onRealtimeCallback,
  subscribeUID,
  onResetCacheNeededCallback,
  lastDailyBar
) {
  var premiere = subscribeUID.split(":");
  var second = premiere[1].split("_");
  var channelName = second[0];

 // console.log("[subscribeBars]: Method call to channel:", channelName);

  var paramWs = "tickers."+channelName.toUpperCase();

  if(socket.readyState  === 1) {
    socket.send(
      JSON.stringify({ "op": "subscribe", 'args': [paramWs] })
    );

    var subscriptionItem = {
      subscribeUID: subscribeUID,
      resolution: resolution,
      lastDailyBar: lastDailyBar,
      channelName: channelName,
      handler: onRealtimeCallback,
    };

    subscriberHolder = subscriptionItem;
  }
}

export function unsubscribeFromStream(subscriberUID) {
  //var premiere = subscriberUID.split(":");
  //var second = premiere[1].split("_");
  //var channelName = second[0];

  //alert(subscriberHolder.subscriberUID);

  //var premiereBis = subscriberHolder.subscribeUID.split(":");
  //var secondBis = premiereBis[1].split("_");
  //var OldchannelName = secondBis[0];
  /*

  if (channelName !== OldchannelName) {
    console.log('[unSubscribeBars]: Method call to channel:', channelName);
    var paramWs = channelName + "@ticker";
    socket.send(
      JSON.stringify({ "method": "UNSUBSCRIBE", 'params': [paramWs], "id": second[2] })
    );
  }*/


  
 /*
  var subscriptionItem = {
    subscribeUID: "",
    resolution: "",
    lastDailyBar: "",
    channelName: "",
    handler: null,
  };

  subscriberHolder = subscriptionItem;*/
}
