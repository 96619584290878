import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import axios from 'axios';
import "./App.css";
import Navbar from "./components/Navbar.js";
import Home from "./components/Home.js";
import Profile from "./components/auth/Profile.js";
import LogIn from "./components/auth/LogIn.js";
import Register from "./components/auth/Register.js";
import ForgotPassword from "./components/auth/ForgotPassword.js";
import ForgotPasswordVerification from "./components/auth/ForgotPasswordVerification.js";
import ChangePassword from "./components/auth/ChangePassword.js";
import ChangePasswordConfirm from "./components/auth/ChangePasswordConfirm.js";
import Welcome from "./components/auth/Welcome.js";
import Footer from "./components/Footer.js";
import Tournament from "./components/Tournament.js";
import GameMan from "./components/GameMan.js";
import Ranking from "./components/Ranking.js";
import Graph from "./components/Graph.js";
import Spectate from "./components/Spectate.js";
import Results from "./components/Results.js";
import Iframe from "./components/Iframe.js";
import TradingViewWidget from "./components/TradingViewWidget.js";
import TradingViewWidgetMini from "./components/TradingViewWidgetMini.js";
import TradingView from "./components/TradingView.js";
import { Auth } from "aws-amplify";
import { clearWeb3LocalStorage } from "./utils/function";

class App extends Component {
  state = {
    isAuthenticated: false,
    isAuthenticating: true,
    user: null,
  };



  setAuthStatus = (authenticated) => {
    this.setState({ isAuthenticated: authenticated });
  };

  setUser = (user) => {
    this.setState({ user: user });
  };

  async componentDidMount() {

    axios.interceptors.response.use(
      response => response, // return the response if it is not a 401 error
      error => {
        const { status } = error.response;
        if (status === 401) {
          // log the user out
          Auth.signOut();
          clearWeb3LocalStorage();
        }
        return Promise.reject(error);
      }
    );
    try {
      const isWeb3 = JSON.parse(localStorage.getItem("web3") || null);
      if (isWeb3) {
        const address = JSON.parse(localStorage.getItem("address") || null);
        const sessionToken = JSON.parse(
          localStorage.getItem("sessionToken") || null
        );
        const secretKey = JSON.parse(localStorage.getItem("secretKey") || null);
        const accessKeyId = JSON.parse(
          localStorage.getItem("accessKeyId") || null
        );
        const expiration = JSON.parse(
          localStorage.getItem("expiration") || null
        );
        const today = new Date();
        if (address && sessionToken && expiration && accessKeyId && secretKey) {
          if (new Date(expiration) > today) {
            this.setAuthStatus(true);
            this.setUser({
              username: address,
              credentials: {
                accessKeyId: accessKeyId,
                sessionToken: sessionToken,
                secretKey: secretKey,
                expiration: expiration,
              },
            });
          } else {
            clearWeb3LocalStorage();
          }
        }
      } else {
        await Auth.currentSession();
        this.setAuthStatus(true);
        //console.log(session);
        const user = await Auth.currentAuthenticatedUser();
        this.setUser(user);
      }
    } catch (error) {
      //console.log(error);
    }
    this.setState({ isAuthenticating: false });
  }

  componentDidUpdate() {
    //console.log(this.state);
  }
  render() {
    const authProps = {
      isAuthenticated: this.state.isAuthenticated,
      user: this.state.user,
      setAuthStatus: this.setAuthStatus,
      setUser: this.setUser,
    };
    return (
      !this.state.isAuthenticating && (
        <div className="App">
          <Router>
            <div>
              <Navbar auth={authProps} />
              <Switch>
                <Route
                  exact
                  path="/"
                  render={(props) => <Home {...props} auth={authProps} />}
                />
                <Route
                  exact
                  path="/home"
                  render={(props) => <Home {...props} auth={authProps} />}
                />
                <Route
                  exact
                  path="/profile"
                  render={(props) => <Profile {...props} auth={authProps} />}
                />
                <Route
                  exact
                  path="/login"
                  render={(props) => <LogIn {...props} auth={authProps} />}
                />
                <Route
                  exact
                  path="/register"
                  render={(props) => <Register {...props} auth={authProps} />}
                />
                <Route
                  exact
                  path="/forgotpassword"
                  render={(props) => (
                    <ForgotPassword {...props} auth={authProps} />
                  )}
                />
                <Route
                  exact
                  path="/forgotpasswordverification"
                  render={(props) => (
                    <ForgotPasswordVerification {...props} auth={authProps} />
                  )}
                />
                <Route
                  exact
                  path="/changepassword"
                  render={(props) => (
                    <ChangePassword {...props} auth={authProps} />
                  )}
                />
                <Route
                  exact
                  path="/changepasswordconfirmation"
                  render={(props) => (
                    <ChangePasswordConfirm {...props} auth={authProps} />
                  )}
                />
                <Route
                  exact
                  path="/welcome"
                  render={(props) => <Welcome {...props} auth={authProps} />}
                />
                <Route
                  exact
                  path="/tournament"
                  render={(props) => <Tournament {...props} auth={authProps} />}
                />
                <Route
                  exact
                  path="/ranking"
                  render={(props) => <Ranking {...props} auth={authProps} />}
                />
                <Route
                  exact
                  path="/gameman"
                  render={(props) => <GameMan {...props} auth={authProps} />}
                />
                <Route
                  exact
                  path="/graph"
                  render={(props) => <Graph {...props} auth={authProps} />}
                />
                <Route
                  exact
                  path="/iframe"
                  render={(props) => <Iframe {...props} auth={authProps} />}
                />
                <Route
                  exact
                  path="/spectate"
                  render={(props) => <Spectate {...props} auth={authProps} />}
                />
                <Route
                  exact
                  path="/results"
                  render={(props) => <Results {...props} auth={authProps} />}
                />
                <Route
                  exact
                  path="/tradingviewwidget"
                  render={(props) => (
                    <TradingViewWidget {...props} auth={authProps} />
                  )}
                />
                <Route
                  exact
                  path="/tradingviewwidgetmini"
                  render={(props) => (
                    <TradingViewWidgetMini {...props} auth={authProps} />
                  )}
                />
                <Route
                  exact
                  path="/tradingview"
                  render={(props) => (
                    <TradingView {...props} auth={authProps} />
                  )}
                />
              </Switch>
              <Footer />
            </div>
          </Router>
        </div>
      )
    );
  }
}

export default App;
