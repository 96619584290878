import React from "react";

export default function Welcome() {
  /////////////////////////////////////////
  ////            Welcome.js           ////
  //// Fichier de post enregistrement  ////
  /////////////////////////////////////////

  return (
    <section className="section auth">
      <div className="containerWc">
        <h1>Bienvenue!</h1>
        <p>Votre compte à bien été créé.</p>
        <p>
          {" "}
          Nous vous avons envoyé un mail de confirmation, veuillez cliquer sur
          le lien qu'il contient. <br />
          Il peut apparaître dans les spams !
        </p>
      </div>
    </section>
  );
}
