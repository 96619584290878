import React, { Component } from "react";
import FormErrors from "../FormErrors";
import Validate from "../../utils/FormValidation";
import { Auth } from "aws-amplify";
import logo from "../logo.svg";
import metamask from "../images/logo/metamask.svg";
import imgIllu from ".././images/illustration.png";
import axios from "axios";
import { apiURL } from "../../utils/const";
import Web3 from "web3";

const web3 = new Web3(Web3.givenProvider);

const isMetaMaskInstalled = () => {
  const { ethereum } = window;
  return Boolean(ethereum && ethereum.isMetaMask);
};

class LogIn extends Component {
  ///////////////////////////////////
  ////          Login.js         ////
  //// Fichier de login cognito  ////
  ///////////////////////////////////

  state = {
    username: "",
    password: "",
    errorsub: "",
    errors: {
      cognito: null,
      blankfield: false,
    },
    initialState: {
      isSubmitting: false,
      errorMessage: null,
      isMetamaskInstalled: true,
    },
  };

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
      },
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error },
      });
    }

    // AWS Cognito integration here

    try {
      const user = await Auth.signIn(this.state.username, this.state.password);
      this.setState({ errorsub: "" });

      this.props.auth.setAuthStatus(true);
      this.props.auth.setUser(user);
      console.log(this.props.auth.user);
      this.props.history.push("/");
    } catch (error) {
      this.setState({ errorsub: "Identifiants incorrects !" });
      let err = null;
      !error.message ? (err = { message: error }) : (err = null);
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: err,
        },
      });
    }
  };

  onInputChange = (event) => {
    document.getElementById("username").value = document
      .getElementById("username")
      .value.toLowerCase();
    this.setState({
      [event.target.id]: event.target.value,
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  checkMetaMaskClient = () => {
    this.setState({
      initialState: {
        ...this.state.initialState,
        isMetamaskInstalled: isMetaMaskInstalled(),
      },
    });

    return isMetaMaskInstalled();
  };

  checkMetaMaskNetwork = async () => {
    // Check if MetaMask is installed
    if (typeof window.ethereum === "undefined") {
      this.setState({
        initialState: {
          ...this.state.initialState,
          errorMessage: "Please install MetaMask first",
        },
      });
      return;
    }

    // Check if user is connected to MetaMask
    const accounts = await window.ethereum.enable();
    if (accounts.length === 0) {
      this.setState({
        initialState: {
          ...this.state.initialState,
          errorMessage: "Please connect to MetaMask",
        },
      });
      return;
    }

    // Check if user is connected to the Polygon network
    const web3 = new Web3(window.ethereum);
    const networkId = await web3.eth.net.getId();
    if (networkId !== 137) {
      this.setState({
        initialState: {
          ...this.state.initialState,
          errorMessage: "Please connect to the Polygon network in MetaMask",
        },
      });
      return;
    }
    this.setState({
      initialState: {
        ...this.state.initialState,
        errorMessage: null,
      },
    });
  };

  componentDidMount() {
    this.checkMetaMaskClient();
    this.checkMetaMaskNetwork();
  }

  handleFormSubmitWeb3 = async (event) => {
    event.preventDefault();

    this.setState({
      initialState: {
        ...this.state.initialState,
        isSubmitting: true,
        errorMessage: null,
      },
    });

    try {
      this.checkMetaMaskNetwork();
      if (this.checkMetaMaskClient()) {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        const address = accounts[0];
        console.log(address);
        let {
          data: { nonce },
        } = await axios(
          `${apiURL}${"/dev/getnonceweb3"}?address=${address.toLowerCase()}`,
          {
            method: "GET",
            validateStatus: false,
          }
        );
        if (!nonce) {
          const { data } = await axios.post(
            `${apiURL}${"/dev/signupweb3"}`,
            { address: address.toLowerCase() },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          console.log(data);
          if (data && data.Attributes) {
            nonce = data.Attributes.nonce;
          }
        }
        const signature = await web3.eth.personal.sign(
          web3.utils.sha3(`Welcome message, nonce: ${nonce}`),
          address
        );

        const { data } = await axios.post(
          `${apiURL}${"/dev/loginweb3"}`,
          {
            address,
            signature,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (data && data.AccessKeyId) {
          localStorage.setItem("web3", JSON.stringify(true));
          localStorage.setItem("address", JSON.stringify(address));
          localStorage.setItem(
            "sessionToken",
            JSON.stringify(data.SessionToken)
          );
          localStorage.setItem("accessKeyId", JSON.stringify(data.AccessKeyId));
          localStorage.setItem("secretKey", JSON.stringify(data.SecretKey));
          localStorage.setItem("expiration", JSON.stringify(data.Expiration));
          console.log(data);

          this.props.auth.setAuthStatus(true);
          this.props.auth.setUser({
            username: address,
            credentials: {
              accessKeyId: data.AccessKeyId,
              sessionToken: data.SessionToken,
              secretKey: data.SecretKey,
              expiration: data.Expiration,
            },
          });
          this.props.history.push("/");
        } else {
          this.setState({
            initialState: {
              isSubmitting: false,
              errorMessage: "Login failed",
              isMetamaskInstalled: true,
            },
          });
        }
      } else {
        this.setState({
          initialState: {
            ...this.state.initialState,
            isSubmitting: false,
            errorMessage: "Metamask isn't installed, Please install Metamask",
          },
        });
      }
    } catch (error) {
      this.setState({
        initialState: {
          ...this.state.initialState,
          isSubmitting: false,
          errorMessage: error.message || error.statusText,
        },
      });
    }
  };

  render() {
    return (
      <section className="section auth">
        <div className="regContLeft">
          <img src={imgIllu} alt="" />
        </div>
        <div className="regCont">
          <p style={{ fontSize: "3vw", fontWeight: "800" }}> Connexion</p>
          <FormErrors formerrors={this.state.errors} />
          {this.state.errorsub}
          <form onSubmit={this.handleSubmit} className="formLog">
            <input
              style={{ width: "10vw" }}
              className="inputInfos"
              type="text"
              id="username"
              aria-describedby="usernameHelp"
              placeholder="Enter username or email"
              value={this.state.username}
              onChange={this.onInputChange}
            />

            <p>
              <input
                className="inputInfos"
                type="password"
                style={{ width: "10vw" }}
                id="password"
                placeholder="Password"
                value={this.state.password}
                onChange={this.onInputChange}
              />
            </p>
            <p>
              <a style={{ color: "white" }} href="/forgotpassword">
                Forgot password ?
              </a>
            </p>

            <button className="buttonsTournament is-success">Login</button>
          </form>

          <p style={{ fontSize: "1rem", fontWeight: "550", marginTop: "5rem" }}>
            {" "}
            OU
          </p>

          <div className="login-container">
            <div className="card">
              <div className="container">
                <form onSubmit={this.handleFormSubmitWeb3}>
                  {this.state.initialState.errorMessage && (
                    <span className="form-error">
                      {this.state.initialState.errorMessage}
                    </span>
                  )}
                  {this.state.initialState.isMetamaskInstalled ? (
                    <button
                      style={{
                        backgroundColor: this.state.initialState.errorMessage
                          ? "#808080"
                          : "#FFFFFF",
                      }}
                    >
                      {this.state.initialState.isSubmitting ? (
                        <img
                          className="spinner"
                          src={logo}
                          alt="loading icon"
                        />
                      ) : (
                        <>
                          <img className="logoWeb3" src={metamask} alt="logo metamask"/>
                        </>
                      )}
                    </button>
                  ) : (
                    <button type="button">Install Metamask</button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default LogIn;
