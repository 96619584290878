import React, { Component } from "react";
import FormErrors from "../FormErrors";
import Validate from "../../utils/FormValidation";
import { Auth } from "aws-amplify";

class ForgotPasswordVerification extends Component {
  state = {
    verificationcode: "",
    email: "",
    newpassword: "",
    errors: {
      cognito: null,
      blankfield: false,
    },
  };

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
      },
    });
  };

  passwordVerificationHandler = async (event) => {
    event.preventDefault();

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error },
      });
    }

    // AWS Cognito integration here
    try {
      await Auth.forgotPasswordSubmit(
        this.state.email,
        this.state.verificationcode,
        this.state.newpassword
      );
      this.props.history.push("/changepasswordconfirmation");
    } catch (error) {
      let err = null;
      !error.message ? (err = { message: error }) : (err = null);
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: err,
        },
      });
      var tt = error.code;
      // alert(JSON.stringify(error));
      if (tt === "InvalidParameterException") {
        alert(
          "Le mot de passe doit faire au moins 8 caractères, Contenir une majuscule, une minuscule, un chiffre et un caractère spécial."
        );
      }

      if (tt === "InvalidPasswordException") {
        alert(
          "Le mot de passe doit faire au moins 8 caractères, Contenir une majuscule, une minuscule, un chiffre et un caractère spécial."
        );
      }
    }
  };

  onInputChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  render() {
    return (
      <section className="section auth">
        <div className="contForgot container">
          <h1>Définir un nouveau mot de passe</h1>
          <p>
            Entrez le code de vérification reçu par mail et re-définissez votre
            mot de passe.
            <br />
            Il doit faire au moins 8 caractères, Contenir une majuscule, une
            minuscule, un chiffre et un caractère spécial.
          </p>
          <br />
          <br />
          <FormErrors formerrors={this.state.errors} />

          <form onSubmit={this.passwordVerificationHandler}>
            <div className="field">
              <p className="control">
                <input
                  type="text"
                  className="inputInfos"
                  id="verificationcode"
                  aria-describedby="verificationCodeHelp"
                  placeholder="Enter verification code"
                  value={this.state.verificationcode}
                  onChange={this.onInputChange}
                />
              </p>
            </div>
            <div className="field">
              <p className="control has-icons-left">
                <input
                  className="inputInfos"
                  type="email"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  value={this.state.email}
                  onChange={this.onInputChange}
                />
              </p>
            </div>
            <div className="field">
              <p className="control has-icons-left">
                <input
                  type="password"
                  className="inputInfos"
                  id="newpassword"
                  placeholder="New password"
                  value={this.state.newpassword}
                  onChange={this.onInputChange}
                />
              </p>
            </div>
            <div className="field">
              <p className="inputInfos">
                <button className="buttonsTournament is-success">Submit</button>
              </p>
            </div>
          </form>
        </div>
      </section>
    );
  }
}

export default ForgotPasswordVerification;
