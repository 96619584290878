import React, { Component } from "react";
import { createChart, CrosshairMode } from "lightweight-charts";

class TradingViewWidget extends Component {


  state = {
    resultats: 0,
    startTime: Math.floor(Date.now() / 1000),
    endTime: Math.floor(Date.now() / 1000) - 600,
    chart: 0,
    lineSeries: 0,
  };

  static defaultProps = {
    containerId: "lightweight_chart_container",
  };

  chart = null;

  async setDataFTX() {
    try {
      const chart = createChart("lightweight_chart_container", {
        width: this.props.width,
        height: this.props.height,
        layout: {
          backgroundColor: '#00000000',
          textColor: '#7AFFD7',
          fontSize: 12,
          fontFamily: 'Calibri',
        },
        grid: {
          vertLines: {
            visible: false,
          },
          horzLines: {
            visible: false,
          },
        },
        crosshair: {
          mode: CrosshairMode.Normal,
        },
        priceScale: {
          borderColor: '#7AFFD7',
          position: 'left',
         },
        timeScale: {
          barSpacing: 3,
          borderVisible: true,
          borderColor: "#7AFFD7",
          visible: true,
          timeVisible: true,
          secondsVisible: true,
      },
      });

      this.chart = chart;

    
      const lineSeries = chart.addLineSeries();
      lineSeries.setData(this.props.data);
      lineSeries.applyOptions({
          lineWidth: 1,
        });
      

      if(this.props.markers !== [])
      {
        var markers = [];
        var marker = {
          time: 0,
          color: '',
          shape: '',
          position: 'inBar',
          size: 3,
          id: "id"
        }

        for (var i = 0; i < this.props.markers.length; i++)
        {
          var color = "";
          var shape = "";
          var time = Math.round(this.props.markers[i].time/1000);
          var position = "";
          var text = "";
          var size = 3;

          if(this.props.markers[i].ordre === "Long")
          {
            color = "#5898f0";
            shape = "arrowUp";
            position  ="aboveBar";
          }
          if(this.props.markers[i].ordre === "Short")
          {
            color = "orange";
            shape = "arrowDown";
            position  ="aboveBar";
          }
          if(this.props.markers[i].ordre === "Liquidation")
          {
            color = "#c060d6";
            shape = "circle";
            position  ="aboveBar";
          }
          if(this.props.markers[i].ordre === "Close")
          {
            shape = "circle";
            position  ="belowBar";
            text = Math.round(this.props.markers[i].comment).toString()+"$";
            size = 1;
            for (var j = i; j >= 0; j--) {
              if(this.props.markers[j].ordre === "Long") {
                color = "orange";
                break;
              }
              if(this.props.markers[j].ordre === "Short") {
                color = "#5898f0";
                break;
              }
            }
          }

          marker = {
            time: time,
            color: color,
            shape: shape,
            position: position,
            size: size,
            id: "id"+i,
            text: text,
          }

          markers.push(marker);
        }
        //alert(JSON.stringify(this.props.markers));
        lineSeries.setMarkers(markers);
      }




      chart.timeScale().fitContent();

      //this.candleSeries.update(result);
    } catch (error) {
      console.log(error.message);
    }
  }

  componentDidMount() {
    this.setDataFTX();
  }

  componentWillUnmount() {
    /*
    if (this.chart !== null) {
      this.chart.remove();
      this.chart = null;
    }*/
  }

  render() {
    return (
      <section className="twwidget">
          <div id={"lightweight_chart_container"} className={"LightweightChart"} />
      </section>
    );
  }
}

export default TradingViewWidget;
