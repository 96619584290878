import * as React from "react";
import { TVChartContainer } from "../components/TVChartContainer/index";

class Graph extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
  }
  state = {
    primSec: "",
    tabDrawTW: {
      entry: {
        value: 0,
        isDraw: false,
      },
      liquid: {
        value: 0,
        isDraw: false,
      },
      closeInf: {
        value: 0,
        isDraw: false,
      },
      closeSup0: {
        value: 0,
        isDraw: false,
      },
      longOrder: {
        isDraw: false,
        value: 0,
      },
      shortOrder: {
        isDraw: false,
        value: 0,
      },
    },
  };


  setSymbol(p) {
    this.child.current.setSymbol(p);
  }

  async sleep(ms) {
    return new Promise((res) => setTimeout(res, ms));
  }

  async createShape(id, price, bet, pseudo, time, gain) {
    if (!this.child.current.isChartReady()) {
      //alert("!Rdy");
      await this.sleep(1000);
      this.createShape(id, price, bet);
      //return;
    }

    //l'id ingame regroupe toutes les infos ingame, Long Short Close est pour l'affichage de l'histo des positions ingame et vertical pour spectate
    if (id === "ingame") {
      this.child.current.createShape(id, price);
    } else if (id === "Long") {
      this.child.current.createShape(id, price, bet, pseudo, time);
    } else if (id === "Short") {
      this.child.current.createShape(id, price, bet, pseudo, time);
    } else if (id === "Close") {
      this.child.current.createShape(id, price, bet, pseudo, time, gain);
    } else if (id === "Vertical") {
      this.child.current.createShape(id, price);
    }
  }

  removeShape(id, value) {
    if (id === "Vertical") {
      this.child.current.removeShape(id);
    } else {
      this.child.current.removeShape(id);
    }
  }


  async handleSpectateTab(id, tab, smooth, gains, pseudos) {
    await this.child.current.handleSpectateTab(id, tab, smooth, gains, pseudos);
  }

  updateSpectateTab(tab, gains, pseudos) {
     this.child.current.updateSpectateTab(tab, gains, pseudos);
  }

  areDataReady() {
     return this.child.current.areDataReady();
  }

  removeAllShapes() {
    this.child.current.removeAllShapes();
  }

  async componentDidMount() {
    try {
      if (this.props.estPrimaire) {
        this.setState({ primSec: "estPrimaire" });
      } else if (this.props.estSecondaire) {
        this.setState({ primSec: "estSecondaire" });
      }
    } catch (error) {
      console.log(error);
    }
  }




  render() {
    return (
      <div className={this.state.primSec}>
        <TVChartContainer
          pseudo={this.props.pseudo}
          symbol={this.props.param.toLowerCase()}
          ref={this.child}
          width={this.props.width}
          height={this.props.height}
          estSecondaire={this.props.estSecondaire}
          externalTradingViewCall={this.props.externalTradingViewCall}
          isSpectate={this.props.isSpectate}
          valueLevierMaxiPNL={this.props.valueLevierMaxiPNL}
          valueLevierMiniPNL={this.props.valueLevierMiniPNL}
        />
      </div>
    );
  }
}

export default Graph;
