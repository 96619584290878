import React, { Component } from "react";
import { apiURL } from "../utils/const";
import { instanceAWS } from "../api/api_instance";
import {
  truncateEthAddress,
  truncateRegex,
} from "../utils/function";

class Results extends Component {

  state = {
    games: [],
    gamesDisplayed: [],
    results: [],
    altArray: [],
  };

componentDidMount() {
    try {
        var myHeaders = new Headers();
	    myHeaders.append("Content-Type", "application/json");
	    var raw;
	    

	    var requestOptions = {
	      method: "GET",
	      headers: myHeaders,
	      redirect: "follow",
	    };

	    fetch(apiURL + "/dev/gethourgames", requestOptions)
	      .then((response) => response.text())
	      .then((result) => {
	        this.setState({games: JSON.parse(result).data})
	      })

	      .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  }

  isAdmin(ps) {
    if (ps === "luchap" || ps === "bazoukiwi") return true;
    else return false;
  }

  handleChange = (value) => (event) => {
    try {
    	var gamesDisplayed = this.state.gamesDisplayed;

    	if(!gamesDisplayed.includes(value.game)) {
    		gamesDisplayed.push(value.game)
    	}
    	else {
    		gamesDisplayed.splice(gamesDisplayed.indexOf(value.game), 1);
    	}

    	//document.getElementById(value.id).checked = !document.getElementById(value.id).checked;

    	this.setState({gamesDisplayed: gamesDisplayed})

    	var myHeaders = new Headers();
	    myHeaders.append("Content-Type", "application/json");
	    //alert(JSON.stringify(gamesDisplayed))
	    var raw = JSON.stringify({ gamesDisplayed: gamesDisplayed });
	    

	    var requestOptions = {
	      method: "POST",
	      headers: myHeaders,
	      redirect: "follow",
	      body: raw,
	    };

	    fetch(apiURL + "/dev/getchampionshipresults", requestOptions)
	      .then((response) => response.text())
	      .then((result) => {
	        this.setState({results: JSON.parse(result).data})

	        let alts = [];
			  	for (var i = 0; i < JSON.parse(result).data.length; i++) {
			  		alts.push(JSON.parse(result).data[i].pseudo)
			  	}
			    this.getAlt(alts);
	      })

	      .catch((error) => console.log("error", error));

    } catch(err) {

    }
  }

  //Displayer games
  epochToDate() {

	var array = [];

	for (var i = 0; i < this.state.games.length; i++) {
		//alert(this.state.games[i])
	  	let date = new Date(Number(this.state.games[i])); 
		let stg = date.toLocaleString('en-GB', { hour12:false } );

		array.push(
			<div> 
				{stg} 
				<input
			      type="checkbox"
			      onChange={this.handleChange({game: this.state.games[i], id: "game"+i })}
			      id={"game"+i}/>

			</div>)
	}

	return array;

  }
//Get alts
  getAlt(usernameArray) {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({ USERNAME: usernameArray, ISARRAY: true });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };


      fetch(apiURL + "/dev/getalt", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          this.setState({ altArray: JSON.parse(result).ALTS });
        });
    } catch (error) {
      console.log(error);
    }
  }
  //Get alt of user
  getAltArray(user) {
    var tab = this.state.altArray

    for (var i = 0; i < tab.length; i++) {
      if(tab[i].user === user) {
        return tab[i].alt
      }
    }
    return "";
  }

  //Displayer results:
  displayResults() {
  	var array = [];
    
  	let arrayGamesTmp = [];

  	if(this.state.results.length === 0) {
  		return;
  	}

  	for (var a = 0; a < this.state.results[0].games.length; a++) {
  		arrayGamesTmp.push(<span className="scoreLegend"> {"P"+a} </span>)
  	}

  	array.push(<div className="resultsLigne"> 
		<span className="resultsPseudo"> Pseudo </span> 
		<span className="resultsFinal">  Score </span>
		<span className="resultsGames"> {arrayGamesTmp} </span> 
	</div>);

	array.push(<br/>);

  	for (var i = 0; i < this.state.results.length; i++) {
  		let arrayGames = [];

  		for (var j = 0; j < this.state.results[i].games.length; j++) {
  			if(this.state.results[i].games[j].saute === false) {
  				arrayGames.push(<span className="scoreBarre"> {this.state.results[i].games[j].score} </span>)
  			}
  			if(this.state.results[i].games[j].saute === true) {
  				arrayGames.push(<span className="scoreValid"> {this.state.results[i].games[j].score} </span>)
  			}
  		}

  		array.push(<div className="resultsLigne"> 
  		    <span style={{display: 'none'}}> 
	          	<img
	            className="resultsMiniAvatar"
	            src={".././images/rewards/emblemes/"+this.state.results[i].portrait+".png"}
	            alt="embleme"
	          	/>
	        </span>

	  			<span className="resultsPseudo"> { this.getAltArray(this.state.results[i].pseudo) === ""
                    ? (this.state.results[i].pseudo.match(truncateRegex)
                      ? truncateEthAddress(this.state.results[i].pseudo)
                      : this.state.results[i].pseudo )
                    : this.getAltArray(this.state.results[i].pseudo)
                  }
	  			</span> 
	  			<span className="resultsFinal">  {this.state.results[i].final} </span>
	  			<span className="resultsGames"> {arrayGames} </span> 

  			</div>);
  	}
  	
  	return <div> {array} </div>
  }


  render() {
    return (
	<section className="ResultsSection">
		{
	    	this.isAdmin(this.props.auth.user.username) ?
	    	<div className="ResultsDiv">
	    		<div className="ResultsDivChecker">
	    			<h2 style={{textAlign: "center", color: "white"}}> 1 hour games </h2>
	    			{this.epochToDate()}
	        	</div>
	        	<div className="ResultsDivMain">
	    			{this.displayResults()}
	        	</div>
	        </div>
	        : "Unauthorised"
	    }
    </section>
    );
  }
}

export default Results;