import React, { Component } from "react";
import FormErrors from "../FormErrors";
import Validate from "../../utils/FormValidation";
import { Auth } from "aws-amplify";
import imgIllu from ".././images/illustration.png";
import metamask from "../images/logo/metamask.svg";

class Register extends Component {
  /////////////////////////////////////////
  ////            Register.js           ////
  //// Fichier denregistrement cognito ////
  /////////////////////////////////////////

  state = {
    username: "",
    email: "",
    password: "",
    confirmpassword: "",
    errors: {
      cognito: null,
      blankfield: false,
      passwordmatch: false,
    },
  };

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
        passwordmatch: false,
      },
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ erreurperso: "" });
    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error },
      });
    } else {
      // AWS Cognito integration here
      const { username, email, password } = this.state;
      try {
        await Auth.signUp({
          username,
          password,
          attributes: {
            email: email,
          },
        });

        this.props.history.push("/welcome");
      } catch (error) {
        let err = null;
        !error.message ? (err = { message: error }) : (err = null);
        this.setState({
          errors: {
            ...this.state.errors,
            cognito: err,
          },
        });
        var tt = error.code;
        // alert(JSON.stringify(error));
        if (tt === "InvalidParameterException") {
          //alert("mdp doit contenir mes couilles");
          this.setState({
            erreurperso:
              "Le mot de passe doit faire au moins 8 caractères, Contenir une majuscule, une minuscule, un chiffre et un caractère spécial.",
          });
        }

        if (tt === "UsernameExistsException") {
          //alert("User exist");
          this.setState({
            erreurperso: "Le nom d'utilisateur choisi existe déjà !",
          });
        }

        if (tt === "InvalidPasswordException") {
          //alert("mdp doit contenir mes couilles");
          this.setState({
            erreurperso:
              "Le mot de passe doit faire au moins 8 caractères, Contenir une majuscule, une minuscule, un chiffre et un caractère spécial.",
          });
        }

        if (tt === "UserLambdaValidationException") {
          //alert("mdp doit contenir mes couilles");
          this.setState({ erreurperso: "Cet email est déjà utilisé." });
        }
      }
    }
  };

  onInputChange = (event) => {
    document.getElementById("username").value = document
      .getElementById("username")
      .value.toLowerCase();
    this.setState({
      [event.target.id]: event.target.value,
    });
    document.getElementById(event.target.id).classList.remove("is-danger");

    // alert(document.getElementById("username").value.toLowerCase());
  };

  render() {
    return (
      <section className="section auth">
        <div className="regContLeft">
          <img src={imgIllu} alt="" />
        </div>
        <div className="regCont">
          <p style={{ fontSize: "3vw", fontWeight: "800" }}>JOIN THE GAME !</p>
          <p style={{ fontSize: "2vw" }}>Affrontez les meilleurs traders</p>
          <FormErrors formerrors={this.state.errors} />
          {this.state.erreurperso}
          <form className="formRegis" onSubmit={this.handleSubmit}>
            <div className="field">
              <p className="control">
                <input
                  className="inputInfos"
                  type="text"
                  id="username"
                  aria-describedby="userNameHelp"
                  placeholder="Enter username"
                  value={this.state.username}
                  onChange={this.onInputChange}
                />
              </p>
            </div>
            <div className="field">
              <p className="control has-icons-left has-icons-right">
                <input
                  className="inputInfos"
                  type="email"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  value={this.state.email}
                  onChange={this.onInputChange}
                />
              </p>
            </div>
            <div className="field">
              <p className="control has-icons-left">
                <input
                  className="inputInfos"
                  type="password"
                  id="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.onInputChange}
                />
              </p>
            </div>
            <div className="field">
              <p className="control has-icons-left">
                <input
                  className="inputInfos"
                  type="password"
                  id="confirmpassword"
                  placeholder="Confirm password"
                  value={this.state.confirmpassword}
                  onChange={this.onInputChange}
                />
              </p>
            </div>
            <div className="field">
              <p className="control">
                <button className="buttonsTournament is-success">
                  Register
                </button>
              </p>
            </div>
          </form>

          <p
            style={{
              marginTop: "2rem",
              marginBottom: "2rem",
              fontSize: "1.2rem",
              fontWeight: "800",
            }}
          >
            Or go directly to login with metamask
          </p>

          <div className="login-container">
            <div className="card">
              <div className="container">
                <button className="buttonMetamask">
                  <a href="/login">
                    <img className="logoWeb3" src={metamask} alt="metamask logo" />
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Register;
