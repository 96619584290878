import React, { Component } from "react";
import { createChart, CrosshairMode } from "lightweight-charts";

class TradingViewWidgetMini extends Component {



  state = {
    resultats: 0,
    startTime: Math.floor(Date.now() / 1000),
    endTime: Math.floor(Date.now() / 1000) - 600,
    chart: 0,
    lineSeries: 0,
  };

  chart = null;

  async setDatas() {
    try {
    	let ind = "lightweight_chart_container_mini"+this.props.indice;
      const chart = createChart(ind, {
        width: this.props.width,
        height: this.props.height,
        layout: {
          backgroundColor: '#00000000',
          textColor: '#7AFFD7',
          fontSize: 12,
          fontFamily: 'Calibri',
        },
        grid: {
          vertLines: {
            visible: false,
          },
          horzLines: {
            visible: false,
          },
        },
        crosshair: {
          mode: CrosshairMode.Normal,
        },
        priceScale: {
          borderColor: '#7AFFD7',
          position: 'left',
         },
        timeScale: {
          barSpacing: 3,
          borderVisible: true,
          borderColor: "#7AFFD7",
          visible: true,
          timeVisible: true,
          secondsVisible: true,
      },
      });

      this.chart = chart;

    
      const lineSeries = chart.addLineSeries();
      lineSeries.setData(this.props.data);

      lineSeries.applyOptions({
          lineWidth: 1,
        });
      

    

      chart.timeScale().fitContent();

    } catch (error) {
      console.log(error.message);
    }
  }

  componentDidMount() {
    this.setDatas();
  }


  render() {
    return (
      <section className="twwidget">
          <div id={"lightweight_chart_container_mini"+this.props.indice} className={"LightweightChart"} />
      </section>
    );
  }
}

export default TradingViewWidgetMini;
