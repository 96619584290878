import React, { Component } from "react";
import { Auth } from "aws-amplify";
import logo from "./images/logoLast.png";
import profil from "./images/profil.png";
import { clearWeb3LocalStorage } from "../utils/function";

export default class Navbar extends Component {
  ////////////////////////////////
  ////          Navbar.js      ////
  //// Fichier de la top bar   ////
  /////////////////////////////////   <img src={logo}  className="navImg"/>

  handleLogOut = async (event) => {
    event.preventDefault();
    try {
      Auth.signOut();
      clearWeb3LocalStorage();
      this.props.auth.setAuthStatus(false);
      this.props.auth.setUser(null);
      this.props.history.push("/");
    } catch (error) {
      console.log(error.message);
    }
  };
  render() {
    return (
      <nav id="navbar" className="navbar" role="navigation">
        <meta name="viewport" content="width=device-width, user-scalable=yes" />

        <div className="navbar-menu">
          <div className="navbar-item">
            <span>
              <a href="/home">
                <img src={logo} className="navImg" alt="" />
              </a>
              {!this.props.auth.isAuthenticated && (
                <>
                  <span className="navbutlogcont">
                    <a href="/register" className="navbutlog">
                      Register
                    </a>
                    <a href="/login" className="navbutlog">
                      Login
                    </a>
                  </span>
                </>
              )}
              {this.props.auth.isAuthenticated && this.props.auth.user && (
                <>
                  <a href="/home" className="navbut navfirst">
                    Home
                  </a>
                  <a href="/tournament" className="navbut">
                    Tournaments
                  </a>
                  <a href="/ranking" className="navbut">
                    Ranking
                  </a>
                  {/*
                  <a href="/Subscribe" className="navbut">
                    Pass Challenger
                  </a>
                  */}
                  <span className="traitGrisBandeau">&nbsp;</span>
                  <a href="/profile" className="navbut navlast">
                    <img src={profil} className="navIcons" alt="" />
                  </a>
                </>
              )}
            </span>
          </div>
        </div>
      </nav>
    );
  }
}
