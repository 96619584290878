import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { apiURL } from "../../utils/const";
import { ipBackend } from "../../utils/const";
import { ethers } from "ethers";
import loading from "../images/loading.svg";

import {
  getValFromCitation,
  truncateEthAddress,
  truncateRegex,
} from "../../utils/function";

import abiNFT from "../web3/Nft.json";

class Profile extends Component {
  /////////////////////////////////////
  ////          Profile.js         ////
  //// Fichier de visu du profil   ////
  /////////////////////////////////////

  state = {
    username: 0,
    mail: "***********",
    cr: 0,
    solde: 0,
    lvl: 0,
    nextreward: 0,
    nexup: 0,
    xp: 0,
    embleme: 0,
    bordure: 0,
    cadre: 0,
    citation: 0,
    circulation: [],
    stat: 0,
    strat: 0,
    selecembleme: 0,
    seleccadre: 0,
    seleccitation: 0,
    selecbordure: 0,
    selecstat: 0,
    selecstrat: 0,
    selectedCadre: "",
    selectedEmbleme: "",
    selectedBordure: "",
    stat1: 0,
    stat2: 0,
    stat3: 0,
    stat4: 0,
    stat5: 0,
    stat6: 0,
    stat7: 0,
    stat8: 0,
    stat9: 0,
    stat10: 0,
    stat11: 0,
    stat12: 0,
    ratioxp: 0,
    joueurligue: "",
    keys: [],
    account: "",
    nftMetadata: [],
    isLoading: false,
    getApprove: false,
    transaction: [],
    contractAddressNFT: "0x9a3316cb30f3934Ef894Da4ec7ddB564985ef067",
    addressOwnerMint: "0x9201b3176A06e9c20Cc94F5A900ae5380e0914a1",
    addressOwnerBurn: "0x2111363bF935115FA9f149E07d055F1c40dcE365",
    isRefered: false,
    needsAlt: false,
    alt : "",
  };

  async componentDidMount() {
    try {
      if (this.props.auth.isAuthenticated) {
        this.getCirculation();
        this.getCr();
        this.getRewards();
        this.getStats();
        this.setState({ username: this.props.auth.user.username });
        this.getBSC();
        await this.checkConnected();
        await this.getTransaction();
        await this.fetchNft();
        await this.getAlt(false);
      } else {
        window.location.href = "/";
      }
    } catch (error) {
      //console.log(error);
    }
  }

  async connectWallet() {
    try {
      const { ethereum } = window;

      if (!ethereum) {
        alert("Tu as besoin de Metamask ou d'un wallet pour te connecter !");
        return;
      }

      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });

      this.setState({
        account: accounts[0],
      });
    } catch (error) {
      console.log(error);
    }
  }

  async checkConnected() {
    const { ethereum } = window;
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    this.setState({
      account: accounts[0],
    });
  }

  async getNftId(nftName) {
    return nftName.match("[0-9].*")[0];
  }

  async getField(nftType) {
    if (nftType.includes("background")) return "0" + nftType.match("[0-9].*")[0];
    if (nftType.includes("avatar")) return "1" + nftType.match("[0-9].*")[0];
    if (nftType.includes("title")) return "2" + nftType.match("[0-9].*")[0];
    if (nftType.includes("border")) return "3" + nftType.match("[0-9].*")[0];
    if (nftType.includes("stat")) return "4" + nftType.match("[0-9].*")[0];
    if (nftType.includes("key")) return "5" + nftType.match("[0-9].*")[0];
  }

  async transferMatic(itemName, mint, idNFT) {
    try {
      if (window.ethereum) {
        this.setState({ isLoading: true });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const toAddress = mint
          ? this.state.addressOwnerMint
          : this.state.addressOwnerBurn;
        const tx = await signer.sendTransaction({
          to: toAddress,
          value: ethers.utils.parseEther("0.001"),
        });
        let accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const txConfirmation = await tx.wait();
        if (txConfirmation.status === 1) {
          mint
            ? this.removeItemFromBDD(itemName, accounts[0])
            : this.addItemFromBDD(itemName, idNFT, accounts[0]);
        }
      }
    } catch (err) {
      console.log(err);
      this.setState({ isLoading: false });
    }
  }

  async removeItemFromBDD(itemName, address) {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        PSEUDO: this.props.auth.user.username,
        ITEM: itemName,
        ADDRESS: address,
        mint: true,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      await fetch(apiURL + "/dev/sendToQueue", requestOptions)
        .then((response) => response.text())
        .catch((error) => console.log("error", error));

      await this.getRewards();
      await this.fetchNft();
      await this.getTransaction();

      this.setState({ isLoading: false });
    } catch (error) {
      console.log(error.message);
    }
  }
  async addItemFromBDD(itemName, idNFT, address) {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        PSEUDO: this.props.auth.user.username,
        ITEM: itemName,
        IDNFT: String(idNFT),
        ADDRESS: address,
        mint: false,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      await fetch(apiURL + "/dev/sendToQueue", requestOptions)
        .then((response) => response.text())
        .catch((error) => console.log("error", error));

      await this.getRewards();
      await this.fetchNft();
      await this.getTransaction();
    } catch (error) {
      console.log(error.message);
    }
  }

  async approveForAll() {
    try {
      if (window.ethereum) {
        this.setState({ isLoading: true });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contractNFT = new ethers.Contract(
          String(this.state.contractAddressNFT),
          abiNFT.abi,
          signer
        );
        const ApproveAllNFT = await contractNFT.setApprovalForAll(
          this.state.addressOwnerBurn,
          true
        );
        await ApproveAllNFT.wait();
        await this.getApproved();
        this.setState({ isLoading: false });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async fetchNft() {
    try {
      if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contractNFT = new ethers.Contract(
          "0x9a3316cb30f3934Ef894Da4ec7ddB564985ef067",
          abiNFT.abi,
          signer
        );
        //let emptyNFt = [];
        const idOwned = await contractNFT.getNFTIds(this.state.account);
        const allNFTOwned = await Promise.all(
          idOwned.map(async (id, index) => {
            const uri = await contractNFT.tokenURI(id);
            //console.log("uri:", uri);
            const response = await fetch(uri);
            //console.log("response:", response);
            const metadata = await response.json();
            //console.log("metadata:", metadata);
            metadata.id = id;
            return metadata;
          })
        );

        this.setState({ nftMetadata: allNFTOwned });
        this.setState({ isLoading: false });
      }
    } catch (err) {
      //console.log(err);
    }
  }

  async getApproved() {
    try {
      if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contractNFT = new ethers.Contract(
          this.state.contractAddressNFT,
          abiNFT.abi,
          signer
        );
        const idOwned = await contractNFT.isApprovedForAll(
          this.state.account,
          this.state.addressOwnerBurn
        );

        this.setState({ getApprove: idOwned });
        this.setState({ isLoading: false });
      }
    } catch (err) {
      console.log(err);
    }
  }

  handleLogOut = async (event) => {
    event.preventDefault();
    try {
      Auth.signOut();
      this.props.auth.setAuthStatus(false);
      this.props.auth.setUser(null);
      this.props.history.push("/");
      localStorage.clear();
    } catch (error) {
      console.log(error.message);
    }
  };

  showMail = (event) => {
    try {
      this.setState({ mail: this.props.auth.user.attributes.email });
      document.getElementById("showMail").style.display = "none";
    } catch (error) {
      console.log(error);
    }
  };

  selecPerso = (value) => (event) => {
    event.preventDefault();
    try {
      const collection = document.getElementsByClassName("rightPanelSelector");

      this.getRewards();

      if (value === 0) {
        document.getElementById("rightFond").style.display = "none";
        document.getElementById("rightCitation").style.display = "none";
        document.getElementById("rightStat").style.display = "none";
        document.getElementById("rightAvatar").style.display = "block";
        document.getElementById("rightBordure").style.display = "none";
        document.getElementById("rightKeys").style.display = "none";
        document.getElementById("rightNft").style.display = "none";
        document.getElementById("rightTransaction").style.display = "none";
        this.fetchNft();
        this.getTransaction();

        collection[0].style.color = "white";
        collection[1].style.color = "grey";
        collection[2].style.color = "grey";
        collection[3].style.color = "grey";
        collection[4].style.color = "grey";
        collection[5].style.color = "grey";
        collection[6].style.color = "grey";
        collection[7].style.color = "grey";
      } else if (value === 1) {
        document.getElementById("rightAvatar").style.display = "none";
        document.getElementById("rightCitation").style.display = "none";
        document.getElementById("rightStat").style.display = "none";
        document.getElementById("rightFond").style.display = "block";
        document.getElementById("rightKeys").style.display = "none";
        document.getElementById("rightNft").style.display = "none";
        document.getElementById("rightTransaction").style.display = "none";
        document.getElementById("rightBordure").style.display = "none";
        this.fetchNft();
        this.getApproved();
        this.getTransaction();
        collection[0].style.color = "grey";
        collection[1].style.color = "white";
        collection[2].style.color = "grey";
        collection[3].style.color = "grey";
        collection[4].style.color = "grey";
        collection[5].style.color = "grey";
        collection[6].style.color = "grey";
        collection[7].style.color = "grey";
      } else if (value === 2) {
        document.getElementById("rightAvatar").style.display = "none";
        document.getElementById("rightFond").style.display = "none";
        document.getElementById("rightCitation").style.display = "none";
        document.getElementById("rightBordure").style.display = "block";
        document.getElementById("rightStat").style.display = "none";
        document.getElementById("rightKeys").style.display = "none";
        document.getElementById("rightNft").style.display = "none";
        document.getElementById("rightTransaction").style.display = "none";
        this.fetchNft();
        this.getApproved();
        this.getTransaction();
        collection[0].style.color = "grey";
        collection[1].style.color = "grey";
        collection[2].style.color = "white";
        collection[3].style.color = "grey";
        collection[4].style.color = "grey";
        collection[5].style.color = "grey";
        collection[6].style.color = "grey";
        collection[7].style.color = "grey";
      } else if (value === 3) {
        document.getElementById("rightAvatar").style.display = "none";
        document.getElementById("rightFond").style.display = "none";
        document.getElementById("rightCitation").style.display = "block";
        document.getElementById("rightStat").style.display = "none";
        document.getElementById("rightKeys").style.display = "none";
        document.getElementById("rightNft").style.display = "none";
        document.getElementById("rightTransaction").style.display = "none";
        document.getElementById("rightBordure").style.display = "none";
        this.fetchNft();
        this.getApproved();
        this.getTransaction();
        collection[0].style.color = "grey";
        collection[1].style.color = "grey";
        collection[2].style.color = "grey";
        collection[3].style.color = "white";
        collection[4].style.color = "grey";
        collection[5].style.color = "grey";
        collection[6].style.color = "grey";
        collection[7].style.color = "grey";
      } else if (value === 4) {
        document.getElementById("rightAvatar").style.display = "none";
        document.getElementById("rightFond").style.display = "none";
        document.getElementById("rightCitation").style.display = "none";
        document.getElementById("rightStat").style.display = "block";
        document.getElementById("rightKeys").style.display = "none";
        document.getElementById("rightNft").style.display = "none";
        document.getElementById("rightTransaction").style.display = "none";
        document.getElementById("rightBordure").style.display = "none";
        this.fetchNft();
        this.getApproved();
        this.getTransaction();
        collection[0].style.color = "grey";
        collection[1].style.color = "grey";
        collection[2].style.color = "grey";
        collection[3].style.color = "grey";
        collection[4].style.color = "white";
        collection[5].style.color = "grey";
        collection[6].style.color = "grey";
        collection[7].style.color = "grey";
      } else if (value === 5) {
        document.getElementById("rightAvatar").style.display = "none";
        document.getElementById("rightFond").style.display = "none";
        document.getElementById("rightBordure").style.display = "none";
        document.getElementById("rightCitation").style.display = "none";
        document.getElementById("rightStat").style.display = "none";
        document.getElementById("rightKeys").style.display = "block";
        document.getElementById("rightNft").style.display = "none";
        document.getElementById("rightTransaction").style.display = "none";

        this.fetchNft();
        this.getApproved();
        this.getTransaction();
        collection[0].style.color = "grey";
        collection[1].style.color = "grey";
        collection[2].style.color = "grey";
        collection[3].style.color = "grey";
        collection[4].style.color = "grey";
        collection[5].style.color = "white";
        collection[6].style.color = "grey";
        collection[7].style.color = "grey";
        collection[7].style.color = "grey";
      } else if (value === 6) {
        document.getElementById("rightAvatar").style.display = "none";
        document.getElementById("rightFond").style.display = "none";
        document.getElementById("rightCitation").style.display = "none";
        document.getElementById("rightStat").style.display = "none";
        document.getElementById("rightKeys").style.display = "none";
        document.getElementById("rightNft").style.display = "block";
        document.getElementById("rightTransaction").style.display = "none";
        document.getElementById("rightBordure").style.display = "none";
        this.fetchNft();
        this.getApproved();
        collection[0].style.color = "grey";
        collection[1].style.color = "grey";
        collection[2].style.color = "grey";
        collection[3].style.color = "grey";
        collection[4].style.color = "grey";
        collection[5].style.color = "grey";
        collection[6].style.color = "white";
        collection[7].style.color = "grey";
      } else if (value === 7) {
        document.getElementById("rightAvatar").style.display = "none";
        document.getElementById("rightFond").style.display = "none";
        document.getElementById("rightCitation").style.display = "none";
        document.getElementById("rightStat").style.display = "none";
        document.getElementById("rightKeys").style.display = "none";
        document.getElementById("rightNft").style.display = "none";
        document.getElementById("rightTransaction").style.display = "block";
        document.getElementById("rightBordure").style.display = "none";
        this.fetchNft();
        this.getApproved();
        this.getTransaction();
        collection[0].style.color = "grey";
        collection[1].style.color = "grey";
        collection[2].style.color = "grey";
        collection[3].style.color = "grey";
        collection[4].style.color = "grey";
        collection[5].style.color = "grey";
        collection[6].style.color = "grey";
        collection[7].style.color = "white";
      }
    } catch (error) {
      console.log(error);
    }
  };

  saveReferal() {
    var referal = document.getElementById("inputReferal").value;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw;
    if (!this.props.auth.user.credentials) {
      raw = JSON.stringify({
        PSEUDO: this.props.auth.user.username,
        JWT: this.props.auth.user.signInUserSession.idToken,
        REFERAL: referal,
        UUID: this.props.auth.user.attributes.sub,
        isWeb3: false,
      });
    } else {
      raw = JSON.stringify({
        PSEUDO: this.props.auth.user.username,
        isWeb3: true,
        REFERAL: referal,
        sessionToken: this.props.auth.user.credentials.sessionToken,
        accessKeyId: this.props.auth.user.credentials.accessKeyId,
        secretKey: this.props.auth.user.credentials.secretKey,
      });
    }

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(apiURL + "/dev/rewardgiver", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        document.getElementById("btReferal").style.display = "none";
      })

      .catch((error) => console.log("error", error));
  }


  savePseudo() {

    if (window.confirm("You only can set your in-game name once. Continue ?") === true) {
    } else {
      return;
    }

    var pseudo = document.getElementById("inputPseudo").value;

    pseudo = pseudo.replaceAll(" ", "");

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw;

    raw = JSON.stringify({
      USERNAME: this.props.auth.user.username,
      ALT: pseudo,
      sessionToken: this.props.auth.user.credentials.sessionToken,
      accessKeyId: this.props.auth.user.credentials.accessKeyId,
      secretKey: this.props.auth.user.credentials.secretKey,
    });
    

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(apiURL + "/dev/setalt", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if(JSON.parse(result).state === "psd") {
          alert("Ce pseudo existe !");
          return;
        }
        document.getElementById("btPseudo").style.display = "none";
      })

      .catch((error) => console.log("error", error));
  }

  getStats() {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({ PSEUDO: this.props.auth.user.username });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(apiURL + "/dev/getstats", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          // alert(result);

          this.setState({ stat1: JSON.parse(result).NBTOURNOIS });
          this.setState({ stat2: JSON.parse(result).MPLACE });
          this.setState({ stat3: JSON.parse(result).MTRADE });
          this.setState({ stat4: JSON.parse(result).MSCORE });
          this.setState({ stat5: JSON.parse(result).WTRADE });
          this.setState({ stat6: JSON.parse(result).NBPODIUM });
          this.setState({ stat7: JSON.parse(result).NBTRADES });
          this.setState({ stat8: JSON.parse(result).NBLIQUIDATIONS });
          this.setState({ stat9: JSON.parse(result).BPLACE });
          this.setState({ stat10: JSON.parse(result).NBVICTOIRE });
          this.setState({ stat11: JSON.parse(result).MPALLIER });
        })

        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error.message);
    }
  }


  getAlt(isArray) {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({ USERNAME: this.props.auth.user.username, ISARRAY: isArray });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(apiURL + "/dev/getalt", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          if(JSON.parse(result).ALT === "") {
            this.setState({ needsAlt: true });
          }else{
            this.setState({ alt: JSON.parse(result).ALT });
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  getRewards() {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({ PSEUDO: this.props.auth.user.username });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(apiURL + "/dev/getrewards", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          var selecembleme = JSON.parse(result).SELECEMBLEME;
          this.setState({
            selecembleme: ".././images/rewards/emblemes/" + selecembleme + ".png",
          });
          this.setState({ selectedEmbleme: JSON.parse(result).SELECEMBLEME });
          this.setState({
            selecbordure:
              ".././images/rewards/bordures/" + JSON.parse(result).SELECBORDURE + ".png",
          });

          this.setState({ selectedBordure: JSON.parse(result).SELECBORDURE });

          this.setState({
            seleccadre: ".././images/rewards/cadres/" + JSON.parse(result).SELECCADRE + ".png",
          });
          this.setState({ selectedCadre: JSON.parse(result).SELECCADRE });

          this.setState({ seleccitation: JSON.parse(result).SELECCITATION });
          this.setState({ selecstat: JSON.parse(result).SELECSTAT }); //'.././images/'+value+'.png'

          var EMBLEME = JSON.parse(result).EMBLEME;
          var CADRE = JSON.parse(result).CADRE;
          var CITATION = JSON.parse(result).CITATION;
          var STAT = JSON.parse(result).STAT;
          var BORDURE = JSON.parse(result).BORDURE;
          var REFERAL = JSON.parse(result).REFERAL;
          var KEYLIST = JSON.parse(result).KEYLIST;

          var tabEmblemes = EMBLEME;
          var tabCadres = CADRE;
          var tabCitations = CITATION;
          var tabStat = STAT;
          var tabBordure = BORDURE;
          var tabReferal = REFERAL;
          var tabKeys = KEYLIST;

          if (tabReferal === undefined) {
            this.setState({ isRefered: false });
          } else {
            this.setState({ isRefered: REFERAL });
          }

          this.setState({ embleme: tabEmblemes });
          this.setState({ cadre: tabCadres });
          this.setState({ bordure: tabBordure });
          this.setState({ citation: tabCitations });
          this.setState({ stat: tabStat });
          this.setState({ keys: tabKeys });
        })

        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error.message);
    }
  }

  async getTransaction() {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({ PSEUDO: this.props.auth.user.username });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(apiURL + "/dev/getTransaction", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          let TRANSACTION = JSON.parse(result).transaction;

          var tabTransaction = TRANSACTION;
          tabTransaction
            ? this.setState({ transaction: tabTransaction })
            : this.setState({ transaction: [{}] });
        });
    } catch (error) {
      console.log(error);
    }
  }

  populateNextReward(lvl) {
    lvl++;
    switch (lvl) {
      case 1:
        this.setState({ nexup: 200 });
        break;
      case 2:
        this.setState({ nexup: 450 });
        break;
      case 3:
        this.setState({ nexup: 700 });
        break;
      case 4:
        this.setState({ nexup: 950 });
        break;
      case 5:
        this.setState({ nexup: 1250 });
        break;
      case 6:
        this.setState({ nexup: 1550 });
        break;
      case 7:
        this.setState({ nexup: 1850 });
        break;
      case 8:
        this.setState({ nexup: 2200 });
        break;
      case 9:
        this.setState({ nexup: 2550 });
        break;
      case 10:
        this.setState({ nexup: 2900 });
        break;
      case 11:
        this.setState({ nexup: 3300 });
        break;
      case 12:
        this.setState({ nexup: 3700 });
        break;
      case 13:
        this.setState({ nexup: 4100 });
        break;
      case 14:
        this.setState({ nexup: 4550 });
        break;
      case 15:
        this.setState({ nexup: 5000 });
        break;
      case 16:
        this.setState({ nexup: 5450 });
        break;
      case 17:
        this.setState({ nexup: 5950 });
        break;
      case 18:
        this.setState({ nexup: 6450 });
        break;
      case 19:
        this.setState({ nexup: 6950 });
        break;
      case 20:
        this.setState({ nexup: 7500 });
        break;
      case 21:
        this.setState({ nexup: 8050 });
        break;
      case 22:
        this.setState({ nexup: 8600 });
        break;
      case 23:
        this.setState({ nexup: 9200 });
        break;
      case 24:
        this.setState({ nexup: 9800 });
        break;
      case 25:
        this.setState({ nexup: 10400 });
        break;
      case 26:
        this.setState({ nexup: 11050 });
        break;
      case 27:
        this.setState({ nexup: 11700 });
        break;
      case 28:
        this.setState({ nexup: 12350 });
        break;
      case 29:
        this.setState({ nexup: 13050 });
        break;
      case 30:
        this.setState({ nexup: 13750 });
        break;
      case 31:
        this.setState({ nexup: 14450 });
        break;
      case 32:
        this.setState({ nexup: 15200 });
        break;  
      case 33:
        this.setState({ nexup: 15950 });
        break;
      case 34:
        this.setState({ nexup: 16700 });
        break;
      case 35:
        this.setState({ nexup: 17500 });
        break;
      case 36:
        this.setState({ nexup: 18300 });
        break;
      case 37:
        this.setState({ nexup: 19100 });
        break;
      case 38:
        this.setState({ nexup: 19950 });
        break;
      case 39:
        this.setState({ nexup: 20800 });
        break;
      case 40:
        this.setState({ nexup: 21650 });
        break;
      case 41:
        this.setState({ nexup: 22550 });
        break;
      case 42:
        this.setState({ nexup: 23450 });
        break;
      case 43:
        this.setState({ nexup: 24350 });
        break;
      case 44:
        this.setState({ nexup: 25300 });
        break; 
      case 45:
        this.setState({ nexup: 26250 });
        break;
      case 46:
        this.setState({ nexup: 27200 });
        break;
      case 47:
        this.setState({ nexup: 28200 });
        break;
      case 48:
        this.setState({ nexup: 29200 });
        break;
      case 49:
        this.setState({ nexup: 30200 });
        break;
      default:
        this.setState({ nextreward: "" });
        this.setState({ nexup: this.state.xp });
        document.getElementById("nextrewcont").style.display = "none";
    }

    var ratio = (this.state.xp * 100) / this.state.nexup;
    ratio = ratio + "%";

    document.getElementById("xpProgressInsinde").style.width = ratio;
  }

  returnNextReward() {
    //'.././images/coffre'+this.state.nextreward+'.png'
    var next = this.state.nextreward.toString();
    var premier = next.split(",");
    // alert(premier[0]);

    if (premier.length === 1) {
      return (
        <img
          className="nextreward"
          src={".././images/coffre" + premier[0] + ".png"}
          alt={premier[0]}
          title={premier[0]}
        />
      );
    } else {
      return (
        <>
          <img
            className="nextreward"
            src={".././images/coffre" + premier[0] + ".png"}
            alt={premier[0]}
            title={premier[0]}
          />
          <img
            className="nextreward"
            src={".././images/coffre" + premier[1] + ".png"}
            alt={premier[2]}
            title={premier[3]}
          />
        </>
      );
    }
  }

  //récupere l'historique et le stocke dans le state
  getCr() {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({ USERNAME: this.props.auth.user.username });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(apiURL + "/dev/getcr", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          var CR = JSON.parse(result).CR;
          var SOLDE = JSON.parse(result).SOLDE;
          var LVL = JSON.parse(result).LVL;
          var XP = JSON.parse(result).XP;

          this.setState({ cr: CR });
          this.setState({ solde: SOLDE });
          this.setState({ lvl: LVL });
          this.setState({ xp: XP });

          this.populateNextReward(LVL);
        })

        .catch((error) => console.log("error", error));

      raw = JSON.stringify({
        USERNAME: this.props.auth.user.username,
        MODE: "SOLO",
      });

      requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(apiURL + "/dev/getranking", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          var ligue = JSON.parse(result).ligueUser;
          if (ligue === "") {
            ligue = "Aspirant";
          }
          this.setState({ joueurligue: ligue });
        })

        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error.message);
    }
  }

  setEmbleme = (value) => (event) => {
    try {
      /*
            var css = '.emblemSelector:hover{ border-bottom-color: lightgreen; } .emblemSelector{ border-bottom-color: grey; }';
            var style = document.createElement('style');

            if (style.styleSheet) {
              style.styleSheet.cssText = css;
            } else {
              style.appendChild(document.createTextNode(css));
            }


            const collectionE = document.getElementsByClassName('emblemSelector');
            for (var i = 0; i < collectionE.length ; i++) {
                
                collectionE[i].style.backgroundColor = '#ffffff00';
            }*/

      //collectionE[9].style.backgroundColor = 'green';
      //document.getElementById("emblemSelector1").style.backgroundColor = 'red';

      document.getElementById("profilez").style.cursor = "wait";



      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw;
      if (!this.props.auth.user.credentials) {
        raw = JSON.stringify({
          PSEUDO: this.props.auth.user.username,
          JWT: this.props.auth.user.signInUserSession.idToken,
          TYPE: "EMBLEME",
          REWARD: value,
          UUID: this.props.auth.user.attributes.sub,
          isWeb3: false,
        });
      } else {
        raw = JSON.stringify({
          PSEUDO: this.props.auth.user.username,
          isWeb3: true,
          TYPE: "EMBLEME",
          REWARD: value,
          sessionToken: this.props.auth.user.credentials.sessionToken,
          accessKeyId: this.props.auth.user.credentials.accessKeyId,
          secretKey: this.props.auth.user.credentials.secretKey,
        });
      }

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(apiURL + "/dev/setselecreward", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          this.setState({ selecembleme: ".././images/rewards/emblemes/" + value + ".png" });
          this.setState({ selectedEmbleme: value });

          document.getElementById("profilez").style.cursor = "default";
        })

        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  setCadre = (value) => (event) => {
    try {

      document.getElementById("profilez").style.cursor = "wait";

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw;
      if (!this.props.auth.user.credentials) {
        raw = JSON.stringify({
          PSEUDO: this.props.auth.user.username,
          JWT: this.props.auth.user.signInUserSession.idToken,
          TYPE: "CADRE",
          REWARD: value,
          UUID: this.props.auth.user.attributes.sub,
          isWeb3: false,
        });
      } else {
        raw = JSON.stringify({
          PSEUDO: this.props.auth.user.username,
          isWeb3: true,
          TYPE: "CADRE",
          REWARD: value,
          sessionToken: this.props.auth.user.credentials.sessionToken,
          accessKeyId: this.props.auth.user.credentials.accessKeyId,
          secretKey: this.props.auth.user.credentials.secretKey,
        });
      }

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(apiURL + "/dev/setselecreward", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          this.setState({ seleccadre: ".././images/rewards/cadres/" + value + ".png" });
          this.setState({ selectedCadre: value });

          document.getElementById("profilez").style.cursor = "default";
        })

        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  setCitation = (value) => (event) => {
    try {

      document.getElementById("profilez").style.cursor = "wait";

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw;
      if (!this.props.auth.user.credentials) {
        raw = JSON.stringify({
          PSEUDO: this.props.auth.user.username,
          JWT: this.props.auth.user.signInUserSession.idToken,
          TYPE: "CITATION",
          REWARD: value,
          UUID: this.props.auth.user.attributes.sub,
          isWeb3: false,
        });
      } else {
        raw = JSON.stringify({
          PSEUDO: this.props.auth.user.username,
          isWeb3: true,
          TYPE: "CITATION",
          REWARD: value,
          sessionToken: this.props.auth.user.credentials.sessionToken,
          accessKeyId: this.props.auth.user.credentials.accessKeyId,
          secretKey: this.props.auth.user.credentials.secretKey,
        });
      }

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(apiURL + "/dev/setselecreward", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          this.setState({ seleccitation: value });

          document.getElementById("profilez").style.cursor = "default";
        })

        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  setStat = (value) => (event) => {
    try {

      document.getElementById("profilez").style.cursor = "wait";


      //alert(value);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw;
      if (!this.props.auth.user.credentials) {
        raw = JSON.stringify({
          PSEUDO: this.props.auth.user.username,
          JWT: this.props.auth.user.signInUserSession.idToken,
          TYPE: "STAT",
          REWARD: value,
          UUID: this.props.auth.user.attributes.sub,
          isWeb3: false,
        });
      } else {
        raw = JSON.stringify({
          PSEUDO: this.props.auth.user.username,
          isWeb3: true,
          TYPE: "STAT",
          REWARD: value,
          sessionToken: this.props.auth.user.credentials.sessionToken,
          accessKeyId: this.props.auth.user.credentials.accessKeyId,
          secretKey: this.props.auth.user.credentials.secretKey,
        });
      }

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(apiURL + "/dev/setselecreward", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          this.setState({ selecstat: value });

          document.getElementById("profilez").style.cursor = "default";
        })

        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  setBordure = (value) => (event) => {
    try {

      document.getElementById("profilez").style.cursor = "wait";


      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw;
      if (!this.props.auth.user.credentials) {
        raw = JSON.stringify({
          PSEUDO: this.props.auth.user.username,
          JWT: this.props.auth.user.signInUserSession.idToken,
          TYPE: "BORDURE",
          REWARD: value,
          UUID: this.props.auth.user.attributes.sub,
          isWeb3: false,
        });
      } else {
        raw = JSON.stringify({
          PSEUDO: this.props.auth.user.username,
          isWeb3: true,
          TYPE: "BORDURE",
          REWARD: value,
          sessionToken: this.props.auth.user.credentials.sessionToken,
          accessKeyId: this.props.auth.user.credentials.accessKeyId,
          secretKey: this.props.auth.user.credentials.secretKey,
        });
      }

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(apiURL + "/dev/setselecreward", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          this.setState({ selecbordure: ".././images/rewards/bordures/" + value + ".png" });
          this.setState({ selectedBordure: value });

          document.getElementById("profilez").style.cursor = "default";
        })

        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  setStrat = (value) => (event) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw;
      if (!this.props.auth.user.credentials) {
        raw = JSON.stringify({
          PSEUDO: this.props.auth.user.username,
          JWT: this.props.auth.user.signInUserSession.idToken,
          TYPE: "STRAT",
          REWARD: value,
          UUID: this.props.auth.user.attributes.sub,
          isWeb3: false,
        });
      } else {
        raw = JSON.stringify({
          PSEUDO: this.props.auth.user.username,
          isWeb3: true,
          TYPE: "STRAT",
          REWARD: value,
          sessionToken: this.props.auth.user.credentials.sessionToken,
          accessKeyId: this.props.auth.user.credentials.accessKeyId,
          secretKey: this.props.auth.user.credentials.secretKey,
        });
      }

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(apiURL + "/dev/setselecreward", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          this.setState({ selecstrat: value });
        })

        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  displayCitation(citation, needMargin) {
    var valRetour = getValFromCitation(citation);

    if(needMargin) {
     return <span style={{float: 'left', width: '55%'}}> {valRetour} </span>;
    }
    else {
      return <span> {valRetour} </span>;
    }
  }
         
  displayStat(stat, needMargin) {
    //stat displayer
    var valRetour = "";

    if (stat === "stat1") {
      valRetour = "Number of tournaments\n" + this.state.stat1;
    } else if (stat === "stat2") {
      valRetour = "Best place\n" + this.state.stat2;
    } else if (stat === "stat3") {
      valRetour = "Best trade\n" + this.state.stat3;
    } else if (stat === "stat4") {
      valRetour = "Best score\n" + this.state.stat4;
    } else if (stat === "stat5") {
      valRetour = "Worst trade\n" + this.state.stat5;
    } else if (stat === "stat6") {
      valRetour = "Number of podiums\n" + this.state.stat6;
    } else if (stat === "stat7") {
      valRetour = "Number of trades\n" + this.state.stat7;
    } else if (stat === "stat8") {
      valRetour = "Number of liquidations\n" + this.state.stat8;
    } else if (stat === "stat9") {
      valRetour = "Best leaderboard place\n" + this.state.stat9;
    } else if (stat === "stat10") {
      valRetour = "Number of 1st places\n" + this.state.stat10;
    } else if (stat === "stat11") {
      var retour = "";
      if(this.state.stat11 === 0) {
        retour = "nc"
      }
      if(this.state.stat11 === 5) {
        retour = "Aspirant"
      }
      if(this.state.stat11 === 4) {
        retour = "Silver"
      }
      if(this.state.stat11 === 3) {
        retour = "Gold"
      }
      if(this.state.stat11 === 2) {
        retour = "Diamond"
      }
      if(this.state.stat11 === 1) {
        retour = "Emerald"
      }
      valRetour = "Best league\n" + retour;
    }
    if(needMargin) {
     return <span style={{float: 'left', width: '55%'}}> {valRetour} </span>;
    }
    else {
      return <span> {valRetour} </span>;
    }
  }

  //
  getCirculation() {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(apiURL + "/dev/getcirculation", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          var t = JSON.parse(result).data;
          this.setState({ circulation: t });
        })

        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error.message);
    }
  }

  rewardCirculation(reward) {
    //alert(JSON.stringify(this.state.circulation));
    for (var i = 0; i < this.state.circulation.length; i++) {
      if (reward === this.state.circulation[i].REWARD) {
        return this.state.circulation[i].NBTOTAL;
      }
    }

    return "nc";
  }

  getBSC() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw;
    if (!this.props.auth.user.credentials) {
      raw = JSON.stringify({
        PSEUDO: this.props.auth.user.username,
        JWT: this.props.auth.user.signInUserSession.idToken,
        UUID: this.props.auth.user.attributes.sub,
        isWeb3: false,
      });
    } else {
      raw = JSON.stringify({
        PSEUDO: this.props.auth.user.username,
        isWeb3: true,
        sessionToken: this.props.auth.user.credentials.sessionToken,
        accessKeyId: this.props.auth.user.credentials.accessKeyId,
        secretKey: this.props.auth.user.credentials.secretKey,
      });

      document.getElementById("BSCadd").value = this.props.auth.user.username;
      return;
    }
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(apiURL + "/dev/getbsc", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        var BSC = JSON.parse(result).BSC;
        document.getElementById("BSCadd").value = BSC;
      })

      .catch((error) => console.log("error", error));
  }

  saveBSC() {
    var BSC = document.getElementById("BSCadd").value;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw;
    if (!this.props.auth.user.credentials) {
      raw = JSON.stringify({
        PSEUDO: this.props.auth.user.username,
        JWT: this.props.auth.user.signInUserSession.idToken,
        BSC: BSC,
        UUID: this.props.auth.user.attributes.sub,
        isWeb3: false,
      });
    } else {
      raw = JSON.stringify({
        PSEUDO: this.props.auth.user.username,
        isWeb3: true,
        BSC: BSC,
        sessionToken: this.props.auth.user.credentials.sessionToken,
        accessKeyId: this.props.auth.user.credentials.accessKeyId,
        secretKey: this.props.auth.user.credentials.secretKey,
      });
    }

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(apiURL + "/dev/postbsc", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        document.getElementById("btBSC").style.display = "none";
      })

      .catch((error) => console.log("error", error));
  }

  _handleKeyDown = (e) => {
    document.getElementById("btBSC").style.display = "inline";
    if (e.key === "Enter") {
      this.saveBSC();
    }
  };

  onInputChange = (event) => {
    document.getElementById("inputPseudo").value = document
      .getElementById("inputPseudo")
      .value.toLowerCase();

    document.getElementById("inputPseudo").value = document.getElementById("inputPseudo").value.replaceAll(" ", "");


    document.getElementById("inputPseudo").value = document.getElementById("inputPseudo").value.slice(0, 13);
  };

  displayKeys() {
    var keys = this.state.keys;

    if(keys === []) {
      return;
    }

    var keyList = [];
    var restricted = false;
    var inscrit = false;
    

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(ipBackend + "/tournaments/isreg/" + this.props.auth.user.username, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        var resultats = JSON.parse(result);
        restricted = resultats[1].infos.estRestricted;
        inscrit = resultats[1].isRegistred
      })

      .catch((error) => console.log("error", error));






    for (var i = 0; i < this.state.keys.length; i++) {
      var dateShort = new Date(this.state.keys[i].date) + " ";
      var splitter = dateShort.split("GMT");
      dateShort = splitter[0];

      var key = this.state.keys[i].key;



      keyList.push(
        <div className="keyListCont" key={"keyListCont"+i}>
          <img
            className="keyList"
            src={".././images/rewards/keys/" + key + ".png"}
            alt="Clef"
            title={
              "Clef obtenue le: " +
              dateShort +
              "\n" +
              "En circulation: " +
              this.rewardCirculation(this.state.keys[i].key)
            }
          />
          {
            restricted === true && inscrit === true 
            ? <button 
                className="buttonsTournamentMintGreyKey" 
                disabled
                onClick={() =>
                  alert("You cant mint a key being used in a tournament !")
                }
              >
                Mint
              </button>
            : <button
                className="buttonsTournamentMintKey"
                onClick={this.transferKey({first: key, second: true, third: 0})}
              >
                Mint {key}
              </button>
          }
          
        </div>
      );
    }

    return <span> {keyList} </span>;
  }
  //Event transfert de clef
  transferKey = (value) => (event) => {
    event.preventDefault();
    try {
      let first = value.first;
      let second = value.second;
      let third = value.third;

      this.transferMatic(first, second, third)

      } catch (error) {
      console.log(error);
    }
  };

  render() {
    var ListElementEmbleme = [];
    var ListElementCadre = [];
    var ListElementCitation = [];
    var ListElementStat = [];
    var ListElementBordure = [];


    if (this.state.embleme != null) {
      for (let i = 0; i < this.state.embleme.length; i++) {
        ListElementEmbleme.push(
          <div className="imgSelector" key={"emblemeSelec"+i}>
            <img
              onClick={this.setEmbleme(this.state.embleme[i].avatar)}
              className={
                this.state.embleme[i].avatar === this.state.selectedEmbleme
                  ? "imgInventaireGreen"
                  : "imgInventaire"
              }
              src={".././images/rewards/emblemes/miniatures/" + this.state.embleme[i].avatar + ".png"}
              alt="embleme"
              title={
                "Avatar obtenu le: " +
                new Date(this.state.embleme[i].date) +
                "\n" +
                "En circulation: " +
                this.rewardCirculation(this.state.embleme[i].avatar)
              }
            />
            {this.state.embleme[i].avatar === "avatar0" || this.state.embleme[i].avatar === "embleme0" ? (
              <button className="buttonsTournamentMintGrey" disabled>
                Mint
              </button>
            ) : (
              <button
                className="buttonsTournamentMint"
                onClick={() =>
                  this.transferMatic(this.state.embleme[i].avatar, true, 0)
                }
              >
                Mint
              </button>
            )}
          </div>
        );
      }

      //cadre
      for (let i = 0; i < this.state.cadre.length; i++) {
        ListElementCadre.push(
          <div className="imgSelector" key={"cadreSelec"+i}>
            <img
              onClick={this.setCadre(this.state.cadre[i].fond)}
              className={
                this.state.cadre[i].fond === this.state.selectedCadre
                  ? "imgInventaireGreen"
                  : "imgInventaire"
              }
              src={".././images/rewards/cadres/" + this.state.cadre[i].fond + ".png"}
              alt="embleme"
              title={
                "Cadre obtenu le: " +
                new Date(this.state.cadre[i].date) +
                "\n" +
                "En circulation: " +
                this.rewardCirculation(this.state.cadre[i].fond)
              }
            />
            {this.state.cadre[i].fond === "background1" || this.state.cadre[i].fond === "background14" ? (
              <button className="buttonsTournamentMintGrey" disabled>
                Mint
              </button>
            ) : (
              <button
                className="buttonsTournamentMint"
                onClick={() =>
                  this.transferMatic(this.state.cadre[i].fond, true, 0)
                }
              >
                Mint
              </button>
            )}
          </div>
        );
      }

      //bordu
      for (let i = 0; i < this.state.bordure.length; i++) {
        ListElementBordure.push(
          <div
            className={
              this.state.bordure[i].bordure === this.state.selectedBordure
                ? "imgSelector greenUnderline"
                : "imgSelector"
            }
            key={"bordSelec"+i}
          >
            <img
              onClick={this.setBordure(this.state.bordure[i].bordure)}
              className="imgInventaire"
              src={".././images/rewards/bordures/miniatures/" + this.state.bordure[i].bordure + ".png"}
              alt="bordure"
              title={
                "Bordure obtenue le: " +
                new Date(this.state.bordure[i].date) +
                "\n" +
                "En circulation: " +
                this.rewardCirculation(this.state.bordure[i].bordure)
              }
            />
            {this.state.bordure[i].bordure === "border13" ? (
              <button className="buttonsTournamentMintGrey" disabled>
                Mint
              </button>
            ) : (
              <button
                className="buttonsTournamentMint"
                onClick={() =>
                  this.transferMatic(this.state.bordure[i].bordure, true, 0)
                }
              >
                Mint
              </button>
            )}
          </div>
        );
      }

      //citation
      for (let i = 0; i < this.state.citation.length; i++) {
        ListElementCitation.push(
          <div
            className={
              this.state.citation[i] === this.state.seleccitation
                ? "citationSelectorSelected"
                : "citationSelector"
            }
            title={
              "Titre obtenu le: " +
              new Date(this.state.citation[i].date) +
              "\n" +
              "En circulation: " +
              this.rewardCirculation(this.state.citation[i].titre)
            }
            key={"citSelec"+i}
          >
            <div onClick={this.setCitation(this.state.citation[i].titre)}>
              {this.displayCitation(this.state.citation[i].titre, true)}
            </div>
            {this.state.citation[i].titre === "title1" || this.state.citation[i].titre === "citation1"  || Number(this.state.citation[i].titre.replace(/^\D+/g, '')) >= 100? (
              <button className="buttonsTournamentMintShortGrey" disabled>
                Mint
              </button>
            ) : (
              <button
                className="buttonsTournamentMintShort"
                onClick={() =>
                  this.transferMatic(this.state.citation[i].titre, true, 0)
                }
              >
                Mint
              </button>
            )}
            
          </div>
        );
      }

      //stat
      for (let i = 0; i < this.state.stat.length; i++) {
        ListElementStat.push(
          <div
            className={
              this.state.citation[i] === this.state.seleccitation
                ? "statSelectorSelected"
                : "statSelector"
            }
            key={"sttSelec"+i}
            title={
              "Stat obtenu le: " +
              new Date(this.state.stat[i].date) +
              "\n" +
              "En circulation: " +
              this.rewardCirculation(this.state.stat[i].stat)
            }
          >
            <div onClick={this.setStat(this.state.stat[i].stat)}>
              {this.displayStat(this.state.stat[i].stat, true)}
            </div>

            {this.state.stat[i].stat === "stat1"? (
              <button className="buttonsTournamentMintShortStatGrey" disabled>
                Mint
              </button>
            ) : (
              <button
                className="buttonsTournamentMintShortStat"
                onClick={() =>
                  this.transferMatic(this.state.stat[i].stat, true, 0)
                }
              >
                Mint
              </button>
            )}
          </div>
        );
      }
    }

    //ok

    return (
      <>
        <section className="profilez" id="profilez">
          {this.props.auth.isAuthenticated && this.props.auth.user && (
            <div className="profile">
              <div
                className="contentProfileWrapper"
                style={{ display: "none" }}
              >
                <div className="contentProfile">
                  <div className="inventaireEmbleme">
                    {" "}
                    Avatar: <br /> {ListElementEmbleme}{" "}
                  </div>
                  <div className="inventaireCadre">
                    {" "}
                    Fond: <br /> {ListElementCadre}{" "}
                  </div>

                  <div className="inventaireCitationConteneur">
                    Citations:
                    <br />
                    <div className="inventaireCitation">
                      {" "}
                      {ListElementCitation}{" "}
                    </div>
                  </div>
                  <div className="inventaireStatConteneur">
                    Statistiques:
                    <br />
                    <div className="inventaireStat"> {ListElementStat} </div>
                  </div>
                </div>
                <div className="contentProfile">
                  <div className="userSpecs">
                    Utilisateur: <b> {this.state.username} </b> <br /> <br />
                    Mail: <b> {this.state.mail} </b>
                    <img
                      id="showMail"
                      className="showMail"
                      onClick={this.showMail}
                      src={".././images/showmail.png"}
                      alt="showmail"
                    />{" "}
                    <br />
                    <br />
                    Adresse publique BSC{" "}
                    <input
                      type="text"
                      id="BSCadd2"
                      title="Adresse de dépot BSC pour les récompenses"
                      onKeyDown={this._handleKeyDown}
                    />
                    <button id="btBSC2" onClick={this.saveBSC.bind(this)}>
                      {" "}
                      Enregistrer{" "}
                    </button>{" "}
                    <br />
                    <br />
                    <a
                      className="logoutText"
                      href="/"
                      onClick={this.handleLogOut}
                    >
                      Logout
                    </a>
                  </div>
                </div>
              </div>

              <div className="profilePublic">
                <div className="cadre">
                  <img
                    className="imgCadre"
                    src={this.state.seleccadre}
                    alt="cadre"
                  />
                  <img
                    className="imgEmbleme"
                    src={this.state.selecembleme}
                    alt="emblem"
                  />
                  { this.state.selectedBordure === ""
                    ? ""
                    : <img
                        className="imgBordure"
                        src={this.state.selecbordure}
                        alt="bordure"
                      />
                  }
                </div>

                <div className="publicPseudo">
                  <div className="publicPsd">
                    { 
                      this.state.alt === "" 
                      ? ( this.state.username.toString().match(truncateRegex) 
                        ? truncateEthAddress(this.state.username) 
                        : this.state.username) 
                      : this.state.alt 
                    }
                  </div>

                  <div className="citation">
                    <p>{this.displayCitation(this.state.seleccitation, false)}</p>
                  </div>

                  <div className="publicLvl">Lvl : {this.state.lvl}</div>
                </div>

                <div className="stat">
                  {this.state.joueurligue === "NC" ? (
                    <img
                      className="ligueRankProf"
                      src={"../././images/NC.png"}
                      alt="ligue"
                    />
                  ) : (
                    ""
                  )}
                  {this.state.joueurligue === "Aspirant" ? (
                    <img
                      className="ligueRankProf"
                      src={"../././images/Aspirant.png"}
                      alt="ligue"
                    />
                  ) : (
                    ""
                  )}
                  {this.state.joueurligue === "Argent" ? (
                    <img
                      className="ligueRankProf"
                      src={"../././images/Argent.png"}
                      alt="ligue"
                    />
                  ) : (
                    ""
                  )}
                  {this.state.joueurligue === "Or" ? (
                    <img
                      className="ligueRankProf"
                      src={"../././images/Or.png"}
                      alt="ligue"
                    />
                  ) : (
                    ""
                  )}
                  {this.state.joueurligue === "Diamant" ? (
                    <img
                      className="ligueRankProf"
                      src={"../././images/Diamant.png"}
                      alt="ligue"
                    />
                  ) : (
                    ""
                  )}
                  {this.state.joueurligue === "Emeraude" ? (
                    <img
                      className="ligueRankProf"
                      src={"../././images/Emeraude.png"}
                      alt="ligue"
                    />
                  ) : (
                    ""
                  )}
                  <span>{this.displayStat(this.state.selecstat, false)}</span>
                </div>

                <div className="xpBar">
                  <div className="textxp">
                    {" "}
                    <b>
                      {" "}
                      Experience: <br />
                      {this.state.xp} / {this.state.nexup}
                    </b>{" "}
                  </div>
                  <div className="xpProgress">
                    <div id="xpProgressInsinde" className="xpProgressInsinde">
                      .
                    </div>
                  </div>
                  <div
                    className="nextrewcont"
                    id="nextrewcont"
                    style={{ display: "none" }}
                  >
                    <span className="nextrewtext">
                      {" "}
                      Prochaine <br /> récompense:{" "}
                    </span>
                    <span className="nextrewimg">
                      {" "}
                      {this.returnNextReward()}{" "}
                    </span>
                  </div>
                </div>
              </div>
              <div className="publicProfBorder"></div>

              <div className="publicProfBorderRight"></div>

              <div className="rightPanelProfil">
                <span
                  className="rightPanelSelector"
                  style={{ color: "white" }}
                  onClick={this.selecPerso(0)}
                >
                  AVATAR
                </span>
                <span className="traitBlanc">|</span>
                <span
                  className="rightPanelSelector"
                  onClick={this.selecPerso(1)}
                >
                  BACKGROUND
                </span>
                <span className="traitBlanc">|</span>
                <span
                  className="rightPanelSelector"
                  onClick={this.selecPerso(2)}
                >
                  BORDER
                </span>
                <span className="traitBlanc">|</span>
                <span
                  className="rightPanelSelector"
                  onClick={this.selecPerso(3)}
                >
                  TITLE
                </span>
                <span className="traitBlanc">|</span>
                <span
                  className="rightPanelSelector"
                  onClick={this.selecPerso(4)}
                >
                  STATS
                </span>
                <span className="traitBlanc">|</span>
                <span
                  className="rightPanelSelector"
                  onClick={this.selecPerso(5)}
                >
                  KEYS
                </span>
                <span className="traitBlanc">|</span>
                <span
                  className="rightPanelSelector"
                  onClick={this.selecPerso(6)}
                >
                  NFT
                </span>
                <span className="traitBlanc">|</span>
                <span
                  className="rightPanelSelector"
                  onClick={this.selecPerso(7)}
                >
                  TRANSACTIONS
                </span>

                <div className="rightAvatar" id="rightAvatar">
                  {this.state.isLoading ? (
                    <img
                      style={{
                        width: "50px",
                      }}
                      src={loading}
                      alt="loading"
                    ></img>
                  ) : (
                    ListElementEmbleme
                  )}
                </div>
                <div
                  className="rightFond"
                  id="rightFond"
                  style={{ display: "none" }}
                >
                  {this.state.isLoading ? (
                    <img
                      style={{
                        width: "50px",
                      }}
                      src={loading}
                      alt="loading"
                    ></img>
                  ) : (
                    ListElementCadre
                  )}
                </div>
                <div
                  className="rightBordure"
                  id="rightBordure"
                  style={{ display: "none" }}
                >
                  {this.state.isLoading ? (
                    <img
                      style={{
                        width: "50px",
                      }}
                      src={loading}
                      alt="loading"
                    ></img>
                  ) : (
                    ListElementBordure
                  )}
                </div>
                <div
                  className="rightCitation"
                  id="rightCitation"
                  style={{ display: "none" }}
                >
                  {ListElementCitation}
                </div>
                <div
                  className="rightStat"
                  id="rightStat"
                  style={{ display: "none" }}
                >
                  {ListElementStat}
                </div>
                <div
                  className="rightKeys"
                  id="rightKeys"
                  style={{ display: "none" }}
                >
                  {this.displayKeys()}
                </div>
                <div
                  className="rightNft"
                  id="rightNft"
                  style={{ display: "none" }}
                >
                  {this.state.isLoading ? (
                    <img
                      style={{
                        width: "50px",
                      }}
                      src={loading}
                      alt="loading"
                    ></img>
                  ) : (
                    this.state.nftMetadata.map((objective, i) => (
                      <div className="imgSelector" key={"nftKeys"+objective.id}>
                        <img
                          className="imgInventaire"
                          src={objective.image}
                          alt="img inventaire"
                        ></img>
                        {this.state.getApprove ? (
                          <button
                            className="buttonsTournamentMint"
                            style={{
                              width: "100%",
                              marginTop: "10px",
                            }}
                            onClick={() =>
                              this.transferMatic(
                                objective.name,
                                false,
                                objective.id
                              )
                            }
                          >
                            Use 
                          </button>
                        ) : (
                          <button
                            className="buttonsTournament"
                            style={{
                              width: "100%",
                              marginTop: "10px",
                              fontSize: "12px",
                            }}
                            onClick={() =>
                              this.approveForAll(
                                objective.name,
                                false,
                                objective.id
                              )
                            }
                          >
                            Approve
                          </button>
                        )}
                      </div>
                    ))
                  )}
                </div>
                <div
                  className="rightTransaction"
                  id="rightTransaction"
                  style={{ display: "none" }}
                >
                  <div
                    style={{
                      color: "white",
                      fontSize: "100%",
                      textAlign: "center",
                      paddingTop: "20px",
                    }}
                  >
                    Historique des transactions
                  </div>
                  {this.state.isLoading ? (
                    <img
                      style={{
                        width: "50px",
                      }}
                      src={loading}
                      alt="loading"
                    ></img>
                  ) : (
                    this.state.transaction.map((objective, i) => (
                      <div
                        className="imgSelectorTransaction"
                        key={"imgSelectorTransaction"+objective.id}
                      > 
                        {
                          objective.item.includes("avatar") ?
                          <img
                            className="imgInventaire"
                            src={".././images/rewards/emblemes/miniatures/" + objective.item + ".png"}
                            alt="img avatar"
                          ></img>
                          : ""
                        }
                        {
                          objective.item.includes("background") ?
                          <img
                            className="imgInventaire"
                            src={".././images/rewards/cadres/" + objective.item + ".png"}
                            alt="background"
                          ></img>
                          : ""
                        }
                        {
                          objective.item.includes("border") ?
                          <img
                            className="imgInventaire"
                            src={".././images/rewards/bordures/miniatures/" + objective.item + ".png"}
                            alt="border"
                          ></img>
                          : ""
                        }
                        {
                          objective.item.includes("title") ?
                          <img
                            className="imgInventaireShort"
                            src={".././images/rewards/citations/" + objective.item + ".png"}
                            alt="title"
                          ></img>
                          : ""
                        }
                        {
                          objective.item.includes("stat") ?
                          <img
                            className="imgInventaireShort"
                            src={".././images/rewards/stats/" + objective.item + ".png"}
                            alt="stat"
                          ></img>
                          : ""
                        }
                        {
                          objective.item.includes("key") ?
                          <img
                            className="imgInventaireShort"
                            src={".././images/rewards/keys/" + objective.item + ".png"}
                            alt="key"
                          ></img>
                          : ""
                        }
                        <div style={{ color: "white" }}>{objective.type}</div>
                        <div style={{ color: "white" }}>
                          ID : {objective.id}
                        </div>
                        {objective.stillRuning ? (
                          <div style={{ color: "#FFA500" }}>En cours</div>
                        ) : (
                          <div style={{ color: "#32CD32" }}>Terminé</div>
                        )}
                      </div>
                    ))
                  )}
                </div>
              </div>

              <div className="bottomPanelProfil">
                {this.state.account ? (
                  <div></div>
                ) : (
                  <>
                    <button
                      style={{ width: "19%", marginRight: "19%" }}
                      className="buttonsTournament"
                      href="/"
                      onClick={this.connectWallet}
                    >
                      Connecte ton wallet
                    </button>
                    <br />
                  </>
                )}
                <br />
                <button
                  style={{ width: "19%", marginRight: "19%" }}
                  className="buttonsTournament"
                  href="/"
                  onClick={this.handleLogOut}
                >
                  Logout
                </button>
                
                {this.props.auth.user.credentials ? (
                  <>
                    POLYGON public adress:{" "}
                    <input
                      type="text"
                      className="inputInfos"
                      id="BSCadd"
                      title="Adresse de dépot des récompenses"
                      onKeyDown={this._handleKeyDown}
                      disabled
                    />
                  </>
                ) : (
                  <>
                    POLYGON public adress:{" "}
                    <input
                      type="text"
                      className="inputInfos"
                      id="BSCadd"
                      title="Adresse de dépot des récompenses"
                      onKeyDown={this._handleKeyDown}
                    />
                  </>
                )}
                {this.props.auth.user.credentials ? (
                  ""
                ) : (
                  <button
                    id="btBSC"
                    style={{ width: "9.5%", marginLeft: "3%" }}
                    className="buttonsTournament"
                    onClick={this.saveBSC.bind(this)}
                  >
                    {" "}
                    Save{" "}
                  </button>
                )}
                <br />
                {
                  this.props.auth.user.credentials && this.state.needsAlt? (
                    <div style={{ marginLeft: "43%" }}>
                       Ingame Pseudo:
                       <input
                          type="text"
                          className="inputInfosReferal"
                          id="inputPseudo"
                          title="input pseudo"
                          onChange={this.onInputChange}
                        />
                        <button
                          id="btPseudo"
                          style={{ width: "19%", marginLeft: "6%" }}
                          className="buttonsTournament"
                          onClick={this.savePseudo.bind(this)}
                        >
                          {" "}
                          Save{" "}
                        </button>
                    </div>
                  ) : (
                    null
                  )
                }
                {this.state.isRefered !== true && false ? (
                  <div style={{ marginLeft: "49.8%" }}>
                    {" "}
                    Referal :{" "}
                    <input
                      type="text"
                      className="inputInfosReferal"
                      id="inputReferal"
                      title="Input Referal"
                    />
                    <button
                      id="btReferal"
                      style={{ width: "19%", marginLeft: "6%" }}
                      className="buttonsTournament"
                      onClick={this.saveReferal.bind(this)}
                    >
                      {" "}
                      Save{" "}
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          )}
          {!this.props.auth.isAuthenticated && (
            <div className="contentProfile">
              Register first to acces Profile !
            </div>
          )}
        </section>
      </>
    );
  }
}

export default Profile;
