import React, { Fragment, Component } from "react";
import Graph from "./Graph";
import Chat from "./Chat";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import levierOne from "./images/leverageOne.png";
import levierLow from "./images/leverageLow.png";
import levierMed from "./images/leverageMed.png";
import levierHigh from "./images/leverageHigh.png";

import { ipBackend } from "../utils/const";
import { apiURL } from "../utils/const";

import loading from "./images/loading.svg";


import {
  getValFromCitation,
  truncateEthAddress,
  truncateRegex,
} from "../utils/function";
import { instanceAWS, instanceTournoi } from "../api/api_instance";

class Ingame extends Component {
  //////////////////////////////////////////
  ////          Tournament.js            ////
  //// Fichier de lancement des parties  ////
  //////////////////////////////////////////

  constructor(props) {
    super(props);
    this.child = React.createRef();
  }

  state = {
    ///////INFOS TOURNOIS PASSéS EN PARAMETRES DE L'APPEL DU COMPOSANT DANS TOURNAMENT.JS//////
    nbtournois: 0,
    tournois: 0,
    tournoisActif: 0,
    indicetournoisActif: 0,
    ///////In Game/////
    ingameTempsrestant: 0,
    ingameResults: 0,
    ingamePosition: 0,
    ingameValuepair: 0,
    ingameSoldeUSDT: 0,
    ingameSoldeUSDT_POS: 0,
    ingameSoldeCrypto: 0,
    ingameTaillePos: 0,
    ingameLevier: 0,
    ingameOrders: 0,
    ingameNbOrders: 0,
    ingamePaire: "",
    ingamePari: "",
    ingameEntreePos: 0,
    ingameLiquid: 0,
    ingameSensLiquid: 0,
    ingameSoldeAvantPos: 0,
    ingameSL: "",
    ingameTP: "",
    ingamePosPair: "",
    ingamePosLevier: 0,
    ingamePositions: [],
    ingameAssets: {},
    ingameVisuals: "BTCPERP",
    ingameModePro: false,
    ingameLogs: [],
    ingameGroups: [],
    ingameGroupResults: [],
    ingameOrdres: [],
    ingameOrdresPair: "",
    ingameOrdresAction: "",
    ingameModeSimple: true,
    ingameLevierVisuel: 1,
    circuCadre: "nc",
    circuBordure: "nc",
    circuEmbleme: "nc",
    circuPP: "",
    valueSell: 0,
    valueBuy: 0,
    valueLevier: 1,
    renderFinish: 0,
    joueurligue: 0,
    tabresultpseudo: 0,
    tabresultsolde: 0,
    sliderMoovable: true,
    nbpreregshort: 0,
    preregshort: 0,
    ispreregshort: "",
    isFirstState: true,
    strat: 0,
    selecstrat: 0,
    nbStrat: 0,
    ///////Resultats///////
    finishJoueurs: 0,
    finishScores: 0,
    finishPosition: 0,
    finishScore: 0,
    finishXp: 0,
    finishCote: 0,
    finishTag: 0,
    finishGroupe: {},
    ////Profil Public//////
    joueurembleme: 0,
    joueurbordure: 0,
    joueurpseudo: "",
    joueurcadre: 0,
    joueurcitation: 0,
    joueurstat: 0,
    joueurlvl: 0,
    joueurcr: 0,
    podiumPseudos: [],
    stat1: 0,
    stat2: 0,
    stat3: 0,
    stat4: 0,
    stat5: 0,
    stat6: 0,
    stat7: 0,
    stat8: 0,
    stat9: 0,
    stat10: 0,
    stat11: 0,
    stat12: 0,
    displaystat: 0,
    displaycitation: 0,
    ratingPerso: 0,
    errorSLTP: "",
    widgetEntryLineID: "",
    scoreEm: 0,
    scoreRubis: 0,
    scoreOr: 0,
    scoreSaphir: 0,
    FscoreEm: 0,
    FscoreRubis: 0,
    FscoreOr: 0,
    FscoreSaphir: 0,
    estimateSup: 0,
    estimateInf: 0,
    mainDisabled: false,
    altArray: [],
    needsAltRefresh: false,
    tabDrawTW: {
      entry: {
        value: 0,
        isDraw: false,
      },
      liquid: {
        value: 0,
        isDraw: false,
      },
      closeInf: {
        value: 0,
        isDraw: false,
      },
      closeSup: {
        value: 0,
        isDraw: false,
      },
    },
  };

  ///////////////////////////////////EXECUTION FIN CHARGEMENT PAGE////////////////////////////

  async componentDidMount() {
    try {
      if (this.props.isauth) {
        document.getElementById("renderFinish").style.display = "none";


        this.gameLoop();
        this.navigatorLayouts();
      } else {
        window.location.href = "/";
      }
    } catch (error) {
      console.log(error);
    }
  }

  ///////////////////////////////////LOOP INFINE////////////////////////////

  async gameLoop() {
    var isGameOver = false;
    while (!isGameOver) {
      //Si tournois selectionné (bouton play)
      if (this.props.tournoisActif !== 0) {
        //Tant que le tournois n'est pas terminé
        if (this.props.tournoisActif.HFIN > Date.now() + 1000) {
          await this.getTournament();
          this.getGameState();
          this.displayTimer();
        }

        //Si le tournois est terminé
        else if (this.props.tournoisActif.HFIN < Date.now()) {
          this.displayIngameResults();
          isGameOver = true;
        }
      }

      await this.sleep(500);
    }
  }

  async navigatorLayouts() {
    /*
      if(navigator.brave && await navigator.brave.isBrave() || false)
      {
          //document.getElementById("selecPro1").style.marginLeft = '29.9vw';
      }*/
  }

  ///////////////////////////////////AFFICHAGE TOURNOIS////////////////////////////
  // Ensemble des fonctions pour afficher les elements relatifs au tournoi //

  //Fonction appelée toutes les s pour interroger le serv sur le classement de la partie
  async getTournament() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");


    var requestOptions = {
      crossDomain: true,
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      ipBackend + "/tournaments/" + this.props.tournoisActif.ID,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        var resultats = JSON.parse(result);
        var ListPP = [];
        var groupes = [];

        var datefull = new Date();
        datefull.setTime(resultats.hDeb);
        var hDebFormat =
          datefull.getDate() +
          "/" +
          Number(datefull.getMonth() + 1) +
          "/" +
          datefull.getFullYear() +
          " : " +
          datefull.getHours() +
          ":" +
          datefull.getMinutes();

        var hfinformat = new Date();
        hfinformat.setTime(resultats.hFin);
        var hFinFormat = hfinformat.getHours() + ":" + hfinformat.getMinutes();

        var tournoisTmp = {
          ID: resultats.id,
          DUREE: resultats.duree,
          HDEBraw: resultats.hDeb,
          HDEB: hDebFormat,
          HFIN: resultats.hFin,
          HFINformat: hFinFormat,
          NBJOUEURS: resultats.nombreJoueurs,
          JOUEURS: resultats.joueurs,
        };
        var tt = "";

        var tabz = [];

        if(this.state.tournois === 0) {

          tabz = [];
          
          for (var b = 0; b < resultats.joueurs.length; b++) {
            if(resultats.joueurs[b].pseudo.match(truncateRegex)) {
              tabz.push(resultats.joueurs[b].pseudo)
            }
          }
          this.getAlt(tabz);
        }
        else
        {
          if(tournoisTmp.JOUEURS.length !== this.state.tournois.JOUEURS.length) {
            tabz = [];
            for (var c = 0; c < resultats.joueurs.length; c++) {
              if(resultats.joueurs[c].pseudo.match(truncateRegex)) {
                tabz.push(resultats.joueurs[c].pseudo)
              }
            }
            this.getAlt(tabz);
          }
        }

        //alert(this.props.tournoisActif.CPTEXT);
        
        //var cpText = [55,35,30,20,10];
        var cpText = this.props.tournoisActif.CPTEXT

        if (cpText !== "") {
          tt = JSON.parse(this.props.tournoisActif.CPTEXT);
        }

        var groupe = {
          Nom: "",
          couleur: "",
          nbJoueur: 0,
          score: 0,
          estUser: false,
          deco: "",
        };

        if (this.props.tournoisActif.ESTGROUPE) {
          var groupeUser = resultats.groupe;

          var tabGroupe = [];

          groupe = {
            Nom: "",
            couleur: "",
            nbJoueur: 0,
            score: 0,
            estUser: false,
            deco: "",
          };

          groupes = resultats.infos.groupes;

          //parse des groupes

          for (var i = 0; i < groupes.length; i++) {
            groupe.Nom = groupes[i].Nom;
            groupe.couleur = groupes[i].couleur;
            groupe.nbJoueur = groupes[i].joueurs;

            if (i + 1 === groupeUser) {
              groupe.estUser = true;
            }

            tabGroupe.push(groupe);

            groupe = {
              Nom: "",
              couleur: "",
              nbJoueur: 0,
              score: 0,
              estUser: false,
              deco: "",
            };
          }

          for (i = 0; i < resultats.joueurs.length; i++) {
            let id = Number(resultats.joueurs[i].groupe) - 1;
            tabGroupe[id].score += Number(
              Number.parseFloat(
                resultats.joueurs[i].soldeUsdtAvantPosition -
                  resultats.joueurs[i].nbRez * 1000
              ).toFixed(2)
            );
            tabGroupe[id].score = tabGroupe[id].score / tabGroupe[id].nbJoueur;

            if (tabGroupe[id].estUser) {
              tabGroupe[id].deco = "underline";
            }
          }

          tabGroupe.sort((a, b) => a.score - b.score);
          tabGroupe.reverse();

          for (i = 0; i < tabGroupe.length; i++) {
            ListPP.push(
              <span>
                {" "}
                <span
                  style={{
                    color: tabGroupe[i].couleur,
                    textDecoration: tabGroupe[i].deco,
                  }}
                >
                  {" "}
                  {tabGroupe[i].Nom}{" "}
                </span>{" "}
                : {tabGroupe[i].score} <br />{" "}
              </span>
            );
          }
        }

        //style={{color: groupes[Number(resultats.joueurs[i].groupe)-1].couleur}}

        //Boucle des resultats
        for (i = 0; i < resultats.joueurs.length; i++) {
          var scorePourcent = Number.parseFloat(
            -(
              1 -
              (resultats.joueurs[i].soldeUsdtAvantPosition -
                resultats.joueurs[i].nbRez * 1000) /
                1000
            ) * 100
          ).toFixed(2);


 

          ListPP.push(
            <span className={"contentListPP"} key={"spanrenderResult"+i}>
              <div className="contentListPPDelimitor"> </div>
              <span className="positionClassement">{i + 1}.</span>
              <span className="blocPlayerRes">
                <span
                  className="blocPlayerPsd"
                  onClick={this.openPP(resultats.joueurs[i].pseudo)} 
                >
                  { this.getAltArray(resultats.joueurs[i].pseudo) === ""
                    ? (resultats.joueurs[i].pseudo.match(truncateRegex)
                      ? truncateEthAddress(resultats.joueurs[i].pseudo)
                      : resultats.joueurs[i].pseudo )
                    : this.getAltArray(resultats.joueurs[i].pseudo)
                  }
                  {resultats.joueurs[i].positions.length !== 0 ? (
                    <img
                      className="enPos"
                      src={".././images/enPos.png"}
                      alt="enPosition"
                    />
                  ) : (
                    ""
                  )}
                </span>
                <span className="rightTT">
                  {scorePourcent > 0 ? "+" + scorePourcent : scorePourcent}%
                </span>
              </span>
              <span id={"cashpriseCell" + i} className="cashpriceCell" title="Cashprize for this position">
                { i < tt.length ?
                  tt[i]+"$" :
                  ""
                }
              </span>{" "}
              <br />
            </span>
          );
        }

        this.setState({ renderresultpseudo: ListPP });

        // Stockage tournois dans le state tournois
        this.setState({ tournois: tournoisTmp });
      })
      .catch((error) => console.log("error", error));
  }

  returnResults() {
    return <div> {this.state.renderresultpseudo} </div>;
  }

  //Fonction appelée en loop pour recuperer les informations personelles du joueur
  getGameState() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      pseudo: this.props.username,
      id: this.props.tournoisActif.ID,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(ipBackend + "/player/gamestate", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        var ingameAssets = {
          BTCPERP: JSON.parse(result).BTCPERP,
          ETHPERP: JSON.parse(result).ETHPERP,
          SOLPERP: JSON.parse(result).SOLPERP,
          AVAXPERP: JSON.parse(result).AVAXPERP,
          XRPPERP: JSON.parse(result).XRPPERP,
          DOGEPERP: JSON.parse(result).DOGEPERP,
          MATICPERP: JSON.parse(result).MATICPERP,
          LTCPERP: JSON.parse(result).LTCPERP,
          OPPERP: JSON.parse(result).OPPERP,
          ATOMPERP: JSON.parse(result).ATOMPERP,
          INJPERP: JSON.parse(result).INJPERP,
          ARBPERP: JSON.parse(result).ARBPERP,
          BONKPERP: JSON.parse(result).BONKPERP,
          NEARPERP: JSON.parse(result).NEARPERP,
          RUNEPERP: JSON.parse(result).RUNEPERP,
          RNDRPERP: JSON.parse(result).RNDRPERP,
          EGLDPERP: JSON.parse(result).EGLDPERP,
          TIAPERP: JSON.parse(result).TIAPERP,
          SEIPERP: JSON.parse(result).SEIPERP,
        };

        this.setState({ ingameAssets: ingameAssets });

        var pairValue = JSON.parse(result).PAIRVALUE;
        this.setState({ ingameValuepair: pairValue });

        var ingamePerso = JSON.parse(result).PERSO;
        if (ingamePerso !== {}) {
          this.setState({ ingameSoldeUSDT: ingamePerso.soldeUsdt });
          this.setState({
            ingameSoldeAvantPos: ingamePerso.soldeUsdtAvantPosition,
          });

          this.setState({ ingameLevier: ingamePerso.levier });
          this.setState({ ingamePaire: ingamePerso.activePair });

          if(this.state.isFirstState === true) {
            this.setState({valueLevier: this.state.ingameLevier})
            this.setState({isFirstState: false})
            if(this.state.ingameLevier <= 20) {
              document.getElementById("ingameLevierColored").style.color = "aquamarine";
            }

            else if(this.state.ingameLevier > 20 && this.state.ingameLevier < 41) {
              document.getElementById("ingameLevierColored").style.color = "orange";
            }

            else if(this.state.ingameLevier > 40 ) {
              document.getElementById("ingameLevierColored").style.color = "red";
            }
          }

          if (this.state.ingamePari === "") {
            document.getElementById("pValue").value = Number(
              this.state.ingameSoldeUSDT * (this.state.valueBuy / 100)
            );
            document.getElementById("pValuetwo").value = -Number(
              this.state.ingameSoldeUSDT * (this.state.valueBuy / 100)
            );
          }
          this.setState({ ingameLogs: ingamePerso.logs });
        }

        var positionActive = JSON.parse(result).POSITION;



        //Si la position active existe
        if (Object.getOwnPropertyNames(positionActive).length !== 0) {
          this.setState({ ingameSoldeCrypto: positionActive.soldeCrypto });
          this.setState({ ingamePari: positionActive.pari });
          this.setState({ ingameEntreePos: positionActive.entree });
          this.setState({ ingameLiquid: positionActive.liquidation });
          this.setState({ ingameTP: positionActive.tp });
          this.setState({ ingameSL: positionActive.sl });
          this.setState({ ingamePosPair: positionActive.pair });
          this.setState({ ingamePosLevier: positionActive.levier });
          this.setState({
            ingamePosition: positionActive.soldeCrypto * pairValue,
          });

          this.setState({ sliderMoovable: true });
          document.getElementById("ingameOrders").style.display = "block";

          if (this.state.ingamePari === "") {
            document.getElementById("pValue").value = Number(
              this.state.ingameSoldeUSDT * (this.state.valueBuy / 100)
            );
            document.getElementById("pValuetwo").value = -Number(
              this.state.ingameSoldeUSDT * (this.state.valueBuy / 100)
            );
          } else if (this.state.ingamePari === "HAUSSE") {
            document.getElementById("pValue").value = Number(
              this.state.ingameSoldeUSDT * (this.state.valueBuy / 100)
            );
            document.getElementById("pValuetwo").value = Number(
              this.state.ingameSoldeCrypto * (this.state.valueBuy / 100)
            );
          } else if (this.state.ingamePari === "BAISSE") {
            document.getElementById("pValue").value = Number(
              this.state.ingameSoldeCrypto * (this.state.valueBuy / 100)
            );
            document.getElementById("pValuetwo").value = -Number(
              this.state.ingameSoldeUSDT * (this.state.valueBuy / 100)
            );
          }
        } else {
          this.setState({ ingamePari: "" });
          this.setState({ ingameSoldeCrypto: 0 });
          this.setState({ ingameEntreePos: 0 });
        }

        var tabPositions = JSON.parse(result).POSITIONS;
        if (tabPositions.length > 0) {
          //alert(JSON.stringify(positions));
          this.setState({ ingamePositions: tabPositions });
        } else {
          this.setState({ ingamePositions: [] });
          this.setState({ ingameEntreePos: 0 });
        }

        var ordresTab = JSON.parse(result).ORDRES;

        var oldNumberofOrders = this.state.ingameOrdres.length
        var oldOrdersTab = this.state.ingameOrdres;

        this.setState({ ingameOrdres: ordresTab });

        //Si un ajout d'ordre est détecté
        if(oldNumberofOrders < this.state.ingameOrdres.length) {

          for (var i = 0; i < ordresTab.length; i++) {
            if (
              (ordresTab[i].action === "closeSup" || ordresTab[i].action === "closeInf") 
            ) {
              let orderExistInOldTab = false;

              for(var j = 0; j < oldOrdersTab.length; j++) {
                if (
                  oldOrdersTab[j].action === ordresTab[i].action &&
                  oldOrdersTab[j].pair === ordresTab[i].pair &&
                  oldOrdersTab[j].indice === ordresTab[i].indice
                ) {
                  orderExistInOldTab = true;
                }
              }

              let indicePosition = 0;
              for (var m = 0; m < this.state.ingamePositions.length; m++) {
                if(ordresTab[i].pair === this.state.ingamePositions[m].pair) {
                  indicePosition = m;
                }
              }

             // console.log(ordresTab[i].pair + " indice should be: "+indicePosition);

              if(orderExistInOldTab === false) {
                  if(ordresTab[i].action === "closeSup") {
                    if(ordresTab[i].indice === 0) {
                      document.getElementById("sellSup"+indicePosition).value = ordresTab[i].value;
                      document.getElementById("percSup"+indicePosition).value = ordresTab[i].percent;
                    }
                    if(ordresTab[i].indice === 1) {
                      document.getElementById("sellSup2"+indicePosition).value = ordresTab[i].value;
                      document.getElementById("percSup2"+indicePosition).value = ordresTab[i].percent;
                    }
                    if(ordresTab[i].indice === 2) {
                      document.getElementById("sellSup3"+indicePosition).value = ordresTab[i].value;
                      document.getElementById("percSup3"+indicePosition).value = ordresTab[i].percent;
                    }
                  }
                  if(ordresTab[i].action === "closeInf") {
                    if(ordresTab[i].indice === 0) {
                      document.getElementById("sellInf"+indicePosition).value = ordresTab[i].value;
                      document.getElementById("percInf"+indicePosition).value = ordresTab[i].percent;
                    }
                    if(ordresTab[i].indice === 1) {
                      document.getElementById("sellInf2"+indicePosition).value = ordresTab[i].value;
                      document.getElementById("percInf2"+indicePosition).value = ordresTab[i].percent;
                    }
                    if(ordresTab[i].indice === 2) {
                      document.getElementById("sellInf3"+indicePosition).value = ordresTab[i].value;
                      document.getElementById("percInf3"+indicePosition).value = ordresTab[i].percent;
                    }
                  }
              }
            }
           
          }  
        }

        //suppresion ordre
        if(oldNumberofOrders > this.state.ingameOrdres.length) {

          for (var i = 0; i < oldOrdersTab.length; i++) {
            if (
              (oldOrdersTab[i].action === "closeSup" || oldOrdersTab[i].action === "closeInf") 
            ) {
              let orderExistInNewTab = false;

              for(var j = 0; j < ordresTab.length; j++) {
                if (
                  ordresTab[j].action === oldOrdersTab[i].action &&
                  ordresTab[j].pair === oldOrdersTab[i].pair &&
                  ordresTab[j].indice === oldOrdersTab[i].indice
                ) {
                  orderExistInNewTab = true;
                }
              }

              let indicePosition = 0;
              for (var m = 0; m < this.state.ingamePositions.length; m++) {
                if(oldOrdersTab[i].pair === this.state.ingamePositions[m].pair) {
                  indicePosition = m;
                }
              }

              if(orderExistInNewTab === false) {
                  if(oldOrdersTab[i].action === "closeSup") {
                    if(oldOrdersTab[i].indice === 0) {
                      document.getElementById("sellSup"+indicePosition).value = "Empty";
                      document.getElementById("percSup"+indicePosition).value = "Empty";
                    }
                    else if(oldOrdersTab[i].indice === 1) {
                      document.getElementById("sellSup2"+indicePosition).value = "Empty";
                      document.getElementById("percSup2"+indicePosition).value = "Empty";
                    }
                    else if(oldOrdersTab[i].indice === 2) {
                      document.getElementById("sellSup3"+indicePosition).value = "Empty";
                      document.getElementById("percSup3"+indicePosition).value = "Empty";
                    }
                  }
                  if(oldOrdersTab[i].action === "closeInf") {
                    if(oldOrdersTab[i].indice === 0) {
                      document.getElementById("sellInf"+indicePosition).value = "Empty";
                      document.getElementById("percInf"+indicePosition).value = "Empty";
                    }
                    else if(oldOrdersTab[i].indice === 1) {
                      document.getElementById("sellInf2"+indicePosition).value = "Empty";
                      document.getElementById("percInf2"+indicePosition).value = "Empty";
                    }
                    else if(oldOrdersTab[i].indice === 2) {
                      document.getElementById("sellInf3"+indicePosition).value = "Empty";
                      document.getElementById("percInf3"+indicePosition).value = "Empty";
                    }
                  }
              }

            }
           
          }
        }

      //A la fin de chaque gamestate, on envoie les données a TW qui va s'occuper de creer modififier ou supprimer les data si il y a besoin  
     // console.log({entry: this.state.ingameEntreePos, liquid: this.state.ingameLiquid, orders: ordresTab})
      this.createShape("ingame", {entry: this.state.ingameEntreePos, liquid: this.state.ingameLiquid, pari: this.state.ingamePari, orders: ordresTab})
      })
      .catch((error) => console.log("error", error));
  }

  //Fonction qui affiche les positions du joueur
  displayPositions() {
    var Positions = [];

    // alert(JSON.stringify(this.state.ingameVisuals));

    for (var i = 0; i < this.state.ingamePositions.length; i++) {
      Positions.push(
        <div className="BlocPosition">
          <div className="BlocPositionGauche">
            <div className={this.state.ingamePositions[i].pari === "BAISSE" ? "BlocPositionPaireShort": "BlocPositionPaire"} >
              {this.state.ingamePositions[i].pair}
              <div className={this.state.ingamePositions[i].pari === "BAISSE" ? "BlocPositionLevierShort": "BlocPositionLevier"}>
                Leverage {this.state.ingamePositions[i].levier}x <br />
                {this.state.ingamePositions[i].pari === "HAUSSE" ? "Long" : ""}
                {this.state.ingamePositions[i].pari === "BAISSE" ? "Short" : ""}
              </div>
              <button
                className="cloturer"
                id="cloturer"
                onClick={this.cloturer(this.state.ingamePositions[i].pair)}
                title="Clos la position et encaisse le gain/la perte"
              >
                {" "}
                CLOSE{" "}
              </button>
            </div>
          </div>
          <div className="BlocPositionCentre">
            <p>
              <span className="spacerPositionsInfosLeft">Size (crypto) : </span>
              <span className="spacerPositionsInfosRight">
                <b>
                  {Number.parseFloat(
                    this.state.ingamePositions[i].levier *
                      this.state.ingamePositions[i].soldeCrypto
                  ).toFixed(5)}{" "}
                </b>
              </span>
              <span className="spacerPositionsInfosLeft">Size ($) :</span>
              <span className="spacerPositionsInfosRight">
                {Number.parseFloat(
                  this.state.ingamePositions[i].soldeCrypto *
                    this.state.ingamePositions[i].levier *
                    this.returnPriceAsset(this.state.ingamePositions[i].pair)
                ).toFixed(0)}
              </span>
              <span className="spacerPositionsInfosLeft">Entry value :</span>
              <span className="spacerPositionsInfosRight">
                <b className="floatRightIG">
                  {Number.parseFloat(
                    this.state.ingamePositions[i].entree
                  ).toFixed(4)}{" "}
                </b>
              </span>
              <br />
              <br />
              <br />
              <br />
              <span className="spacerPositionsInfosLeft">Liquidation :</span>
              <span className="spacerPositionsInfosRight">
                <b className="floatRightIG">
                  {Number.parseFloat(
                    this.state.ingamePositions[i].liquidation
                  ).toFixed(3)}{" "}
                </b>
              </span>
              <span
                className="spacerPositionsInfosLeft"
                style={{ width: "50%" }}
              >
                PNL :
              </span>
              <span className="spacerPositionsInfosRight">
                <b className="floatRightIG"> {this.displayPNL(i)} </b>
              </span>
              <span
                className="spacerPositionsInfosLeft"
                style={{ width: "60%" }}
              >
                Fees :
              </span>
              <span className="spacerPositionsInfosRight">
                {Math.abs(
                  Number.parseFloat(
                    this.state.ingamePositions[i].soldeCrypto *
                      this.returnPriceAsset(
                        this.state.ingamePositions[i].pair
                      ) *
                      0.0005 *
                      (this.state.ingamePositions[i].levier)
                  ).toFixed(2)
                )}
              </span>
            </p>
          </div>
          <div className="BlocPositionDroite">
            <div className="autoClot" id="autoClot">
              <b> Close if superior : </b> 
              {
                this.state.ingamePositions[i].pari === "HAUSSE" ?
                <span style={{color: 'aquamarine'}}> TP </span> : ""
              }
              {
                this.state.ingamePositions[i].pari === "BAISSE" ?
                <span style={{color: 'red'}}> SL </span> : ""
              }
              <br />
              @
              <input
                className="inputSLTPLarge"
                id={"sellSup" + i}
                type="number"
                onChange={this.onChangeSLTP({idInput: "sellSup" + i, idPercent: "percSup" + i})}
              />
              <input
                className="inputSLTP"
                id={"percSup" + i}
                onChange={this.onChangePercent("percSup" + i)}
                type="number"
              />
              %
              <button
                className="btSLTP4"
                onClick={this.validAuto({
                  action: "closeSup",
                  value: "sellSup" + i,
                  percent: "percSup" + i,
                  indice: 0,
                  pair: this.state.ingamePositions[i].pair,
                  taillePosInit: this.state.ingamePositions[i].soldeCrypto,
                })}
              >
                &#x2705;
              </button>
              {this.displayEstimateSup({
                entree: this.state.ingamePositions[i].entree,
                solde: this.state.ingamePositions[i].soldeCrypto,
                levier: this.state.ingamePositions[i].levier,
                trigger: "sellSup" + i,
                percent: "percSup" + i
              })}
              <br />

              @
              <input
                className="inputSLTPLarge"
                id={"sellSup2" + i}
                type="number"
                onChange={this.onChangeSLTP({idInput: "sellSup2" + i, idPercent: "percSup2" + i})}
              />
              <input
                className="inputSLTP"
                id={"percSup2" + i}
                type="number"
                onChange={this.onChangePercent("percSup2" + i)}
              />%
              <button
                className="btSLTP4"
                onClick={this.validAuto({
                  action: "closeSup",
                  value: "sellSup2" + i,
                  percent: "percSup2" + i,
                  indice: 1,
                  pair: this.state.ingamePositions[i].pair,
                  taillePosInit: this.state.ingamePositions[i].soldeCrypto,
                })}
              >
                &#x2705;
              </button>
              {this.displayEstimateSup({
                entree: this.state.ingamePositions[i].entree,
                solde: this.state.ingamePositions[i].soldeCrypto,
                levier: this.state.ingamePositions[i].levier,
                trigger: "sellSup2" + i,
                percent: "percSup2" + i
              })}
              <br />

              @
              <input
                className="inputSLTPLarge"
                id={"sellSup3" + i}
                type="number"
                onChange={this.onChangeSLTP({idInput: "sellSup3" + i, idPercent: "percSup3" + i})}
              />
              <input
                className="inputSLTP"
                id={"percSup3" + i}
                type="number"
                onChange={this.onChangePercent("percSup3" + i)}
              />%
              <button
                className="btSLTP4"
                onClick={this.validAuto({
                  action: "closeSup",
                  value: "sellSup3" + i,
                  percent: "percSup3" + i,
                  indice: 2,
                  pair: this.state.ingamePositions[i].pair,
                  taillePosInit: this.state.ingamePositions[i].soldeCrypto,
                })}
              >
                &#x2705;
              </button>
              {this.displayEstimateSup({
                entree: this.state.ingamePositions[i].entree,
                solde: this.state.ingamePositions[i].soldeCrypto,
                levier: this.state.ingamePositions[i].levier,
                trigger: "sellSup3" + i,
                percent: "percSup3" + i
              })}
              <br />

              <b> Close if inferior : </b> 
              {
                this.state.ingamePositions[i].pari === "HAUSSE" ?
                <span style={{color: 'red'}}> SL </span> : ""
              }
              {
                this.state.ingamePositions[i].pari === "BAISSE" ?
                <span style={{color: 'aquamarine'}}> TP </span> : ""
              }
              <br />
              @
              <input
                className="inputSLTPLarge"
                id={"sellInf" + i}
                type="number"
                onChange={this.onChangeSLTP({idInput: "sellInf" + i, idPercent: "percInf" + i})}
              />
              <input
                className="inputSLTP"
                id={"percInf" + i}
                type="number"
                onChange={this.onChangePercent("percInf" + i)}
              />
              %
              <button
                className="btSLTP4"
                onClick={this.validAuto({
                  action: "closeInf",
                  value: "sellInf" + i,
                  percent: "percInf" + i,
                  indice: 0,
                  pair: this.state.ingamePositions[i].pair,
                  taillePosInit: this.state.ingamePositions[i].soldeCrypto,
                })}
              >
                &#x2705;
              </button>
              {this.displayEstimateInf({
                entree: this.state.ingamePositions[i].entree,
                solde: this.state.ingamePositions[i].soldeCrypto,
                levier: this.state.ingamePositions[i].levier,
                trigger: "sellInf" + i,
                percent: "percInf" + i
              })}
              <br />

              @
              <input
                className="inputSLTPLarge"
                id={"sellInf2" + i}
                type="number"
                onChange={this.onChangeSLTP({idInput: "sellInf2" + i, idPercent: "percInf2" + i})}
              />
              <input
                className="inputSLTP"
                id={"percInf2" + i}
                type="number"
                onChange={this.onChangePercent("percInf2" + i)}
              />%
              <button
                className="btSLTP4"
                onClick={this.validAuto({
                  action: "closeInf",
                  value: "sellInf2" + i,
                  percent: "percInf2" + i,
                  indice: 1,
                  pair: this.state.ingamePositions[i].pair,
                  taillePosInit: this.state.ingamePositions[i].soldeCrypto,
                })}
              >
                &#x2705;
              </button>
              {this.displayEstimateInf({
                entree: this.state.ingamePositions[i].entree,
                solde: this.state.ingamePositions[i].soldeCrypto,
                levier: this.state.ingamePositions[i].levier,
                trigger: "sellInf2" + i,
                percent: "percInf2" + i
              })}
              <br />

              @
              <input
                className="inputSLTPLarge"
                id={"sellInf3" + i}
                type="number"
                onChange={this.onChangeSLTP({idInput: "sellInf3" + i, idPercent: "percInf3" + i})}
              />
              <input
                className="inputSLTP"
                id={"percInf3" + i}
                type="number"
                pattern="[0-9]"
                onChange={this.onChangePercent("percInf3" + i)}
              />%
              <button
                className="btSLTP4"
                onClick={this.validAuto({
                  action: "closeInf",
                  value: "sellInf3" + i,
                  percent: "percInf3" + i,
                  indice: 2,
                  pair: this.state.ingamePositions[i].pair,
                  taillePosInit: this.state.ingamePositions[i].soldeCrypto,
                })}
              >
                &#x2705;
              </button>
              {this.displayEstimateInf({
                entree: this.state.ingamePositions[i].entree,
                solde: this.state.ingamePositions[i].soldeCrypto,
                levier: this.state.ingamePositions[i].levier,
                trigger: "sellInf3" + i,
                percent: "percInf3" + i
              })}
              <br />
            </div>
          </div>
        </div>
      );
    }

    return Positions;
  }
  //changer sl
  onChangeSLTP = (value) => (event) => {
    let idInput = value.idInput;
    let idPercent = value.idPercent

    if(!document.getElementById(idInput) || !document.getElementById(idPercent)) {
      return;
    }

    if(document.getElementById(idPercent).value === "") {
      document.getElementById(idPercent).value = 100;
    }


    if(!this.isNumeric(document.getElementById(idInput).value)) {
      alert("Invalid number !");
      document.getElementById(idInput).value = ""
      return;
    }



    return;
  };

  onChangePercent = (value) => (event) => {
    let idPercent = value



    if(!document.getElementById(idPercent)) {
      return;
    }  

    if(!this.isNumeric(document.getElementById(idPercent).value)) {
      document.getElementById(idPercent).value = 100
      return;
    }

    if(document.getElementById(idPercent).value === "") {
      document.getElementById(idPercent).value = 100;
      return;
    }

    if(Number(document.getElementById(idPercent).value) < 1 || document.getElementById(idPercent).value > 100) {
      document.getElementById(idPercent).value = 100
      return;
    }



    return;
  };



  isNumeric(num) {
    return (typeof(num) === 'number' || typeof(num) === "string") && !isNaN(num);
  }


  //Displayer of estimate gains
  displayEstimateSup(objet) {
    var entree = objet.entree;
    var solde = objet.solde;
    var levier = objet.levier;
    

    if(!document.getElementById(objet.trigger)) {
      return <span> </span>;
    }
    if(Number(document.getElementById(objet.trigger).value) === 0) {
      return <span> </span>;
    }
    if(Number(document.getElementById(objet.percent).value) === 0) {
      return <span> </span>;
    }

    var trigger =  document.getElementById(objet.trigger).value;
    var percent = document.getElementById(objet.percent).value;


    var variation = (trigger - entree) / entree;
    var gainBloc = solde * trigger * variation * levier ;


    var fees = (solde * entree + Number(gainBloc)) * 0.0005 * levier;
    gainBloc = gainBloc - fees;
  
    gainBloc = Number.parseFloat(gainBloc * (percent/100)).toFixed(0);
    var color = "aquamarine"
    if(gainBloc < 0) {
      color = "red";
    }
    return < span style={{color: color, fontSize: '0.9vw'}}> {gainBloc}$ </span>;
  }
  //
  displayEstimateInf(objet) {
    var entree = objet.entree;
    var solde = objet.solde;
    var levier = objet.levier;

    //console.log(objet.solde)
    

    if(!document.getElementById(objet.trigger)) {
      return <span> </span>;
    }
    if(Number(document.getElementById(objet.trigger).value) === 0) {
      return <span> </span>;
    }
    if(Number(document.getElementById(objet.percent).value) === 0) {
      return <span> </span>;
    }

    var trigger =  Number(document.getElementById(objet.trigger).value);
    var percent = document.getElementById(objet.percent).value;

    var variation = (trigger - entree) / entree;
    var gainBloc = Math.abs(solde) * trigger * variation * levier;

    var fees = (solde * entree + Number(gainBloc)) * 0.0005 * levier;
    gainBloc = gainBloc - fees;

   
    let shortindic = 1;

    if(objet.solde < 0) {
      shortindic = shortindic * -1 ;
    }

     gainBloc = Number.parseFloat(gainBloc * shortindic * (percent/100) ).toFixed(0);


    var color = "aquamarine"
    if(gainBloc < 0) {
      color = "red";
    }
    return <span style={{color: color, fontSize: '0.9vw'}}> {gainBloc}$ </span>;
  }

  //Parser des noms de paires
  displayCorrectPaire(paire) {
    if(paire === "BTCPERP") {
      return "Bitcoin";
    }
    if(paire === "ETHPERP") {
      return "Ethereum";
    }
    if(paire === "SOLPERP") {
      return "Solana";
    }
    if(paire === "AVAXPERP") {
      return "Avalanche";
    }
    if(paire === "DOGEPERP") {
      return "Dogecoin";
    }
    if(paire === "XRPPERP") {
      return "Ripple";
    }
    if(paire === "MATICPERP") {
      return "Matic";
    }
    if(paire === "LTCPERP") {
      return "Litecoin";
    }
    if(paire === "OPPERP") {
      return "Op";
    }
    if(paire === "ATOMPERP") {
      return "Atom";
    }
    if(paire === "INJPERP") {
      return "Injective";
    }
    if(paire === "ARBPERP") {
      return "Arbritrum";
    }
    if(paire === "BONKPERP") {
      return "Bonk";
    }
    if(paire === "NEARPERP") {
      return "Near";
    }
    if(paire === "RUNEPERP") {
      return "Rune";
    }
    if(paire === "RNDRPERP") {
      return "Render";
    }
    if(paire === "EGLDPERP") {
      return "Elrond";
    }
    if(paire === "TIAPERP") {
      return "Celestia";
    }
    if(paire === "SEIPERP") {
      return "Sei";
    }








  }


  //Affichage des ordres complx
  displayOrdres() {
    var Orders = [];

    Orders.push(<h3 key={"firstOne"}> Open orders </h3>);

    for (var i = 0; i < this.state.ingameOrdres.length; i++) {
      Orders.push(
        <div className="openOrders" key={"ingameOrdres" + i}>
          Action: {this.state.ingameOrdres[i].action} @{" "}
          {this.state.ingameOrdres[i].signe} {this.state.ingameOrdres[i].value}{" "}
          {this.state.ingameOrdres[i].action === "closeSup" ||
          this.state.ingameOrdres[i].action === "closeInf"
            ? this.state.ingameOrdres[i].percent + "% of "+ Number.parseFloat(this.state.ingameOrdres[i].taillePosInit).toFixed(5)  + " " + this.state.ingameOrdres[i].pair
            : "Size: " + Number.parseFloat(this.state.ingameOrdres[i].size).toFixed(2) + "$ " + this.state.ingameOrdres[i].pair
          }
        
          <button className="validLevier2" onClick={this.deleteOrdre(i,this.state.ingameOrdres[i].value, this.state.ingameOrdres[i].action)} key={"validLevier2" + i}>

            {" "}
            Suppr{" "}
          </button>
          {this.state.ingameOrdres[i].size !== undefined
            ? this.displayAttention(this.state.ingameOrdres[i].size)
            : ""}
        </div>
      );
    }

    return Orders;
  }

  //Affichage historique via logs
  displayHisto() {
    var hist = [];
    var color = "";

    hist.push(
      <h3 key={"h3histo1"}>
        Trade history
        <span
          className="chatSelector"
          style={{ cursor: "pointer" }}
          onClick={this.afficherGraph}
          title="Afficher sur le graphique les trades effectués"
        >
          {" "}
          Show on graph.{" "}
        </span>
        <span
          className="chatSelector"
          style={{ cursor: "pointer" }}
          onClick={this.cacherGraph}
          title="Cacher les trades passés"
        >
          {" "}
          Hide{" "}
      </span>
    </h3>);

    var logs = this.state.ingameLogs;

    for (var i = 0; i < logs.length; i++) {
      logs[i] = logs[i].replace(":", "");
      var indicateur = logs[i].split(" ");
      if (indicateur[0] === "[newPosition]") {
        logs[i] = logs[i].replace("Type: ", "");

        logs[i] = logs[i].replace("HAUSSE", "Long");
        logs[i] = logs[i].replace("BAISSE", "Short");

        if (logs[i].includes("Long")) {
          color = "#11d89c";
        }
        if (logs[i].includes("Short")) {
          color = "#ff5757";
        }

        var DELIMITER = " ";
        var parts = logs[i].split(DELIMITER);
        var t = parts[3];
        parts[3] = "";
        logs[i] = parts.join(DELIMITER);

        indicateur = logs[i].split(" ");

        hist.push(
          <span style={{ color: color }} key={"h3histo1"+i}>
            {"Entry @ " +
              indicateur[6] +
              " de " +
              t +
              " " +
              indicateur[4] +
              " " +
              indicateur[7]}
            <br />
          </span>
        );
      }
      if (indicateur[0] === "[reducePosition]") {
        hist.push(
          <span style={{ color: "orange" }} key={"h3histo1"+i}>
            {"     Reduction @ " +
              indicateur[6] +
              " de " +
              Number.parseFloat(indicateur[3]).toFixed(0) +
              "$ en " +
              indicateur[4]}
            <br />
          </span>
        );
      }
      if (indicateur[0] === "[augmentPosition]") {
        hist.push(
          <span style={{ color: "cyan" }} key={"h3histo1"+i}>
            {"     Augmentation @ " +
              indicateur[8] +
              " de " +
              indicateur[5] +
              " en " +
              indicateur[3]}
            <br />
          </span>
        );
      }
      if (indicateur[0] === "[closePosition]") {
        hist.push(
          <span style={{ color: color }} key={"h3histo1"+i}>
            {"Close @ " + indicateur[5] + " Gain :"}{" "}
            <span style={{ color: "white" }}> {indicateur[7]}</span> {" Fees: "}
            <span style={{ color: "white" }}> {indicateur[10]} </span>
            <br />
            <br />
          </span>
        );
      }
      if (indicateur[0] === "[Liquidation]") {
        hist.push(
          <span style={{ color: "#e96bff" }} key={"h3histo1"+i}>
            Liquidation {indicateur[3]} @ {indicateur[5]}
            <br />
          </span>
        );
      }
    }

    return <div>{hist}</div>;
  }

  //Estimation des gains pour autocloture
  estimateGain(pair, trigger) {
    var indicePosition = 0;

    for (var i = 0; i < this.state.ingamePositions.length; i++) {
      if (this.state.ingamePositions[i].pair === pair) {
        indicePosition = i;
      }
    }

    if (this.state.ingamePositions.length === 0) {
      return;
    }

    var entree = this.state.ingamePositions[indicePosition].entree;
    var soldeCrypto = this.state.ingamePositions[indicePosition].soldeCrypto;
    var levier = this.state.ingamePositions[indicePosition].levier;
    //var fees = 0.0005;

    var variation = (trigger - entree) / entree;

    var gainBloc = soldeCrypto * trigger * variation * levier;
    gainBloc = Number.parseFloat(gainBloc).toFixed(2);
    //fees = (soldeCrypto * entree + gainBloc) * levier;

    return (
      <span>
        {" "}
        Gain: {gainBloc}${" "}
      </span>
    );
  }

  displayAttention(size) {
    if (Math.abs(Number(size)) > Number(this.state.ingameSoldeUSDT)) {
      return (
        <span title="Vous manquez de capital pour réaliser cet ordre !">
          {" "}
          <img
            className="warning"
            src={".././images/warning.png"}
            alt="enPosition"
          />{" "}
        </span>
      );
    }
  }

  //Suppression d'un ordre
  deleteOrdre = (id, value, action) => (event) => {
    event.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      id: this.props.tournoisActif.ID,
      idOrdre: id,
      pseudo: this.props.username,
      uuid: this.props.uuid ? this.props.uuid : undefined,
      jwt: this.props.jwt ? this.props.jwt : undefined,
      crdt: this.props.crdt ? this.props.crdt : undefined,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: raw,
    };


    fetch(ipBackend + "/player/deleteorder", requestOptions)
      .then((response) => response.text())
      .then((result) => {});
    

  };

  //Affiche le PNL de l'indice de la position en param
  displayPNL(i) {
    var valuePair = this.returnPriceAsset(this.state.ingamePositions[i].pair);
    var pnlc = 0;
    //var pnlp = 0;

    if (i !== undefined) {
      /*
      pnlc =
        valuePair *
          this.state.ingamePositions[i].soldeCrypto *
          * this.state.ingamePositions[i].levier -
        this.state.ingamePositions[i].entree *
          this.state.ingamePositions[i].soldeCrypto *
          this.state.ingamePositions[i].levier;*/

      pnlc =
        valuePair *
          this.state.ingamePositions[i].soldeCrypto *
          this.state.ingamePositions[i].levier -
        this.state.ingamePositions[i].entree *
          this.state.ingamePositions[i].soldeCrypto *
          this.state.ingamePositions[i].levier;
      //pnlc = pnlc - (this.state.ingamePositions[i].soldeCrypto * this.state.ingamePositions[i].levier * valuePair * 0.0005);

      var variation =
        ((this.state.ingamePositions[i].soldeCrypto +
          pnlc / valuePair -
          this.state.ingamePositions[i].soldeCrypto) /
          this.state.ingamePositions[i].soldeCrypto) *
        100;

      //var fees = ((this.state.ingamePositions[i].soldeCrypto*this.state.ingamePositions[i].entree) + pnlc)*0.0005*this.state.ingamePositions[i].levier;

      var varColor;
      if (pnlc > 0) {
        varColor = "#11d89c";
      } else {
        varColor = "#ff7a7a";
      }
    }

    return (
      <span style={{ color: varColor }}>
        {" "}
        {Number.parseFloat(pnlc).toFixed(2)} (
        {Number.parseFloat(variation).toFixed(2)}%){" "}
      </span>
    );
  }

  //Affiche la taille de la position en cours
  displayTaillePos() {
    return (
      <p>
        Position:{" "}
        <b style={{ color: "#D8D357" }}>
          {" "}
          {Number.parseFloat(
            this.state.ingameSoldeCrypto * this.state.ingameValuepair
          ).toFixed(2)}
          $
        </b>{" "}
      </p>
    );
  }

  //Affiche la fenetre de fin de partie (resultats)
  async displayIngameResults() {
    this.setState({ tournoisActif: 0 });
    this.setState({ indicetournoisActif: null });

    //document.getElementById("renderTournament").style.display = 'none';

    document.getElementById("renderPlayIG").style.display = "none";
    document.getElementById("renderFinish").style.display = "block";

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      pseudo: this.props.username,
      id: this.props.tournoisActif.ID,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: raw,
    };

    await fetch(ipBackend + "/tournaments/getfinalresult/", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        var finish;
        if (JSON.parse(result).LOCKED === 1) {
          finish = "Please wait...";
          this.setState({ renderFinish: finish });

          this.sleep(2000);
          this.displayIngameResults();
        } else {
          this.setState({ tournoisActif: 0 });

          finish = JSON.stringify(result);
          this.setState({ renderFinish: finish });

          var finishJrs = JSON.parse(result).JOUEURS;
          var finishJrsSc = JSON.parse(result).SCORES;

          for (var k = 0; k < finishJrs.length; k++) {
            finishJrs[k] = finishJrs[k] + "\n";
            finishJrsSc[k] = Number.parseFloat(
              -(1 - finishJrsSc[k] / 1000) * 100
            ).toFixed(2);
            finishJrsSc[k] = finishJrsSc[k] + "\n";
          }

          this.setState({ finishJoueurs: finishJrs });
          this.setState({ finishScores: finishJrsSc });
          this.setState({ finishXp: JSON.parse(result).XPGAGNEE });
          this.setState({ finishCote: JSON.parse(result).COTEGAGNEE });
          this.setState({ finishTag: JSON.parse(result).TAGGAGNEES });

          this.setState({ finishPosition: JSON.parse(result).PLACE });
          this.setState({
            finishScore: Number.parseFloat(JSON.parse(result).SCORE).toFixed(2),
          });
          this.setState({ finishGroupe: JSON.parse(result).GROUPES });


          
          



          for (k = 0; k < finishJrs.length; k++) {
            if (k === 0 || k === 1 || k === 2) {
              var psd = finishJrs[k].replace("\n", "");
              var score = finishJrsSc[k];



              this.setStatePodium(psd, score);

             

              

            }
          }
        } //End of locked
      });
  }


  setStatePodium(psd, score) {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({ PSEUDO: psd }),
      redirect: "follow",
    };

    var joueurPodium = {
            pseudo: "",
            score: "",
            embleme: "",
            cadre: ""
          }
    

    fetch(apiURL + "/dev/getrewards", requestOptions)
    .then((response) =>  response.text())
    .then((result) => {
        joueurPodium = {
          pseudo: psd,
          score: score,
          embleme: JSON.parse(result).SELECEMBLEME,
          cadre: JSON.parse(result).SELECCADRE
        }
        var podiumPseudos = this.state.podiumPseudos;
        podiumPseudos.push(joueurPodium);
        this.setState({ podiumPseudos: podiumPseudos});
    })
    .catch((error) => console.log("error", error));
  }




  displayPodium()
  {

    var displayer = [];
    for (var i = 0; i < this.state.podiumPseudos.length; i++) {
      displayer.push(
        <>
          <div className="tiersIG">
            <div className="cadrePodiumEndgame">
              <img
                className="imageCadrePodiumEndgame"
                src={
                  "./images/rewards/cadres/" + this.state.podiumPseudos[i].cadre + ".png"
                }
                alt="cadre"
              />
            </div>
            <div className="emblemePodiumEndgame">
              <img
                className="imageEmblemePodiumEndgame"
                src={
                  "./images/rewards/emblemes/" + this.state.podiumPseudos[i].embleme + ".png"
                }
                alt="cadre"
              />
            </div>
            <div className="psdPodiumEndgame">
              {i + 1 + "." + this.state.podiumPseudos[i].pseudo} <br />
              {this.state.podiumPseudos[i].score}
            </div>
          </div>
        </>
      );
    }
    return <div className="podiumEndgameFunc"> {displayer} </div>;
  }

  displayGroupResults() {
    var finishGroupe = this.state.finishGroupe;
    var List = [];

    //"groupes":{"noms":["Alpha","Beta"],"couleurs":["#00f7ff","#fff000"],"scores":[1000,null],"nbJoueurs":[1,0],"pseudos":["bazoukiwi\n",""]}}
    if (
      this.state.finishGroupe !== undefined &&
      Object.keys(finishGroupe).length !== 0
    ) {
      if (finishGroupe.noms.length > 0) {
        List.push(
          <div className="titreGrosGroupeRes"> Resultats de groupe </div>
        );
      }
      //Boucle des groupes
      for (var i = 0; i < finishGroupe.noms.length; i++) {
        List.push(
          <div className="groupeRes">
            <div
              style={{ color: this.state.finishGroupe.couleurs[i] }}
              className="titreGroupeRes"
            >
              {this.state.finishGroupe.noms[i]}
            </div>
            <div className="scoreGroupeRes">
              {this.state.finishGroupe.scores[i]}
            </div>
            <div className="psdGroupeRes">
              {this.state.finishGroupe.pseudos[i]}
            </div>
          </div>
        );
      }
    }

    return List;
  }

  //Affiche le profil public
  openPP = (pseudo) => (event) => {
    event.preventDefault();
    try {
      this.setState({ loading: true });
      /*
           document.getElementById("tvct").style.display = "none";
           document.getElementById("commands").style.display = "none";
           document.getElementById("recapingame").style.display = "none";
           document.getElementById("comLevierToggle").style.display = "none";
           
           document.getElementById("stratBut").style.display = "none";*/

      this.setState({ joueurpseudo: pseudo });

      instanceAWS
        .post("/dev/getrewards", { PSEUDO: pseudo })
        .then((response) => {
          const result = response.data;
          this.setState({ joueurembleme: result.SELECEMBLEME });
          this.setState({ joueurcadre: result.SELECCADRE });
          this.setState({ joueurcitation: result.SELECCITATION });
          this.setState({ joueurstat: result.SELECSTAT });
          this.setState({ joueurbordure: result.SELECBORDURE });
          this.setState({ joueurlvl: result.LVL });
          this.setState({ joueurcr: result.CR });

          var valRetour = getValFromCitation(this.state.joueurcitation);
          
          if(result.REFERAL === true) {
            valRetour = 
              <a
                href={result.URL}
                style={{ color: "#e7ce4a" }}
                target="_blank"
                rel="noreferrer"
              >
                {getValFromCitation(this.state.joueurcitation)}
              </a> ;
          }

          this.setState({ displaycitation: valRetour });

         //circulation system
          instanceAWS
          .get("/dev/getcirculation")
          .then((response) => {
            const result = response.data;

            var parsed = result.data
            //alert(JSON.stringify(parsed));
          

            var circuPP = "Nb total of items in circulation: \n"

            for (var i = 0; i < parsed.length; i++) {
              if(parsed[i].REWARD === this.state.joueurembleme) {
                //this.setState({circuEmbleme: result[i].NBTOTAL});
                circuPP += "Avatar: "+parsed[i].NBTOTAL+"\n";
              }
              if(parsed[i].REWARD === this.state.joueurcadre) {
                //this.setState({circuCadre: result[i].NBTOTAL});
                circuPP += "Fond: "+parsed[i].NBTOTAL+"\n";
              }
              if(parsed[i].REWARD === this.state.joueurbordure) {
                //this.setState({circuBordure: result[i].NBTOTAL});
                circuPP += "Bordure: "+parsed[i].NBTOTAL+"\n";
              }
            }

            //alert(circuPP);

            this.setState({circuPP: circuPP});

          })

          .catch((error) => console.log("error", error));
        })

        .catch((error) => console.log("error", error));
      //
      //var caller = this;

      instanceAWS
        .post("/dev/getstats", { PSEUDO: pseudo })
        .then((response) => {
          const result = response.data;
          this.setState({ stat1: result.NBTOURNOIS });
          this.setState({ stat2: result.MPLACE });
          this.setState({ stat3: result.MTRADE });
          this.setState({ stat4: result.MSCORE });
          this.setState({ stat5: result.WTRADE });
          this.setState({ stat6: result.NBPODIUM });
          this.setState({ stat7: result.NBTRADES });
          this.setState({ stat8: result.NBLIQUIDATIONS });
          this.setState({ stat9: result.BPLACE });
          this.setState({ stat10: result.NBVICTOIRE });
          this.setState({ stat11: result.MPALLIER });

          if (this.state.joueurstat !== 0) {
            var valRetour = "";
            if (this.state.joueurstat === "stat1") {
              valRetour = "Number of tournaments\n" + this.state.stat1;
            } else if (this.state.joueurstat === "stat2") {
              valRetour = "Best place\n" + this.state.stat2;
            } else if (this.state.joueurstat === "stat3") {
              valRetour = "Best trade\n" + this.state.stat3;
            } else if (this.state.joueurstat === "stat4") {
              valRetour = "Best score\n" + this.state.stat4;
            } else if (this.state.joueurstat === "stat5") {
              valRetour = "Worst trade\n" + this.state.stat5;
            } else if (this.state.joueurstat === "stat6") {
              valRetour = "Number of podiums\n" + this.state.stat6;
            } else if (this.state.joueurstat === "stat7") {
              valRetour = "Number of trades\n" + this.state.stat7;
            } else if (this.state.joueurstat === "stat8") {
              valRetour = "Number of liquidations\n" + this.state.stat8;
            } else if (this.state.joueurstat === "stat9") {
              valRetour = "Best leaderboard place\n" + this.state.stat9;
            } else if (this.state.joueurstat === "stat10") {
              valRetour = "Number of 1st places\n" + this.state.stat10;
            } else if (this.state.joueurstat === "stat11") {
              var retour = "";
              if(this.state.stat11 === 0) {
                retour = "nc"
              }
              if(this.state.stat11 === 5) {
                retour = "Aspirant"
              }
              if(this.state.stat11 === 4) {
                retour = "Silver"
              }
              if(this.state.stat11 === 3) {
                retour = "Gold"
              }
              if(this.state.stat11 === 2) {
                retour = "Diamond"
              }
              if(this.state.stat11 === 1) {
                retour = "Emerald"
              }
              valRetour = "Best league\n" + retour;
            }

            this.setState({ displaystat: valRetour });
          } else {
            this.getRewards(pseudo);
          }

          this.setState({ loading: false });
        })

        .catch((error) => console.log("error", error));
       //
      instanceAWS
        .post("/dev/getranking", { USERNAME: pseudo, MODE: "SOLO" })
        .then((response) => {
          var ligue = response.data.ligueUser;
          this.setState({ joueurligue: ligue });
        })

        .catch((error) => console.log("error", error));

    
      document.getElementById("playerView").style.display = "block";
    } catch (error) {
      console.log(error.message);
    }
  };
  //Ferme le profil public
  closeProfile() {
    document.getElementById("playerView").style.display = "none";
  }

  ///////////////////////////////////INGAME FONCTIONS////////////////////////////
  // Bloc des fonctions de jeu //

  //Achat
  buy = async (event) => {
    event.preventDefault();
    try {
      document.getElementById("buyMode").style.display = "none";
      document.getElementById("buyMode").disabled = true;

      document.getElementById("pValue").value = Number(
        this.state.ingameSoldeUSDT * (this.state.valueBuy / 100)
      );
      document.getElementById("pValuetwo").value = -Number(
        this.state.ingameSoldeUSDT * (this.state.valueBuy / 100)
      );

      if (this.state.ingameModeSimple === false) {
        if (
          document.getElementById("inputComplex").value === "" ||
          document.getElementById("inputComplex").value === "0"
        ) {
          alert("Please choose a trigger value on the field: Trigger price");
          document.getElementById("buyMode").style.display = "inline-block";
          document.getElementById("buyMode").disabled = false;
          return;
        }

        var value = document.getElementById("inputComplex").value;

        var somme = document.getElementById("pValue").value;

        var val = document.getElementById("inputBuy").value;

        if (val) {
          somme = val;
        }

        const body = {
          action: "long",
          size: somme,
          value: value,
          pair: this.state.ingamePaire,
          id: this.props.tournoisActif.ID,
          pseudo: this.props.username,
          uuid: this.props.uuid ? this.props.uuid : undefined,
          jwt: this.props.jwt ? this.props.jwt : undefined,
          crdt: this.props.crdt ? this.props.crdt : undefined,
        };

        instanceTournoi
          .post("/player/autoAction", body)
          .then((response) => {})
          .catch((error) => console.log("error", error));

        document.getElementById("buyMode").style.display = "inline-block";
        document.getElementById("buyMode").disabled = false;

        return;
      }

      somme = 0;

      if (this.state.ingamePari === "") {
        somme = this.state.ingameSoldeUSDT * (this.state.valueBuy / 100);
      } else if (this.state.ingamePari === "HAUSSE") {
        somme = this.state.ingameSoldeUSDT * (this.state.valueBuy / 100);
      } else if (this.state.ingamePari === "BAISSE") {
        somme =
          -this.state.ingameValuepair *
          this.state.ingameSoldeCrypto *
          (this.state.valueBuy / 100);
      }

      val = document.getElementById("inputBuy").value;
      if (val) {
        somme = val;
      }

      const body = {
        id: this.props.tournoisActif.ID,
        pseudo: this.props.username,
        somme: somme,
        pair: this.state.ingamePaire,
        uuid: this.props.uuid ? this.props.uuid : undefined,
        jwt: this.props.jwt ? this.props.jwt : undefined,
        crdt: this.props.crdt ? this.props.crdt : undefined,
      };

      instanceTournoi
        .post(ipBackend + "/player/buy", body)
        .then(async (response) => {
          document.getElementById("buyMode").style.display = "inline-block";
          document.getElementById("buyMode").disabled = false;

          if (response.data === "CLOT") {
            document.getElementById("pValue").value = Number(
              this.state.ingameSoldeUSDT * (this.state.valueBuy / 100)
            );
            document.getElementById("pValuetwo").value = -Number(
              this.state.ingameSoldeUSDT * (this.state.valueBuy / 100)
            );
          } 
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error.message);
    }
  };

  //Vente
  sell = async (event) => {
    event.preventDefault();
    try {
       

      document.getElementById("sellMode").style.display = "none";
      document.getElementById("sellMode").disabled = true;

      document.getElementById("pValue").value = Number(
        this.state.ingameSoldeUSDT * (this.state.valueBuy / 100)
      );
      document.getElementById("pValuetwo").value = -Number(
        this.state.ingameSoldeUSDT * (this.state.valueBuy / 100)
      );

      var somme = document.getElementById("pValuetwo").value;

      if (this.state.ingameModeSimple === false) {
        if (
          document.getElementById("inputComplex").value === "" ||
          document.getElementById("inputComplex").value === "0"
        ) {
          alert("Please choose a trigger value on the field: Trigger price");
          document.getElementById("sellMode").style.display = "inline-block";
          document.getElementById("sellMode").disabled = false;
          return;
        }

        var value = document.getElementById("inputComplex").value;

        var val = document.getElementById("inputSell").value;

        if (val) {
          somme = -val;
        }


        var body = JSON.stringify({
          action: "short",
          size: somme,
          value: value,
          pair: this.state.ingamePaire,
          id: this.props.tournoisActif.ID,
          pseudo: this.props.username,
          uuid: this.props.uuid ? this.props.uuid : undefined,
          jwt: this.props.jwt ? this.props.jwt : undefined,
          crdt: this.props.crdt ? this.props.crdt : undefined,
        });



        instanceTournoi
          .post("/player/autoAction", body)
          .then((response) => {})
          .catch((error) => console.log("error", error));

        document.getElementById("sellMode").style.display = "inline-block";
        document.getElementById("sellMode").disabled = false;

        return;
      }

      somme = 0;

      if (this.state.ingamePari === "") {
        somme = -this.state.ingameSoldeUSDT * (this.state.valueBuy / 100);
      } else if (this.state.ingamePari === "HAUSSE") {
        somme =
          -this.state.ingameValuepair *
          this.state.ingameSoldeCrypto *
          (this.state.valueBuy / 100);
      } else if (this.state.ingamePari === "BAISSE") {
        somme = -this.state.ingameSoldeUSDT * (this.state.valueBuy / 100);
      }

      val = document.getElementById("inputSell").value;
      if (val) {
        somme = -val;
        somme = somme + 0.1;
      }

      const body2 = {
        id: this.props.tournoisActif.ID,
        uuid: this.props.uuid ? this.props.uuid : undefined,
        jwt: this.props.jwt ? this.props.jwt : undefined,
        crdt: this.props.crdt ? this.props.crdt : undefined,
        pseudo: this.props.username,
        somme: somme,
        pair: this.state.ingamePaire,
      };

      instanceTournoi
        .post("/player/sell", body2)
        .then(async (response) => {
          document.getElementById("sellMode").style.display = "inline-block";
          document.getElementById("sellMode").disabled = false;

          if (response.data === "CLOT") {
            document.getElementById("pValue").value = Number(
              this.state.ingameSoldeUSDT * (this.state.valueBuy / 100)
            );
            document.getElementById("pValuetwo").value = -Number(
              this.state.ingameSoldeUSDT * (this.state.valueBuy / 100)
            );
          } 
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error.message);
    }
  };

  //Cloture de la position
  cloturer = (value) => (event) => {
    event.preventDefault();
    try {
      //document.getElementById("cloturer").disabled = 'true';

      const body = {
        id: this.props.tournoisActif.ID,
        pair: value,
        pseudo: this.props.username,
        uuid: this.props.uuid ? this.props.uuid : undefined,
        jwt: this.props.jwt ? this.props.jwt : undefined,
        crdt: this.props.crdt ? this.props.crdt : undefined,
      };

      instanceTournoi
        .post("/player/cloturerPaire", body)
        .then((response) => {
          if (response.data === "OK") {
            document.getElementById("inputSLTP1Brut").value = "";
            document.getElementById("inputSLTP2Brut").value = "";
            document.getElementById("inputSLTP1").value = "";
            document.getElementById("inputSLTP2").value = "";
            // document.getElementById("ingameOrders").style.display = 'none';
            document.getElementById("pValue").value = Number(
              this.state.ingameSoldeUSDT * (this.state.valueBuy / 100)
            );
            document.getElementById("pValuetwo").value = -Number(
              this.state.ingameSoldeUSDT * (this.state.valueBuy / 100)
            );
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error.message);
    }
  };

  showPositionsDiv = (event) => {
    event.preventDefault();
    try {
      document.getElementById("positionsPanel").style.display = "block";
      document.getElementById("ordersPanel").style.display = "none";
      document.getElementById("histoPanel").style.display = "none";
    } catch (error) {
      console.log(error.message);
    }
  };

  showOrdersDiv = (event) => {
    event.preventDefault();
    try {
      document.getElementById("positionsPanel").style.display = "none";
      document.getElementById("ordersPanel").style.display = "block";
      document.getElementById("histoPanel").style.display = "none";
    } catch (error) {
      console.log(error.message);
    }
  };

  showHistoDiv = (event) => {
    event.preventDefault();
    try {
      document.getElementById("positionsPanel").style.display = "none";
      document.getElementById("ordersPanel").style.display = "none";
      document.getElementById("histoPanel").style.display = "block";
    } catch (error) {
      console.log(error.message);
    }
  };

  validerOrdre = (event) => {
    event.preventDefault();
    try {
      if (this.state.ingameOrdresPair === "") {
        alert("Selectionnez une paire !");
        return;
      }
      if (this.state.ingameOrdresAction === "") {
        alert("Selectionnez une action !");
        return;
      }
      if (document.getElementById("valueAction").value === "") {
        alert("Saisissez une valeur !");
        return;
      }

      const body = {
        action: this.state.ingameOrdresAction,
        value: document.getElementById("valueAction").value,
        pair: this.state.ingameOrdresPair,
        id: this.props.tournoisActif.ID,
        pseudo: this.props.username,
        uuid: this.props.uuid ? this.props.uuid : undefined,
        jwt: this.props.jwt ? this.props.jwt : undefined,
        crdt: this.props.crdt ? this.props.crdt : undefined,
      };

      instanceTournoi
        .post("/player/autoAction", body)
        .then((response) => {})
        .catch((error) => console.log("error", error));

      var idpair = "ordre" + this.state.ingameOrdresPair;
      var idaction = "ordre" + this.state.ingameOrdresAction;

      document.getElementById(idpair).style.color = "white";
      document.getElementById(idaction).style.color = "white";

      document.getElementById("valueAction").value = "";
      this.setState({ ingameOrdresPair: "" });
      this.setState({ ingameOrdresAction: "" });
    } catch (error) {
      console.log(error.message);
    }
  };

  setOrdrePair = (value) => (event) => {
    event.preventDefault();
    try {
      var idpair = "ordre" + this.state.ingameOrdresPair;

      if (this.state.ingameOrdresPair !== "") {
        document.getElementById(idpair).style.color = "white";
      }

      this.setState({ ingameOrdresPair: value });

      idpair = "ordre" + value;
      document.getElementById(idpair).style.color = "#ff5757";
    } catch (error) {
      console.log(error.message);
    }
  };

  setOrdreAction = (value) => (event) => {
    event.preventDefault();
    try {
      var idpair = "ordre" + this.state.ingameOrdresAction;

      if (this.state.ingameOrdresAction !== "") {
        document.getElementById(idpair).style.color = "white";
      }

      this.setState({ ingameOrdresAction: value });

      idpair = "ordre" + value;
      document.getElementById(idpair).style.color = "#ff5757";
    } catch (error) {
      console.log(error.message);
    }
  };

  toutcloturer = (event) => {
    event.preventDefault();
    try {
      //document.getElementById("cloturer").disabled = 'true';

      const body = {
        id: this.props.tournoisActif.ID,
        pseudo: this.props.username,
        uuid: this.props.uuid ? this.props.uuid : undefined,
        jwt: this.props.jwt ? this.props.jwt : undefined,
        crdt: this.props.crdt ? this.props.crdt : undefined,
      };

      instanceTournoi
        .post("/player/cloturerPaires", body)
        .then((response) => {
          if (response.data === "OK") {
            document.getElementById("inputSLTP1Brut").value = "";
            document.getElementById("inputSLTP2Brut").value = "";
            document.getElementById("inputSLTP1").value = "";
            document.getElementById("inputSLTP2").value = "";
            document.getElementById("pValue").value = Number(
              this.state.ingameSoldeUSDT * (this.state.valueBuy / 100)
            );
            document.getElementById("pValuetwo").value = -Number(
              this.state.ingameSoldeUSDT * (this.state.valueBuy / 100)
            );
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error.message);
    }
  };

  setSimple = (event) => {
    event.preventDefault();
    try {
      this.setState({ ingameModeSimple: true });
      document.getElementById("selectSimple").style.color = "white";
      document.getElementById("selectComplex").style.color = "grey";
    } catch (error) {
      console.log(error.message);
    }
  };

  setComplex = (event) => {
    event.preventDefault();
    try {
      this.setState({ ingameModeSimple: false });
      document.getElementById("selectSimple").style.color = "grey";
      document.getElementById("selectComplex").style.color = "white";
    } catch (error) {
      console.log(error.message);
    }
  };

  //Appel fonction changement de levier
  setLevier = (event) => {
    event.preventDefault();
    try {
      var levier = document.getElementById("inputLevier").value;

      if (levier === "") {
        return;
      }

      const body = {
        id: this.props.tournoisActif.ID,
        levier: levier,
        pseudo: this.props.username,
        uuid: this.props.uuid ? this.props.uuid : undefined,
        jwt: this.props.jwt ? this.props.jwt : undefined,
        crdt: this.props.crdt ? this.props.crdt : undefined,
      };

      instanceTournoi.post("/player/setlevier", body).then((response) => {});
    } catch (error) {
      console.log(error.message);
    }
  };

  //Appel fonction changement pair
  setPair = (value) => async (event) => {
    event.preventDefault();
    try {
      var symbol = "bybit:" + value.replace("PERP", "USDT");
      this.setSymbol(symbol.toLowerCase());

      const body = JSON.stringify({
        id: this.props.tournoisActif.ID,
        pair: value,
        pseudo: this.props.username,
        uuid: this.props.uuid ? this.props.uuid : undefined,
        jwt: this.props.jwt ? this.props.jwt : undefined,
        crdt: this.props.crdt ? this.props.crdt : undefined,
      });

      await instanceTournoi
        .post("/player/setpair", body)
        .then((response) => {
          this.setState({ ingameVisuals: value });
          this.setState({ ingamePaire: value });
          
          this.closePairSelector();
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error.message);
    }
  };

  areDataReady() {
     return this.child.current.areDataReady();
  }


  externalTradingViewCall = (value) => {
   // event.preventDefault();
    try {
      alert(JSON.stringify(value))
      var action = "close"+value.sens;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var requestOptions;

      var raw;

      if(value.close === true) {

         for (var i = 0; i < this.state.ingameOrdres.length; i++) {
          if(this.state.ingameOrdres[i].action === action && this.state.ingameOrdres[i].pair === value.paire) {
            

            raw = JSON.stringify({
              id: this.props.tournoisActif.ID,
              idOrdre: i,
              pseudo: this.props.username,
              uuid: this.props.uuid,
              jwt: this.props.jwt,
            });

            requestOptions = {
              method: "POST",
              headers: myHeaders,
              redirect: "follow",
              body: raw,
            };

            fetch(ipBackend + "/player/deleteorder", requestOptions)
              .then((response) => response.text())
              .then((result) => {
                return;
              });
          }
         }
      }

      else {

        action = "close"+value.sens;
        var valueElement = value.price;
        //alert(this.state.ingamePaire);
        var pair = value.paire;


        raw = JSON.stringify({
          action: action,
          value: valueElement,
          pair: pair,
          id: this.props.tournoisActif.ID,
          pseudo: this.props.username,
          uuid: this.props.uuid,
          jwt: this.props.jwt,
        });

        requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
        };

        fetch(ipBackend + "/player/autoAction", requestOptions)
          .then((response) => response.text())
          .then((result) => {
            if (result === "OK") {
            }
          })
          .catch((error) => console.log("error", error));


      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //Fonction d'assainissement des entrees SL TP
  validAuto = (value) => async (event) => {
    event.preventDefault();
    try {
      var action = value.action;
      var valueElement = document.getElementById(value.value).value;

      var pair = value.pair;

      if(valueElement === "") {
        alert("Invalid number !")
        return
      }
      if(action === 'closeSup') {
        if(Number(valueElement) <= Number(this.returnPriceAsset(pair)) ) {
          alert("This trade will trigger instantly. Please input another value.");
          return;
        }
      }
      if(action === 'closeInf') {
        if(Number(valueElement) >= Number(this.returnPriceAsset(pair))) {
          alert("This trade will trigger instantly. Please input another value.");
          return;
        }
      }


      

      const body = {
        action: action,
        value: valueElement,
        pair: pair,
        id: this.props.tournoisActif.ID,
        pseudo: this.props.username,
        uuid: this.props.uuid ? this.props.uuid : undefined,
        jwt: this.props.jwt ? this.props.jwt : undefined,
        crdt: this.props.crdt ? this.props.crdt : undefined,
      };

      if(action === "closeSup" || action === "closeInf" ) {
        body.percent = document.getElementById(value.percent).value;
        body.indice = value.indice;
        body.taillePosInit = value.taillePosInit;

        if(!this.isNumeric(valueElement)) {
          alert("Invalid number !");
          return;
        }

        if(!this.isNumeric(document.getElementById(value.percent).value)) {
          alert("Invalid number !");
          return;
        }
      }

      instanceTournoi
        .post("/player/autoAction", body)
        .then((response) => {
          if (response.data === "OK") {
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error.message);
    }
  };

  //////////////////////////FONCTIONS HELPERS/////////////////////////:

  onChangeInputBuy = (event) => {
    var val = document.getElementById("inputBuy").value;
    if (val) {
      this.setState({ mainDisabled: true });

      if (this.state.ingamePari === "HAUSSE" || this.state.ingamePari === "") {
        if (val > this.state.ingameSoldeUSDT) {
          document.getElementById("inputBuy").value = Number.parseFloat(
            this.state.ingameSoldeUSDT - 1
          ).toFixed(0);
        }
      }

      if (this.state.ingamePari === "BAISSE") {
        var max = Number.parseFloat(
          this.state.ingameSoldeCrypto *
            this.returnPriceAsset(this.state.ingamePaire)
        ).toFixed(0);
        if (Number(val) > -Number(max)) {
          document.getElementById("inputBuy").value = -max;
        }
      }
    } else {
      this.setState({ mainDisabled: false });
    }
  };

  onChangeInputSell = (event) => {
    var val = document.getElementById("inputSell").value;
    if (val) {
      this.setState({ mainDisabled: true });

      this.setState({ mainDisabled: true });

      if (this.state.ingamePari === "BAISSE" || this.state.ingamePari === "") {
        if (val > this.state.ingameSoldeUSDT) {
          document.getElementById("inputSell").value = Number.parseFloat(
            this.state.ingameSoldeUSDT - 1
          ).toFixed(0);
        }
      }

      if (this.state.ingamePari === "HAUSSE") {
        var max = Number.parseFloat(
          this.state.ingameSoldeCrypto *
            this.returnPriceAsset(this.state.ingamePaire)
        ).toFixed(0);
        if (Number(val) > Number(max)) {
          document.getElementById("inputSell").value = max;
        }
      }
    } else {
      this.setState({ mainDisabled: false });
    }
  };

  returnAcheter() {
    var prix = Number.parseFloat(
      this.state.ingameSoldeUSDT * (this.state.valueBuy / 100)
    ).toFixed(0);

    var prixReduction = Number.parseFloat(
      -(
        this.state.ingameValuepair *
        this.state.ingameSoldeCrypto *
        (this.state.valueBuy / 100)
      )
    ).toFixed(0);

    if (document.getElementById("inputBuy")) {
      var valInputBuy = document.getElementById("inputBuy").value;
      if (valInputBuy) {
        prix = valInputBuy;
        prixReduction = valInputBuy;
      }
    }

    if (this.state.ingameModeSimple === false) {
      return (
        <span>
          {" "}
          <b> Order Long </b> <br /> {prix}$
          <br />
          {Number.parseFloat(prix / this.state.ingameValuepair).toFixed(5)}{" "}
        </span>
      );
    }
    //Ouvrir
    if (this.state.ingamePari === "") {
      return (
        <span>
          {" "}
          <b> Buy/Long </b> <br /> {prix}$
          <br />
          {Number.parseFloat(prix / this.state.ingameValuepair).toFixed(5)}{" "}
        </span>
      );
    }
    //Augmenter
    else if (this.state.ingamePari === "HAUSSE") {
      return (
        <span>
          {" "}
          <b> Buy/Long </b> <br /> {prix}$
          <br />
          {Number.parseFloat(prix / this.state.ingameValuepair).toFixed(5)}{" "}
        </span>
      );
      //document.getElementById("pValuetwo").value = Number((this.state.ingameSoldeCrypto*(this.state.valueBuy/100)));
    }
    //Réduire
    else if (this.state.ingamePari === "BAISSE") {
      return (
        <span>
          {" "}
          <b> Buy/Long </b> <br /> {prixReduction}$
          <br />
          {Number.parseFloat(
            prixReduction / this.state.ingameValuepair
          ).toFixed(5)}{" "}
        </span>
      );
      //document.getElementById("pValuetwo").value = -Number((this.state.ingameSoldeUSDT*(this.state.valueBuy/100)));
    }
  }

  returnVendre() {
    var prix = Number.parseFloat(
      this.state.ingameSoldeUSDT * (this.state.valueBuy / 100)
    ).toFixed(0);

    var prixReduction = Number.parseFloat(
      this.state.ingameValuepair *
        this.state.ingameSoldeCrypto *
        (this.state.valueBuy / 100)
    ).toFixed(0);

    if (document.getElementById("inputSell")) {
      var valInputSell = document.getElementById("inputSell").value;
      if (valInputSell) {
        prix = valInputSell;
        prixReduction = valInputSell;
      }
    }
    if (this.state.ingameModeSimple === false) {
      return (
        <span>
          {" "}
          Order Short <br /> {prix}$ <br />
          {Number.parseFloat(prix / this.state.ingameValuepair).toFixed(5)}
        </span>
      );
    }

    //Ouvrir
    if (this.state.ingamePari === "") {
      return (
        <span>
          {" "}
          Sell/Short <br /> {prix}$ <br />
          {Number.parseFloat(prix / this.state.ingameValuepair).toFixed(5)}
        </span>
      );
    }
    //Réduire
    else if (this.state.ingamePari === "HAUSSE") {
      return (
        <span>
          {" "}
          <b> Sell/Short </b> <br /> {prixReduction}$ <br />
          {Number.parseFloat(
            prixReduction / this.state.ingameValuepair
          ).toFixed(5)}
        </span>
      );
      //document.getElementById("pValuetwo").value = Number((this.state.ingameSoldeCrypto*(this.state.valueBuy/100)));
    }
    //Augmenter
    else if (this.state.ingamePari === "BAISSE") {
      return (
        <span>
          {" "}
          <b> Sell/Short </b> <br /> {prix}$ <br />
          {Number.parseFloat(prix / this.state.ingameValuepair).toFixed(5)}
        </span>
      );
      //document.getElementById("pValuetwo").value = -Number((this.state.ingameSoldeUSDT*(this.state.valueBuy/100)));
    }
  }

  //Achat
  displayPairSelector = (event) => {
    event.preventDefault();
    try {
      //document.getElementById("slider").style.display = "none";
      document.getElementById("butSelPairSpan").style.display = "block";
    } catch (error) {
      console.log(error.message);
    }
  };

  returnPriceAsset(assetName) {
    if (assetName === "BTCPERP") {
      return this.state.ingameAssets.BTCPERP;
    } else if (assetName === "ETHPERP") {
      return this.state.ingameAssets.ETHPERP;
    } else if (assetName === "SOLPERP") {
      return this.state.ingameAssets.SOLPERP;
    } else if (assetName === "AVAXPERP") {
      return this.state.ingameAssets.AVAXPERP;
    } else if (assetName === "XRPPERP") {
      return this.state.ingameAssets.XRPPERP;
    } else if (assetName === "DOGEPERP") {
      return this.state.ingameAssets.DOGEPERP;
    } else if (assetName === "MATICPERP") {
      return this.state.ingameAssets.MATICPERP;
    } else if (assetName === "LTCPERP") {
      return this.state.ingameAssets.LTCPERP;
    } else if (assetName === "OPPERP") {
      return this.state.ingameAssets.OPPERP;
    } else if (assetName === "ATOMPERP") {
      return this.state.ingameAssets.ATOMPERP;
    } else if (assetName === "INJPERP") {
      return this.state.ingameAssets.INJPERP;
    } else if (assetName === "ARBPERP") {
      return this.state.ingameAssets.ARBPERP;
    } else if (assetName === "BONKPERP") {
      return this.state.ingameAssets.BONKPERP;
    } else if (assetName === "NEARPERP") {
      return this.state.ingameAssets.NEARPERP;
    } else if (assetName === "RUNEPERP") {
      return this.state.ingameAssets.RUNEPERP;
    } else if (assetName === "RNDRPERP") {
      return this.state.ingameAssets.RNDRPERP;
    } else if (assetName === "EGLDPERP") {
      return this.state.ingameAssets.EGLDPERP;
    } else if (assetName === "TIAPERP") {
      return this.state.ingameAssets.TIAPERP;
    } else if (assetName === "SEIPERP") {
      return this.state.ingameAssets.SEIPERP;
    } else {
      return 0;
    }
  }




  async sleep(ms) {
    return new Promise((res) => setTimeout(res, ms));
  }

  //Event appelé quand le slider buy est bougé
  onSliderChangeBuy = (valueBuy) => {
    this.setState(
      {
        valueBuy,
      },
      () => {
        if (this.state.ingamePari === "") {
          document.getElementById("pValue").value = Number(
            this.state.ingameSoldeUSDT * (this.state.valueBuy / 100)
          );
          document.getElementById("pValuetwo").value = -Number(
            this.state.ingameSoldeUSDT * (this.state.valueBuy / 100)
          );
        }
        //ingameSoldeUSDT
        //ingameSoldeCrypto
        else if (this.state.ingamePari === "HAUSSE") {
          document.getElementById("pValue").value = Number(
            this.state.ingameSoldeUSDT * (this.state.valueBuy / 100)
          );
          document.getElementById("pValuetwo").value = Number(
            this.state.ingameSoldeCrypto * (this.state.valueBuy / 100)
          );
        } else if (this.state.ingamePari === "BAISSE") {
          document.getElementById("pValue").value = Number(
            this.state.ingameSoldeCrypto * (this.state.valueBuy / 100)
          );
          document.getElementById("pValuetwo").value = -Number(
            this.state.ingameSoldeUSDT * (this.state.valueBuy / 100)
          );
        }
      }
    );
  };

  //Event appelé quand le slider sell est bougé
  onSliderChangeSell = (valueSell) => {
    this.setState(
      {
        valueSell,
      },
      () => {
        //alert(this.state.ingameSoldeCrypto);
        if (this.state.ingamePari === "HAUSSE") {
          document.getElementById("pValuetwo").value = Number(
            this.state.ingameSoldeCrypto * (this.state.valueSell / 100)
          );
        } else if (
          this.state.ingamePari === "" ||
          this.state.ingamePari === "BAISSE"
        ) {
          document.getElementById("pValuetwo").value = -Number(
            this.state.ingameSoldeUSDT * (this.state.valueSell / 100)
          );
        }
      }
    );
  };

  //Event appelé quand le slider levier est bougé
  onSliderChangeLevier = (valueLevier) => {
    this.setState(
      {
        valueLevier,
      },
      () => {
        //  alert(this.state.valueLevier);
        if(valueLevier < 20) {
          document.getElementById("ingameLevierColored").style.color = "aquamarine";
        }

        else if(valueLevier > 20 && valueLevier < 41) {
          document.getElementById("ingameLevierColored").style.color = "orange";
        }

        else if(valueLevier > 40 ) {
          document.getElementById("ingameLevierColored").style.color = "red";
        }

        document.getElementById("inputLevier").value = this.state.valueLevier;
      }
    );
  };


  inputSLTPChange = (event) => {
    event.preventDefault();
    try {
      document.getElementById("btSLTP").style.display = "inline";
      this.setState({ errorSLTP: "" });
    } catch (error) {
      console.log(error.message);
    }
  };

  setValueSlider = (sc) => {
    this.setState({ valueSlider: sc });
  };

  closePairSelector() {
    //document.getElementById("slider").style.display = "block";
    document.getElementById("butSelPairSpan").style.display = "none";
  }

  showLogs = (event) => {
    event.preventDefault();
    try {
      document.getElementById("logs").style.display = "block";
      document.getElementById("chatLogs").style.display = "none";
    } catch (error) {
      console.log(error.message);
    }
  };

  showChat = (event) => {
    event.preventDefault();
    try {
      document.getElementById("logs").style.display = "none";
      document.getElementById("chatLogs").style.display = "block";
    } catch (error) {
      console.log(error.message);
    }
  };

  setPairVisuelle = (value) => (event) => {
    event.preventDefault();
    try {
      this.setState({ ingameVisuals: value });
      document.getElementById("graphPairSelector").style.display = "block";
      document.getElementById("graphPairSelector2").style.display = "block";

      document.getElementById("butSelPairVisu").style.display = "none";
    } catch (error) {
      console.log(error.message);
    }
  };


  showListVisu = (event) => {
    event.preventDefault();
    try {
      document.getElementById("graphPairSelector").style.display = "none";
      document.getElementById("graphPairSelector2").style.display = "none";

      document.getElementById("butSelPairVisu").style.display = "block";
    } catch (error) {
      console.log(error.message);
    }
  };

  displayLogs() {
    var logs = this.state.ingameLogs;

    var filtredLogs = [];

    //alert(JSON.stringify(logs)); 

    for (var i = 0; i < logs.length; i++) {
      
      logs[i] = logs[i].replace(":", "");
      var indicateur = logs[i].split(" ");
      if (indicateur[0] === "[newPosition]") {
        logs[i] = logs[i].replace("Type: ", "");

        logs[i] = logs[i].replace("HAUSSE", "Long");
        logs[i] = logs[i].replace("BAISSE", "Short");

        var color = "";
        if (logs[i].includes("Long")) {
          color = "#11d89c";
        }
        if (logs[i].includes("Short")) {
          color = "#ff5757";
        }


        var DELIMITER = " ";
        var parts = logs[i].split(DELIMITER);
        parts[3] = "";
        logs[i] = parts.join(DELIMITER);

        filtredLogs.push(<span style={{ color: color }} key={"newPosition"+i}> {logs[i]} </span>);
      }
      if (indicateur[0] === "[closePosition]") {
        filtredLogs.push(<span style={{ color: "#fff59d" }} key={"closePosition"+i}> {logs[i]} </span>);
      }
      if (indicateur[0] === "[augmentPosition]") {
        filtredLogs.push(<span style={{ color: "cyan" }} key={"augmentPosition"+i}> {logs[i]} </span>);
      }
      if (indicateur[0] === "[reducePosition]") {
        filtredLogs.push(<span style={{ color: "orange" }} key={"reducePosition"+i}> {logs[i]} </span>);
      }
      if (indicateur[0] === "[updatePNL]") {
        filtredLogs.push(<span key={"updatePNL"+i}> {logs[i]} </span>);
      }
      if (indicateur[0] === "[Liquidation]") {
        filtredLogs.push(<span key={"Liquidation"+i} > {logs[i]} </span>);
      }
      if (indicateur[0] === "[setAutoClose]") {
        filtredLogs.push(<span key={"setAutoClose"+i} > {logs[i]} </span>);
      }
      if (indicateur[0] === "[modifyAutoClose]") {
        filtredLogs.push(<span key={"modifyAutoClose"+i} > {logs[i]} </span>);
      }
      if (indicateur[0] === "[autoCloture]") {
        filtredLogs.push(<span key={"autoCloture"+i} > {logs[i]} </span>);
      }
    }

    return <div key={"filtredLogs22"}>{filtredLogs}</div>;
  }

  afficherGraph = (event) => {
    event.preventDefault();
    //alert("Display positions for:"+value+" at pair:"+this.state.pair+ "for tournament:"+this.state.tournamentID);
    //alert(JSON.stringify(this.displayTournamentLogs(this.state.tournamentID, true)));

    var logs = this.state.ingameLogs;

    //var indice = 0;

    for (var i = 0; i < logs.length; i++) {
      logs[i] = logs[i].replaceAll("   ", " ");
      logs[i] = logs[i].replaceAll("  ", " ");
      var indicateur = logs[i].split(" ");

      // alert(JSON.stringify(logs[i]));
      //alert(indicateur[1] + " " + indicateur[2]);
      if (indicateur[2] === this.state.ingamePaire) {
        //indice++;
        if (indicateur[0] === "[newPosition]") {
          this.createShape(
            indicateur[5],
            indicateur[4],
            null,
            "-",
            indicateur[8]
          );
        } else if (indicateur[0] === "[closePosition]") {
          //alert("Close "+ indicateur[6]);
          var gain =
            Number.parseFloat(
              indicateur[6].slice(0, -1) - indicateur[8]
            ).toFixed(2) + "$";
          this.createShape(
            "Close",
            indicateur[4],
            null,
            "-",
            indicateur[9],
            gain
          );
        }
      }
    }
  };

  cacherGraph = (event) => {
    event.preventDefault();
    this.removeShape("-");
  };

  //Fonction d'affichage du temps restant en partie
  displayTimer = (event) => {
    //event.preventDefault();
    try {
      var heure = Date.now();

      var tempsRestant = 44;
      var timer = new Date();
      var mtn = new Date();

      mtn.setTime(heure);

      var endTime = Number(this.props.tournoisActif.HFIN) - Number(heure);

      timer.setTime(endTime);
      timer.setHours(timer.getHours() - 1);
      // timer.setDate(timer.getDate()-1);

      var dateF = Number(timer.getDate()) - 1;

      tempsRestant =
        dateF +
        "j " +
        timer.getHours() +
        "h " +
        timer.getMinutes() +
        "min " +
        timer.getSeconds() +
        "s.";

      this.setState({ ingameTempsrestant: tempsRestant });
    } catch (error) {
      console.log(error.message);
    }
  };

  returnTicksBR() {
    //var now = new Date();
    var tk = Number(this.props.tournoisActif.TICKBR);

    var tabHorsPos = [0,0.344852,0.356332,0.368195,0.380452,0.393117,0.406204,0.419727,0.433700,0.448138,0.463056,0.478471,0.494400,0.510858,0.527865,0.545438,0.563595,0.582357,0.601744,0.621776,0.642475,0.663863,0.685963,0.708799,0.732395,0.756777,0.781970,0.808002,0.834900,0.862694,0.891413,0.921089,0.951752,0.983436,1.016174,1.050003,0,0];
    

    //alert(this.props.tournoisActif);

    var retour = "";
    if (this.props.tournoisActif.ESTBATTLEROYAL) {
      retour = tabHorsPos[tk] + "%";
    }

    return <span> {retour} </span>;
  }
  //Get alt of user
  getAltArray(user) {
    var tab = this.state.altArray

    for (var i = 0; i < tab.length; i++) {
      if(tab[i].user === user) {
        return tab[i].alt
      }
    }
    return "";
  }


  getAlt(usernameArray) {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({ USERNAME: usernameArray, ISARRAY: true });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };


      fetch(apiURL + "/dev/getalt", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          this.setState({ altArray: JSON.parse(result).ALTS });
        });
    } catch (error) {
      console.log(error);
    }
  }



  //Tronque x pour 2 ch apres la virgule
  tronque(x) {
    return Number.parseFloat(x).toFixed(2);
  }

  setSymbol(value) {
    this.child.current.setSymbol(value);
  }

  async createShape(id, price, bet, pseudo, time, gain) {
   
    try {
    var sortie = false
    while(sortie === false) {
      await this.sleep(1000);

      //console.log(this.areDataReady() );
      sortie = this.areDataReady()

      if(this.areDataReady()) {
        this.child.current.createShape(id, price, bet, pseudo, time, gain);
      }
      
    } 

    } catch (error) {
      console.log(error.message);
    }
  }

  removeShape(id, value) {
    this.child.current.removeShape(id, value);
  }

  render() {
    return (
      <section className="tournament">
        <div id="renderPlayIG" className="renderPlayIG">
          <div
            id="bandeauIngame"
            className="bandeauIngame"
            style={{ color: "white", display: "none" }}
          >
            {this.returnTicksBR()}
            {typeof this.props.nomSponsor === "string" &&
            this.props.nomSponsor !== "" ? (
              <span>
                <a
                  href={this.props.urlSponsor}
                  style={{ color: "white" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  Sponsored by: {this.props.nomSponsor}
                </a>
              </span>
            ) : (
              ""
            )}
          </div>

          <div className="ingameLeftPanel">
            <div
              id="graphPairSelector"
              className="graphPairSelector"
              onClick={this.showListVisu}
              style={{ display: "none" }}
            >
              {this.state.ingameVisuals}
              <span className="floatRightIG">
                {this.state.ingameModePro === false ? <> &#9658; </> : ""}
              </span>
            </div>
            <div
              id="graphPairSelector2"
              className="graphPairSelector"
              style={{ display: "none" }}
            >
              Mode Pro
              {this.props.tournoisActif !== "ID004" ? (
                <span
                  className="modeProOn"
                  id="modeProOn"
                  onClick={this.setModePro}
                >
                  <span
                    id="appleSelector"
                    className="floatRightIG appleSelector"
                  >
                    <span
                      id="appleSelectorIn"
                      className="floatLeftIG appleSelectorIn"
                    >
                      .
                    </span>
                  </span>
                </span>
              ) : (
                ""
              )}
            </div>
            <div id="resInGame" className="resInGame">
              <span className="TitreIG">RANKING</span> <br />
              {this.returnResults()}
            </div>
            <span className="chatSelectorCont">
            <span
              className="chatSelector"
              style={{ cursor: "pointer" }}
              onClick={this.showChat}
            >
              {" "}
              Chat{" "}
            </span>{" "}
            <span
              className="chatSelector"
              style={{ cursor: "pointer" }}
              onClick={this.showLogs}
            >
              {" "}
              Logs{" "}
            </span>
            </span>
            <div id="chatLogs">
              <Fragment>
                <Chat 
                  param={this.props.username} 
                  pair={this.state.ingamePaire}
                  pari={this.state.ingamePari}
                  levier ={this.state.ingameLevier}
                  entree={this.state.ingameEntreePos}
                  soldeCrypto={this.state.ingameSoldeCrypto}
                  asset={this.returnPriceAsset(this.state.ingamePaire)}
                />
              </Fragment>
            </div>
            <div id="logs" className="logs">
              {this.displayLogs()}
            </div>
            <span
              id="recapingame"
              className="recapingame"
              style={{ display: "none" }}
            >
              <b> Position: {this.state.ingamePosition} </b> <br />
            </span>
          </div>

          <div id="tvct">
            <div id="tradingView" className="tradingView">
              {this.state.ingamePaire !== "" &&
              this.state.ingameModePro === false &&
              this.props.tournoisActif !== "ID004" ? (
                <>
                  <Graph
                    pseudo={this.props.username}
                    param={this.state.ingamePaire.replace("PERP", "USDT")}
                    ref={this.child}
                    width={"100%"}
                    height={"58.5%"}
                    entry={this.state.ingameEntreePos}
                    pari={this.state.ingamePari}
                    liquid={this.state.ingameLiquid}
                    sl={this.state.ingameSL}
                    tp={this.state.ingameTP}
                    estPrimaire={false}
                    estSecondaire={false}
                    externalTradingViewCall={this.externalTradingViewCall}
                  />
                </>
              ) : (
                ""
              )}
              {(this.state.ingamePaire !== "" &&
                this.state.ingameModePro === true &&
                this.props.tournoisActif === "ID004") ||
              (this.state.ingamePaire !== "" &&
                this.props.tournoisActif === "ID004") ? (
                <>
                  <Graph
                    pseudo={this.props.username}
                    param={this.state.ingamePaire}
                    ref={this.child}
                    width={"100%"}
                    height={"58.5%"}
                    entry={this.state.ingameEntreePos}
                    pari={this.state.ingamePari}
                    liquid={this.state.ingameLiquid}
                    sl={this.state.ingameSL}
                    tp={this.state.ingameTP}
                    estPrimaire={false}
                    estSecondaire={false}
                  />
                </>
              ) : (
                ""
              )}
              {this.state.ingamePaire !== "" &&
              this.state.ingameModePro === true &&
              this.props.tournoisActif !== "ID004" ? (
                <>
                  <Graph
                    param={this.state.ingamePaire}
                    ref={this.child}
                    width={"100%"}
                    height={"58.5%"}
                    entry={this.state.ingameEntreePos}
                    pari={this.state.ingamePari}
                    liquid={this.state.ingameLiquid}
                    sl={this.state.ingameSL}
                    tp={this.state.ingameTP}
                    estPrimaire={false}
                    estSecondaire={true}
                  />
                  <Graph
                    param={this.state.ingamePaire}
                    ref={this.child}
                    width={"100%"}
                    height={"58.5%"}
                    entry={this.state.ingameEntreePos}
                    pari={this.state.ingamePari}
                    liquid={this.state.ingameLiquid}
                    estPrimaire={true}
                    estSecondaire={false}
                  />
                </>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="bottomPanelIG">
            <div className="bottomLeftPanelIG">
              <div className="bottomLeftPanelIGleft">
                <div
                  className="pairSelectorPro"
                  onClick={this.displayPairSelector}
                >
                  {this.displayCorrectPaire(this.state.ingamePaire)} {this.state.ingameValuepair} &#9660;

                </div>
                <div
                  className="butSelPairSpan"
                  id="butSelPairSpan"
                  style={{ display: "none" }}
                >
                  <button
                    className="butSelPair"
                    id="butSelBTC"
                    onClick={this.setPair("BTCPERP")}
                  >
                    {" "}
                    Bitcoin{" "}
                  </button>
                  <button
                    className="butSelPair"
                    id="butSelETH"
                    onClick={this.setPair("ETHPERP")}
                  >
                    {" "}
                    Ethereum{" "}
                  </button>
                  <button
                    className="butSelPair"
                    id="butSelSOL"
                    onClick={this.setPair("SOLPERP")}
                  >
                    {" "}
                    Solana{" "}
                  </button>
                  <button
                    className="butSelPair"
                    id="butSelSOL"
                    onClick={this.setPair("AVAXPERP")}
                  >
                    {" "}
                    Avalanche{" "}
                  </button>
                  <button
                    className="butSelPair"
                    id="butSelSOL"
                    onClick={this.setPair("XRPPERP")}
                  >
                    {" "}
                    Ripple{" "}
                  </button>
                  <button
                    className="butSelPair"
                    id="butSelSOL"
                    onClick={this.setPair("DOGEPERP")}
                  >
                    {" "}
                    Dogecoin{" "}
                  </button>
                  <button style={{display: 'none'}}
                    className="butSelPair"
                    id="butSelMATIC"
                    onClick={this.setPair("MATICPERP")}
                  >
                    {" "}
                    Matic{" "}
                  </button>
                  <button style={{display: 'none'}}
                    className="butSelPair"
                    id="butSelLTC"
                    onClick={this.setPair("LTCPERP")}
                  >
                    {" "}
                    Litecoin{" "}
                  </button>
                   <button style={{display: 'none'}}
                    className="butSelPair"
                    id="butSelLTC"
                    onClick={this.setPair("OPPERP")}
                  >
                    {" "}
                    Op{" "}
                  </button>
                   <button style={{display: 'none'}}
                    className="butSelPair"
                    id="butSelLTC"
                    onClick={this.setPair("ATOMPERP")}
                  >
                    {" "}
                    Atom{" "}
                  </button>
                   <button style={{display: 'none'}}
                    className="butSelPair"
                    id="butSelLTC"
                    onClick={this.setPair("INJPERP")}
                  >
                    {" "}
                    Injective{" "}
                  </button>
                   <button style={{display: 'none'}}
                    className="butSelPair"
                    id="butSelLTC"
                    onClick={this.setPair("ARBPERP")}
                  >
                    {" "}
                    Arbritrum{" "}
                  </button>
                   <button style={{display: 'none'}}
                    className="butSelPair"
                    id="butSelLTC"
                    onClick={this.setPair("NEARPERP")}
                  >
                    {" "}
                    Near{" "}
                  </button>
                   <button style={{display: 'none'}}
                    className="butSelPair"
                    id="butSelLTC"
                    onClick={this.setPair("RUNEPERP")}
                  >
                    {" "}
                    Rune{" "}
                  </button>
                   <button style={{display: 'none'}}
                    className="butSelPair"
                    id="butSelLTC"
                    onClick={this.setPair("RNDRPERP")}
                  >
                    {" "}
                    Render{" "}
                  </button>
                   <button style={{display: 'none'}}
                    className="butSelPair"
                    id="butSelLTC"
                    onClick={this.setPair("EGLDPERP")}
                  >
                    {" "}
                    Elrond{" "}
                  </button>
                   <button style={{display: 'none'}}
                    className="butSelPair"
                    id="butSelLTC"
                    onClick={this.setPair("TIAPERP")}
                  >
                    {" "}
                    Celestia{" "}
                  </button>
                   <button style={{display: 'none'}}
                    className="butSelPair"
                    id="butSelLTC"
                    onClick={this.setPair("SEIPERP")}
                  >
                    {" "}
                    Sei{" "}
                  </button>
                </div>
                <div className="setSimpleComplexCont">
                  <button
                    style={{ color: "white" }}
                    className="setSimpleComplex"
                    id="selectSimple"
                    onClick={this.setSimple}
                  >
                    {" "}
                    Simple{" "}
                  </button>
                  <button
                    className="setSimpleComplex"
                    id="selectComplex"
                    onClick={this.setComplex}
                  >
                    {" "}
                    Complex{" "}
                  </button>
                </div>
                <div className="slider" id="slider">
                  <Slider
                    disabled={this.state.mainDisabled}
                    onChange={this.onSliderChangeBuy}
                    trackStyle={{ backgroundColor: "lightgreen", height: 5 }}
                    railStyle={{ backgroundColor: "white", height: 5 }}
                  />
                  <span className="percentSlider" style={{ display: "none" }}>
                    {this.state.valueBuy}%
                  </span>

                  <div className="buyerContainer">
                    <button
                      className="buyMode"
                      id="buyMode"
                      onClick={this.buy}
                      title="Acheter des cryptos"
                    >
                      {this.returnAcheter()}
                    </button>
                    <button
                      className="sellMode"
                      id="sellMode"
                      onClick={this.sell}
                      title="Vendre des cryptos"
                    >
                      {this.returnVendre()}
                    </button>
                    <input
                      className="inputBuy"
                      id="inputBuy"
                      type="number"
                      min="0"
                      max={this.state.ingameSoldeUSDT}
                      onChange={this.onChangeInputBuy}
                    />
                    <input
                      className="inputSell"
                      id="inputSell"
                      type="number"
                      min="0"
                      max={this.state.ingameSoldeUSDT}
                      onChange={this.onChangeInputSell}
                    />
                  </div>
                  <div className="bottomInfos">
                    <div className="floatLeftIG5">
                      Capital 
                      available:
                    </div>
                    <div className="floatRightIG5">
                      {Number.parseFloat(this.state.ingameSoldeUSDT).toFixed(2)}
                      $
                    </div>
                  </div>
                  <div className="bottomInfos">
                    <div className="floatLeftIG3">

                       <span style={{float: 'right'}}> Time left: {this.state.ingameTempsrestant} </span>

                    </div>
                  </div>
                  <div className="bottomInfos">
                    {this.props.tournoisActif.ESTBATTLEROYAL ? (
                      <div className="floatLeftIG3">
                        Next tick BR: {this.returnTicksBR()}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="bottomLeftPanelIGright">
                {this.state.ingameModeSimple === false ? (
                  <div className="contInputComplex" id="contInputComplex">
                    Trigger price: <br />
                    <input
                      className="inputComplex"
                      id="inputComplex"
                      type="number"
                    />
                  </div>
                ) : (
                  ""
                )}
                <div
                  className="comLevierToggle"
                  id="comLevierToggle"
                  onClick={this.showLevier}
                  title="Click here to set leverage. It multiply gains, but also loses !"
                >
                  { this.state.ingameLevier <= 5 ?
                    <img src={levierOne} className="iconLevier" alt="levier" /> : ""
                  }
                  { this.state.ingameLevier < 21 && this.state.ingameLevier > 5 ?
                    <img src={levierLow} className="iconLevier" alt="levier" /> : ""
                  }
                  { this.state.ingameLevier > 20 &&  this.state.ingameLevier < 41?
                    <img src={levierMed} className="iconLevier" alt="levier" /> : ""
                  }
                  { this.state.ingameLevier > 40 ?
                    <img src={levierHigh} className="iconLevier" alt="levier" /> : ""
                  }
                  
                  <div id="ingameLevierColored" className="ingameLevier">{this.state.valueLevier}</div>
                  <span className="sliderLevier">
                    <Slider
                      key={"slider" + this.state.ingameLevier}
                      defaultValue={this.state.ingameLevier}
                      onChange={this.onSliderChangeLevier}
                      trackStyle={{ backgroundColor: "blue", height: 5 }}
                      railStyle={{ backgroundColor: "white", height: 5 }}
                      dots={true}
                      min={1}
                      max={50}
                      disabled={false}
                    />
                  </span>
                  <span className="levierUnder">
                    <span className="floatLeftIGLeverage0">0</span>

                    <input
                      id="inputLevier"
                      className="inputLevier"
                      disabled
                      type="text"
                      style={{ display: "none" }}
                    />
                    <span className="floatRightIG">50</span>
                  </span>

                  <span className="validLevier" onClick={this.setLevier}>
                    {" "}
                    <b> Set leverage</b>{" "}
                  </span>
                </div>

                <div
                  id="comLevier"
                  className="comLevier"
                  style={{ display: "none" }}
                >
                  <b className="indicLevier"> Levier </b> <br />
                  {!this.state.sliderMoovable && (
                    <>
                      <span className="validLevier" onClick={this.setLevier}>
                        {" "}
                        <b> Set </b>{" "}
                      </span>
                    </>
                  )}
                  {this.state.sliderMoovable && (
                    <>
                      <span className="validLevier" onClick={this.exitLevier}>
                        {" "}
                        <b> Close </b>{" "}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="bottomRightSelector">
              <span className="pointer" onClick={this.showPositionsDiv}>
                Positions ({this.state.ingamePositions.length})
              </span>

              <span
                className="bottomRightSelectorSeparator pointer"
                onClick={this.showOrdersDiv}
              >
                Orders ({this.state.ingameOrdres.length})
              </span>
              <span
                className="bottomRightSelectorSeparator pointer"
                onClick={this.showHistoDiv}
              >
                History
              </span>
            </div>
            <div id="positionsPanel" className="bottomRightPanelIG">
              <span className="inputSlider" style={{ display: "none" }}>
                <input
                  id="pValue"
                  disabled={true}
                  className="inputT"
                  type="text"
                />
                <input
                  id="pValuetwo"
                  disabled={true}
                  className="inputT"
                  type="text"
                />
              </span>

              <button
                className="toutcloturer"
                id="toutcloturer"
                onClick={this.toutcloturer}
                title="Clos les positions et encaisse les gains/les pertes"
                style={{ display: "none" }}
              >
                {" "}
                TOUT CLOTURER ({this.state.ingamePositions.length})&#9660;
              </button>

              {this.displayPositions()}
            </div>
            <div
              id="ordersPanel"
              className="bottomRightPanelIG2"
              style={{ display: "none" }}
            >
              <div className="displayedOrders">{this.displayOrdres()}</div>
            </div>
            <div
              id="histoPanel"
              className="bottomRightPanelIG2"
              style={{ display: "none" }}
            >
              <div className="displayedOrders">{this.displayHisto()}</div>
            </div>

            <span style={{ display: "none", color: "white", fontSize: "1vw" }}>
              {" "}
              {this.state.ingameTempsrestant} | {this.returnTicksBR()}
            </span>
          </div>

          {this.props.tournoisActif === "ID003" ? (
            <span className="gainsFin" id="gainsFin">
              {" "}
              <b> Gains fin partie: </b>{" "}
            </span>
          ) : (
            ""
          )}

          <div id="playerView" className="playerView">
          { this.state.loading 
            ? <div className="loadingIngame">
              <img
                style={{
                  width: "50px",
                  marginTop: "10%",
                  marginBottom: "3%",
                  marginRight: "15%"
                }}
                src={loading}
                alt="loading"
              ></img>
             </div>
            :
            <div className="pvContain">
              <div className="pvImages" title={this.state.circuPP}>
                <img
                  className="imgCadre2"
                  src={".././images/rewards/cadres/" + this.state.joueurcadre + ".png"}
                  alt="cadre"
                />
                <img
                  className="imgEmbleme2"
                  src={".././images/rewards/emblemes/" + this.state.joueurembleme + ".png"}
                  alt="embleme"
                />
                <img
                  className="imgBordure2"
                  src={".././images/rewards/bordures/" + this.state.joueurbordure + ".png"}
                  alt=""
                />
              </div>
              <div className="pvInfos">
                <img
                  className="ligueRankPP"
                  src={".././images/" + this.state.joueurligue + ".png"}
                  alt="ligue"
                />
                <div className="pvPseudo">
                  <span className="jpsize">
                    {" "}
                    {this.state.joueurpseudo.match(truncateRegex)
                      ? truncateEthAddress(this.state.joueurpseudo)
                      : this.state.joueurpseudo}{" "}
                  </span>{" "}
                  <br />
                  <div className="pvCitation">{this.state.displaycitation}</div>
                  <div className="pvLVL">
                    LVL: {this.state.joueurlvl} <br />
                  </div>
                  <div className="pvStat">{this.state.displaystat}</div>
                </div>
                <div className="profileCloser" onClick={this.closeProfile}>
                  <img
                    className="imgClose"
                    src={".././images/closer.png"}
                    alt="closer"
                  />
                </div>
              </div>
            </div>
            }
          </div>

          <div id="commands" className="commands" style={{ display: "none" }}>
            <div id="buy" className="buy">
              <span className="scoreGameR">
                <b>
                  {this.state.ingameSoldeAvantPos > 1000 && <>+</>}
                  {this.state.ingameSoldeAvantPos < 1000 && <>-</>}
                  {Math.abs(
                    Number.parseFloat(
                      (1 - this.state.ingameSoldeAvantPos / 1000) * 100
                    ).toFixed(2)
                  )}
                  %<br />
                  {Number.parseFloat(this.state.ingameSoldeUSDT).toFixed(2)} $
                  <br /> <br />{" "}
                </b>
                {this.state.ingameTempsrestant}
                <br />
              </span>

              <span className="spanValuePair">
                {" "}
                {this.state.ingameValuepair} $ <br />{" "}
              </span>

              <div id="sliderBuy" className="sliderBuy">
                <div className="slider" id="slider">
                  <Slider
                    onChange={this.onSliderChangeBuy}
                    trackStyle={{ backgroundColor: "lightgreen", height: 5 }}
                    railStyle={{ backgroundColor: "white", height: 5 }}
                  />
                  <span className="percentSlider">{this.state.valueBuy}%</span>
                  <div className="holderPerc">
                    <span className="inputSlider">
                      <input
                        id="pValue"
                        disabled={true}
                        className="inputT"
                        type="text"
                      />
                    </span>
                    $
                  </div>
                </div>
              </div>
              <div className="sliderSell" id="sliderSell">
                <div className="slider">
                  <Slider
                    onChange={this.onSliderChangeSell}
                    trackStyle={{ backgroundColor: "lightcoral", height: 5 }}
                    railStyle={{ backgroundColor: "white", height: 5 }}
                  />
                  <span className="percentSlider">{this.state.valueSell}%</span>
                  <div className="holderPerc">
                    <span className="inputSlider">
                      <input
                        id="pValuetwo"
                        disabled={true}
                        className="inputT"
                        type="text"
                      />
                    </span>
                    $
                  </div>
                </div>
              </div>
              <div className="multiSelec">
                <span
                  className="buySelec"
                  id="buySelec"
                  onClick={this.goBuy}
                  title="Passer en mode achat"
                  style={{ fontSize: "2vw" }}
                >
                  &#8592;
                </span>
                {Number(this.state.ingameSoldeUSDT) > 0 ? (
                  <>
                    <button
                      className="buyMode"
                      id="buyMode"
                      onClick={this.buy}
                      title="Acheter des cryptos"
                    >
                      <b>Acheter</b>
                    </button>
                    <button
                      className="sellMode"
                      id="sellMode"
                      onClick={this.sell}
                      title="Vendre des cryptos"
                    >
                      Vendre
                    </button>
                    <span
                      id="sellSelec"
                      onClick={this.goSell}
                      title="Passer en mode vente"
                      style={{ fontSize: "2vw" }}
                    >
                      &#8594;
                    </span>
                  </>
                ) : (
                  ""
                )}
              </div>

              <div className="ingameInfos" id="ingameOrders">
                <div className="leftIG">
                  Pari :{" "}
                  <span id="greenRed">
                    {" "}
                    {this.state.ingamePari} <br />{" "}
                  </span>
                  {this.displayTaillePos()}
                  Levier: {this.state.ingamePosLevier} <br />
                  <br />
                  Crypto: {this.state.ingamePaire}
                  <br />
                </div>
                <div className="rightIG">
                  Prix entrée:{" "}
                  <b style={{ color: "#5EA1D8" }}>
                    {" "}
                    {Number.parseFloat(this.state.ingameEntreePos).toFixed(
                      3
                    )}{" "}
                  </b>{" "}
                  <br /> <br />
                  Liquidation:{" "}
                  {Number.parseFloat(this.state.ingameLiquid).toFixed(
                    3
                  )} <br /> <br />
                  Frais:{" "}
                  <b style={{ color: "red" }}>
                    {" "}
                    {Math.abs(
                      Number.parseFloat(
                        this.state.ingameSoldeCrypto *
                          this.state.ingameValuepair *
                          0.0005 *
                          this.state.ingamePosLevier
                      ).toFixed(2)
                    )}{" "}
                  </b>
                </div>
                <button
                  className="cloturer"
                  id="cloturer"
                  onClick={this.cloturer}
                  title="Clos la position et encaisse le gain/la perte"
                >
                  {" "}
                  CLOTURER{" "}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div id="renderFinish" style={{ display: "none" }}>
          <div className="resultsEndgame">
            <div className="podiumEndgame">{this.displayPodium()}</div>
            <div className="chartsEndgame">
              <span className="resultsEndgameP">
                {" "}
                {this.state.finishJoueurs}{" "}
              </span>
              <span className="resultsEndgameS">
                {" "}
                {this.state.finishScores}{" "}
              </span>
            </div>
            <div className="scoreEndgame">
              <div className="scoregame">
                {" "}
                Score final:{" "}
                <div className="scoregameUnit">
                  {" "}
                  {this.state.finishScore}{" "}
                </div>{" "}
              </div>
              <div className="placeEndgame">
                {" "}
                Place:
                <br />{" "}
                <div className="placegameUnit">
                  {this.state.finishPosition + 1}{" "}
                </div>{" "}
              </div>
              <div className="xpEndgame">
                {" "}
                XP Gagnée:
                <br /> {this.state.finishXp}{" "}
              </div>
              <div className="coteEndgame">
                {" "}
                Cote gagnée:
                <br />{" "}
                <div className="cotegameUnit">{this.state.finishCote}</div>{" "}
              </div>
            </div>
            <div className="resumeEndgame">
              <div className="cashEndgame" style={{ display: "none" }}>
                {" "}
                %Cash prize gagnés: {this.state.finishTag}{" "}
              </div>
            </div>
            {this.displayGroupResults()}
          </div>
        </div>
      </section>
    );
  }
}

export default Ingame;
