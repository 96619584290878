import React, { Component } from "react";
import { instanceAWS, instanceTournoi } from "../api/api_instance";
import { ipBackend } from "../utils/const";
class GameMan extends Component {
  /////////////////////////////////////////
  ////            GameMan.js           ////
  //// Fichier de creation des parties ////
  /////////////////////////////////////////

  state = {
    tabTournois: [],
    results1: [],
    results2: [],
    results3: [],
    ingameGroups: [],
  };

  async sleep(ms) {
    return new Promise((res) => setTimeout(res, ms));
  }

  componentDidMount() {
    this.getTournaments();
    this.getTournamentsClass();
  }

  display01 = (event) => {
    event.preventDefault();
    try {
      document.getElementById("div001").style.display = "block";
      document.getElementById("div002").style.display = "none";
      document.getElementById("div003").style.display = "none";
      document.getElementById("div004").style.display = "none";
    } catch (error) {
      console.log(error.message);
    }
  };

  display02 = (event) => {
    event.preventDefault();
    try {
      document.getElementById("div001").style.display = "none";
      document.getElementById("div002").style.display = "block";
      document.getElementById("div003").style.display = "none";
      document.getElementById("div004").style.display = "none";
    } catch (error) {
      console.log(error.message);
    }
  };

  display03 = (event) => {
    event.preventDefault();
    try {
      document.getElementById("div001").style.display = "none";
      document.getElementById("div002").style.display = "none";
      document.getElementById("div003").style.display = "block";
      document.getElementById("div004").style.display = "none";
    } catch (error) {
      console.log(error.message);
    }
  };

  display04 = (event) => {
    event.preventDefault();
    try {
      document.getElementById("div001").style.display = "none";
      document.getElementById("div002").style.display = "none";
      document.getElementById("div003").style.display = "none";
      document.getElementById("div004").style.display = "block";
    } catch (error) {
      console.log(error.message);
    }
  };

  getTournaments() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      crossDomain: true,
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(ipBackend + "/tournaments/", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        //alert(JSON.stringify(result));

        var resultats = JSON.parse(result);
        var tmpTabTournois = [];

        for (var i = 0; i < resultats.length; i++) {
          var datefull = new Date();
          datefull.setTime(resultats[i].hDeb);
          var hDebFormat =
            datefull.getDate() +
            "/" +
            Number(datefull.getMonth() + 1) +
            "/" +
            datefull.getFullYear() +
            " : " +
            datefull.getHours() +
            ":" +
            datefull.getMinutes();

          var hfinformats = new Date();
          hfinformats.setTime(resultats[i].hFin);
          var hFinFormat =
            hfinformats.getDate() +
            "/" +
            Number(hfinformats.getMonth() + 1) +
            "/" +
            hfinformats.getFullYear() +
            " : " +
            hfinformats.getHours() +
            ":" +
            hfinformats.getMinutes();

          var tmp = {
            ID: resultats[i].id,
            HDEBraw: resultats[i].hDeb,
            HDEB: hDebFormat,
            HFINformat: hFinFormat,
            HFIN: resultats[i].hFin,
            DUREE: resultats[i].duree,
            NBJOUEURS: resultats[i].nombreJoueurs,
            JOUEURS: resultats[i].joueurs,
            ESTJOUABLE: resultats[i].infos.estJouable,
            ESTAFFICHABLE: resultats[i].infos.estAffichable,
            ESTENREGISTRABLE: resultats[i].infos.estEnregistrable,
            ESTBATTLEROYAL: resultats[i].infos.estBattleRoyal,
            ESTIMMORTEL: resultats[i].infos.estImmortel,
            ESTGROUPE: resultats[i].infos.estGroupe,
            ESTRANKED: resultats[i].infos.estRanked,
            ESTRESTRICTED: resultats[i].infos.estRestricted,
            ESTRANKRESTRICTED: resultats[i].infos.estRankRestricted,
            ESTPASSWD: resultats[i].infos.estPasswd,
            CPTEXT: resultats[i].infos.cpText,
            CPTOTAL: resultats[i].infos.cpTotal,
            SPONSOR: resultats[i].infos.sponsor,
            SPONSORURL: resultats[i].infos.sponsorUrl,
            LOGS: resultats[i].infos.logs,
            ASSETS: resultats[i].assets,
          };

          tmpTabTournois.push(tmp);
        }

        this.setState({ tabTournois: tmpTabTournois });
      })
      .catch((error) => console.log("error", error));

    fetch(ipBackend + "/admin/", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        var re = JSON.parse(result);

        var usages =
          "Charge : 1m: " +
          re.load1 +
          "%, 5m: " +
          re.load5 +
          "%, 15m: " +
          re.load15 +
          "%, RAM: " +
          re.free +
          "/" +
          re.mem +
          "mo";

        this.setState({ usages: usages });
      })
      .catch((error) => console.log("error", error));
  }
  getTournamentsClass() {
    this.getTournamentClass(1);
    this.getTournamentClass(2);
    this.getTournamentClass(3);
  }

  getTournamentClass(id) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      crossDomain: true,
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(ipBackend + "/tournaments/" + id, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        var resultats = JSON.parse(result);
        var ListPP = [];

        for (var i = 0; i < resultats.joueurs.length; i++) {
          ListPP.push(
            <span>
              {resultats.joueurs[i].pseudo}- nbRez :{resultats.joueurs[i].nbRez}
              , Score :
              {Number.parseFloat(
                resultats.joueurs[i].soldeUsdtAvantPosition -
                  resultats.joueurs[i].nbRez * 1000
              ).toFixed(2)}
              , Solde dispo :
              {Number.parseFloat(resultats.joueurs[i].soldeUsdt).toFixed(2)},
              Positions: {resultats.joueurs[i].positions.length}
              <br />
            </span>
          );
        }
        //k

        if (id === 1) {
          this.setState({ results1: ListPP });
        }

        if (id === 2) {
          this.setState({ results2: ListPP });
        }

        if (id === 3) {
          this.setState({ results3: ListPP });
        }
      })
      .catch((error) => console.log("error", error));


    requestOptions = {
      crossDomain: true,
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(ipBackend + "/player/getgroupes/" + id, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        var parsed = JSON.parse(result);

        var ret = [];

        for (var i = 0; i < parsed.length; i++) {
          ret.push({
            Nom: parsed[i].Nom,
            Leader: parsed[i].Leader,
            url: parsed[i].url,
            joueurs: parsed[i].joueurs,
            couleur: parsed[i].couleur,
          });
        }

        if (id === 1) {
          this.setState({ ingameGroups1: ret });
        }

        if (id === 2) {
          this.setState({ ingameGroups2: ret });
        }

        if (id === 2) {
          this.setState({ ingameGroups2: ret });
        }
      });
  }

  displayGroupes(id) {
    var list = [];
    for (var i = 0; i < this.state.ingameGroups.length; i++) {
      list.push(
        <div>
          Nom: {this.state.ingameGroups[i].Nom}
          <br />
          couleur: {this.state.ingameGroups[i].couleur}
          <br />
          url: {this.state.ingameGroups[i].url}
          <br />
          leader: {this.state.ingameGroups[i].Leader}
          <br />
          players: {this.state.ingameGroups[i].joueurs}
          <br />
          <br />
        </div>
      );
    }

    return list;
  }

  displayResults(id) {
    if (id === 1) {
      return <p> {this.state.results1} </p>;
    }

    if (id === 2) {
      return <p> {this.state.results2} </p>;
    }

    if (id === 3) {
      return <p> {this.state.results3} </p>;
    }
  }

  //

  addInfo = (value) => (event) => {
    event.preventDefault();
    try {
      var info = value.info;
      var param = value.param;
      var id = value.id;

      const body = {
        id: id,
        info: info,
        param: param,
        jwt:
          this.props.auth.user &&
          this.props.auth.user.signInUserSession &&
          this.props.auth.user.signInUserSession.idToken
            ? this.props.auth.user.signInUserSession.idToken
            : undefined,
        uuid:
          this.props.auth.user.attributes && this.props.auth.user.attributes.sub
            ? this.props.auth.user.attributes.sub
            : undefined,
        crdt:
          this.props.auth.user && this.props.auth.user.credentials
            ? this.props.auth.user.credentials
            : undefined,
      };

      instanceTournoi
        .post("/admin/basicinfos", body)
        .then((response) => {
          this.getTournaments();
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error.message);
    }
  };

  modifyTournament = (value) => (event) => {
    event.preventDefault();
    try {
      var paramId = "inputD" + value;
      var paramId2 = "inputE" + value;
      var debut = document.getElementById(paramId).value;
      var fin = document.getElementById(paramId2).value;

      const body = {
        id: value + 1,
        hDeb: debut,
        hFin: fin,
        jwt:
          this.props.auth.user &&
          this.props.auth.user.signInUserSession &&
          this.props.auth.user.signInUserSession.idToken
            ? this.props.auth.user.signInUserSession.idToken
            : undefined,
        uuid:
          this.props.auth.user.attributes && this.props.auth.user.attributes.sub
            ? this.props.auth.user.attributes.sub
            : undefined,
        crdt:
          this.props.auth.user && this.props.auth.user.credentials
            ? this.props.auth.user.credentials
            : undefined,
      };

      instanceTournoi
        .post("/admin/", body)
        .then((result) => {
          this.getTournaments();
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error.message);
    }
  };

  setSolde = (value) => (event) => {
    event.preventDefault();
    try {
      var paramId = "solde" + value;
      var paramId2 = "pseudo" + value;
      var solde = document.getElementById(paramId).value;
      var psd = document.getElementById(paramId2).value;

      var body = {
        id: value + 1,
        solde: solde,
        pseudo: psd,
        jwt:
          this.props.auth.user &&
          this.props.auth.user.signInUserSession &&
          this.props.auth.user.signInUserSession.idToken
            ? this.props.auth.user.signInUserSession.idToken
            : undefined,
        uuid:
          this.props.auth.user.attributes && this.props.auth.user.attributes.sub
            ? this.props.auth.user.attributes.sub
            : undefined,
        crdt:
          this.props.auth.user && this.props.auth.user.credentials
            ? this.props.auth.user.credentials
            : undefined,
      };

      instanceTournoi
        .post("/admin/setSolde", body)
        .then((response) => {
          this.getTournaments();
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error.message);
    }
  };

  backup = (value) => (event) => {
    event.preventDefault();
    try {
      var backupb = "backup" + value;

      var backup = document.getElementById(backupb).value;

      const body = {
        id: value + 1,
        backup: backup,
        jwt:
          this.props.auth.user &&
          this.props.auth.user.signInUserSession &&
          this.props.auth.user.signInUserSession.idToken
            ? this.props.auth.user.signInUserSession.idToken
            : undefined,
        uuid:
          this.props.auth.user.attributes && this.props.auth.user.attributes.sub
            ? this.props.auth.user.attributes.sub
            : undefined,
        crdt:
          this.props.auth.user && this.props.auth.user.credentials
            ? this.props.auth.user.credentials
            : undefined,
      };

      instanceTournoi
        .post("/admin/loadTournament", body)
        .then((response) => {
          this.getTournaments();
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error.message);
    }
  };

  deleteGroup = (value) => (event) => {
    event.preventDefault();
    try {
      var paramId = "deleteGroupe" + value;
      var idTnt = value + 1;

      var nom = document.getElementById(paramId).value;

      const body = {
        id: idTnt,
        nom: nom,
        jwt:
          this.props.auth.user &&
          this.props.auth.user.signInUserSession &&
          this.props.auth.user.signInUserSession.idToken
            ? this.props.auth.user.signInUserSession.idToken
            : undefined,
        uuid:
          this.props.auth.user.attributes && this.props.auth.user.attributes.sub
            ? this.props.auth.user.attributes.sub
            : undefined,
        crdt:
          this.props.auth.user && this.props.auth.user.credentials
            ? this.props.auth.user.credentials
            : undefined,
      };

      instanceTournoi
        .post("/admin/supprGroupe", body)
        .then((response) => {
          this.getTournaments();
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error.message);
    }
  };

  pushGroup = (value) => (event) => {
    event.preventDefault();
    try {
      var paramNom = "nomGroupe" + value;
      var paramLeader = "leadGroupe" + value;
      var paramUrl = "urlGroupe" + value;
      var paramCc = "ccGroupe" + value;
      var idTnt = value + 1;

      var nom = document.getElementById(paramNom).value;
      var leader = document.getElementById(paramLeader).value;
      var url = document.getElementById(paramUrl).value;
      var cc = document.getElementById(paramCc).value;

      const body = {
        id: idTnt,
        nom: nom,
        leader: leader,
        url: url,
        cc: cc,
        jwt:
          this.props.auth.user &&
          this.props.auth.user.signInUserSession &&
          this.props.auth.user.signInUserSession.idToken
            ? this.props.auth.user.signInUserSession.idToken
            : undefined,
        uuid:
          this.props.auth.user.attributes && this.props.auth.user.attributes.sub
            ? this.props.auth.user.attributes.sub
            : undefined,
        crdt:
          this.props.auth.user && this.props.auth.user.credentials
            ? this.props.auth.user.credentials
            : undefined,
      };

      instanceTournoi
        .post(ipBackend + "/admin/addUpGroupe", body)
        .then((response) => {
          this.getTournaments();
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error.message);
    }
  };

  displayBackups3() {
    var body = JSON.stringify({ TOURNOIS: "ID003" });

    instanceAWS
      .post("/dev/getbackups", body)
      .then((result) => {
        var body = result.data.body;

        var date = body.date;

        this.setState({ backups: date });
        // alert(JSON.stringify(date));
      })

      .catch((error) => console.log("error", error));
  }

  displayDatesBkups() {
    var dates = "";

    for (var i = 0; i < this.state.backups.length; i++) {
      dates += this.state.backups[i] + " ";
    }
    return <p> {dates} </p>;
  }

  displayTournamentInfos(index) {
    //Ecriture infos basiques tournois

    if (this.state.tabTournois[index] === undefined) {
      return;
    }

    var aff = "Affichable: " + this.state.tabTournois[index].ESTAFFICHABLE;
    var jou = "Jouable: " + this.state.tabTournois[index].ESTJOUABLE;
    var enr =
      "Enregistrable: " + this.state.tabTournois[index].ESTENREGISTRABLE;

    var br = "Battle Royal: " + this.state.tabTournois[index].ESTBATTLEROYAL;
    var imo = "Immortel: " + this.state.tabTournois[index].ESTIMMORTEL;
    var grp = "Groupe: " + this.state.tabTournois[index].ESTGROUPE;
    var rank = "Ranked: " + this.state.tabTournois[index].ESTRANKED;
    var passwd = "Passwd: " + this.state.tabTournois[index].ESTPASSWD;
    var restri = "Restricted: " + this.state.tabTournois[index].ESTRESTRICTED;
    var rkrestri = "Rank-restricted: " + this.state.tabTournois[index].ESTRANKRESTRICTED;

    return (
      <div>
        <h1> ID: {this.state.tabTournois[index].ID} </h1>
        Epoch start: {this.state.tabTournois[index].HDEBraw} - - - Epoch end:{" "}
        {this.state.tabTournois[index].HFIN} <br />
        UTC+2 start: {this.state.tabTournois[index].HDEB} - - - UTC+2 end:{" "}
        {this.state.tabTournois[index].HFINformat} <br />
        DUREE: {this.state.tabTournois[index].DUREE} ({" "}
        {this.state.tabTournois[index].DUREE / 86400000} jours) <br />
        <br />
        {aff} {jou} {enr}
        <br />
        <br />
        {br} <br />
        {imo} <br />
        {grp} <br />
        {rank} <br />
        {restri} <br />
        {rkrestri} <br />
        {passwd}
        <br />
        <br />
        CashPrize array: {JSON.stringify(
          this.state.tabTournois[index].CPTEXT
        )}{" "}
        CP Total: {this.state.tabTournois[index].CPTOTAL} <br />
        <br />
        Sponsor: {JSON.stringify(this.state.tabTournois[index].SPONSOR)}{" "}
        SponsorURL: {this.state.tabTournois[index].SPONSORURL} <br />
        <br />
        Affichable :
        <button
          onClick={this.addInfo({
            id: index + 1,
            info: "estAffichable",
            param: "true",
          })}
        >
          {" "}
          ON{" "}
        </button>
        <button
          onClick={this.addInfo({
            id: index + 1,
            info: "estAffichable",
            param: "false",
          })}
        >
          {" "}
          OFF{" "}
        </button>
        <br />
        Enregistrable :
        <button
          onClick={this.addInfo({
            id: index + 1,
            info: "estEnregistrable",
            param: "true",
          })}
        >
          {" "}
          ON{" "}
        </button>
        <button
          onClick={this.addInfo({
            id: index + 1,
            info: "estEnregistrable",
            param: "false",
          })}
        >
          {" "}
          OFF{" "}
        </button>
        <br />
        Jouable :
        <button
          onClick={this.addInfo({
            id: index + 1,
            info: "estJouable",
            param: "true",
          })}
        >
          {" "}
          ON{" "}
        </button>
        <button
          onClick={this.addInfo({
            id: index + 1,
            info: "estJouable",
            param: "false",
          })}
        >
          {" "}
          OFF{" "}
        </button>
        <br />
        battleRoyal :
        <button
          onClick={this.addInfo({
            id: index + 1,
            info: "estBattleRoyal",
            param: "true",
          })}
        >
          {" "}
          ON{" "}
        </button>
        <button
          onClick={this.addInfo({
            id: index + 1,
            info: "estBattleRoyal",
            param: "false",
          })}
        >
          {" "}
          OFF{" "}
        </button>
        <br />
        Immortel :
        <button
          onClick={this.addInfo({
            id: index + 1,
            info: "estImmortel",
            param: "true",
          })}
        >
          {" "}
          ON{" "}
        </button>
        <button
          onClick={this.addInfo({
            id: index + 1,
            info: "estImmortel",
            param: "false",
          })}
        >
          {" "}
          OFF{" "}
        </button>
        <br />
        Groupe :
        <button
          onClick={this.addInfo({
            id: index + 1,
            info: "estGroupe",
            param: "true",
          })}
        >
          {" "}
          ON{" "}
        </button>
        <button
          onClick={this.addInfo({
            id: index + 1,
            info: "estGroupe",
            param: "false",
          })}
        >
          {" "}
          OFF{" "}
        </button>
        <br />
        Classé :
        <button
          onClick={this.addInfo({
            id: index + 1,
            info: "estRanked",
            param: "true",
          })}
        >
          {" "}
          ON{" "}
        </button>
        <button
          onClick={this.addInfo({
            id: index + 1,
            info: "estRanked",
            param: "false",
          })}
        >
          {" "}
          OFF{" "}
        </button>
        <br />
        Restricted :
        <button
          onClick={this.addInfo({
            id: index + 1,
            info: "estRestricted",
            param: "true",
          })}
        >
          {" "}
          ON{" "}
        </button>
        <button
          onClick={this.addInfo({
            id: index + 1,
            info: "estRestricted",
            param: "false",
          })}
        >
          {" "}
          OFF{" "}
        </button>
        <br />
        Passwd :
        <button
          onClick={this.addInfo({
            id: index + 1,
            info: "estPasswd",
            param: "true",
          })}
        >
          {" "}
          ON{" "}
        </button>
        <button
          onClick={this.addInfo({
            id: index + 1,
            info: "estPasswd",
            param: "false",
          })}
        >
          {" "}
          OFF{" "}
        </button>
        <br />
        Rank restr :
        <button
          onClick={this.addInfo({
            id: index + 1,
            info: "estRankRestricted",
            param: "true",
          })}
        >
          {" "}
          ON{" "}
        </button>
        <button
          onClick={this.addInfo({
            id: index + 1,
            info: "estRankRestricted",
            param: "false",
          })}
        >
          {" "}
          OFF{" "}
        </button>

        <br />
        <input id={"inputPasswd" + index} type="text" />
        {document.getElementById("inputCPT" + index) ? (
          <button
            onClick={this.addInfo({
              id: index + 1,
              info: "passwd",
              param: this.getValue("inputPasswd" + index),
            })}
          >
            {" "}
            Send{" "}
          </button>
        ) : (
          ""
        )}
        <br />
        Total CP :
        <input id={"inputCPT" + index} type="text" />
        {document.getElementById("inputCPT" + index) ? (
          <button
            onClick={this.addInfo({
              id: index + 1,
              info: "cpTotal",
              param: this.getValue("inputCPT" + index),
            })}
          >
            {" "}
            Send{" "}
          </button>
        ) : (
          ""
        )}
        <br />
        Text CP :
        <input id={"inputCPTx" + index} type="text" />
        {document.getElementById("inputCPTx" + index) ? (
          <button
            onClick={this.addInfo({
              id: index + 1,
              info: "cpText",
              param: this.getValue("inputCPTx" + index),
            })}
          >
            {" "}
            Send{" "}
          </button>
        ) : (
          ""
        )}
        <br />
        Sponsor :
        <input id={"inputsp" + index} type="text" />
        {document.getElementById("inputsp" + index) ? (
          <button
            onClick={this.addInfo({
              id: index + 1,
              info: "sponsor",
              param: this.getValue("inputsp" + index),
            })}
          >
            {" "}
            Send{" "}
          </button>
        ) : (
          ""
        )}
        <br />
        Sponsor URL :
        <input id={"inputspu" + index} type="text" />
        {document.getElementById("inputspu" + index) ? (
          <button
            onClick={this.addInfo({
              id: index + 1,
              info: "sponsorUrl",
              param: this.getValue("inputspu" + index),
            })}
          >
            {" "}
            Send{" "}
          </button>
        ) : (
          ""
        )}
        <br />
      </div>
    );
  }

  getValue(id) {
    if (document.getElementById(id)) {
      return document.getElementById(id).value;
    }
  }

  //k
  displayTournamentClassement(index) {
    if (this.state.tabTournois[index] === undefined) {
      return;
    }

    return (
      <div>
        <h1> Joueurs: {this.state.tabTournois[index].NBJOUEURS} </h1>
      </div>
    );
  }

  displayTournamentLogs(index) {
    //Ecriture infos basiques tournois

    if (this.state.tabTournois[index] === undefined) {
      return;
    }

    return <div>{this.state.tabTournois[index].LOGS}</div>;
  }

  //k

  render() {
    return (
      <section className="gameman">
        {this.props.auth.user.username === "bazoukiwi" ||
        this.props.auth.user.username === "luchap" ? (
          <div className="container">
            <div className="titleGM">
              <button onClick={this.display01} className="gmSelecT">
                {" "}
                Tournoi 1{" "}
              </button>
              <button onClick={this.display02} className="gmSelecT">
                {" "}
                Tournoi 2{" "}
              </button>
              <button onClick={this.display03} className="gmSelecT">
                {" "}
                Tournoi 3{" "}
              </button>
              <button onClick={this.display04} className="gmSelecT">
                {" "}
                Tournoi 4{" "}
              </button>

              <span className="usages">{this.state.usages}</span>
            </div>

            <div id="div001" className="infosSimplesTnt">
              <div className="logsTnt">
                <h1> Logs </h1>
                {this.displayTournamentLogs(0)}
              </div>
              <div className="RankTnt">
                <h1> Classement </h1>
                {this.displayResults(1)}
              </div>
              <div className="InfsTnt">
                {this.displayTournamentInfos(0)}
                <br />
                Start: <input id={"inputD0"} type="text" />
                <br />
                End: <input id={"inputE0"} type="text" />
                <br />
                <button onClick={this.modifyTournament(0)}> Update </button>
                <br />
                Solde: <input id={"solde0"} type="text" />
                <br />
                Pseudo: <input id={"pseudo0"} type="text" />
                <br />
                <button onClick={this.setSolde(0)}> Update </button>
                <br />
                Backup: <input id={"backup0"} type="text" />
                <br />
                <button onClick={this.backup(0)}> Load bkup </button>
                <h1> Groupes </h1>
                {JSON.stringify(this.state.ingameGroups1)}
                <br />
                <br />
                Creation/update
                <br />
                Rentrer les infos sur le groupe a creer. Si le nom d'un groupe
                déja existant est saisi, il sera mis a jour avec les nouvelles
                infos. Note: Il faut remplir tout les champs !<br />
                Nom: <input id={"nomGroupe0"} type="text" />
                <br />
                Leader: <input id={"leadGroupe0"} type="text" />
                <br />
                Url: <input id={"urlGroupe0"} type="text" />
                <br />
                code Couleur: <input id={"ccGroupe0"} type="text" />
                <br />
                <button onClick={this.pushGroup(0)}> Creer/update </button>
                <br />
                <br />
                Suppression:
                <br />
                Nom: <input id={"deleteGroupe0"} type="text" />
                <br />
                <button onClick={this.deleteGroup(0)}> Supprimer </button>
              </div>
            </div>

            <div
              id="div002"
              style={{ display: "none" }}
              className="infosSimplesTnt"
            >
              <div className="logsTnt">
                <h1> Logs </h1>
                {this.displayTournamentLogs(1)}
              </div>
              <div className="RankTnt">
                <h1> Classement </h1>
                {this.displayResults(2)}
              </div>
              <div className="InfsTnt">
                {this.displayTournamentInfos(1)}
                <br />
                Start: <input id={"inputD1"} type="text" /> <br />
                End: <input id={"inputE1"} type="text" />
                <br />
                <button onClick={this.modifyTournament(1)}> Update </button>
                <br />
                Solde: <input id={"solde1"} type="text" />
                <br />
                Pseudo: <input id={"pseudo1"} type="text" />
                <br />
                <button onClick={this.setSolde(1)}> Update </button>
                <h1> Groupes </h1>
                {JSON.stringify(this.state.ingameGroups2)}
                <br />
                <br />
                Creation/update
                <br />
                Rentrer les infos sur le groupe a creer. Si le nom d'un groupe
                déja existant est saisi, il sera mis a jour avec les nouvelles
                infos. Note: Il faut remplir tout les champs !<br />
                Nom: <input id={"nomGroupe1"} type="text" />
                <br />
                Leader: <input id={"leadGroupe1"} type="text" />
                <br />
                Url: <input id={"urlGroupe1"} type="text" />
                <br />
                code Couleur: <input id={"ccGroupe1"} type="text" />
                <br />
                <button onClick={this.pushGroup(1)}> Creer/update </button>
                <br />
                <br />
                Nom: <input id={"deleteGroupe1"} type="text" />
                <br />
                <button onClick={this.deleteGroup(1)}> Supprimer </button>
                <br />
                Backup: <input id={"backup1"} type="text" />
                <br />
                <button onClick={this.backup(1)}> Load bkup </button>
              </div>
            </div>

            <div
              id="div003"
              style={{ display: "none" }}
              className="infosSimplesTnt"
            >
              <div className="logsTnt">
                <h1> Logs </h1>
                {this.displayTournamentLogs(2)}
              </div>
              <div className="RankTnt">
                <h1> Classement </h1>
                {this.displayResults(3)}
              </div>
              <div className="InfsTnt">
                {this.displayTournamentInfos(2)}
                <br />
                Start: <input id={"inputD2"} type="text" /> <br />
                End: <input id={"inputE2"} type="text" />
                <br />
                <button onClick={this.modifyTournament(2)}> Update </button>
                <br />
                Solde: <input id={"solde2"} type="text" />
                <br />
                Pseudo: <input id={"pseudo2"} type="text" />
                <br />
                <button onClick={this.setSolde(2)}> Update </button>
                <h1> Groupes </h1>
                {JSON.stringify(this.state.ingameGroups3)}
                <br />
                <br />
                Creation/update
                <br />
                Rentrer les infos sur le groupe a creer. Si le nom d'un groupe
                déja existant est saisi, il sera mis a jour avec les nouvelles
                infos. Note: Il faut remplir tout les champs !<br />
                Nom: <input id={"nomGroupe2"} type="text" />
                <br />
                Leader: <input id={"leadGroupe2"} type="text" />
                <br />
                Url: <input id={"urlGroupe2"} type="text" />
                <br />
                code Couleur: <input id={"ccGroupe2"} type="text" />
                <br />
                <button onClick={this.pushGroup(2)}> Creer/update </button>
                <br />
                <br />
                Nom: <input id={"deleteGroupe2"} type="text" />
                <br />
                <button onClick={this.deleteGroup(2)}> Supprimer </button>
                <br />
                Backup: <input id={"backup2"} type="text" />
                <br />
                <button onClick={this.backup(2)}> Load bkup </button>
              </div>
            </div>

            <div
              id="div004"
              style={{ display: "none" }}
              className="infosSimplesTnt"
            ></div>
          </div>
        ) : (
          <p style={{ color: "white" }}> Acces interdit ! </p>
        )}
      </section>
    );
  }
}

export default GameMan;
