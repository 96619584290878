import axios from "axios"; 
import {  apiURL, ipBackend } from "../utils/const";

export const instanceAWS = axios.create({
  baseURL : apiURL,
  timeout: 4000, 
  headers: {'Content-Type': 'application/json'}
  // .. other options
});


export const instanceTournoi = axios.create({
  baseURL : ipBackend,
  timeout: 2000, 
  headers: {'Content-Type': 'application/json'}
  // .. other options
});

