/* eslint-disable no-useless-concat */
import * as React from "react";
import "./index.css";
import { widget } from "../../charting_library";
import Datafeed from "./datafeed.js";
import { instanceAWS } from "../../api/api_instance";
import {
  truncateEthAddress,
  truncateRegex,
} from "../../utils/function";

export class TVChartContainer extends React.PureComponent {
  tvWidget = null;

  state = {
    isChartReady: false,
    entryLineID: "",
    liquidLineID: "",
    closeSupLineID: "",
    closeInfLineID: "",
    ordersLineIDs: [],
    chart: {},
    chartToSave: {},
    chartsName: [],
    shapesCustom: [],
    vertical: "",
    symbol: "BTCPERP",
    shapesSpectate: [],
    objetIdJoueurs: [],
    valueLevierMiniPNL: this.props.valueLevierMiniPNL,
    valueLevierMaxiPNL: this.props.valueLevierMaxiPNL,
    isSpectate: this.props.isSpectate,
    lastPositionOpen: {},
    lastPositionClose: {},
    tableauShapesGlobalesOpen: 0,
    tableauShapesGlobalesClose: 0,
    gamestate: {},
    gamestateID: {
      entry : {
        id: ""
      },
      liquid : {
        id: ""
      },
      orders : []
    },
  };

  constructor(props) {
    super(props);

    this.ref = React.createRef();
  }

  

  componentDidMount() {
    var candleUpColor = "#11d89c";
    var candleDownColor="#ff5757";
    var mecheUpColor="#11d89c";
    var mecheDownColor="#ff5757";


    if(true || this.props.isSpectate) {
      candleUpColor = "#11d89c";
      candleDownColor="#c2185b";
      mecheUpColor="#11d89c";
      mecheDownColor="#c2185b";
    }


    const widgetOptions = {
      symbol: "bybit:" + this.props.symbol,
      debug: false,
      datafeed: Datafeed,
      interval: "60",
      timezone: "Europe/Paris",
      container: this.ref.current,
      library_path: "/charting_library/",
      charts_storage_url:
        "https://f0bvh92lm8.execute-api.eu-west-3.amazonaws.com/dev",
      charts_storage_api_version: "1.1",
      client_id: "tradeasgame",
      user_id: "bazoukiwi",
      width: this.props.width,
      height: this.props.height,
      disabled_features: [
        "save_chart_properties_to_local_storage",
        "header_saveload",
        "control_bar",
        "go_to_date",
        "header_compare",
        "header_symbol_search",
        "header_screenshot",
        "timeframes_toolbar",
      ],
      //enabled_features: ["disable_resolution_rebuild"],seconds_resolution
      enabled_features: ["seconds_resolution"],
      loading_screen: { backgroundColor: "#1E242F" },
      toolbar_bg: "#1e3736",
      theme: "Dark",
      overrides: {
        showCountdown: true,
        "paneProperties.backgroundType": "solid",
        "paneProperties.background": "#1E242F",
        //"paneProperties.backgroundGradientStartColor": "#1E242F",
        //"paneProperties.backgroundGradientEndColor": "#1E242F",
        volumePaneSize: "small",
        "paneProperties.vertGridProperties.color": "#1E242F",
        "paneProperties.horzGridProperties.color": "#1E242F",
        "paneProperties.crossHairProperties.color": "#28303e",
        "paneProperties.crossHairProperties.style": 0,
        "paneProperties.legendProperties.showLegend": false,
        "scalesProperties.backgroundColor": "#1E242F",
        "scalesProperties.lineColor": "#FFFFFF",
        "scalesProperties.textColor": "#FFFFFF",
        "mainSeriesProperties.candleStyle.upColor": candleUpColor,
        "mainSeriesProperties.candleStyle.downColor": candleDownColor,
        "mainSeriesProperties.candleStyle.borderUpColor": mecheUpColor,
        "mainSeriesProperties.candleStyle.borderDownColor": mecheDownColor,
        "mainSeriesProperties.candleStyle.wickDownColor": "#b2b5be",
        "mainSeriesProperties.candleStyle.wickUpColor": "#b2b5be",
        //"paneProperties.legendProperties.color": "#11d89c",
      },
    };

    const tvWidget = new widget(widgetOptions);
    this.tvWidget = tvWidget;

    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        this.setState({ isChartReady: true });


        if (true) {
          tvWidget.createDropdown({
            title: "Choix Crypto",
            tooltip: "Choisis la crypto à afficher",
            align: "left",
            items: [
              {
                title: "Bitcoin",
                onSelect: () => {
                  this.tvWidget.chart().setSymbol("bybit:btcusdt");
                  this.setState({symbol: "BTCPERP"});
                },
              },
              {
                title: "Ethereum",
                onSelect: () => {
                  this.tvWidget.chart().setSymbol("bybit:ethusdt");
                  this.setState({symbol: "ETHPERP"});
                },
              },
              {
                title: "Solana",
                onSelect: () => {
                  this.tvWidget.chart().setSymbol("bybit:solusdt");
                  this.setState({symbol: "SOLPERP"});
                },
              },
              {
                title: "Avalanche",
                onSelect: () => {
                  this.tvWidget.chart().setSymbol("bybit:avaxusdt");
                  this.setState({symbol: "AVAXPERP"});
                },
              },
              {
                title: "Ripple",
                onSelect: () => {
                  this.tvWidget.chart().setSymbol("bybit:xrpusdt");
                  this.setState({symbol: "XRPPERP"});
                },
              },
              {
                title: "Dogecoin",
                onSelect: () => {
                  this.tvWidget.chart().setSymbol("bybit:dogeusdt");
                  this.setState({symbol: "DOGEPERP"});
                },
              },
              {
                title: "Matic",
                onSelect: () => {
                  this.tvWidget.chart().setSymbol("bybit:maticusdt");
                  this.setState({symbol: "MATICPERP"});
                },
              },
              {
                title: "Litecoin",
                onSelect: () => {
                  this.tvWidget.chart().setSymbol("bybit:ltcusdt");
                  this.setState({symbol: "LTCPERP"});
                },
              },
              {
                title: "Op",
                onSelect: () => {
                  this.tvWidget.chart().setSymbol("bybit:opusdt");
                  this.setState({symbol: "OPPERP"});
                },
              },
              {
                title: "Atom",
                onSelect: () => {
                  this.tvWidget.chart().setSymbol("bybit:atomusdt");
                  this.setState({symbol: "ATOMPERP"});
                },
              },
            ],
          });
        }

         const now = new Date();
      const time = now.getTime();
/*
      if(this.props.symbol === "btcusdt") {
        var test = this.tvWidget.chart().createShape(
          {
             time: time,
              price: 69
          }, 
          {
             shape: 'text',
             lock: true,
             overrides: {
               text: "KH70PbaD",
               showLabel: true,
               fontsize: 15,
               horzLabelsAlign: 'right',
               color: "lightgrey",
               borderColor: "aquamarine"
             }
          }
        );
      }*/

         


        /*

                const button = tvWidget.createButton();
                //button.setAttribute('title', 'Click to show a notification popup');
                //button.classList.add('apply-common-tooltip');
                button.addEventListener('click', () => tvWidget.showConfirmDialog({
                    title: 'Swing Swiss',
                    body: "Plus d'informations sur les indicateurs ici: https://swingswiss.com. Pour obtenir l'indicateur, cliquez sur Yes :",
                    callback: function(result) { if(result) window.open("https://discord.gg/swingswiss", '_blank'); },
                }));

                button.innerHTML = 'Sponsor : SwingSwiss';

                const button2 = tvWidget.createButton();
                button2.setAttribute('title', 'Click to show a notification popup');
                button2.classList.add('apply-common-tooltip');
                button2.addEventListener('click', () => tvWidget.showConfirmDialog({
                    title: 'Bybit',
                    body: 'Bybit fait parti des 3 plus grosses plateformes pour achater/vente et parier sur la hausse/baisse des crypto. Cliquez sur Yes pour y jeter un œil !',
                    callback: function(result) { if(result) window.open("https://www.bybit.com/fr-FR", '_blank'); },
                }));

                button2.innerHTML = 'Sponsor : Bybit';

                */



        this.presentLoadedChart();
        this.presentSaveDelete();
      });

      tvWidget.chart().executeActionById("showCountdown");


    });
  }

  componentWillUnmount() {
    if (this.tvWidget !== null) {
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  }

  openSSlink() {
    window.open("https://swingswiss.com", "_blank");
  }

  openBBlink() {
    window.open("https://www.bybit.com/fr-FR", "_blank");
  }

  setSymbol(p) {
    this.tvWidget.chart().setSymbol(p);
    /*
    if(p === "bybit:btcusdt") {

      const now = new Date();
      const time = now.getTime();

      var test = this.tvWidget.chart().createShape(
        {
           time: time,
            price: 69
        }, 
        {
           shape: 'text',
           lock: true,
           overrides: {
             text: "KH70PbaD",
             showLabel: true,
             fontsize: 15,
             horzLabelsAlign: 'right',
             color: "lightgrey",
             borderColor: "aquamarine"
           }
        }
      );
    }*/
    
  }

  isChartReady() {
    return this.state.isChartReady;
  }

  getMsTime(resolution) {
    if (resolution === "1S") {
      return 1000;
    } else if (resolution === "1") {
      return 60000;
    } else if (resolution === "3") {
      return 180000;
    } else if (resolution === "5") {
      return 300000;
    } else if (resolution === "15") {
      return 900000;
    } else if (resolution === "30") {
      return 1800000;
    } else if (resolution === "60") {
      return 3600000;
    } else if (resolution === "120") {
      return 7200000;
    } else if (resolution === "240") {
      return 14400000;
    } else if (resolution === "1D") {
      return 86400000;
    } 
  }

  async getLastClosedBar(resolution, symbol) {
    try {
      var end = Date.now();
      var start = end-this.getMsTime(resolution);
      var ret = 0;
      //start = start - this.getMsTime(resolution);

      var splitter = symbol.split(":");



      await instanceAWS.post("/dev/getbars", {
        PAIR: splitter[1],
        RESOLUTION: resolution,
        START: start,
        END: end
      }).then((result) => {
        const data = result.data;

        
        ret = data.body[0][0];
      });

      return ret;


    } catch (error) {
      console.log("get error", error);
    }
  }

  removeAllShapes() {
    this.tvWidget.chart().removeAllShapes();
    this.setState({objetIdJoueurs: []});
   // alert("all delete");
  }

 
  areDataReady() {
    if (this.tvWidget.chart().dataReady()) {
      return true;
    } 
    return false;
  }


  async updateSpectateTab(tabNouvellesPositions, gains, pseudos) {
    //alert("Needs to add:"+JSON.stringify(tabNouvellesPositions));
    //var tableauShapesGlobalesOpen = this.state.tableauShapesGlobalesOpen;
    //var tableauShapesGlobalesClose = this.state.tableauShapesGlobalesClose;

    for (var i = 0; i < tabNouvellesPositions.length; i++) {

      if(tabNouvellesPositions[i].orderType === "[newPosition]" && tabNouvellesPositions[i].sens === "Long") {

        var lastPosition = this.state.lastPositionOpen;

        var condition = false;
        if(JSON.stringify(lastPosition) === "{}") {
          condition = true;
        }
        else {
          if(tabNouvellesPositions[i].time > Number(lastPosition.objetPosition.candleStart)+Number(this.getMsTime(this.tvWidget.chart().resolution()))) {
            condition = true;
          }
        }

        if(condition) {
          var nouvellePosition = {
            ID:0,
            objetPosition:{
              candleStart:tabNouvellesPositions[i].time,
              count:1,
              long:1,
              close:0,
              liq:0,
              pseudo:tabNouvellesPositions[i].pseudo,
              txt: "\n" + tabNouvellesPositions[i].pseudo.match(truncateRegex) ? truncateEthAddress(tabNouvellesPositions[i].pseudo) : tabNouvellesPositions[i].pseudo + " L"
            }
          }

          let txt = nouvellePosition.objetPosition.txt;

          let texte = "";
          if(nouvellePosition.objetPosition.long > 0 && !pseudos) {
            texte += "L:"+nouvellePosition.objetPosition.long+"\n";
          }
          if(nouvellePosition.objetPosition.close > 0 && !pseudos) {
            texte += "CS:"+nouvellePosition.objetPosition.close+"\n";
          }
          if(nouvellePosition.objetPosition.liq > 0 && !pseudos) {
            texte += "Liq:"+nouvellePosition.objetPosition.liq+"\n";
          }
          if(pseudos) {
            texte += txt;
          }

          let color = "#5b9cf6";
          let size = 45;

          let ID = this.tvWidget.chart().createShape(
          {
            time: Math.trunc(nouvellePosition.objetPosition.candleStart / 1000),
            channel: "low"
          },
          {
            shape: "arrow_up",
            lock: false,
            zOrder: "top",
            overrides: {
              text: texte,
              arrowColor: color,
              color: color,
              size: size,
            },
          }
          );
          nouvellePosition.ID = ID;
          this.setState({lastPositionOpen: nouvellePosition});
          return
        }
        


        lastPosition.objetPosition.long += 1;
        lastPosition.objetPosition.count += 1;

        lastPosition.objetPosition.pseudo += tabNouvellesPositions[i].pseudo + "\n";
        lastPosition.objetPosition.txt += "\n" + tabNouvellesPositions[i].pseudo + " L";
        //var pseudo = this.state.lastPosition.objetPosition.pseudo;
        let txt = lastPosition.objetPosition.txt;

        let texte = "";
        if(lastPosition.objetPosition.long > 0 && !pseudos) {
          texte += "L:"+lastPosition.objetPosition.long+"\n";
        }
        if(lastPosition.objetPosition.close > 0 && !pseudos) {
          texte += "CS:"+lastPosition.objetPosition.close+"\n";
        }
        if(lastPosition.objetPosition.liq > 0 && !pseudos) {
          texte += "Liq:"+lastPosition.objetPosition.liq+"\n";
        }
        if(pseudos) {
          texte += txt;
        }

        //alert("adding:"+JSON.stringify(lastPosition));
        //alert("texte: "+lastPosition.ID);

        this.tvWidget.chart().removeEntity(lastPosition.ID);

        let color = "#5b9cf6";
        let size = 45;

        //alert("adding entry at;" + Math.trunc(lastPosition.objetPosition.candleStart / 1000) )



        let ID = this.tvWidget.chart().createShape(
        {
          time: Math.trunc(lastPosition.objetPosition.candleStart / 1000),
          channel: "low"
        },
        {
          shape: "arrow_up",
          lock: false,
          zOrder: "top",
          overrides: {
            text: texte,
            arrowColor: color,
            color: color,
            size: size,
          },
        }
        );

        this.setState({lastPositionOpen: {ID: ID, objetPosition: lastPosition.objetPosition}});


        
      }
      if(tabNouvellesPositions[i].orderType === "[closePosition]" && tabNouvellesPositions[i].closureType === "Short") {
        let lastPosition = this.state.lastPositionOpen;

        let condition = false;
        if(JSON.stringify(lastPosition) === "{}") {
          condition = true;
        }
        else {
          if(tabNouvellesPositions[i].time > Number(lastPosition.objetPosition.candleStart)+Number(this.getMsTime(this.tvWidget.chart().resolution()))) {
            condition = true;
          }
        }

        if(condition) {
          let nouvellePosition = {
            ID:0,
            objetPosition:{
              candleStart:tabNouvellesPositions[i].time,
              count:1,
              long:0,
              close:1,
              liq:0,
              pseudo:tabNouvellesPositions[i].pseudo,
              txt: "\n" + tabNouvellesPositions[i].pseudo + " Cs"
            }
          }

          let txt = nouvellePosition.objetPosition.txt;


          let texte = "";
          if(gains) {
            txt += " "+tabNouvellesPositions[i].gain;
          }
          if(nouvellePosition.objetPosition.long > 0 && !pseudos) {
            texte += "L:"+nouvellePosition.objetPosition.long+"\n";
          }
          if(nouvellePosition.objetPosition.close > 0 && !pseudos) {
            texte += "CS:"+nouvellePosition.objetPosition.close+"\n";
          }
          if(nouvellePosition.objetPosition.liq > 0 && !pseudos) {
            texte += "Liq:"+nouvellePosition.objetPosition.liq+"\n";
          }
          if(pseudos) {
            texte += txt;
          }

          

          let color = "#5b9cf6";
          let size = 45;

          let ID = this.tvWidget.chart().createShape(
          {
            time: Math.trunc(nouvellePosition.objetPosition.candleStart / 1000),
            channel: "low"
          },
          {
            shape: "arrow_up",
            lock: false,
            zOrder: "top",
            overrides: {
              text: texte,
              arrowColor: color,
              color: color,
              size: size,
            },
          }
          );
          nouvellePosition.ID = ID;
          this.setState({lastPositionOpen: nouvellePosition});
          return
        }


        lastPosition.objetPosition.close += 1;
        lastPosition.objetPosition.count += 1;

        lastPosition.objetPosition.pseudo += tabNouvellesPositions[i].pseudo + "\n";
        lastPosition.objetPosition.txt += "\n" + tabNouvellesPositions[i].pseudo + " Cs";
        //var pseudo = this.state.lastPosition.objetPosition.pseudo;
        let txt = lastPosition.objetPosition.txt;

        if(gains) {
          txt += " "+tabNouvellesPositions[i].gain;
        }

        var texte = "";
        if(lastPosition.objetPosition.close > 0 && !pseudos) {
          texte += "L:"+lastPosition.objetPosition.long+"\n";
        }
        if(lastPosition.objetPosition.close > 0 && !pseudos) {
          texte += "CS:"+lastPosition.objetPosition.close+"\n";
        }
        if(lastPosition.objetPosition.liq > 0 && !pseudos) {
          texte += "Liq:"+lastPosition.objetPosition.liq+"\n";
        }
        if(pseudos) {
          texte += txt;
        }

        //alert("adding:"+JSON.stringify(lastPosition));
        //alert("texte: "+lastPosition.ID);

        this.tvWidget.chart().removeEntity(lastPosition.ID);

        let color = "#5b9cf6";
        let size = 45;

        //alert("adding entry at;" + Math.trunc(lastPosition.objetPosition.candleStart / 1000) )

        let ID = this.tvWidget.chart().createShape(
        {
          time: Math.trunc(lastPosition.objetPosition.candleStart / 1000),
          channel: "low"
        },
        {
          shape: "arrow_up",
          lock: false,
          zOrder: "top",
          overrides: {
            text: texte,
            arrowColor: color,
            color: color,
            size: size,
          },
        }
        );

        this.setState({lastPositionOpen: {ID: ID, objetPosition: lastPosition.objetPosition}});
      }

      if(tabNouvellesPositions[i].orderType === "[newPosition]" && tabNouvellesPositions[i].sens === "Short") {
        let lastPosition = this.state.lastPositionOpen;

        let condition = false;
        if(JSON.stringify(lastPosition) === "{}") {
          condition = true;
        }
        else {
          if(tabNouvellesPositions[i].time > Number(lastPosition.objetPosition.candleStart)+Number(this.getMsTime(this.tvWidget.chart().resolution()))) {
            condition = true;
          }
        }

        if(condition) {
          let nouvellePosition = {
            ID:0,
            objetPosition:{
              candleStart:tabNouvellesPositions[i].time,
              count:1,
              short:1,
              close:0,
              liq:0,
              pseudo:tabNouvellesPositions[i].pseudo,
              txt: "\n" + tabNouvellesPositions[i].pseudo + " S"
            }
          }

          let txt = nouvellePosition.objetPosition.txt;

          let texte = "";
          if(nouvellePosition.objetPosition.short > 0 && !pseudos) {
            texte += "S:"+nouvellePosition.objetPosition.short+"\n";
          }
          if(nouvellePosition.objetPosition.close > 0 && !pseudos) {
            texte += "CL:"+nouvellePosition.objetPosition.close+"\n";
          }
          if(nouvellePosition.objetPosition.liq > 0 && !pseudos) {
            texte += "Liq:"+nouvellePosition.objetPosition.liq+"\n";
          }
          if(pseudos) {
            texte += txt;
          }

          let color = "orange";
          let size = 45;

          let ID = this.tvWidget.chart().createShape(
          {
            time: Math.trunc(nouvellePosition.objetPosition.candleStart / 1000),
            channel: "high"
          },
          {
            shape: "arrow_down",
            lock: false,
            zOrder: "top",
            overrides: {
              text: texte,
              arrowColor: color,
              color: color,
              size: size,
            },
          }
          );
          nouvellePosition.ID = ID;
          this.setState({lastPositionClose: nouvellePosition});
          return
        }



        lastPosition.objetPosition.short += 1;
        lastPosition.objetPosition.count += 1;

        lastPosition.objetPosition.pseudo += tabNouvellesPositions[i].pseudo + "\n";
        lastPosition.objetPosition.txt += "\n" + tabNouvellesPositions[i].pseudo + " S";
        //var pseudo = this.state.lastPosition.objetPosition.pseudo;
        let txt = lastPosition.objetPosition.txt;

        let texte = "";
        if(lastPosition.objetPosition.short > 0 && !pseudos) {
          texte += "S:"+lastPosition.objetPosition.short+"\n";
        }
        if(lastPosition.objetPosition.close > 0 && !pseudos) {
          texte += "CL:"+lastPosition.objetPosition.close+"\n";
        }
        if(lastPosition.objetPosition.liq > 0 && !pseudos) {
          texte += "Liq:"+lastPosition.objetPosition.liq+"\n";
        }
        if(pseudos) {
          texte += txt;
        }

        //alert("adding:"+JSON.stringify(lastPosition));
        //alert("texte: "+lastPosition.ID);

        this.tvWidget.chart().removeEntity(lastPosition.ID);

        let color = "orange";
        let size = 45;

        //alert("adding entry at;" + Math.trunc(lastPosition.objetPosition.candleStart / 1000) )

        let ID = this.tvWidget.chart().createShape(
        {
          time: Math.trunc(lastPosition.objetPosition.candleStart / 1000),
          channel: "high"
        },
        {
          shape: "arrow_down",
          lock: false,
          zOrder: "top",
          overrides: {
            text: texte,
            arrowColor: color,
            color: color,
            size: size,
          },
        }
        );

        this.setState({lastPositionClose: {ID: ID, objetPosition: lastPosition.objetPosition}});
      }
      if(tabNouvellesPositions[i].orderType === "[closePosition]" && tabNouvellesPositions[i].closureType === "Long") {
        let lastPosition = this.state.lastPositionOpen;

        let condition = false;
        if(JSON.stringify(lastPosition) === "{}") {
          condition = true;
        }
        else {
          if(tabNouvellesPositions[i].time > Number(lastPosition.objetPosition.candleStart)+Number(this.getMsTime(this.tvWidget.chart().resolution()))) {
            condition = true;
          }
        }

        if(condition) {
          let nouvellePosition = {
            ID:0,
            objetPosition:{
              candleStart:tabNouvellesPositions[i].time,
              count:1,
              short:0,
              close:1,
              liq:0,
              pseudo:tabNouvellesPositions[i].pseudo,
              txt: "\n" + tabNouvellesPositions[i].pseudo + " Cl"
            }
          }

          let txt = nouvellePosition.objetPosition.txt;


          let texte = "";
          if(gains) {
            txt += " "+tabNouvellesPositions[i].gain;
          }
          if(nouvellePosition.objetPosition.short > 0 && !pseudos) {
            texte += "S:"+nouvellePosition.objetPosition.short+"\n";
          }
          if(nouvellePosition.objetPosition.close > 0 && !pseudos) {
            texte += "CL:"+nouvellePosition.objetPosition.close+"\n";
          }
          if(nouvellePosition.objetPosition.liq > 0 && !pseudos) {
            texte += "Liq:"+nouvellePosition.objetPosition.liq+"\n";
          }
          if(pseudos) {
            texte += txt;
          }

          

          let color = "orange";
          let size = 45;

          let ID = this.tvWidget.chart().createShape(
          {
            time: Math.trunc(nouvellePosition.objetPosition.candleStart / 1000),
            channel: "high"
          },
          {
            shape: "arrow_down",
            lock: false,
            zOrder: "top",
            overrides: {
              text: texte,
              arrowColor: color,
              color: color,
              size: size,
            },
          }
          );
          nouvellePosition.ID = ID;
          this.setState({lastPositionClose: nouvellePosition});
          return
        }


        lastPosition.objetPosition.close += 1;
        lastPosition.objetPosition.count += 1;

        lastPosition.objetPosition.pseudo += tabNouvellesPositions[i].pseudo + "\n";
        lastPosition.objetPosition.txt += "\n" + tabNouvellesPositions[i].pseudo + " Cl";
        //var pseudo = this.state.lastPosition.objetPosition.pseudo;
        let txt = lastPosition.objetPosition.txt;

        if(gains) {
          txt += " "+tabNouvellesPositions[i].gain;
        }

        let texte = "";
        if(lastPosition.objetPosition.short > 0 && !pseudos) {
          texte += "S:"+lastPosition.objetPosition.short+"\n";
        }
        if(lastPosition.objetPosition.close > 0 && !pseudos) {
          texte += "CL:"+lastPosition.objetPosition.close+"\n";
        }
        if(lastPosition.objetPosition.liq > 0 && !pseudos) {
          texte += "Liq:"+lastPosition.objetPosition.liq+"\n";
        }
        if(pseudos) {
          texte += txt;
        }

        //alert("adding:"+JSON.stringify(lastPosition));
        //alert("texte: "+lastPosition.ID);

        this.tvWidget.chart().removeEntity(lastPosition.ID);

        let color = "orange";
        let size = 45;

        //alert("adding entry at;" + Math.trunc(lastPosition.objetPosition.candleStart / 1000) )

        let ID = this.tvWidget.chart().createShape(
        {
          time: Math.trunc(lastPosition.objetPosition.candleStart / 1000),
          channel: "high"
        },
        {
          shape: "arrow_down",
          lock: false,
          zOrder: "top",
          overrides: {
            text: texte,
            arrowColor: color,
            color: color,
            size: size,
          },
        }
        );

        this.setState({lastPositionClose: {ID: ID, objetPosition: lastPosition.objetPosition}});

      }


    }
  }

  //tab est le nouveau tableau mis a jour avec les nouveaux ordres des joueurs a afficher
  async handleSpectateTab(id, tab, smooth, gains, pseudos) {


    //alert(JSON.stringify(tab));

    if(tab.length === 0) {
      this.tvWidget.chart().removeAllShapes();
      this.setState({objetIdJoueurs: []});
    }

    if(smooth) {
      var tabOpen = [];
      var tabClose = [];
      var newTabOpen = [];
      var newTabClose = [];
      var newTabLiq = [];

      var tre = [];

      for (let i = 0; i < tab.length; i++) {
        if(tab[i][0] !== undefined) {
          tre.push(tab[i][0].pseudo);
        }
      }
      

      this.tvWidget.chart().removeAllShapes();


      for (var i = 0; i < tab.length; i++) {
        for (var j = 0; j < tab[i].length; j++) {
          if( 
            (tab[i][j].orderType === "[newPosition]" && tab[i][j].sens === "Long" ) || 
            (tab[i][j].orderType === "[augmentPosition]" && tab[i][j].sens === "Long") || 
            (tab[i][j].orderType === "[closePosition]" && tab[i][j-1].sens === "Short")) {

              if(tab[i][j].sens === "Long")
              {
                tabOpen.push({time: tab[i][j].time, entry: tab[i][j].entry, long: 1, close: 0, liq: 0,pseudo: tab[i][j].pseudo, txt: tab[i][j].pseudo+" "+"L"})
              }
              else
              {
                let tmpTxt = tab[i][j].pseudo+" "+"CS";
                if(gains) {
                  tmpTxt += " "+tab[i][j].gain;
                }
                tabOpen.push({time: tab[i][j].time, entry: tab[i][j].entry, long: 0, close: 1, liq: 0,pseudo: tab[i][j].pseudo, txt: tmpTxt})
              }
          }
          if( 
            (tab[i][j].orderType === "[newPosition]" && tab[i][j].sens === "Short" ) || 
            (tab[i][j].orderType === "[augmentPosition]" && tab[i][j].sens === "Short") || 
            (tab[i][j].orderType === "[closePosition]" && tab[i][j-1].sens === "Long")) {

              if(tab[i][j].sens === "Short")
              {
                tabClose.push({time: tab[i][j].time, entry: tab[i][j].entry, short: 1, close: 0, liq: 0, pseudo: tab[i][j].pseudo, txt: tab[i][j].pseudo+" "+"S"})
              }
              else
              {
                let tmpTxt = tab[i][j].pseudo+" "+"CL";
                if(gains) {
                  tmpTxt += " "+tab[i][j].gain;
                }
                tabClose.push({time: tab[i][j].time, entry: tab[i][j].entry, short: 0, close: 1, liq: 0, pseudo: tab[i][j].pseudo, txt: tmpTxt})
              }
              
          }
          if(tab[i][j].orderType === "[Liquidation]" ) {
           
            if(tab[i][j-1].sens === "Long") {
              tabClose.push({time: tab[i][j].time, entry: tab[i][j].entry, short: 0, close: 0, liq: 1, pseudo: tab[i][j].pseudo, txt: tab[i][j].pseudo+" "+"Liq"})
            }
            if(tab[i][j-1].sens === "Short") {
              tabOpen.push({time: tab[i][j].time, entry: tab[i][j].entry, short: 0, close: 0, liq: 1, pseudo: tab[i][j].pseudo, txt: tab[i][j].pseudo+" "+"Liq"})
            }
          }
        }
      }


      var msResolution = this.getMsTime(this.tvWidget.chart().resolution());
      var lastCandle = await this.getLastClosedBar(this.tvWidget.chart().resolution(), this.tvWidget.chart().symbol());

      tabOpen.sort((a, b) => parseFloat(a.time) - parseFloat(b.time));
      tabClose.sort((a, b) => parseFloat(a.time) - parseFloat(b.time));

      tabOpen.reverse();
      tabClose.reverse();











      for (let i = 0; i < tabOpen.length; i++) {
        if(tabOpen[i].time >= lastCandle) {
          if(newTabOpen.length > 0  && tabOpen[i].time > newTabOpen[newTabOpen.length-1].candleStart) {
            newTabOpen[newTabOpen.length-1].long += tabOpen[i].long;
            newTabOpen[newTabOpen.length-1].close += tabOpen[i].close;
            newTabOpen[newTabOpen.length-1].liq += tabOpen[i].liq;
            newTabOpen[newTabOpen.length-1].count += 1;
            newTabOpen[newTabOpen.length-1].pseudo += "\n"+tabOpen[i].pseudo;
            newTabOpen[newTabOpen.length-1].txt += "\n"+tabOpen[i].txt;
          }
          else {
            newTabOpen.push({candleStart: lastCandle, count:1, long: tabOpen[i].long, close: tabOpen[i].close, liq: tabOpen[i].liq, pseudo: tabOpen[i].pseudo, txt: tabOpen[i].txt})

          }
        }
        else {
          while(tabOpen[i].time < lastCandle) {
            lastCandle = lastCandle - msResolution;
          }
          if(newTabOpen.length > 0  && tabOpen[i].time > newTabOpen[newTabOpen.length-1].candleStart) {
            newTabOpen[newTabOpen.length-1].long += tabOpen[i].long;
            newTabOpen[newTabOpen.length-1].close += tabOpen[i].close;
            newTabOpen[newTabOpen.length-1].liq += tabOpen[i].liq;
            newTabOpen[newTabOpen.length-1].count += 1;
            newTabOpen[newTabOpen.length-1].pseudo += "\n"+tabOpen[i].pseudo;
            newTabOpen[newTabOpen.length-1].txt += "\n"+tabOpen[i].txt;
          }
          else {
            newTabOpen.push({candleStart: lastCandle, count:1, long: tabOpen[i].long, close: tabOpen[i].close, liq: tabOpen[i].liq, pseudo: tabOpen[i].pseudo, txt: tabOpen[i].txt})
          }
        }
      }

      lastCandle = await this.getLastClosedBar(this.tvWidget.chart().resolution(), this.tvWidget.chart().symbol());



      for (let i = 0; i < tabClose.length; i++) {
        if(tabClose[i].time >= lastCandle) {
          if(newTabClose.length > 0  && tabClose[i].time > newTabClose[newTabClose.length-1].candleStart) {
            newTabClose[newTabClose.length-1].short += tabClose[i].short;
            newTabClose[newTabClose.length-1].close += tabClose[i].close;
            newTabClose[newTabClose.length-1].liq += tabClose[i].liq;
            newTabClose[newTabClose.length-1].count += 1;
            newTabClose[newTabClose.length-1].pseudo += "\n"+tabClose[i].pseudo;
            newTabClose[newTabClose.length-1].txt += "\n"+tabClose[i].txt;
          }
          else {
            newTabClose.push({candleStart: lastCandle, count:1, short: tabClose[i].short, close: tabClose[i].close, liq: tabClose[i].liq,pseudo: tabClose[i].pseudo, txt: tabClose[i].txt})
          }
          
        }
        else {
          while(tabClose[i].time < lastCandle) {
            lastCandle = lastCandle - msResolution;
          }
          if(newTabClose.length > 0  && tabClose[i].time > newTabClose[newTabClose.length-1].candleStart) {
            newTabClose[newTabClose.length-1].short += tabClose[i].short;
            newTabClose[newTabClose.length-1].close += tabClose[i].close;
            newTabClose[newTabClose.length-1].liq += tabClose[i].liq;
            newTabClose[newTabClose.length-1].count += 1;
            newTabClose[newTabClose.length-1].pseudo += "\n"+tabClose[i].pseudo;
            newTabClose[newTabClose.length-1].txt += "\n"+tabClose[i].txt;
          }
          else {
            newTabClose.push({candleStart: lastCandle, count:1, short: tabClose[i].short, close: tabClose[i].close, liq: tabClose[i].liq,pseudo: tabClose[i].pseudo, txt: tabClose[i].txt})
          }
        }

      }


      //var tableauShapesGlobalesOpen = [];
     // var tableauShapesGlobalesClose = [];

     // var lastPositionOpen = {};


      //alert(JSON.stringify(newTabOpen))

      

      for (let i = 0; i < newTabOpen.length; i++) {


        let color = "#5b9cf6";
        let size = 45;
        let texte = "";

        if(newTabOpen[i].long > 0 && !pseudos) {
          texte += "L:"+newTabOpen[i].long+"\n";
        }
        if(newTabOpen[i].close > 0 && !pseudos) {
          texte += "CS:"+newTabOpen[i].close+"\n";
        }
        if(newTabOpen[i].liq > 0 && !pseudos) {
          texte += "Liq:"+newTabOpen[i].liq+"\n";
        }
        if(pseudos) {

          texte += this.cleanedPseudos(newTabOpen[i].txt);
        }
        let ID = this.tvWidget.chart().createShape(
        {
          time: Math.trunc(newTabOpen[i].candleStart / 1000),
          channel: "low"
        },
        {
          shape: "arrow_up",
          lock: false,
          zOrder: "top",
          overrides: {
            text: texte,
            arrowColor: color,
            color: color,
            size: size,
          },
        }
        );

        if(i === 0) {
          this.setState({lastPositionOpen: {ID: ID, objetPosition: newTabOpen[i]}});
        }
      }





      for (let i = 0; i < newTabClose.length; i++) {
        let color = "orange";
        let texte = "";
        var size = 45;
        /*

        if(newTabClose[i].count >= 3) {
          color = "orange";
          size = 50;
        }
        if(newTabClose[i].count >= 6) {
          color = "red";
          size = 55;
        }*/
        if(pseudos) {
          texte += this.cleanedPseudos(newTabClose[i].txt)+"\n";
        }
        if(newTabClose[i].close > 0 && !pseudos) {
          texte += "CL:"+newTabClose[i].close+"\n";
        }
        if(newTabClose[i].short > 0 && !pseudos) {
          texte += "S:"+newTabClose[i].short;
        }
        if(newTabClose[i].liq > 0 && !pseudos) {
          texte += "Liq:"+newTabClose[i].liq;
        }
        let ID = this.tvWidget.chart().createShape(
        {
          time: Math.trunc(newTabClose[i].candleStart / 1000),
          channel: "high"
        },
        {
          shape: "arrow_down",
          lock: false,
          zOrder: "top",
          overrides: {
            text: texte,
            arrowColor: color,
            size: size,
            color: color
          },
        }
        );

        if(i === 0) {
          this.setState({lastPositionClose: {ID: ID, objetPosition: newTabClose[i]}});
        }
      }



      for (let i = 0; i < newTabLiq.length; i++) {
        let color = "purple";
        let texte = newTabLiq[i].count+"\n";

        if(pseudos) {
          texte += newTabLiq[i].pseudo+"\n";
        }
        this.tvWidget.chart().createShape(
        {
          time: Math.trunc(newTabLiq[i].candleStart / 1000),
          channel: "high"
        },
        {
          shape: "arrow_down",
          lock: false,
          zOrder: "top",
          overrides: {
            text: texte,
            arrowColor: color,
            size: size,
            color: color
          },
        }
        );
      }







      return;
    }
    //Si un seuil de PNL est defini
    var seuilPNL = this.props.valueLevierMiniPNL;
    var maxPNL = this.props.valueLevierMaxiPNL;


    //Demande d'affichage de tableau vide
    if(tab.length === 0) {
      this.tvWidget.chart().removeAllShapes();
      this.setState({objetIdJoueurs: []});
    }

    //objetIdJoueurs contient le tableau des ordres tels qu'ils sont actuellement affichés
    var objetIdJoueurs = this.state.objetIdJoueurs;


    //Si le joueur est encore présent dans objetID mais n'existe plus dans tab, il faut supprimer le joueur
    for (let i = 0; i < objetIdJoueurs.length; i++) {
      if(this.existInObject(objetIdJoueurs,objetIdJoueurs[i].pseudo) !== -1 )
      {
        if(this.existInTab(tab, objetIdJoueurs[i].pseudo) === false) {
          this.deletePlayer(objetIdJoueurs[i]);
        }
      }
    }



    /////////MODE ANALYSE/////////
    

    let objetJoueur = {};
    var joueurExiste = -1;

    //Pour chaque pseudo de tab
    for (let i = 0; i < tab.length; i++) {
      
      objetJoueur = {
        pseudo: "",
        positions: [],
        time: []
      }

      joueurExiste = -1;

      //Si le joueur existe déja dans objetIdJoueurs:
        for (var k = 0; k < objetIdJoueurs.length; k++) {
          for (var m = 0; m < tab[i].length; m++) {
            if(objetIdJoueurs[k].pseudo === tab[i][m].pseudo) {
              joueurExiste = k;
            }
          }
        }


      if(joueurExiste === -1) {
      //Si le joueur n'existe pas, on cree une shape pour chaque position
        for (let j = 0; j < tab[i].length; j++) {


          if(tab[i][j].orderType === "[newPosition]" && j < tab[i].length-1) {


           // alert(Math.abs(tab[i][j+1].gain));
            if(Math.abs(tab[i][j+1].gain) > seuilPNL && Math.abs(tab[i][j+1].gain) < maxPNL) {

              var texteGains = "";

              if(gains === true) {
                texteGains = tab[i][j+1].gain;
              }

              var textePseudos = "";

              if(pseudos === true) {
                textePseudos = tab[i][j].pseudo;
              }



              let id1 = this.addSpectateShape(Math.trunc(tab[i][j].time / 1000), tab[i][j].entry, textePseudos+" "+tab[i][j].sens);
              let id2 = this.addSpectateShape(Math.trunc(tab[i][j+1].time / 1000), tab[i][j+1].entry, textePseudos+" "+texteGains, tab[i][j].sens);

              objetJoueur.pseudo = tab[i][j].pseudo;
              objetJoueur.positions.push(id1);
              objetJoueur.positions.push(id2);
              objetJoueur.time.push(tab[i][j].time);
              objetJoueur.time.push(tab[i][j+1].time);
              
            }
          }



          else if(tab[i][j].orderType === "[newPosition]" && j === tab[i].length-1) {

              var textePseudos = "";

              if(pseudos === true) {
                textePseudos = tab[i][j].pseudo;
              }


              let id1 = this.addSpectateShape(Math.trunc(tab[i][j].time / 1000), tab[i][j].entry, textePseudos+" "+tab[i][j].sens);

              objetJoueur.pseudo = tab[i][j].pseudo;
              objetJoueur.positions.push(id1);
              objetJoueur.time.push(tab[i][j].time);
          }


          if(tab[i][j].orderType === "[Liquidation]") {
            if(pseudos === true) {
                textePseudos = tab[i][j].pseudo;
              }

            let id0 = this.addSpectateShape(Math.trunc(tab[i][j-1].time / 1000), tab[i][j-1].entry, textePseudos+" "+tab[i][j-1].sens);  
            let id1 = this.addSpectateShape(Math.trunc(tab[i][j].time / 1000), tab[i][j].entry, textePseudos+" "+tab[i][j].sens);

            objetJoueur.pseudo = tab[i][j].pseudo;
            objetJoueur.positions.push(id1);
            objetJoueur.positions.push(id0);
            objetJoueur.time.push(tab[i][j-1].time);
            objetJoueur.time.push(tab[i][j].time);
          }
        }
        objetIdJoueurs.push(objetJoueur);
      }

      else {
      //Si joueur existe, on compare son nombre de positions de tab avec celles du state. Si il y a difference, mise a jour.

      //  alert(objetIdJoueurs[joueurExiste].positions.length + " vs "+tab[i].length);

        if(objetIdJoueurs[joueurExiste].positions.length === tab[i].length) {
          //Positions inchangées
          
        }
        else {
          //Si nouvelles de positions:
          if(objetIdJoueurs[joueurExiste].positions.length !== tab[i].length) {
            objetJoueur = {
              pseudo: "",
              positions: [],
              time: []
            }

            this.deletePlayer(objetIdJoueurs[joueurExiste]);

            objetIdJoueurs[joueurExiste] = {};

            for (let j = 0; j < tab[i].length; j++) {

              if(tab[i][j].orderType === "[newPosition]" && j < tab[i].length-1) {
                if(Math.abs(tab[i][j+1].gain) > seuilPNL && Math.abs(tab[i][j+1].gain) < maxPNL) {

                  let texteGains = "";

                  if(gains === true) {
                    texteGains = tab[i][j+1].gain;
                  }

                  let textePseudos = "";

                  if(pseudos === true) {
                    textePseudos = tab[i][j].pseudo;
                  }

                  let id1 = this.addSpectateShape(Math.trunc(tab[i][j].time / 1000), tab[i][j].entry, textePseudos+" "+tab[i][j].sens);
                  let id2 = this.addSpectateShape(Math.trunc(tab[i][j+1].time / 1000), tab[i][j+1].entry, textePseudos+" "+texteGains, tab[i][j].sens);

                  objetJoueur.pseudo = tab[i][j].pseudo;
                  objetJoueur.positions.push(id1);
                  objetJoueur.positions.push(id2);
                  objetJoueur.time.push(tab[i][j].time);
                  objetJoueur.time.push(tab[i][j+1].time);
                }
              }

              if(tab[i][j].orderType === "[Liquidation]") {

                if(pseudos === true) {
                  textePseudos = tab[i][j].pseudo;
                }
                let id0 = this.addSpectateShape(Math.trunc(tab[i][j-1].time / 1000), tab[i][j-1].entry, textePseudos+" "+tab[i][j-1].sens);
                let id1 = this.addSpectateShape(Math.trunc(tab[i][j].time / 1000), tab[i][j].entry, textePseudos+" "+tab[i][j].sens);
                objetJoueur.pseudo = tab[i][j].pseudo;
                objetJoueur.positions.push(id0);
                objetJoueur.positions.push(id1);
                objetJoueur.time.push(tab[i][j-1].time);
                objetJoueur.time.push(tab[i][j].time);
              }
            }

            objetIdJoueurs[joueurExiste] = objetJoueur;
          }
        }
      }

        this.setState({objetIdJoueurs: objetIdJoueurs});


    }


    
  }

  cleanedPseudos(texte) { 

    var final = "";

     var returnLine = texte.split("\n");

     for (var i = 0; i < returnLine.length; i++) {
       var spaceLine = returnLine[i].split(" ");
       var finalSpace = "";
       if(spaceLine[0].match(truncateRegex)) {
          spaceLine[0] = truncateEthAddress(spaceLine[0])
       }
       for (var j = 0; j < spaceLine.length; j++) {
         finalSpace += spaceLine[j] + " ";
       }

       final += finalSpace + "\n"
     }

     return final
  }

  existInObject(objetIdJoueurs, psd) {
    for (var k = 0; k < objetIdJoueurs.length; k++) {
      if(objetIdJoueurs[k].pseudo === psd) {
        return k;
      }
    }

    return -1;
  }


  existInTab(tab, psd) {
    for (var i = 0; i < tab.length; i++) {
      for (var j = 0; j < tab[i].length; j++)
      if(tab[i][j].pseudo === psd) {
        return tab[i][j].pseudo;
      }
    }

    return false;
  }

  deletePlayer(tab) {
    for (var i = 0; i < tab.positions.length; i++) {
      this.tvWidget.chart().removeEntity(tab.positions[i]);
    }
  }




  addSpectateShape(time, price, type, previousType) {
    var color = "#e96bff";
    var shape = "arrow_down"
    
    var indicateur = type.split(" ");
    var text = indicateur[0];

    //console.log("price: "+price+ " type: "+type+" prev: "+previousType)

    if(type.includes("Long"))
    {
      color = "#5b9cf6";
      shape = "arrow_up";
      text = type;
    }

    else if(type.includes("Short"))
    { 
      color="orange";
      shape = "arrow_up";
      text = type;
    }

    if(!type.includes("Short") && !type.includes("Long") && previousType !== undefined)
    {
      shape = "arrow_down";
      if(previousType.includes("Long")) {
        color = "orange";
      }
      if(previousType.includes("Short")) {
        color="#5b9cf6";
      }
      text = type;
    }


    text = text.replace("Short", "");
    text = text.replace("Long", "");
    text = text.replace("undefined", "");

    
    if(color !== "#e96bff") {
      var ID = this.tvWidget.chart().createShape(
      {
        time: time,
        price: price
      },
      {
        shape: shape,
        lock: true,
        zOrder: "top",
        overrides: {
          text: text,
          showLabel: true,
          fontsize: 24,
          horzLabelsAlign: "right",
          backgroundColor: color,
          arrowColor: color,
          borderColor: color,
          color: color,
          textcolor: color
        },
      }
      );
    }
    if(color === "#e96bff") {
      var ID = this.tvWidget.chart().createShape(
      {
        time: time,
        channel: "close"
      },
      {
        shape: shape,
        lock: true,
        zOrder: "top",
        overrides: {
          text: text,
          showLabel: true,
          fontsize: 24,
          horzLabelsAlign: "right",
          backgroundColor: color,
          arrowColor: color,
          borderColor: color,
          color: color,
          textcolor: color
        },
      }
      );
    }
    

    return ID;
  }

  drawIngameShape(type, param1, param2) {

    var time = Math.trunc(Date.now() / 1000);

    var gamestateID = this.state.gamestateID;
    /*
    gamestateID = {
      entry : {
        id: ""
      },
      liquid : {
        id: ""
      },
      orders : [
        {
          id: "",
          order: {}
        }
      ]
    }
    */
    if(type === "entry") {
      var txt = "";
      var color = "";

      if (param2 === "HAUSSE") {
        txt = "Entry Long";
        color = "#11d89c";
      } else if (param2 === "BAISSE") {
        txt = "Entry Short";
        color = "#ff5757";
      }

      if(gamestateID.entry.id !== "") {
        this.tvWidget.chart().removeEntity(gamestateID.entry.id);
        gamestateID.entry.id = "";
      }
      gamestateID.entry.id = this.tvWidget.chart().createShape(
        {
          price: Number(param1),
          time: time,
        },
        {
          shape: "horizontal_line",
          lock: true,
          overrides: {
            text: txt,
            showLabel: true,
            fontsize: 20,
            horzLabelsAlign: "left",
            linecolor: color,
            textcolor: color,
          },
        }
      );
    }

    if(type === "liquid") {
      if(gamestateID.liquid.id !== "") {
        this.tvWidget.chart().removeEntity(gamestateID.liquid.id);
        gamestateID.liquid.id = "";
      }
      gamestateID.liquid.id = this.tvWidget.chart().createShape(
        {
          price: Number(param1),
          time: time,
        },
        {
          shape: "horizontal_line",
          lock: true,
          overrides: {
            text: "Liquidation",
            showLabel: true,
            fontsize: 20,
            horzLabelsAlign: "left",
            linecolor: "#66e0ff",
            textcolor: "#66e0ff",
          },
        }
      );
    }

    if(type === "order") {
      let orderToDraw = param1;
      let indice = -1;
      //console.log("Request to draw an order...:"+JSON.stringify(orderToDraw)+" existing orders:" +JSON.stringify(gamestateID.orders))


      //Existe t'il un ordre déja déssiné avec le meme indice ?
      for (var i = 0; i < gamestateID.orders.length; i++) {
        if(gamestateID.orders[i].order.indice === orderToDraw.indice && gamestateID.orders[i].order.action === orderToDraw.action && gamestateID.orders[i].order.pair === orderToDraw.pair) {
          indice = i;
        }
      }

      //Il n'existe pas d'ordre a update
      if(indice === -1) {
        //console.log("Order doesnt exist !");

        let caller = this;

        let idd = this.tvWidget.chart().createOrderLine()
        .setTooltip("Execute order if price goes @")
        .setText(orderToDraw.action +" @ :")
        .setPrice(orderToDraw.value)
        .setQuantity(orderToDraw.value)
        .setBodyBackgroundColor("rgb(30, 36, 47)")
        .setCancelButtonBackgroundColor("rgb(30, 36, 47)")
        .setQuantityTextColor("rgb(30, 36, 47)")

        gamestateID.orders.push({id: idd, order: orderToDraw})
        //console.log("AAAA"+gamestateID)
      }
      //Il en existe un
      else {
        //console.log("Order exist !");
        //this.tvWidget.chart().removeEntity(gamestateID.orders[indice].id);
        gamestateID.orders[indice].id.remove();

        let caller = this;

        let idd = this.tvWidget.chart().createOrderLine()
        .setTooltip("Execute order if price goes @")
        .setText(orderToDraw.action +" @ :")
        .setPrice(orderToDraw.value)
        .setQuantity(orderToDraw.value)
        .setBodyBackgroundColor("rgb(30, 36, 47)")
        .setCancelButtonBackgroundColor("rgb(30, 36, 47)")
        .setQuantityTextColor("rgb(30, 36, 47)")

        gamestateID.orders[indice].id = idd;
        gamestateID.orders[indice].order = orderToDraw;

        //gamestateID.orders.push({id: idd, order: orderToDraw})
      }
    }


    this.setState({gamestateID: gamestateID});
  }

  eraseIngameShape(type, param) {
    var gamestateID = this.state.gamestateID;
    /*
    gamestateID = {
      entry : {
        id: ""
      },
      liquid : {
        id: ""
      },
      orders : [
        {
          id: "",
          order: {}
        }
      ]
    }
    */
    if(type === "entry") {
      if(gamestateID.entry.id !== "") {
        this.tvWidget.chart().removeEntity(gamestateID.entry.id);
        gamestateID.entry.id = "";
      }
    }
    if(type === "liquid") {
      if(gamestateID.liquid.id !== "") {
        this.tvWidget.chart().removeEntity(gamestateID.liquid.id);
        gamestateID.liquid.id = "";
      }
    }
    if(type === "order") {
      //console.log("Order deleted !");
      for (var i = 0; i < gamestateID.orders.length; i++) {
        if(JSON.stringify(gamestateID.orders[i].order) === JSON.stringify(param)) {
          gamestateID.orders[i].id.remove();
          gamestateID.orders.splice(i, 1);
        }
      }
    }




    this.setState({gamestateID: gamestateID});
  }
    

  createShape(id, price, bet, pseudo, time, gain) {
    //price = Number(price);
   // const now = new Date();
    //const time = now.getTime();

    time = Math.trunc(time / 1000);

    //Toutes les données ingame sont gérées ici, passées en second param
    if (id === "ingame") {
      var gamestate = price;
      var oldGamestate = this.state.gamestate;

      //Si les nouvelles données sont identiques aux anciennes, rien ne passe, sinon....
      if(JSON.stringify(gamestate) === JSON.stringify(oldGamestate)) {
      }
      else {
        if(gamestate.entry !== oldGamestate.entry) {
          if(gamestate.entry === 0) {
            this.eraseIngameShape("entry");
            this.eraseIngameShape("liquid");
          } 
          else {
            this.drawIngameShape("entry", gamestate.entry, gamestate.pari);
            this.drawIngameShape("liquid", gamestate.liquid, gamestate.pari);

            for (let i = 0; i < gamestate.orders.length; i++) {
              this.drawIngameShape("order", gamestate.orders[i]);
            }
          }
        }
        //Une mise a jour des ordres est necessaire

        if(JSON.stringify(gamestate.orders) !== JSON.stringify(oldGamestate.orders) ) {
          //Il n'existe pas d'ordres, on peut les dessiner directement
          if(oldGamestate.orders === undefined || oldGamestate.orders.length === 0) {
            for (let i = 0; i < gamestate.orders.length; i++) {
              this.drawIngameShape("order", gamestate.orders[i]);
            }
          }
          //Il faut mettre a jour les ordres
          else if(gamestate.orders.length === oldGamestate.orders.length) {
            for (let i = 0; i < gamestate.orders.length; i++) {
              for (let j = 0; j < oldGamestate.orders.length; j++) {
                if(gamestate.orders[i].action === oldGamestate.orders[j].action && gamestate.orders[i].indice === oldGamestate.orders[j].indice && oldGamestate.orders[j].pair === gamestate.orders[i].pair &&gamestate.orders[i].value !== oldGamestate.orders[j].value ) {
                  this.drawIngameShape("order", gamestate.orders[i]);
                }
              }
              
            }
          }
          //Il faut ajouter un ordre
          else if(gamestate.orders.length > oldGamestate.orders.length) {
            for (let i = 0; i < gamestate.orders.length; i++) {
              let indiceAAjouter = -1;
              for (let j = 0; j < oldGamestate.orders.length; j++) {
                if(gamestate.orders[i].action === oldGamestate.orders[j].action && gamestate.orders[i].indice === oldGamestate.orders[j].indice && oldGamestate.orders[j].pair === gamestate.orders[i].pair && gamestate.orders[i].value === oldGamestate.orders[j].value ) {
                  indiceAAjouter = j;
                }
              }

              

              if(indiceAAjouter === -1) {

                this.drawIngameShape("order", gamestate.orders[i]);
              }
              
            }
          }
          else if(gamestate.orders.length < oldGamestate.orders.length) {

            for (let i = 0; i < oldGamestate.orders.length; i++) {
              let indiceASupprimer = -1;
              for (let j = 0; j < gamestate.orders.length; j++) {
                if(oldGamestate.orders[i].action === gamestate.orders[j].action && oldGamestate.orders[i].indice === gamestate.orders[j].indice && oldGamestate.orders[i].pair === gamestate.orders[j].pair) {
                  indiceASupprimer = i;
                }
              }
              if(indiceASupprimer === -1) {

                this.eraseIngameShape("order", oldGamestate.orders[i]);
              }
            }
          }
        }
      }

      this.setState({ gamestate: gamestate });
    }

    if (id === "closeSup0") {

      if (this.state.closeSupLineID !== "") {
        this.state.closeInfLineID.remove();
        this.setState({ closeInfLineID: "" });
      }

      let caller = this;

      

      var closeSup = this.tvWidget.chart().createOrderLine()
        .setTooltip("Close position if price goes above the trigger")
        .setCancelTooltip("Cancel order")
        .onMove(function() {
            
            var symbol = caller.props.symbol.toUpperCase().replace("USDT", "PERP");
            this.setText("Close above :"+this.getPrice());
            caller.handleExternal({id: "closeSup0", price: this.getPrice(),sens: "Sup", paire: symbol});
            
        })
        .onCancel("onCancel called", function(text) {
            var symbol = caller.props.symbol.toUpperCase().replace("USDT", "PERP");
            caller.handleExternal({id: "closeSup0", sens: "Sup", close: true, paire: symbol});
        })
        .setText("Close above :")
        .setPrice(price)
        .setQuantity(price)
        .setBodyBackgroundColor("rgb(30, 36, 47)")
        .setCancelButtonBackgroundColor("rgb(30, 36, 47)")
        .setQuantityTextColor("rgb(30, 36, 47)")



      this.setState({ closeSupLineID: closeSup });
    }

    if (id === "closeInf") {

      if (this.state.closeInfLineID !== "") {
        this.state.closeInfLineID.remove();
        this.setState({ closeInfLineID: "" });
      }

      let caller = this;

      var closeInf = this.tvWidget.chart().createOrderLine()
        .setTooltip("Close position if price goes bellow the trigger")
        .setCancelTooltip("Cancel order")
        .onMove(function() {
            this.setText("Close bellow :"+this.getPrice());
            var symbol = caller.props.symbol.toUpperCase().replace("USDT", "PERP");
            caller.handleExternal({price: this.getPrice(),sens: "Inf", paire: symbol});
        })
        .onCancel("onCancel called", function(text) {
          var symbol = caller.props.symbol.toUpperCase().replace("USDT", "PERP");
            caller.handleExternal({sens: "Inf", close: true, paire: symbol});
        })
        .setText("Close bellow :")
        .setPrice(price)
        .setQuantity(price)
        .setBodyBackgroundColor("rgb(30, 36, 47)")
        .setCancelButtonBackgroundColor("rgb(30, 36, 47)")
        .setLineColor("rgb(255, 87, 87)")
        .setBodyBorderColor("rgb(255, 87, 87)")
        .setBodyTextColor("rgb(255, 87, 87)")
        .setQuantityBackgroundColor("rgb(255, 87, 87)")
        .setQuantityBorderColor("rgb(255, 87, 87)")
        .setQuantityTextColor("rgb(30, 36, 47)")
        .setCancelButtonIconColor("rgb(255, 87, 87)")
        .setCancelButtonBorderColor("rgb(255, 87, 87)")


      this.setState({ closeInfLineID: closeInf });
    }

    if (id === "longOrder") {

      /*
      for (var i = 0; i < this.state.ordersLineIDs.length; i++) {
        var splitter = this.state.ordersLineIDs[i].split(" ");

        if (Number(splitter[0]) === Number(price)) {
          return;
        }
      }*/
      let prix = Number(price);

      let closeInf = this.tvWidget.chart().createShape(
        {
          time: time,
          price: prix,
        },
        {
          shape: "horizontal_line",
          lock: true,
          overrides: {
            text: "Ordre Long",
            showLabel: true,
            fontsize: 16,
            horzLabelsAlign: "right",
            linecolor: "#11d89c",
            textcolor: "#11d89c",
            linestyle: 1,
          },
        }
      );
      /*

      var lineID = prix + " " + closeInf;

      var tabID = this.state.ordersLineIDs;
      tabID.push(lineID);
      this.setState({ ordersLineIDs: tabID });*/


      var tabID = this.state.ordersLineIDs;

     // alert("before adding: "+JSON.stringify(tabID))

      tabID.push(closeInf);

     // alert("after adding: "+JSON.stringify(tabID))

      this.setState({ ordersLineIDs: tabID });
    }

    if (id === "shortOrder") {
      /*
      for (var i = 0; i < this.state.ordersLineIDs.length; i++) {
        var splitter = this.state.ordersLineIDs[i].split(" ");

        if (Number(splitter[0]) === Number(price)) {
          return;
        }
      }*/

      let prix = Number(price);

      let closeInf = this.tvWidget.chart().createShape(
        {
          time: time,
          price: prix,
        },
        {
          shape: "horizontal_line",
          lock: true,
          overrides: {
            text: "Ordre Short",
            showLabel: true,
            fontsize: 16,
            horzLabelsAlign: "right",
            linecolor: "#ff5757",
            textcolor: "#ff5757",
            linestyle: 1,
          },
        }
      );

      let tabID = this.state.ordersLineIDs;
    //  alert("before adding: "+JSON.stringify(tabID))

      tabID.push(closeInf);

     // alert("after adding: "+JSON.stringify(tabID))
      this.setState({ ordersLineIDs: tabID });

      /*

      var lineID = prix + " " + closeInf;

      var tabID = this.state.ordersLineIDs;
      tabID.push(lineID);
      this.setState({ ordersLineIDs: tabID });*/
    }

    var pseudoV = pseudo;

    if(id === "Object") {
      //alert(JSON.stringify(price));
    }

    if (id === "Long") {
      //alert("LONG REQ");

      var prix = Number(price);

      if (pseudoV === "-") {
        pseudoV = "Long";
      }

      let id = this.tvWidget.chart().createShape(
        {
          time: time,
          price: prix,
        },
        {
          shape: "arrow_right",
          lock: true,
          zOrder: "top",
          overrides: {
            text: pseudoV,
            showLabel: true,
            wordWrapWidth: 30,
            horzLabelsAlign: "right",
            arrowColor: "#18af2a",
            color: "#18af2a",
            textcolor: "#18af2a",
            fontsize: 30,
          },
        }
      );

      let tab = this.state.shapesCustom;
      tab.push(pseudo + " " + id);

      this.setState({ shapesCustom: tab });
    }

    if (id === "Short") {
      //alert("SHORT REQ");
      if (false && this.state.closeInfLineID !== "") {
        this.tvWidget.chart().removeEntity(this.state.closeInfLineID);
        this.setState({ closeInfLineID: "" });
      }
      let prix = Number(price);

      if (pseudoV === "-") {
        pseudoV = "Short ";
      }

      let id = this.tvWidget.chart().createShape(
        {
          time: time,
          price: prix,
        },
        {
          shape: "arrow_right",
          lock: true,
          zOrder: "top",
          overrides: {
            text: pseudoV,
            showLabel: true,
            horzLabelsAlign: "right",
            arrowColor: "#e10d0d",
            color: "#e10d0d",
            textcolor: "#e10d0d",
            fontsize: 30,
          },
        }
      );
      let tab = this.state.shapesCustom;
      tab.push(pseudo + " " + id);

      this.setState({ shapesCustom: tab });
    }

    if (id === "Close") {
      // alert("CLOSE REQ");
      if (false && this.state.closeInfLineID !== "") {
        this.tvWidget.chart().removeEntity(this.state.closeInfLineID);
        this.setState({ closeInfLineID: "" });
      }
      let prix = Number(price);

      if (pseudoV === "-") {
        pseudoV = "";
      }

      let id = this.tvWidget.chart().createShape(
        {
          time: time,
          price: prix,
        },
        {
          shape: "arrow_left",
          lock: true,
          zOrder: "top",
          overrides: {
            text: gain + " " + pseudoV,
            showLabel: true,
            horzLabelsAlign: "right",
            arrowColor: "#fff59d",
            textcolor: "#fff59d",
            color: "#fff59d",
            backgroundColor: "#fff59d",
            fontsize: 30,
          },
        }
      );
      let tab = this.state.shapesCustom;
      tab.push(pseudo + " " + id);

      this.setState({ shapesCustom: tab });
    }

    if (
      id === "Vertical" &&
      price !== undefined &&
      this.state.vertical === ""
    ) {
      let id = this.tvWidget.chart().createShape(
        {
          time: price / 1000,
        },
        {
          shape: "vertical_line",
          lock: true,
          zOrder: "top",
          overrides: {
            text: "Game starts",
            showLabel: true,
            horzLabelsAlign: "right",
            linecolor: "#FFFFFF",
            textcolor: "#FFFFFF",
            showTime: true,
            backgroundColor: "#fff59d",
          },
        }
      );

      this.setState({ vertical: id });
    }
  }


  handleExternal(value) {
    this.props.externalTradingViewCall(value);
  }




  removeShape(id, value) {
    if (id === "entry") {
      this.tvWidget.chart().removeEntity(this.state.entryLineID);
      this.setState({ entryLineID: "" });
    }

    if (id === "liquid") {
      this.tvWidget.chart().removeEntity(this.state.liquidLineID);
      this.setState({ entryLineID: "" });
    }

    if (id === "closeSup") {
      if(this.state.closeSupLineID !== "")
      {
        this.state.closeSupLineID.remove();
        this.setState({ closeSupLineID: "" });
        
      }
    }

    if (id === "Vertical") {
      this.tvWidget.chart().removeEntity(this.state.vertical);
      this.setState({ vertical: "" });
    }

    if (id === "order") {
      var tabTest = this.state.ordersLineIDs;
      //alert("before delete: "+JSON.stringify(tabTest))

      for (var i = 0; i < tabTest.length; i++) {
          this.tvWidget.chart().removeEntity(tabTest[i]);
          tabTest.splice(i, 1);
      }

        
      

     // alert("after delete: "+JSON.stringify(tabTest))
      /*

      for (var i = 0; i < tabTest.length; i++) {
        var splitter = tabTest[i].split(" ");
        if (splitter[0] === value) {
          this.tvWidget.chart().removeEntity(splitter[1]);
          tabTest.splice(i, 1);
        }
      }*/

      this.setState({ ordersLineIDs: tabTest });
    }

    if (id === "closeInf") {
      if(this.state.closeInfLineID !== "")
      {
        this.state.closeInfLineID.remove();
        this.setState({ closeInfLineID: "" });

      }
    } 

    else {
      var tab = this.state.shapesCustom;
      var mots = "";
      for (let i = 0; i < tab.length; i++) {
        mots = tab[i].split(" ");
        var testeur = mots[0];

        if (!isNaN(testeur)) {
          testeur = mots[1];
        }

        if (testeur === id) {
          this.tvWidget.chart().removeEntity(mots[1]);
          // tab.splice(i, 1);
        }

        if (testeur === "-") {
          this.tvWidget.chart().removeEntity(mots[1]);
          // tab.splice(i, 1);
        }
      }

      this.setState({ shapesCustom: tab });
    }
  }

  async fetchSavedNames() {
    instanceAWS
      .post("/dev/getcharts", { PSEUDO: this.props.pseudo })
      .then((result) => {
        this.setState({ chartsName: result.data.body });
      });
  }

  async displayLoadChart() {
    await this.fetchSavedNames();
    document.getElementById("loadChart").style.display = "block";
  }

  returnLoadChart() {
    var chartTmp = [];
    for (var i = 0; i < this.state.chartsName.length; i++) {
      chartTmp.push(
        <div
          className="clickableChartLoad"
          onClick={this.loadChart(this.state.chartsName[i])}
        >
          {" "}
          {this.state.chartsName[i]}{" "}
        </div>
      );
    }

    return <div className="clickableChartLoadCont"> {chartTmp} </div>;
  }

  loadChart = (value) => (event) => {
    event.preventDefault();

    document.getElementById("loadChart").style.display = "none";

    instanceAWS
      .post("/dev/getcharts", { PSEUDO: this.props.pseudo, CHARTNAME: value })
      .then((result) => {
        var body = result.data.body;
        this.tvWidget.load(body[0]);
      });
    //this.tvWidget.load(this.state.chart);
  };

  presentLoadedChart() {
    const button3 = this.tvWidget.createButton();
    button3.setAttribute("title", "Chart Loading");
    button3.classList.add("apply-common-tooltip");
    button3.addEventListener("click", () => this.displayLoadChart());

    button3.innerHTML = "Load Chart";
  }

  presentSaveDelete() {
    const button4 = this.tvWidget.createButton();
    button4.setAttribute("title", "Chart Saving");
    button4.classList.add("apply-common-tooltip");
    button4.addEventListener("click", () =>
      this.tvWidget.save((saveObject) => this.saveAs(saveObject))
    );

    button4.innerHTML = "Save Chart";

    const button5 = this.tvWidget.createButton();
    button5.setAttribute("title", "Chart Loading");
    button5.classList.add("apply-common-tooltip");
    button5.addEventListener("click", () => this.deleteAs());

    button5.innerHTML = "Delete Chart";
    /*
        const save_del = this.tvWidget.createDropdown(
        {
            title: 'Save...',
            tooltip: 'Saving Charts',
            items: [
            {
                title: 'Save As',
                onSelect: () => this.tvWidget.save((saveObject) => this.saveAs(saveObject)),
            },
            {
                title: 'Delete...',
                onSelect: () => {this.deleteAs();},
            },
            ],
        });*/
  }

  saveAs(saveObject) {
    document.getElementById("saveChart").style.display = "block";
    this.setState({ chartToSave: saveObject });
  }

  deleteAs(saveObject) {
    document.getElementById("deleteChart").style.display = "block";
  }

  saveAsAPICall = (event) => {
    event.preventDefault();

    document.getElementById("saveChart").style.display = "none";

    instanceAWS
      .post("/dev/savechart", {
        CHART: this.state.chartToSave,
        PSEUDO: this.props.pseudo,
        CHARTNAME: document.getElementById("inputSaveChart").value,
      })
      .then((result) => {
        this.setState({ chartToSave: {} });
        document.getElementById("inputSaveChart").value = "";
      });
  };

  deleteAPICall = (event) => {
    event.preventDefault();
    //this.setState({chart: {}});
    //update presentLoadedChart
    document.getElementById("deleteChart").style.display = "none";

    instanceAWS
      .post("/dev/deletechart", {
        PSEUDO: this.props.pseudo,
        CHARTNAME: document.getElementById("inputDeleteChart").value,
      })
      .then((result) => {
        document.getElementById("inputDeleteChart").value = "";
      });
  };

  closeSave = (event) => {
    event.preventDefault();
    document.getElementById("saveChart").style.display = "none";
  };

  closeDelete = (event) => {
    event.preventDefault();
    document.getElementById("deleteChart").style.display = "none";
  };

  closeLoad = (event) => {
    event.preventDefault();
    document.getElementById("loadChart").style.display = "none";
  };

  render() {
    return (
      <>
        <div ref={this.ref} className={"TVChartContainer"} />

        <div className="saveChart" id="saveChart">
          Saisissez le nom de la sauvegarde : <br />
          <input className="inputSaveChart" id="inputSaveChart" type="text" />
          <button
            style={{ width: "50%" }}
            className="buttonsTournament"
            title="sauvegarder le chart"
            onClick={this.saveAsAPICall}
          >
            {" "}
            Save{" "}
          </button>
          <div className="saveloadCloser" onClick={this.closeSave}>
            {" "}
            X{" "}
          </div>
        </div>

        <div className="deleteChart" id="deleteChart">
          <input
            className="inputDeleteChart"
            id="inputDeleteChart"
            type="text"
          />
          <button
            style={{ width: "50%" }}
            className="buttonsTournament"
            title="delete le chart"
            onClick={this.deleteAPICall}
          >
            {" "}
            delete{" "}
          </button>

          <div className="saveloadCloser" onClick={this.closeDelete}>
            {" "}
            X{" "}
          </div>
        </div>

        <div className="loadChart" id="loadChart">
          {this.returnLoadChart()}

          <div className="saveloadCloser" onClick={this.closeLoad}>
            {" "}
            X{" "}
          </div>
        </div>
      </>
    );
  }
}
