export function getValFromCitation(citation) {
  var valRetour = "";

  if (citation === "title1" || citation === "citation1") {
    valRetour = "Newcomer";
  } else if (citation === "title2" || citation === "citation2") {
    valRetour = "Apprentice trader";
  } else if (citation === "title3" || citation === "citation3") {
    valRetour = "Trading maxi";
  } else if (citation === "title4" || citation === "citation4") {
    valRetour = "Captain P&L";
  } else if (citation === "title5" || citation === "citation5") {
    valRetour = "Dr Shitcoin";
  } else if (citation === "title6" || citation === "citation6") {
    valRetour = "Chad";
  } else if (citation === "title7" || citation === "citation7") {
    valRetour = "Lord Degen";
  } else if (citation === "title8" || citation === "citation8") {
    valRetour = "Mad Gambler";
  } else if (citation === "title9" || citation === "citation9") {
    valRetour = "Sir rekt-less";
  } else if (citation === "title100" || citation === "citation100") {
    valRetour = "TradeAsGame Team";
  } else if (citation === "title101") {
    valRetour = "TradeAsGame CEO";
  } else if (citation === "title102") {
    valRetour = "TradeAsGame CTO";
  } else if (citation === "title103") {
    valRetour = "Tech helper";
  } else if (citation === "title104") {
    valRetour = "Beta supporter";
  } else if (citation === "title105") {
    valRetour = "Emerald beta player";
  } else if (citation === "title106") {
    valRetour = "Diamond beta player";
  } else if (citation === "title107") {
    valRetour = "Gold beta player";
  } else if (citation === "title108") {
    valRetour = "The taoulesvignes";
  } else if (citation === "title109") {
    valRetour = "Beta's ladder emperor";
  } else if (citation === "title110") {
    valRetour = "Beta's ladder Second";
  } else if (citation === "title111") {
    valRetour = "Beta's ladder third";
  } else if (citation === "title112") {
    valRetour = "Beta's highest participation";
  } else if (citation === "title113") {
    valRetour = "Beta's highest score";
  } else if (citation === "title114") {
    valRetour = "Beta's highest number of top 1";
  } else if (citation === "title115") {
    valRetour = "Beta's highest number of podium";
  } else if (citation === "title116") {
    valRetour = "Beta tester";
  }
  return valRetour;
}


export function clearWeb3LocalStorage(){
  localStorage.removeItem("web3");
  localStorage.removeItem("address");
  localStorage.removeItem("sessionToken");
  localStorage.removeItem("accessKeyId");
  localStorage.removeItem("secretKey");
  localStorage.removeItem("expiration");
}
// Captures 0x + 4 characters, then the last 4 characters.
export const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;

/**
 * Truncates an ethereum address to the format 0x0000…0000
 * @param address Full address to truncate
 * @returns Truncated address
 */
 export const truncateEthAddress = (address) => {
  const match = address.match(truncateRegex);
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};
