import { instanceAWS } from "../../api/api_instance.js";
import { subscribeOnStream, unsubscribeFromStream } from "./streaming.js";
import { RestClientV5 } from 'bybit-api';
const lastBarsCache = new Map();


const BBclient = new RestClientV5({
    testnet: false,
});

const configurationData = {
  supported_resolutions: ["1", "3", "5", "15", "30", "60", "120", "240", "1D"],
  exchanges: [
    {
      value: "bybit",
      name: "bybit",
      desc: "bybit",
    },
    {
      // `exchange` argument for the `searchSymbols` method, if a user selects this exchange
      value: "bybit",

      // filter name
      name: "bybit",

      // full exchange name displayed in the filter popup
      desc: "bybit exchange",
    },
  ],
  symbols_types: [
    {
      name: "crypto",

      // `symbolType` argument for the `searchSymbols` method, if a user selects this symbol type
      value: "crypto",
    },
    // ...
  ],
};

async function getAllSymbols() {
  var allSymbols = [
    {
      symbol: "BTCUSDT",
      full_name: "bybit:btcusdt",
      description: "Bitcoin",
      exchange: "bybit",
      type: "crypto",
    },
    {
      symbol: "ETHUSDT",
      full_name: "bybit:ethusdt",
      description: "Ethereum",
      exchange: "bybit",
      type: "crypto",
    },
    {
      symbol: "SOLUSDT",
      full_name: "bybit:solusdt",
      description: "Solana",
      exchange: "bybit",
      type: "crypto",
    },
    {
      symbol: "AVAXUSDT",
      full_name: "bybit:avaxusdt",
      description: "Avalanche",
      exchange: "bybit",
      type: "crypto",
    },
    {
      symbol: "XRPUSDT",
      full_name: "bybit:xrpusdt",
      description: "Ripple",
      exchange: "bybit",
      type: "crypto",
    },
    {
      symbol: "DOGEUSDT",
      full_name: "bybit:dogeusdt",
      description: "Dogecoin",
      exchange: "bybit",
      type: "crypto",
    },
    {
      symbol: "MATICUSDT",
      full_name: "bybit:maticusdt",
      description: "Matic",
      exchange: "bybit",
      type: "crypto",
    },
    {
      symbol: "LTCUSDT",
      full_name: "bybit:ltcusdt",
      description: "Litecoin",
      exchange: "bybit",
      type: "crypto",
    },
    {
      symbol: "OPUSDT",
      full_name: "bybit:opusdt",
      description: "Op",
      exchange: "bybit",
      type: "crypto",
    },
    {
      symbol: "ATOMUSDT",
      full_name: "bybit:atomusdt",
      description: "Atom",
      exchange: "bybit",
      type: "crypto",
    },
    {
      symbol: "INJUSDT",
      full_name: "bybit:injusdt",
      description: "Injective",
      exchange: "bybit",
      type: "crypto",
    },
    {
      symbol: "ARBUSDT",
      full_name: "bybit:arbusdt",
      description: "Arbritrum",
      exchange: "bybit",
      type: "crypto",
    },
    {
      symbol: "BONKUSDT",
      full_name: "bybit:bonkusdt",
      description: "Bonk",
      exchange: "bybit",
      type: "crypto",
    },
    {
      symbol: "NEARUSDT",
      full_name: "bybit:nearusdt",
      description: "Near",
      exchange: "bybit",
      type: "crypto",
    },
    {
      symbol: "RUNEUSDT",
      full_name: "bybit:runeusdt",
      description: "Rune",
      exchange: "bybit",
      type: "crypto",
    },
    {
      symbol: "RNDRUSDT",
      full_name: "bybit:rndrusdt",
      description: "Render",
      exchange: "bybit",
      type: "crypto",
    },
    {
      symbol: "EGLDUSDT",
      full_name: "bybit:egldusdt",
      description: "Elrond",
      exchange: "bybit",
      type: "crypto",
    },
    {
      symbol: "TIAUSDT",
      full_name: "bybit:tiausdt",
      description: "Celestia",
      exchange: "bybit",
      type: "crypto",
    },
    {
      symbol: "SEIUSDT",
      full_name: "bybit:seiusdt",
      description: "Sei",
      exchange: "bybit",
      type: "crypto",
    },
  ];
  return allSymbols;
}

var dtfeed = {
  onReady: (callback) => {
    setTimeout(() => callback(configurationData));
  },

  searchSymbols: async (
    userInput,
    exchange,
    symbolType,
    onResultReadyCallback
  ) => {
    const symbols = await getAllSymbols();
    const newSymbols = symbols.filter((symbol) => {
      const isExchangeValid = exchange === "" || symbol.exchange === exchange;
      const isFullSymbolContainsInput =
        symbol.full_name.toLowerCase().indexOf(userInput.toLowerCase()) !== -1;
      return isExchangeValid && isFullSymbolContainsInput;
    });
    onResultReadyCallback(newSymbols);
  },

  resolveSymbol: async (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback
  ) => {
//    console.log("[resolveSymbol]: Method call", symbolName);
    const symbols = await getAllSymbols();
    const symbolItem = symbols.find(
      ({ full_name }) => full_name === symbolName
    );
    if (!symbolItem) {
    //  console.log("[resolveSymbol]: Cannot resolve symbol", symbolName);
      onResolveErrorCallback("cannot resolve symbol");
      return;
    }
    const symbolInfo = {
      ticker: symbolItem.full_name,
      name: symbolItem.symbol,
      description: symbolItem.description,
      type: symbolItem.type,
      session: "24x7",
      timezone: "Etc/UTC",
      exchange: symbolItem.exchange,
      minmov: 1,
      pricescale: 100,
      has_intraday: true,
      has_no_volume: true,
      has_seconds: true,
      has_weekly_and_monthly: false,
      supported_resolutions: configurationData.supported_resolutions,
      volume_precision: 2,
      data_status: "streaming",
      supports_marks: true,
    };

    //console.log('[resolveSymbol]: Symbol resolved', symbolName);
    onSymbolResolvedCallback(symbolInfo);
  },

  getBars: async (
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback
  ) => {
    const { from, countBack, to, firstDataRequest } = periodParams;
    try {

    // console.log(`[getBars]: asked: resolution: ${resolution} from: ${from} to: ${to} bar(s)`);


    BBclient.getKline({
      category: 'linear',
      symbol: symbolInfo.name,
      interval: resolution,
      start: from * 1000,
      end: to * 1000,
      limit: 999
    }).then((result) => {

        


        const data = result.result.list;

        

        let bars = [];

        if (data === undefined) {
          onHistoryCallback(bars, {
            noData: true,
          });
          return;
        }

        if (data.length === 0) {
          onHistoryCallback(bars, {
            noData: true,
          });
          return;
        }

        

        for (var i = data.length-1; i >= 0; i--) {

          if (data[i][0] === undefined) {
            onHistoryCallback(bars, {
              noData: true,
            });
            return;
          }


          var tempBar = {
            time: Number(data[i][0]),
            low: Number(data[i][3]),
            high: Number(data[i][2]),
            open: Number(data[i][1]),
            close: Number(data[i][4]),
          };

         // console.log(tempBar.time + " vs " + from * 1000);

          if (tempBar.time >= from * 1000 && tempBar.time < to * 1000) {
            bars.push(tempBar);
          }
        }

        if (firstDataRequest) {
          lastBarsCache.set(symbolInfo.full_name, {
            ...bars[bars.length - 1],
          });
        }

//        console.log(`[getBars]: returned ${bars.length} bar(s)`);

        onHistoryCallback(bars, {
          noData: false,
        });
      });
    } catch (error) {
//      console.log("[getBars]: Get error", error);
      onErrorCallback(error);
    }
  },

  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback
  ) => {
    subscribeOnStream(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscribeUID,
      onResetCacheNeededCallback,
      lastBarsCache.get(symbolInfo.full_name)
    );
  },

  unsubscribeBars: (subscriberUID) => {
    unsubscribeFromStream(subscriberUID);
  },
  getMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
    var Arr = [
      {
        id: 1,
        time: 1654236086400 / 1000,
        color: "blue",
        text: "Hello",
        label: "B",
        labelFontColor: "yellow",
        minSize: 60,
      },
    ];
    onDataCallback(Arr);

    console.log("=====getMarks running");
  },
};
export default dtfeed;
