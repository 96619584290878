import React from "react";

export default function Footer() {
  ///////////////////////////////////
  ////          Footer.js        ////
  //// Fichier de bas de page    ////
  ///////////////////////////////////

  return (
    <footer>
      <div className="content has-text-centered"></div>
    </footer>
  );
}
