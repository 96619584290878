import React, { Component } from "react";

import { Link } from "react-router-dom";

import graphAcceuil from "./images/graphacceuil.png";

import assetTesla from "./images/doge.png";
import assetApple from "./images/solana.png";
import assetNetflix from "./images/polygon.png";

import assetBitcoin from "./images/assetBitcoin.png";
import assetEth from "./images/assetEth.png";
import assetAvax from "./images/assetAvax.png";

import imgRecomp1 from "./images/imgRecomp1.png";
import imgRecomp2 from "./images/imgRecomp2.png";
import imgRecomp3 from "./images/imgRecomp3.png";
import imgRecomp4 from "./images/imgRecomp4.png";

import emeraude from "./images/Emeraude.png";
import diamant from "./images/Diamant.png";
import or from "./images/Or.png";
import argent from "./images/Argent.png";
import aspirant from "./images/Aspirant.png";
import key from "./images/key.png";
import axios from "axios";
import { apiURL } from "../utils/const";

export default class Home extends Component {
  state = {
    first: 0,
    firstRating: 0,
    second: 0,
    secondRating: 0,
    third: 0,
    thirdRating: 0,
    hideNavbar: true,
    count: 0,
    nextTour: 0,
  };

  async componentDidMount() {
    try {
      //alert(this.props.auth.isAuthenticated);
      this.getPodium();

      this.nextTour();
    } catch (error) {
      console.log(error);
    }
  }

  async sleep(ms) {
    return new Promise((res) => setTimeout(res, ms));
  }

  async nextTour() {
    while (true) {
      var today = new Date();
      //var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var minutes = (today.getMinutes() < 10 ? "0" : "") + today.getMinutes();
      var secondes = 60 - today.getSeconds();
      //var dateTime = date+' '+time;

      if (minutes < 30) {
        this.setState({ nextTour: 29 - minutes + ":" + secondes });
      } else {
        this.setState({ nextTour: 60 - minutes + ":" + secondes });
      }

      await this.sleep(1000);
    }
  }

  clickPlay = (event) => {
    event.preventDefault();
    try {
      this.props.history.push("/tournament");
    } catch (error) {
      console.log(error);
    }
  };

  clickEnter = (event) => {
    event.preventDefault();
    try {
      document.getElementById("navbar").style.display = "block";
      document.getElementById("home").style.display = "block";
      document.getElementById("gifHome").style.display = "none";
    } catch (error) {
      console.log(error);
    }
  };

  //récupere l'historique et le stocke dans le state
  async getPodium() {
    try {
      axios({
        method: "POST",
        url: apiURL + "/dev/getpodium",
        data: "",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          this.setState({ first: response.data.first });
          this.setState({ firstRating: response.data.firstRating });
          this.setState({ second: response.data.second });
          this.setState({ secondRating: response.data.secondRating });
          this.setState({ third: response.data.third });
          this.setState({ thirdRating: response.data.thirdRating });
          this.setState({ count: response.data.count });
        })

        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error.message);
    }
  }

  async goDiscord() {
    try {
      window.open("https://discord.com/invite/KgH2qwXNHw", "_blank").focus();
    } catch (error) {
      console.log(error.message);
    }
  }

  async goTwit() {
    try {
      window.open("https://twitter.com/TradeAsGamee", "_blank").focus();
    } catch (error) {
      console.log(error.message);
    }
  }

  async goInsta() {
    try {
      window
        .open("https://www.instagram.com/tradeasgame/?hl=fr", "_blank")
        .focus();
    } catch (error) {
      console.log(error.message);
    }
  }

  async goMedium() {
    try {
      window
        .open("https://medium.com/@luchap/tradeasgame-trading-esport-nfts-fr-192157e3784d", "_blank")
        .focus();
    } catch (error) {
      console.log(error.message);
    }
  }

  async goOpenseas() {
    try {
      window
        .open("https://opensea.io/fr/collection/tradeasgame", "_blank")
        .focus();
    } catch (error) {
      console.log(error.message);
    }
  }
  /*

            <div className="gifHomeDiscord" onClick={this.goDiscord} >
                <img className="imgDiscord" src={discord} alt=""/>
            </div>
            <div className="gifHomeTwit" onClick={this.goTwit}>
                <img className="imgTwit" src={twitter} alt=""/>
            </div>
            <div className="gifHomeInsta" onClick={this.goInsta}>
                <img className="imgInsta" src={insta} alt=""/>
            </div>


*/

  render() {
    return (
      <>
        <div className="HomeSlogan teko">
          TRADE AS <span className="sloganTitreBeta">GAME</span>
          <br />
          <span className="sloganCaps">World's first trading video game</span>
          <br />
          <br />
          <span className="sloganFake">
            Trade for free <br />
            Grind leaderboard <br />
            Grab some cashprizes <br />
          </span>
        </div>
        <div className="HomeSloganImg">
          <img className="graphAcceuil" src={graphAcceuil} alt="" />
        </div>
        <br />
        <br />
        <div className="centeredContent">
          {!this.props.auth.isAuthenticated && !this.props.auth.user ? (
            <>
              <br />
              <Link to="/register" className="buttonsTournament">
                Register
              </Link>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to="/login" className="buttonsTournament">
                Login
              </Link>{" "}
              <br />
              <br />
            </>
          ) : (
            <>
              <br/>
              <Link to="/tournament" className="buttonsTournament">
                
                Play
              </Link>{" "}
              <br />
              <br />{" "}
            </>
          )}
          Join tournaments for free <br />
          <br />
          Bet virtual money on the <font color="#11D89C"> rise </font> <br />
          or <font color="#FF7A7A"> fall </font> of real-time assets.
          <div className="AccAssets">
            <span className="assetBloc">
              {" "}
              <img
                className="imgAssetsBitcoin"
                src={assetBitcoin}
                alt=""
              />{" "}
            </span>
            <span className="assetBloc">
              {" "}
              <img className="imgAssetsBitcoin" src={assetEth} alt="" />{" "}
            </span>
            <span className="assetBloc">
              {" "}
              <img className="imgAssetsBitcoin" src={assetAvax} alt="" />{" "}
            </span>
            <span className="assetBloc">
              {" "}
              <img className="imgAssetsBitcoin" src={assetApple} alt="" />{" "}
            </span>
            <span className="assetBloc">
              {" "}
              <img
                className="imgAssetsBitcoin"
                src={assetNetflix}
                alt=""
              />{" "}
            </span>
            <span className="assetBloc">
              {" "}
              <img className="imgAssetsBitcoin" src={assetTesla} alt="" />{" "}
            </span>
          </div>
          <div className="centeredTitle teko">
            PLAY REGULARLY <br />
            TO UNLOCK AWARDS
          </div>
          <div className="imgRecomp">
            <img className="imgRecompA" src={imgRecomp1} alt="" />
            <img className="imgRecompA" src={imgRecomp2} alt="" />
            <img className="imgRecompA" src={imgRecomp3} alt="" />
            <img className="imgRecompA" src={imgRecomp4} alt="" />
          </div>
          <div className="centeredContent2">
            Earn experience and level-up <br />
            Proudly display your war trophies <br /><br />
            All items can be minted in NFT on Polygon Network and selled on Opensea<br />

          </div>
          <div className="centeredTitle teko">SMILE, YOU'RE BEING RANKED</div>
          <div className="centeredContent2">
            Become better and climb the ladder
            <br />
            to obtain privileges
          </div>
        </div>
        <br />
        <br />
        <div className="expLigues">
          <div className="expColone">
            <img className="expImage1" src={emeraude} alt="" />
            <b
              style={{
                color: "#93EA6E",
                padding: "10px",
                borderStyle: "solid",
                borderWidth: "1px",
              }}
            >
              {" "}
              Emerald{" "}
            </b>
            <div className="blocExp">Top 10%</div>
          </div>
          <div className="expColone">
            <img className="expImage2" src={diamant} alt="" />
            <b
              style={{
                color: "#70738B",
                padding: "10px",
                borderStyle: "solid",
                borderWidth: "1px",
              }}
            >
              {" "}
              Diamond{" "}
            </b>
            <div className="blocExp">Top 20%</div>
          </div>
          <div className="expColoneOr">
            <img className="expImage3" src={or} alt="" />
            <b
              style={{
                color: "#FFEB5A",
                padding: "14px",
                borderStyle: "solid",
                borderWidth: "1px",
              }}
            >
              {" "}
              Gold{" "}
            </b>
            <div className="blocExp">Top 40%</div>
          </div>
          <div className="expColone">
            <img className="expImage" src={argent} alt="" />
            <b
              style={{
                color: "#6D7071",
                padding: "10px",
                borderStyle: "solid",
                borderWidth: "1px",
              }}
            >
              {" "}
              Silver{" "}
            </b>
            <div className="blocExp">Top 60%</div>
          </div>
          <div className="expColone">
            <img className="expImage" src={aspirant} alt="" />
            <b
              style={{
                color: "#6E6C5C",
                padding: "10px",
                borderStyle: "solid",
                borderWidth: "1px",
              }}
            >
              {" "}
              Aspirant{" "}
            </b>
            <div className="blocExp">Top 60%+</div>
          </div>
        </div>
        <br /><br />

        <div className="centeredContent3">
          Ranking is reset monthly. <br />
          At the end of the 3rd week of each month, <br />
          a key is given to players within diamond and emerald rank.  <br />
          <br />
          <div className="keyCenterer">
            <img className="expKey" src={key} alt="" />
          </div>
          During the 4th week, two tournaments are organized. <br />
          One for players who have been given the key this month. <br />
          One for all ranked players accros the leaderboard. <br />
          Both tournaments will have cash prizes.

        </div>

        <div className="bandeauLegal">
          <span className="" onClick={this.goDiscord}>
            <img className="imgDiscord" src="./images/discord.png" alt="" />
          </span>
          <span className="" onClick={this.goTwit}>
            <img className="imgTwit" src="./images/twitter.png" alt="" />
          </span>
          <span className="" onClick={this.goInsta}>
            <img className="imgInsta" src="./images/insta.png" alt="" />
          </span>
          <span className="" onClick={this.goMedium}>
            <img className="imgInsta" src="./images/medium.png" alt="" />
          </span>
          <span className="" onClick={this.goOpenseas}>
            <img className="imgInsta" src="./images/openseas.png" alt="" />
          </span>
          <br />
          Tradeasgame, SAS, 190 avenue de Poussan, 2 000.00 €, 908 180 847 R.C.S
          Montpellier, FR54908180847 netlify,
        </div>
      </>
    );
  }
}
