import React, { Component } from "react";
import Graph from "./Graph";
import { ipBackend } from "../utils/const";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { apiURL } from "../utils/const";
import loading from "./images/loading.svg";

import {
  truncateEthAddress,
  truncateRegex,
} from "../utils/function";

class Spectate extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
  }

  state = {
    isMenuOpen: false,
    menuText: "Réduire",
    tournamentID: 1,
    pair: "btcusdt",
    results1: [],
    tabTournois: [],
    pairVisuelle: "",
    tabPseudo: [],
    joueurAffiches: [],
    assets: {},
    ingameTempsrestant: "",
    hFin: "",
    hDeb: 0,
    nombreJoueurs: 0,
    tableauAffichage: [],
    valueLevierMiniPNL: 1,
    valueLevierMaxiPNL: 10000,
    smooth: true,
    tabLogs: [],
    gains: false,
    pseudos: false,
    altArray: [],
    loading: false,
  };

  async componentDidMount() {
    if (this.isAdmin(this.props.auth.user.username)) this.gameLoop();
  }

  isAdmin(ps) {
    //this.props.auth.user.username
    if (ps === "luchap" || ps === "bazoukiwi") return true;
    else return false;
  }

  async gameLoop() {
    while (true) {
      this.getTournamentClass(this.state.tournamentID);
      this.getTournaments();
      this.updateGraphs();
      this.displayTimer();
      await this.sleep(1000);
    }
  }

  async sleep(ms) {
    return new Promise((res) => setTimeout(res, ms));
  }

  getTournamentClass(id) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      crossDomain: true,
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(ipBackend + "/tournaments/" + id, requestOptions)
      .then((response) => response.text())
      .then((result) => {

        var resultats = JSON.parse(result);
        var ListPP = [];

        var tabPsd = [];

        var positions = [];
        var posObj = {
          entree: 0,
          pair: "",
          pari: "",
          taille: 0,
        };

        
        var assets = {
          BTCPERP: resultats.assets[0],
          ETHPERP: resultats.assets[1],
          SOLPERP: resultats.assets[2],
          AVAXPERP: resultats.assets[3],
          XRPPERP: resultats.assets[4],
          DOGEPERP: resultats.assets[5],
          MATICPERP: resultats.assets[6],
          LTCPERP: resultats.assets[7],
        }


        this.setState({ assets: assets });

        this.setState({ hFin: resultats.hFin });
        this.setState({ hDeb: resultats.hDeb });

        

        var tabz = [];

        for (var b = 0; b < resultats.joueurs.length; b++) {
          if(resultats.joueurs[b].pseudo.match(truncateRegex)) {
            tabz.push(resultats.joueurs[b].pseudo)
          }
        }

        this.getAlt(tabz);
/*
        for (var b = 0; b < resultats.joueurs.length; b++) {
          if(resultats.joueurs[b].pseudo.match(truncateRegex)) {
            resultats.joueurs[b].pseudo = this.getAltArray(resultats.joueurs[b].pseudo)
          }
        }*/

        this.setState({ joueurs: resultats.joueurs });

        ListPP.push(
            <span className="SpectateCont" key={"SpectateCont01"}>
             <div className="traitBlanc3"></div>
              <span className="SpectatePseudo">
                Pseudo
              </span>
              <span className="SpectateSolde">
                <b style={{ color: "#11d89c" }}>
                  Score
                </b>
              </span>
              
              <b className="SpectatePositionsTitles" style={{ color: "#E7CE4A", fontSize: "0.7vw", marginLeft: "0.5vw" }}>
                Trades passés
              </b>
              <br /><br />
              <div className="traitBlanc2"></div>
            </span>
          );
        this.setState({nombreJoueurs: resultats.joueurs.length});
        //Boucle des res 
        for (var i = 0; i < resultats.joueurs.length; i++) {

          if(!tabPsd.includes(resultats.joueurs[i].pseudo)) {
            tabPsd.push(resultats.joueurs[i].pseudo);
          }
 



          for (var j = 0; j < resultats.joueurs[i].positions.length; j++) {
            posObj.entree = resultats.joueurs[i].positions[j].entree;
            posObj.pair = resultats.joueurs[i].positions[j].pair;
            posObj.pari = resultats.joueurs[i].positions[j].pari;
            posObj.taille = resultats.joueurs[i].positions[j].soldeCrypto;


            if (posObj.pari === "HAUSSE") posObj.pari = "Long";

            if (posObj.pari === "BAISSE") posObj.pari = "Short";

           // var pnl = Number.parseFloat(this.getPNL(posObj.pair, posObj.pari, posObj.entree, posObj.taille)).toFixed(2);
           var pnl = this.getPNL(posObj.pair, posObj.pari, posObj.entree, posObj.taille, resultats.joueurs[i].positions[j].levier);
            var color = "";

            if (pnl < 0) {
              color = "#ff7a7a";
            } else {
              color = "#11d89c";
            }

            positions.push(<span> {posObj.pari} {posObj.pair} @  {Number.parseFloat(posObj.entree).toFixed(3)}  <span style={{color: color}}> {pnl}%  </span> <br/>  </span>);
          }
         //
          var pourcent = Number.parseFloat( -(1 - (resultats.joueurs[i].soldeUsdtAvantPosition-resultats.joueurs[i].nbRez*1000) / 1000) * 100).toFixed(2)

          ListPP.push(
            <span className="SpectateCont" key={"SpectateCont"+i}>
             &nbsp;&nbsp;{Number(i)+1}
              <span className="SpectateLogsSelector" key={"SpectateLogsSelector"+i}>
                  {this.state.tabLogs.includes(resultats.joueurs[i].pseudo) ? <span onClick={this.cacherLogsHandle(resultats.joueurs[i].pseudo)}>-</span> : <span onClick={this.afficherLogsHandle(resultats.joueurs[i].pseudo)}>+</span> }
              </span>
              <span className= {this.state.joueurAffiches.includes(resultats.joueurs[i].pseudo) ? "SpectatePseudo": "SpectatePseudoGrey"} key={"SpectatePseudo"+i}>
                
                {(resultats.joueurs[i].pseudo.match(truncateRegex)
                  ? this.getAltArray(resultats.joueurs[i].pseudo)
                  : resultats.joueurs[i].pseudo )}
              </span>
              <span className="SpectateSolde" key={"SpectateSolde"+i}>
                <b style={{ color: "#11d89c" }}>
                  {Number.parseFloat(
                    resultats.joueurs[i].soldeUsdtAvantPosition -
                      resultats.joueurs[i].nbRez * 1000
                  ).toFixed(2)}
                  $
                  {" "}
                  ({pourcent})%
                </b>
              </span>
              <img style={{display: 'none' }} className="grave" src={".././images/grave.png"} alt="RIP" />{" "}
              <b style={{ color: "red", display: 'none' }}> {resultats.joueurs[i].nbRez} </b>
              
              <b className="SpectatePositions" style={{ color: "#E7CE4A" }}>{"  "}
                {this.afficherTrades(resultats.joueurs[i].pseudo)}
              {"  "}</b>
              <img
                className="spcAfficher"
                onClick={this.afficherGraph(resultats.joueurs[i].pseudo)}
                src={".././images/afficher.png"}
                alt="afficher"
              />
              <img
                className="spcCacher"
                onClick={this.cacherGraph(resultats.joueurs[i].pseudo)}
                src={".././images/cacher.png"}
                alt="cacher"
              />
              <br />
              <span className="SpectatePosition" key={"SpectatePosition"+i}>
                {positions}
                {this.state.tabLogs.includes(resultats.joueurs[i].pseudo) ? this.displayLogsPlayer(resultats.joueurs[i].pseudo) : "" }
              
              </span>
              <br />
            </span>
          );

          positions = [];
        } //

        this.setState({ results1: ListPP });
        this.setState({ tabPseudo: tabPsd });

        this.createShape("Vertical", this.state.hDeb);


      })


      

      .catch((error) => console.log("error", error));
  }


    //Get alt of user
  getAltArray(user) {
    var tab = this.state.altArray

    for (var i = 0; i < tab.length; i++) {
      if(tab[i].user === user && tab[i].alt !== user) {
        return tab[i].alt
      }
    }
    return truncateEthAddress(user);
  }


  getAlt(usernameArray) {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({ USERNAME: usernameArray, ISARRAY: true });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };


      fetch(apiURL + "/dev/getalt", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          this.setState({ altArray: JSON.parse(result).ALTS });
        });
    } catch (error) {
      console.log(error);
    }
  }




  getPositionsJoueur(pseudo) {
    var tableauAffichage = this.state.tableauAffichage;
     
     for (var i = 0; i < tableauAffichage.length; i++) {
       for (var j = 0; j < tableauAffichage[i].length; j++){
        if(tableauAffichage[i][j].pseudo === pseudo) {
          return tableauAffichage[i];
        }
       }
     }
  }
  afficherLogsHandle = (pseudo) => (event) => {
    event.preventDefault();
    var tabLogs = this.state.tabLogs;
    tabLogs.push(pseudo);
    this.setState({tabLogs: tabLogs});
  };

  cacherLogsHandle = (pseudo) => (event) => {
    event.preventDefault();
    var tabLogs = this.state.tabLogs;
    var indice = tabLogs.indexOf(pseudo)
    tabLogs.splice(indice, 1);
    this.setState({tabLogs: tabLogs});
  };


  getPNL(pair, pari, entree, tailleNegativeMaybe, levier) {
    var taille = Math.abs(tailleNegativeMaybe);
    var assets = this.state.assets;
    var prixPaire = 0;

    if (pair === "BTCPERP") {
      prixPaire = assets.BTCPERP
    }
    if (pair === "ETHPERP") {
      prixPaire = assets.ETHPERP
    }
    if (pair === "SOLPERP") {
      prixPaire = assets.SOLPERP
    }
    if (pair === "AVAXPERP") {
      prixPaire = assets.AVAXPERP
    }
    if (pair === "XRPPERP") {
      prixPaire = assets.XRPPERP
    }
    if (pair === "DOGEPERP") {
      prixPaire = assets.DOGEPERP
    }
    if (pair === "MATICPERP") {
      prixPaire = assets.MATICPERP
    }
    if (pair === "LTCPERP") {
      prixPaire = assets.LTCPERP
    }

    var pnlc = 0;

    pnlc = prixPaire * taille  - entree *taille ;
    pnlc = pnlc - (taille * prixPaire * 0.0005);



    

    //var variation = (((taille+pnlc/prixPaire) - taille) / taille ) * 100 * levier;

    var variation = ((prixPaire - entree) / entree ) * 100 * levier;

    if(pari === "Short") {
      variation = variation * -1;
    }
    

    return Number.parseFloat(variation).toFixed(2);


  }

  displayLogsPlayer(player) {

    var logs = this.displayTournamentLogs(this.state.tournamentID, true);
    logs.reverse();

    var tableauJoueur = [];


    
    //for (var i = logs.length-1; i >= 0; i--) {
    for (var i = 0; i < logs.length; i++) {
      logs[i] = logs[i].replaceAll("   ", " ");
      logs[i] = logs[i].replaceAll("  ", " ");
      var indicateur = logs[i].split(" ");


      var paireIndicateur = indicateur[2].replace("PERP", "USDT");
      paireIndicateur = paireIndicateur.toLowerCase();
      // && paireIndicateur === this.state.pair
      if (indicateur[1] === player  && indicateur[1] !== null) {

       // alert(JSON.stringify(indicateur))

        
        if (indicateur[0] === "[newPosition]" && i > 1) {
          tableauJoueur.push( <span key={"newPositionzz"+i}> {indicateur[5]} {indicateur[2]} @ {indicateur[4]}  <br/> </span>);
        } else if (indicateur[0] === "[closePosition]") {
          var gain =  Number.parseFloat(indicateur[6].slice(0, -1) - indicateur[8]).toFixed(2);
          //Calcul modif PNL
          let pnl = 1000;
          for (var j = i; j < logs.length; j++) {
            logs[j] = logs[j].replaceAll("   ", " ");
            logs[j] = logs[j].replaceAll("  ", " ");

            let indicateur2 = logs[j].split(" ");

            if(indicateur[1] === indicateur2[1]) {
              if(indicateur2[0] === "[updatePNL]") {
                pnl = indicateur2[6].slice(0, indicateur2[6].length-3);
                break;
              }
            }
          }

          let gainPNL = (Number(gain) * 100) / Number(pnl);

          let colorGainPNL = "green";

          if(gainPNL < 0) {
            colorGainPNL = "red";
          }

          tableauJoueur.push( <span key={"closePositionzz"+i}>  Close {indicateur[2]} @ {indicateur[4]} <span style={{color: 'gold'}}> {gain} $</span>   <span style={{color: colorGainPNL}}> {Number.parseFloat(gainPNL).toFixed(2)}% </span> <br/> </span>);
        } else if (indicateur[0] === "[Liquidation]") {
          //Calcul valeur liquidation
          let somme = 0;
          for (var j = i; j <= logs.length; j++) {
            logs[j] = logs[j].replaceAll("   ", " ");
            logs[j] = logs[j].replaceAll("  ", " ");

            let indicateur2 = logs[j].split(" ");

            if(indicateur[1] === indicateur2[1]) {
              if(indicateur2[0] === "[newPosition]" && indicateur[2] === indicateur2[2]) {
                somme = Math.round(Number(indicateur2[4])*Number(indicateur2[9]));
                break;
              }
            }
          }
          tableauJoueur.push( <span key={"Liquidationzz"+i}> Liquidation {indicateur[2]} <span style={{color: "#e96bff"}}> {somme}$ </span> <br/> </span>);
        }
      }

      
    }


    return <span style={{color: "lightgrey"}}> {tableauJoueur} </span>;
  }

  displayTournamentLogs(index, isArray) {
    //Ecriture infos basiques tournois

    var filtredLogs = [];
    //alert(JSON.stringify(this.state.tabTournois[index - 1]))

    if (this.state.tabTournois[index-1] === undefined) {
      return;
    }

    

    var logs = this.state.tabTournois[index - 1].LOGS;

    // alert(JSON.stringify(this.state.tabTournois[index-1].LOGS[2]));
    // logs = logs.replaceAll('"', "");

    
    for (var i = 0; i < logs.length; i++) {
    //for (var i = logs.length-1; i >= 0; i--) {



      logs[i] = logs[i].replace(":", "");
      var indicateur = logs[i].split(" ");
      if (indicateur[0] === "[newPosition]") {
        logs[i] = logs[i].replace("Type: ", "");

        logs[i] = logs[i].replace("HAUSSE", "Long");
        logs[i] = logs[i].replace("BAISSE", "Short");

        var color = "";
        if(logs[i].includes("Long")) {
          color = "#11d89c";
        }
        if(logs[i].includes("Short")) {
          color = "#ff5757";
        }

        var DELIMITER = " ";
        var parts = logs[i].split(DELIMITER);

        parts[parts.length] = parts[3];
        parts[3] = "";

        logs[i] = parts.join(DELIMITER);

        if (isArray) {
          filtredLogs.push(logs[i]);
        }
        if (!isArray) {
          filtredLogs.push( <span style={{color: color}} key={"isArray"+i}> {logs[i]} </span>);
        }
      }
      if (isArray) {
        if (indicateur[0] === "[closePosition]") {
          filtredLogs.push(logs[i]);
        }
        if (indicateur[0] === "[augmentPosition]") {
          filtredLogs.push(logs[i]);
        }
        if (indicateur[0] === "[reducePosition]") {
          filtredLogs.push(logs[i]);
        }
        if (indicateur[0] === "[Liquidation]") {
          filtredLogs.push(logs[i]);
        }
        if (indicateur[0] === "[updatePNL]") {
          filtredLogs.push(logs[i]);
        }
      }
      if (!isArray) {
        if (indicateur[0] === "[closePosition]") {
          filtredLogs.push( <span style={{color: "#fff59d"}} key={"closePosition"+i}> {logs[i]} </span>);
        }
        if (indicateur[0] === "[augmentPosition]") {
          filtredLogs.push( <span style={{color: "cyan"}} key={"augmentPosition"+i}> {logs[i]} </span>);
        }
        if (indicateur[0] === "[reducePosition]") {
          filtredLogs.push( <span style={{color: "orange"}} key={"reducePosition"+i}> {logs[i]} </span>);
        }
        if (indicateur[0] === "[Liquidation]") {
          filtredLogs.push( <span style={{color: "purple"}} key={"Liquidation"+i}> {logs[i]} </span>);
        }
      }
    }
    

    if (isArray) {
      return filtredLogs;
    }
    else {
      filtredLogs.reverse();
    }

    return <div>{filtredLogs}</div>;
  }
  //ok
  getTournaments() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      crossDomain: true,
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(ipBackend + "/tournaments/", requestOptions)
      .then((response) => response.text())
      .then((result) => {

        var resultats = JSON.parse(result);
        var tmpTabTournois = [];

        for (var i = 0; i < resultats.length; i++) {
          var tmp = {
            NBJOUEURS: resultats[i].nombreJoueurs,
            LOGS: resultats[i].infos.logs,
          };


          tmpTabTournois.push(tmp);
        }

        this.setState({ tabTournois: tmpTabTournois });
      })
      .catch((error) => console.log("error", error));
  }

  afficherGraph = (value) => (event) => {
    event.preventDefault();
    this.afficherGraphFunc(value);
    this.handleSpectateTab("Object", this.state.tableauAffichage, this.state.smooth, this.state.gains, this.state.pseudos);
  };


  //Ajoute le joueur passé en param au tableau d'affichage
  afficherGraphFunc(value, isUpdateRequest) {

    if(this.ajouterJoueurAffiche(value) && isUpdateRequest !== true) {
      return;
    }

    var logs = this.displayTournamentLogs(this.state.tournamentID, true);

    var tableauJoueur = [];


    for (var i = 0; i < logs.length; i++) {
      logs[i] = logs[i].replaceAll("   ", " ");
      logs[i] = logs[i].replaceAll("  ", " ");
      var indicateur = logs[i].split(" ");




      var paireIndicateur = indicateur[2].replace("PERP", "USDT");
      paireIndicateur = paireIndicateur.toLowerCase();
      if (indicateur[1] === value && paireIndicateur === this.state.pair && indicateur[1] !== null) {

        
        if (indicateur[0] === "[newPosition]") {
          let objetPosition = {
            pseudo: value,
            orderType: indicateur[0],
            sens: indicateur[5],
            entry: indicateur[4],
            time: indicateur[8].replace("\n\n", "")
          }
          tableauJoueur.push(objetPosition);
        } else if (indicateur[0] === "[closePosition]") {
          let gain =  Number.parseFloat(indicateur[6].slice(0, -1) - indicateur[8]).toFixed(2);
          let objetPosition = {
            pseudo: value,
            orderType: indicateur[0],
            entry: indicateur[4],
            gain: gain,
            time: indicateur[9].replace("\n\n", "")
          }
          tableauJoueur.push(objetPosition);
        } else if (indicateur[0] === "[Liquidation]") {
          let objetPosition = {
            pseudo: value,
            orderType: indicateur[0],
            entry: indicateur[4].replace("\n\n", ""),
            time: indicateur[5].replace("\n\n", "")
          }
          tableauJoueur.push(objetPosition);
        }
      }

      
    }

    if(isUpdateRequest !== true) {

    var tableauAffichage = this.state.tableauAffichage;
    
    tableauAffichage.push(tableauJoueur);
    this.setState({tableauAffichage: tableauAffichage});

    }


     
  }


  updateSpectateTab(tab) {
     this.child.current.updateSpectateTab(tab, this.state.gains, this.state.pseudos);
  }



  updateGraphs() {
    var tableauAffichage = this.state.tableauAffichage;
    var tabNouvellesPositions = [];

    for (var i = 0; i < tableauAffichage.length; i++) {
      //this.afficherGraphFunc(joueurAffiches[i], true);
      //alert(JSON.stringify(joueurAffiches))
      if(tableauAffichage[i][0] !== undefined) {
        let psd = tableauAffichage[i][0].pseudo;
        let oldPositions = tableauAffichage[i];
        let newPositions = this.returnTableauJoueur(psd);

     
        if(oldPositions.length !== newPositions.length) {

          var difference = newPositions.length - oldPositions.length
          tabNouvellesPositions = [];

          for (var j = newPositions.length-1; j > newPositions.length-1-difference; j--) {
            
            if(newPositions[j].orderType === "[closePosition]") {
              newPositions[j].closureType = newPositions[j-1].sens;
            }
            tabNouvellesPositions.push(newPositions[j]);
          }

          tableauAffichage[i] = newPositions;
          this.setState({tableauAffichage})
        }
      }
    }

    
    if(tabNouvellesPositions.length !== 0) {
      this.updateSpectateTab(tabNouvellesPositions);
    }
    
  }

 //Renvoi le tab de positions d'un joueur
 returnTableauJoueur(pseudo) {
  var value = pseudo
  var tableauJoueur = [];
  var logs = this.displayTournamentLogs(this.state.tournamentID, true);


    for (var i = 0; i < logs.length; i++) {
      logs[i] = logs[i].replaceAll("   ", " ");
      logs[i] = logs[i].replaceAll("  ", " ");
      var indicateur = logs[i].split(" ");




      var paireIndicateur = indicateur[2].replace("PERP", "USDT");
      paireIndicateur = paireIndicateur.toLowerCase();
      if (indicateur[1] === value && paireIndicateur === this.state.pair && indicateur[1] !== null) {

        
        if (indicateur[0] === "[newPosition]") {
          let objetPosition = {
            pseudo: value,
            orderType: indicateur[0],
            sens: indicateur[5],
            entry: indicateur[4],
            time: indicateur[8].replace("\n\n", "")
          }
          tableauJoueur.push(objetPosition);
        } else if (indicateur[0] === "[closePosition]") {
          let gain =  Number.parseFloat(indicateur[6].slice(0, -1) - indicateur[8]).toFixed(2);
          let objetPosition = {
            pseudo: value,
            orderType: indicateur[0],
            entry: indicateur[4],
            gain: gain,
            time: indicateur[9].replace("\n\n", "")
          }
          tableauJoueur.push(objetPosition);
        } else if (indicateur[0] === "[Liquidation]") {
          let objetPosition = {
            pseudo: value,
            orderType: indicateur[0],
            entry: indicateur[4].replace("\n\n", ""),
            time: indicateur[5].replace("\n\n", "")
          }
          tableauJoueur.push(objetPosition);
        }
      }

      
    }

    return tableauJoueur;


 }



  //Ajoute le joueur passé en param au tableau d'affichage sans appel vers api TV
  afficherFunc(value, isUpdateRequest) {

    if(this.ajouterJoueurAffiche(value)) {
      return;
    }
    var logs = this.displayTournamentLogs(this.state.tournamentID, true);

    var tableauJoueur = [];


    for (var i = 0; i < logs.length; i++) {
      logs[i] = logs[i].replaceAll("   ", " ");
      logs[i] = logs[i].replaceAll("  ", " ");
      var indicateur = logs[i].split(" ");


      var paireIndicateur = indicateur[2].replace("PERP", "USDT");
      paireIndicateur = paireIndicateur.toLowerCase();
      if (indicateur[1] === value && paireIndicateur === this.state.pair && indicateur[1] !== null) {

        
        if (indicateur[0] === "[newPosition]") {
          let objetPosition = {
            pseudo: value,
            orderType: indicateur[0],
            sens: indicateur[5],
            entry: indicateur[4],
            time: indicateur[8].replace("\n\n", "")
          }
          tableauJoueur.push(objetPosition);
        } else if (indicateur[0] === "[closePosition]") {
          var gain =  Number.parseFloat(indicateur[6].slice(0, -1) - indicateur[8]).toFixed(2);
          let objetPosition = {
            pseudo: value,
            orderType: indicateur[0],
            entry: indicateur[4],
            gain: gain,
            time: indicateur[9].replace("\n\n", "")
          }
          tableauJoueur.push(objetPosition);
        } else if (indicateur[0] === "[Liquidation]") {
          let objetPosition = {
            pseudo: value,
            orderType: indicateur[0],
            entry: indicateur[4].replace("\n\n", ""),
            time: indicateur[5].replace("\n\n", "")
          }
          tableauJoueur.push(objetPosition);
        }
      }

      
    }


    var tableauAffichage = this.state.tableauAffichage;
    
    tableauAffichage.push(tableauJoueur);

    this.setState({tableauAffichage: tableauAffichage}, () => { 
        return;
      });

     
  }




  toutCacherGraph = (value) => (event) => {
    event.preventDefault();

    this.setState({tableauAffichage: [], joueurAffiches: []}, () => { 
      this.handleSpectateTab("Object", this.state.tableauAffichage, this.state.smooth, this.state.gains, this.state.pseudos); 
    });

    

  };


  toutAfficherGraph = (value) => (event) => {
    event.preventDefault();

    var tabPsd = this.state.tabPseudo

    if(this.state.smooth) {


      for (let i = 0; i < tabPsd.length ; i++) {
        this.afficherFunc(tabPsd[i]);
      }

      
      this.handleSpectateTab("Object", this.state.tableauAffichage, this.state.smooth, this.state.gains, this.state.pseudos);
    }

    

    else {
      for (let i = 0; i < tabPsd.length; i++) {
        this.afficherGraphFunc(tabPsd[i]);
      }

      this.handleSpectateTab("Object", this.state.tableauAffichage, this.state.smooth, this.state.gains, this.state.pseudos);
    }
  };



  toutAfficherGraph10 = (value) => (event) => {
    event.preventDefault();

    var indice = 10;
    var tabPsd = this.state.tabPseudo;


    if(this.state.smooth) {

     // indice = this.state.tabPseudo.length;

      for (let i = 0; i < tabPsd.length && i < indice; i++) {
        this.afficherFunc(tabPsd[i]);
      }

      this.handleSpectateTab("Object", this.state.tableauAffichage, this.state.smooth, this.state.gains, this.state.pseudos);
    }
    else {
      for (let i = 0; i < tabPsd.length && i < indice; i++) {
        this.afficherGraphFunc(tabPsd[i]);
      }

      this.handleSpectateTab("Object", this.state.tableauAffichage, this.state.smooth, this.state.gains, this.state.pseudos);
    }
  };



  displayTimer = (event) => {
    //event.preventDefault();
    try {
      var heure = Date.now();

      var tempsRestant = 44;
      var timer = new Date();
      var mtn = new Date();

      mtn.setTime(heure);

      var endTime = Number(this.state.hFin) - Number(heure);

      timer.setTime(endTime);
      timer.setHours(timer.getHours() - 1);
      // timer.setDate(timer.getDate()-1);

      var dateF = Number(timer.getDate()) - 1;

      tempsRestant =
        dateF +
        "j " +
        timer.getHours() +
        "h " +
        timer.getMinutes() +
        "min " +
        timer.getSeconds() +
        "s.";
      this.setState({ ingameTempsrestant: tempsRestant });
    } catch (error) {
      console.log(error.message);
    }
  };


  //Affiche le nombre de trades passés de chaque joueur
  afficherTrades(value){

    var logs = this.displayTournamentLogs(this.state.tournamentID, true);

    if(logs === undefined) {
      return
    }

    var indice = 0;

    for (var i = 0; i < logs.length; i++) {
      var indicateur = logs[i].split(" ");

      if (indicateur[1] === value && indicateur[0] === "[closePosition]") {
        indice++
      }

      if (indicateur[1] === value && indicateur[0] === "[Liquidation]") {
        indice++
      }
    }
    return indice;
  };

  createShape(id, price, bet, pseudo, time, gain) {
    if(this.child === undefined) {
      return;
    }
    this.child.current.createShape(id, price, bet, pseudo, time, gain);
  }

  async handleSpectateTab(id, tab, smooth, gains, pseudos) {
    await this.child.current.handleSpectateTab(id, tab, smooth, gains, pseudos);
  }

  


  //Ajoute le joueur au tableau des joueurs affichés
  ajouterJoueurAffiche(pseudo) {
     var tab = this.state.joueurAffiches;

     if(!tab.includes(pseudo)) {
        tab.push(pseudo);
        this.setState({joueurAffiches: tab});

        return false;
      }

      return true;
  }


  //Supprime le joueur du tableau affichage
  deleteJoueurAffiche(pseudo, noHandle) {
    
     var tab = this.state.joueurAffiches;
     
     for (let i = 0; i < tab.length; i++) {
       if(tab[i] === pseudo) {
        tab.splice(i, 1);
       }
     }

     this.setState({joueurAffiches: tab});

    var tableauAffichage = this.state.tableauAffichage;
     
     for (var i = 0; i < tableauAffichage.length; i++) {
       for (var j = 0; j < tableauAffichage[i].length; j++){
        if(tableauAffichage[i][j].pseudo === pseudo) {
          tableauAffichage.splice(i, 1);
          this.setState({tableauAffichage: tableauAffichage});
          if(!noHandle) {
            this.handleSpectateTab("Object", this.state.tableauAffichage, this.state.smooth, this.state.gains, this.state.pseudos);
          }
          return;
        }
       }
     }  
  }

  removeShape(id) {
    this.child.current.removeShape(id);
  }

  cacherGraph = (value) => (event) => {
    event.preventDefault();
    this.deleteJoueurAffiche(value);
    //this.removeShape(value);
  };

  onSliderChangeMiniPNL = (valueLevierMiniPNL) => {
    this.setState(
      {
        valueLevierMiniPNL,
      },
      () => {
        this.changeValueLevierMiniPNL(); 
      }
    );
  };

  onSliderChangeMaxiPNL = (valueLevierMaxiPNL) => {
    this.setState(
      {
        valueLevierMaxiPNL,
      },
      () => {
        this.changeValueLevierMaxiPNL(); 
      }
    );
  };

  changeValueLevierMiniPNL() {
    this.handleSpectateTab("Object", this.state.tableauAffichage, this.state.smooth, this.state.gains, this.state.pseudos);
  }

  changeValueLevierMaxiPNL() {
    this.handleSpectateTab("Object", this.state.tableauAffichage, this.state.smooth, this.state.gains, this.state.pseudos);
  }



  handleMenu = (event) => {
    event.preventDefault();

    if (this.state.isMenuOpen === false) {
      this.setState({ isMenuOpen: true });
      this.setState({ menuText: "Agrandir" });
      document.getElementById("SpectateLeftPanelTop").style.height = "95%";
      document.getElementById("SpectateLeftPanelBottom").style.height = "0%";
    } else {
      this.setState({ isMenuOpen: false });
      this.setState({ menuText: "Réduire" });
      document.getElementById("SpectateLeftPanelTop").style.height = "70%";
      document.getElementById("SpectateLeftPanelBottom").style.height = "26%";
    }
  };

  setID = (value) => (event) => {
    event.preventDefault();
    

    this.setState({ tournamentID: value });
    document.getElementById("butSelIdSpan").style.display = "none";

    this.setState({joueurAffiches: []});
    this.setState({tableauAffichage: []});


    this.handleSpectateTab("Object", [], this.state.smooth, this.state.gains, this.state.pseudos);


    this.removeShape("Vertical");
  };

  setPair = (value) => (event) => {
    event.preventDefault();
    this.setState({joueurAffiches: []});
    this.setState({tableauAffichage: []});

    this.handleSpectateTab("Object", [], this.state.smooth, this.state.gains, this.state.pseudos);

    this.setState({ pair: value });
    
    var symbol = "bybit:" + value.replace("PERP", "-PERP");
    this.setSymbol(symbol);
    document.getElementById("butSelPairSpanS").style.display = "none";
  };

  setSymbol(value) {
    this.child.current.setSymbol(value);
  }


  displayPairSelector = (event) => {
    event.preventDefault();
    try {
      document.getElementById("butSelPairSpanS").style.display = "block";
    } catch (error) {
      console.log(error.message);
    }
  };

  displayIdSelector = (event) => {
    event.preventDefault();
    try {
      document.getElementById("butSelIdSpan").style.display = "block";
    } catch (error) {
      console.log(error.message);
    }
  };

  removeAllShapes() {
    this.child.current.removeAllShapes();
  }

  handleClickSmooth = async (event) => {
    event.preventDefault();
    this.setState({loading: true})
    if(this.state.smooth === true) {
      //going hard more
      this.setState({smooth: false}, () => { 
        this.setState({tableauAffichage: [], joueurAffiches: []}, () => { 
          this.handleSpectateTab("Object", [], false, this.state.gains, this.state.pseudos);
        });
      });
    }

    if(this.state.smooth === false) {
      //going smooth more
      this.setState({smooth: true}, () => { 
        this.setState({tableauAffichage: [], joueurAffiches: []}, () => { 
          this.handleSpectateTab("Object", [], false, this.state.gains, this.state.pseudos);
        });

      });
    }

    await this.sleep(2000);
    this.setState({loading: false})
  };

  handleClickGains = async (event) => {
    event.preventDefault();
    this.setState({loading: true})

    if(this.state.gains === true) {
      //going hard more
      this.setState({gains: false}, () => { 
        this.handleSpectateTab("Object", [], this.state.smooth, this.state.gains, this.state.pseudos);
        this.handleSpectateTab("Object", this.state.tableauAffichage, this.state.smooth, this.state.gains, this.state.pseudos);
        });
      }

    if(this.state.gains === false) {
      //going smooth more
      this.setState({gains: true}, () => { 
        this.handleSpectateTab("Object", [], this.state.smooth, this.state.gains, this.state.pseudos);
        this.handleSpectateTab("Object", this.state.tableauAffichage, this.state.smooth, this.state.gains, this.state.pseudos);

      });
    }

    await this.sleep(2000);
    this.setState({loading: false})
    
  };

  handleClickPseudos = async (event) => {
    event.preventDefault();
    this.setState({loading: true})

    
    if(this.state.pseudos === true) {
      //going hard more
      if(this.state.smooth) {
        this.setState({pseudos: false}, () => { 
          this.handleSpectateTab("Object", [], this.state.smooth, this.state.gains, this.state.pseudos);
          this.handleSpectateTab("Object", this.state.tableauAffichage, this.state.smooth, this.state.gains, this.state.pseudos);
        });
      }
      else {
        this.setState({pseudos: false}, () => { 
          this.setState({joueurAffiches: []});
          this.setState({tableauAffichage: []});


          this.handleSpectateTab("Object", [], this.state.smooth, this.state.gains, this.state.pseudos);
        });
      }
    }

    if(this.state.pseudos === false) {
      //going smooth more
      if(this.state.smooth) {
        this.setState({pseudos: true}, () => { 
         this.handleSpectateTab("Object", [], this.state.smooth, this.state.gains, this.state.pseudos);
         this.handleSpectateTab("Object", this.state.tableauAffichage, this.state.smooth, this.state.gains, this.state.pseudos);
        });
      }
      else {
        this.setState({pseudos: true}, () => { 
          this.setState({joueurAffiches: []});
          this.setState({tableauAffichage: []});


          this.handleSpectateTab("Object", [], this.state.smooth, this.state.gains, this.state.pseudos);
        });
      }
    }
    
    await this.sleep(2000);
    this.setState({loading: false})
    
  };

  displaySelectedPair() {
    var pair = this.state.pair;
    
    if(pair === "btcusdt")
      pair = "Bitcoin";
    if(pair === "ethusdt")
      pair = "Ethereum";
    if(pair === "solusdt")
      pair = "Solana";
    if(pair === "avaxusdt")
      pair = "Avalanche";
    if(pair === "xrpusdt")
      pair = "Ripple";
    if(pair === "dogeusdt")
      pair = "Dogecoin";
    if(pair === "maticusdt")
      pair = "Matic";
    if(pair === "ltcusdt")
      pair = "Litecoin";
    

    return <span> {pair} </span>;
  }

  render() {
    return (
      <section className="Spectate">
        <div className="SpectateLeftPanel">
          <div className="SpectateLeftPanelTop" id="SpectateLeftPanelTop">
            

            <div className="SpectateLeftPanelRes">{this.state.results1}</div>
          </div>
          <div className="SpectateLeftPanelBut" onClick={this.handleMenu}>
          </div>
          <div className="SpectateLeftPanelBottom" id="SpectateLeftPanelBottom">
            <div className="spectateControls">
            <div className="SpectateLeftPanelTools">
              <div
                  className="spectateIdSelector"
                  onClick={this.displayIdSelector}
                >
                  Tournoi {this.state.tournamentID} 
              </div>

              <div
                  className="spectateIdSelector"
                  onClick={this.displayPairSelector}
                >
                  {this.displaySelectedPair()} 
              </div>
              
              <div
                  className="butSelIdSpan"
                  id="butSelIdSpan"
                  style={{ display: "none" }}
                >
                  <div className="SpectateButtonID" onClick={this.setID(1)}>
                    Tournoi 1
                  </div>
                  <div className="SpectateButtonID" onClick={this.setID(2)}>
                    Tournoi 2
                  </div>
                  <div className="SpectateButtonID" onClick={this.setID(3)}>
                    Tournoi 3
                  </div>
                  <div className="SpectateButtonID" onClick={this.setID(4)}>
                    Tournoi 4
                  </div>
              </div>
              <div
                  className="butSelPairSpanS"
                  id="butSelPairSpanS"
                  style={{ display: "none" }}
                >
                  <div className="SpectateButtonID2" onClick={this.setPair("btcusdt")} >
                    Bitcoin
                  </div>
                  <div className="SpectateButtonID2" onClick={this.setPair("ethusdt")} >
                    Ethereum
                  </div>
                  <div className="SpectateButtonID2" onClick={this.setPair("solusdt")} >
                    Solana
                  </div>
                  <div className="SpectateButtonID2" onClick={this.setPair("avaxusdt")} >
                    Avalanche
                  </div>
                  <div className="SpectateButtonID2" onClick={this.setPair("xrpusdt")} >
                    Ripple
                  </div>
                  <div className="SpectateButtonID2" onClick={this.setPair("dogeusdt")} >
                    Dogecoin
                  </div>
                  <div className="SpectateButtonID2" onClick={this.setPair("maticusdt")} >
                    Matic
                  </div>
                  <div className="SpectateButtonID2" onClick={this.setPair("ltcusdt")} >
                    Litecoin
                  </div>
              </div>
              <img
                className="spcImageAfficherCacher"
                onClick={this.toutCacherGraph()}
                src={".././images/cacher.png"}
                alt="afficher"
              />
              <img
                className="spcImageAfficherCacher"
                onClick={this.toutAfficherGraph()}
                src={".././images/afficher.png"}
                alt="afficher"
              />
              <img
                className="spcImageAfficherCacher"
                onClick={this.toutAfficherGraph10()}
                src={".././images/afficher10.png"}
                alt="afficher"
              />



              
            </div>
            { this.state.loading 
             ? <div className="loadingSpectate">
                <img
                  style={{
                    width: "50px",
                    marginTop: "10%",
                    marginBottom: "3%",
                    marginRight: "15%"
                  }}
                  src={loading}
                  alt="load"
                ></img>
               </div>
             :
             <div className="modeSmooth">
              <br/>
              Mode :
              <span
                className="smoothSelector"
                style={{ cursor: "pointer" }}
                onClick={this.handleClickSmooth}
                title="Mode smooth"
              > 
              {this.state.smooth === true 
                ? <span className="smoothSelected"> Global </span>
                : <span className="smoothNotSelected"> Global </span>
              }
              {"  "}
              {this.state.smooth === false 
                ? <span className="smoothSelected"> Analyse </span>
                : <span className="smoothNotSelected"> Analyse </span> 
              }
              </span><br/><br/>
              PNL : 
              <span className="onOffSpace"> {" "} </span>
              <span
                className="smoothSelector"
                style={{ cursor: "pointer" }}
                onClick={this.handleClickGains}
                title="Mode pnl"
              > 
              {this.state.gains === true 
                ? <span className="onOffSelected"> On </span>
                : <span className="onOffNotSelected"> On </span>
              }
              {"  "}
              {this.state.gains === false 
                ? <span className="onOffSelected"> Off </span>
                : <span className="onOffNotSelected"> Off </span> 
              }
              </span><br/>
              Psdo : 
              <span
                className="smoothSelector"
                style={{ cursor: "pointer" }}
                onClick={this.handleClickPseudos}
                title="Mode pseudo"
              > 
              {this.state.pseudos === true 
                ? <span className="onOffSelected"> On </span>
                : <span className="onOffNotSelected"> On </span>
              }
              {"  "}
              {this.state.pseudos === false 
                ? <span className="onOffSelected"> Off </span>
                : <span className="onOffNotSelected"> Off </span> 
              }
              </span>
            </div>
          }

            <br/><br/>

            <div style={{width: '39%', float: 'left', textAlign: 'left'}}>
            PNL Minimum : <span style={{display: 'inline-block', width: '40%', textAlign: 'center'}}>{this.state.valueLevierMiniPNL} </span>
            <br/><br/>
            PNL Maximum: <span style={{display: 'inline-block', width: '40%', textAlign: 'center'}}> {this.state.valueLevierMaxiPNL} </span>
            </div>
            <div style={{width: '60%', float: 'right'}}>
              <Slider
                key={"slidera"}
                defaultValue={1}
                onChange={this.onSliderChangeMiniPNL}
                trackStyle={{ backgroundColor: "#1e3736", height: 5 }}
                railStyle={{ backgroundColor: "white", height: 5 }}
                disabled={this.state.smooth === true}
                min={0}
                max={200}
              />
              <br/>
              
                <Slider
                  key={"sliderb"}
                  defaultValue={10000}
                  onChange={this.onSliderChangeMaxiPNL}
                  trackStyle={{ backgroundColor: "#1e3736", height: 5 }}
                  railStyle={{ backgroundColor: "white", height: 5 }}
                  disabled={this.state.smooth === true}
                  min={10}
                  max={10000}
                />
              
            </div>
            
            
            </div>
            <br/><br/><br/><br/>
            {this.displayTournamentLogs(this.state.tournamentID, false)}
          </div>
        </div>
        <div className="SpectateGraph">
          <Graph
            param={this.state.pair}
            ref={this.child}
            width={"100%"}
            height={"90%"}
            isSpectate={true}
            valueLevierMaxiPNL={this.state.valueLevierMaxiPNL}
            valueLevierMiniPNL={this.state.valueLevierMiniPNL}
          />
          <span className="spectateTime">
            <span style={{marginLeft: "0%", color: 'orange'}}>
              Short/Clôture Long
            </span>
            <span style={{marginLeft: "5%", color: '#5b9cf6'}}>
              Long/Clôture Short
            </span>
            {this.state.smooth === false 
                ? <span style={{marginLeft: "5%", color: '#e96bff'}}>
                    Liquidation
                  </span>
                : ""
            }
            <span style={{marginLeft: "10%"}}>
              Temps restant: {this.state.ingameTempsrestant}
            </span>
            <span style={{marginLeft: "5%"}}>
              Joueurs: {this.state.nombreJoueurs}
            </span>

          </span>
          
        </div>
        
      </section>
    );
  }
}

export default Spectate;