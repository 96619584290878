import React, { Fragment, Component } from "react";
import "rc-slider/assets/index.css";
import Ingame from "./Ingame";
import joueurs from "./images/joueurs.png";
import horloge from "./images/horloge.png";

import { ipBackend } from "../utils/const";
import { apiURL } from "../utils/const";
import { instanceTournoi } from "../api/api_instance";
class Tournament extends Component {
  //////////////////////////////////////////
  ////          Tournament.js            ////
  //// Fichier de lancement des parties  ////
  //////////////////////////////////////////

  state = {
    nbtournois: 0,
    tournois: 0,
    tournois2: 0,
    tournois3: 0,
    tournois4: 0,
    tabTournois: [],
    tournoisActif: 0,
    tournoisFin: 0,
    indicetournoisActif: 0,
    iptournoisActif: "",
    ///////In Game/////
    ingameTempsrestant: 0,
    ingameResults: 0,
    ingamePosition: 0,
    ingameValuepair: 0,
    ingameSoldeUSDT: 0,
    ingameSoldeUSDT_POS: 0,
    ingameSoldeCrypto: 0,
    ingameTaillePos: 0,
    ingameLevier: 0,
    ingameOrders: 0,
    ingameNbOrders: 0,
    ingamePaire: "",
    ingamePari: 0,
    ingameEntreePos: 0,
    ingameLiquid: 0,
    ingameSensLiquid: 0,
    ingameSoldeAvantPos: 0,
    ingameGroups: [],
    ingameSL: 0,
    ingameTP: 0,
    valueSell: 0,
    valueBuy: 0,
    valueLevier: 0,
    renderFinish: 0,
    tabresultpseudo: 0,
    joueurligue: "",
    tabresultsolde: 0,
    sliderMoovable: true,
    ////////////////
    nbpreregshort: 0,
    preregshort: 0,
    //////////////////
    nbpreregmid: 0,
    preregmid: 0,
    ////////////////
    nbprereglong: 0,
    prereglong: 0,
    ////////////////
    nbpreregspec: 0,
    preregspec: 0,
    ////////////////
    ispreregshort: "",
    ispreregmid: "",
    isprereglong: "",
    ispreregspec: "",
    ///////////////////
    strat: 0,
    selecstrat: 0,
    nbStrat: 0,
    ///////Resultats///////
    finishJoueurs: 0,
    finishScores: 0,
    finishPosition: 0,
    finishScore: 0,
    finishXp: 0,
    finishCote: 0,
    finishTag: 0,
    ////Profil Public//////
    joueurembleme: 0,
    joueurpseudo: 0,
    joueurcadre: 0,
    joueurcitation: 0,
    joueurstat: 0,
    joueurlvl: 0,
    joueurcr: 0,
    stat1: 0,
    stat2: 0,
    stat3: 0,
    stat4: 0,
    stat5: 0,
    stat6: 0,
    displaystat: 0,
    displaycitation: 0,
    ratingPerso: 0,
    errorSLTP: "",
    cpText: "",
    isRegistrable: true,
    urlSponsor: "",
    nomSponsor: "",
    groupe: "",
    nbGroupe1: 0,
    nbGroupe2: 0,
    nbGroupe3: 0,
    nbGroupe4: 0,
  };

  ///////////////////////////////////EXECUTION FIN CHARGEMENT PAGE////////////////////////////

  async componentDidMount() {
    try {
      if (this.props.auth.isAuthenticated) {
        /*
                this.getTournamentShort();
                this.getTournamentMed();
                this.getTournamentLong();
                this.getTournamentSpec();
                */
        
        this.getTournaments();
        this.getRanking();
      } else {
        window.location.href = "/";
      }
    } catch (error) {
      console.log(error);
    }
  }

  ///////////////////////////////////LOOP INFINE////////////////////////////

  ///////////////////////////////////GESTION TOURNOIS////////////////////////////

  getTournaments() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");


    var requestOptions = {
      crossDomain: true,
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      ipBackend + "/tournaments/isreg/" + this.props.auth.user.username,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        var resultats = JSON.parse(result);
        var tmpTabTournois = [];

        for (var i = 0; i < resultats.length; i++) {
          var datefull = new Date();
          datefull.setTime(resultats[i].hDeb);
          var hDebFormat =
            datefull.getDate() +
            "/" +
            Number(datefull.getMonth() + 1) +
            "/" +
            datefull.getFullYear() +
            " : " +
            datefull.getHours() +
            ":" +
            datefull.getMinutes();

          var hfinformat = new Date();
          hfinformat.setTime(resultats[i].hFin);
          var hFinFormat =
            hfinformat.getHours() + ":" + hfinformat.getMinutes();

          var tmp = {
            ID: resultats[i].id,
            HDEBraw: resultats[i].hDeb,
            HDEB: hDebFormat,
            HFINformat: hFinFormat,
            HFIN: resultats[i].hFin,
            DUREE: resultats[i].duree,
            NBJOUEURS: resultats[i].nombreJoueurs,
            JOUEURS: resultats[i].joueurs,
            ISREGISTRED: resultats[i].isRegistred,
            GROUPE: resultats[i].groupe,
            ESTJOUABLE: resultats[i].infos.estJouable,
            ESTAFFICHABLE: resultats[i].infos.estAffichable,
            ESTENREGISTRABLE: resultats[i].infos.estEnregistrable,
            ESTBATTLEROYAL: resultats[i].infos.estBattleRoyal,
            ESTIMMORTEL: resultats[i].infos.estImmortel,
            ESTGROUPE: resultats[i].infos.estGroupe,
            ESTRANKED: resultats[i].infos.estRanked,
            ESTPASSWD: resultats[i].infos.estPasswd,
            ESTRESTRICTED: resultats[i].infos.estRestricted,
            ESTRANKRESTRICTED: resultats[i].infos.estRankRestricted,
            CPTEXT: resultats[i].infos.cpText,
            CPTOTAL: resultats[i].infos.cpTotal,
            SPONSOR: resultats[i].infos.sponsor,
            SPONSORURL: resultats[i].infos.sponsorUrl,
            ASSETS: resultats[i].assets,
            TICKBR: resultats[i].infos.tickBR,
          };

          tmpTabTournois.push(tmp);
        }

        this.setState({ tabTournois: tmpTabTournois });
      })
      .catch((error) => console.log("error", error));
  }

  //Enregistrement en partie de l'utilisateur connécté
  register = (value) => async (event) => {
    event.preventDefault();
    try {
      var passwd = "";

      if (this.state.tabTournois[value.i].ESTPASSWD) {
        let pass = prompt("Entrez le mot de passe !", "");

        if (pass === null || pass === "") {
        } else {
          passwd = pass;
        }
      }


      if (this.state.tabTournois[value.i].ESTRANKRESTRICTED) {

        var isUserRanked = this.state.joueurligue;
        
        if (isUserRanked !== "NC") {
        } else {
          alert("You must complete at least one ranked tournament to join !");
          return;
        }
      }

      const body = {
        id: value.ID,
        passwd: passwd,
        pseudo: this.props.auth.user.username,
        jwt:
          this.props.auth.user &&
          this.props.auth.user.signInUserSession &&
          this.props.auth.user.signInUserSession.idToken
            ? this.props.auth.user.signInUserSession.idToken
            : undefined,
        uuid:
          this.props.auth.user.attributes && this.props.auth.user.attributes.sub
            ? this.props.auth.user.attributes.sub
            : undefined,
        crdt:
          this.props.auth.user && this.props.auth.user.credentials
            ? this.props.auth.user.credentials
            : undefined,
      };

      instanceTournoi.post("/player/register", body).then((response) => {
        if (response.data === "WRONGPASS") {
          alert("Mauvais mot de passe !");
        }
        if (response.data === "NOKEY") {
          alert("Vous ne possedez pas la clef !");
        }
        this.getTournaments();
      });
    } catch (error) {
      console.log(error.message);
    }
  };





  play = (value) => (event) => {
    event.preventDefault();
    try {
      var indiceTournoi = value;
      this.setState({ tournoisActif: this.state.tabTournois[indiceTournoi] });

      document.getElementById("renderTournament").style.display = "none";
      document.getElementById("renderPlayIG").style.display = "block";
      document.getElementById("renderPlay").style.display = "block";
      document.getElementById("renderFinish").style.display = "none";
    } catch (error) {
      console.log(error.message);
    }
  };

  getRanking() {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        USERNAME: this.props.auth.user.username,
        MODE: "SOLO",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(apiURL + "/dev/getranking", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          var ligue = JSON.parse(result).ligueUser;

          if (ligue === "") {
            ligue = "Aspirant";
          }


          this.setState({ joueurligue: ligue });
        })


    } catch (error) {
      console.log(error.message);
    }
  }

  openGroupPage = (value) => (event) => {
    event.preventDefault();

    var id = Number(value) + 1;

    //API call: getGroupes(pseudo, i)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      crossDomain: true,
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(ipBackend + "/player/getgroupes/" + id, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        var parsed = JSON.parse(result);

        var ret = [];

        for (var i = 0; i < parsed.length; i++) {
          ret.push({
            Nom: parsed[i].Nom,
            Leader: parsed[i].Leader,
            url: parsed[i].url,
            joueurs: parsed[i].joueurs,
            couleur: parsed[i].couleur,
          });
        }

        this.setState({ ingameGroups: ret });
      });

    id = "setGroupe" + value;
    document.getElementById(id).style.display = "block";
  };

  displayGroupes(id) {
    var list = [];
    for (var i = 0; i < this.state.ingameGroups.length; i++) {
      if (this.state.tabTournois[id].GROUPE === 0) {
        list.push(
          <div>
            <h1 style={{ color: this.state.ingameGroups[i].couleur }}>
              {this.state.ingameGroups[i].Nom}
            </h1>
            <a
              href={this.state.ingameGroups[i].url}
              style={{ color: "white" }}
              target="_blank"
              rel="noreferrer"
            >
              Leader: {this.state.ingameGroups[i].Leader}
            </a>{" "}
            <br />
            Players: {this.state.ingameGroups[i].joueurs}
            <br />
            <button
              className="buttonsTournamentGp"
              onClick={this.joinGroup({
                i: id + 1,
                nom: this.state.ingameGroups[i].Nom,
              })}
            >
              {" "}
              Join
            </button>
          </div>
        );
      } else {
        list.push(
          <div>
            <h1 style={{ color: this.state.ingameGroups[i].couleur }}>
              {this.state.ingameGroups[i].Nom}
            </h1>
            <a
              href={this.state.ingameGroups[i].url}
              style={{ color: "white" }}
              target="_blank"
              rel="noreferrer"
            >
              Leader: {this.state.ingameGroups[i].Leader}
            </a>{" "}
            <br />
            Joueurs: {this.state.ingameGroups[i].joueurs}
          </div>
        );
      }
    }

    return list;
  }

  //
  joinGroup = (value) => (event) => {
    event.preventDefault();
    const body = {
      pseudo: this.props.auth.user.username,
      id: value.i,
      groupe: value.nom,
      jwt:
        this.props.auth.user &&
        this.props.auth.user.signInUserSession &&
        this.props.auth.user.signInUserSession.idToken
          ? this.props.auth.user.signInUserSession.idToken
          : undefined,
      uuid:
        this.props.auth.user.attributes && this.props.auth.user.attributes.sub
          ? this.props.auth.user.attributes.sub
          : undefined,
      crdt:
        this.props.auth.user && this.props.auth.user.credentials
          ? this.props.auth.user.credentials
          : undefined,
    };

    instanceTournoi.post("/player/setgroupe/", body).then((response) => {
      var t = Number(value.i) - 1;
      var id = "setGroupe" + t;

      document.getElementById(id).style.display = "none";

      this.getTournaments();
    });
  };

  closeGroupSelector = (i) => (event) => {
    event.preventDefault();
    var id = "setGroupe" + i;

    document.getElementById(id).style.display = "none";
  };

  ///////////////////////////////////HELPERS////////////////////////////
  async sleep(ms) {
    return new Promise((res) => setTimeout(res, ms));
  }

  //Tronque x pour 2 ch apres la virgule
  tronque(x) {
    return Number.parseFloat(x).toFixed(2);
  }

  render() {
    var ListTournament = [];

    //////////////////////////////////////BLOC RENDU TOURNOIS////////////////
    //Ce bloc vérifie plusieurs parametres (utilisateur enregistré, tournois jouable...)
    // Pour ensuite ajouter dans ListTournament les elements html qui correspondent




    if (this.state.tabTournois !== []) {
      for (var i = 0; i < this.state.tabTournois.length; i++) {
        if (this.state.tabTournois[i].ESTAFFICHABLE === true) {
          ListTournament.push(
            <div
              className="tournamentContainerCenter3"
              id="tournamentContainerCenter"
              key={i+ "listTournamentss" + i}
            >
              <div id={"tournament" + [i]} key={"center1" + i}>
                {this.state.tabTournois[i].SPONSOR !== "" ? (
                  <div className="bannerEvent" key={"bannerEvent" + i}>
                    <a
                      href={this.state.tabTournois[i].SPONSORURL}
                      style={{ color: "white" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {this.state.tabTournois[i].SPONSOR}
                    </a>
                  </div>
                ) : (
                  ""
                )}
                <div className="titreTournoiNormal" key={"titreTournoiNormal" + i}>
                  {this.state.tabTournois[i].ESTRANKED === true && i !== 2
                    ? "Ranked"
                    : i === 2
                      ? "Event"
                      : "Event"}
                  <div className="sousTitreTournoi" key={"sousTitreTournoi" + i}>
                    {this.state.tabTournois[i].ESTBATTLEROYAL === true
                      ? "BattleRoyal "
                      : ""}
                    {this.state.tabTournois[i].ESTIMMORTEL === true
                      ? "Immortal "
                      : ""}
                    {this.state.tabTournois[i].ESTGROUPE === true
                      ? "Party"
                      : ""}
                    {this.state.tabTournois[i].ESTBATTLEROYAL === false &&
                    this.state.tabTournois[i].ESTIMMORTEL === false &&
                    this.state.tabTournois[i].ESTGROUPE === false ? (
                      <br />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <span className="tournaDate" key={"tournaDate" + i}>
                  <img src={horloge} className="iconeDuree" alt="" /> <br />
                  Start: {this.state.tabTournois[i].HDEB} <br />
                  Duration:{" "}
                  {Number.parseFloat(
                    this.state.tabTournois[i].DUREE / 86400000
                  ).toFixed(0)}{" "}
                  jours
                </span>
                <span className="assetTour" key={"assetTour" + i} >
                  <img
                    src={".././images/settings.png"}
                    className="iconeSettings"
                    alt="settings"
                  />{" "}
                  <br />
                  Leverage : 1 - 50 <br />
                  Assets: Cryptos (6)
                </span>
                <span className="tournaNbJoueurs" key={"tournaNbJoueurs" + i}>
                  <img src={joueurs} className="iconeJoueurs" alt="" /> <br />
                  Players: {this.state.tabTournois[i].NBJOUEURS} <br />
                </span>
                <span className="cashPTourna" key={"cashPTourna" + i}>
                  <img
                    className="imgCashPrize"
                    src={".././images/logocash.png"}
                    alt="cashprize:"
                  />{" "}
                  <br />
                  Cashprize : {this.state.tabTournois[i].CPTOTAL}$ <br />
                </span>
                <span className="spanButtonsTournamentCP" key={"spanButtonsTournamentCP" + i}>
                  {this.state.tabTournois[i].ESTENREGISTRABLE === true &&
                  this.state.tabTournois[i].ISREGISTRED === false ? (
                    <button
                      id="butRegister3"
                      className="buttonsTournament"
                      onClick={this.register({
                        ID: this.state.tabTournois[i].ID,
                        i: i,
                      })}
                    >
                      Register
                    </button>
                  ) : (
                    ""
                  )}
                  {this.state.tabTournois[i].ESTJOUABLE === true &&
                  this.state.tabTournois[i].HDEBraw < Date.now() &&
                  this.state.tabTournois[i].ISREGISTRED === true &&
                  this.state.tabTournois[i].ESTGROUPE === false ? (
                    <button
                      className="buttonsTournament"
                      onClick={this.play(i)}
                    >
                      Play
                    </button>
                  ) : (
                    ""
                  )}
                  {this.state.tabTournois[i].ESTJOUABLE === true &&
                  this.state.tabTournois[i].HDEBraw < Date.now() &&
                  this.state.tabTournois[i].ISREGISTRED === true &&
                  this.state.tabTournois[i].ESTGROUPE === true &&
                  this.state.tabTournois[i].GROUPE !== 0 ? (
                    <button
                      className="buttonsTournament"
                      onClick={this.play(i)}
                    >
                      Play
                    </button>
                  ) : (
                    ""
                  )}
                  {this.state.tabTournois[i].HDEBraw > Date.now() &&
                  this.state.tabTournois[i].ISREGISTRED === true ? (
                    <button className="buttonsTournament"> Bientot !</button>
                  ) : (
                    ""
                  )}
                  {this.state.tabTournois[i].ESTENREGISTRABLE === false &&
                  this.state.tabTournois[i].ISREGISTRED === false ? (
                    <button className="buttonsTournament">
                      {" "}
                      Registration closed !
                    </button>
                  ) : (
                    ""
                  )}
                  {this.state.tabTournois[i].ESTENREGISTRABLE &&
                  this.state.tabTournois[i].ESTJOUABLE &&
                  this.state.tabTournois[i].ESTGROUPE &&
                  this.state.tabTournois[i].ISREGISTRED === true ? (
                    <button
                      style={{ marginTop: "1vh" }}
                      className=" buttonsTournament "
                      onClick={this.openGroupPage(i)}
                    >
                      Party
                    </button>
                  ) : (
                    ""
                  )}
                </span>

                {i === 0 ? (
                  <div className="sousTournoi" key={"rthher" + i}>
                    This game mode allows players to regain full capital when
                    it is lost. The score (PNL) accounts the number of resets.
                  </div>
                ) : (
                  ""
                )}
                {i === 1 ? (
                  this.state.tabTournois[i].ESTRANKRESTRICTED ?
                  <div className="sousTournoi" key={"tyjtyjtyj" + i}>
                    Tournament opened for all ranked players.
                    <br />
                    <br />
                  </div>
                  :
                  <div className="sousTournoi" key={"jtyjtyjty" + i}>
                    Bet on the rise or fall of assets without limits.
                    Placement and performance will define how much ranking
                    points will be gained.
                    <br />
                    <br />
                  </div>
                ) : (
                  ""
                )}
                {i === 2 ? (
                  this.state.tabTournois[i].ESTRESTRICTED ?
                  <div className="sousTournoi" key={"yuiouio" + i}>
                    High reward tournament for top players
                    <br />
                    <br />
                  </div>

                  :
                  <div className="sousTournoi" key={"htrhhh" + i}>
                    Event tournament streamed by Luchap <br />
                    <a href="https://www.twitch.tv/luchap2btc">See here on Twitch</a>
                    <br />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div id={"setGroupe" + i} className="setGroupe" key={"setGroupe" + i}>
                  <div className="setGroupeInside" key={"setGroupeInside" + i}>
                    {this.displayGroupes(i)}
                  </div>

                  <button
                    className="closeGroupSelector"
                    onClick={this.closeGroupSelector(i)}
                    key={"closeGroupSelector" + i}
                  >
                    Fermer
                  </button>
                </div>
            </div>
          );
        }
      }
    }

    

    /////////////////////////////BLOC PLAY////////////////////

    return (
      <section className="tournament">
        {this.props.auth.isAuthenticated && this.props.auth.user ? (
          <>
            <div id="renderTournament">{ListTournament}</div>

            <div id="renderPlay" style={{ display: "none" }}>
              <span
                onClick={this.backTournament}
                className="backTour"
                style={{ display: "none" }}
              >
                Retour Tournois
              </span>

              <Fragment>
                <Ingame
                  username={this.props.auth.user.username}
                  Ingame
                  uuid={
                    this.props.auth.user.attributes &&
                    this.props.auth.user.attributes.sub
                      ? this.props.auth.user.attributes.sub
                      : undefined
                  }
                  isauth={this.props.auth.isAuthenticated}
                  tournoisActif={this.state.tournoisActif}
                  jwt={
                    this.props.auth.user &&
                    this.props.auth.user.signInUserSession &&
                    this.props.auth.user.signInUserSession.idToken
                      ? this.props.auth.user.signInUserSession.idToken
                      : undefined
                  }
                  crdt={
                    this.props.auth.user && this.props.auth.user.credentials
                      ? this.props.auth.user.credentials
                      : undefined
                  }
                />
              </Fragment>
            </div>
          </>
        ) : (
          <div className="needloged">
            {" "}
            You must register to acces tournaments !{" "}
          </div>
        )}
      </section>
    );
  }
}

export default Tournament;
