import React, { Component } from "react";
import { webSocketUrl } from "../utils/const";
import { truncateEthAddress, truncateRegex } from "../utils/function";

import io from 'socket.io-client';

const client = io(webSocketUrl);

class Chat extends Component {
  state = {
    contenuChat: "",
  };


  constructor(props) {
    super(props);

    this._isMounted = false;

    this.postMessage = this.postMessage.bind(this);
  }

  
  async componentDidMount() {
    client.onopen = () => {
      const message = {
        type: "join",
      };
      client.send(JSON.stringify(message));
    };

    client.on('message', (message) => {
      
      var listMessages = [];
      var pseudosTab = JSON.parse(message).pseudos;
      var messagesTab = JSON.parse(message).messages;

      var nb = pseudosTab.length;

      //listMessages.push(<br />);
      for (var i = 0; i < nb; i++) {
        listMessages.push(
          <div key={"keyChat" + i}>
            {" "}
            <b style={{ color: this.returnColor(pseudosTab[i]) }} key={"keyChatInside" + i}>
              {pseudosTab[i].match(truncateRegex) 
                ? truncateEthAddress(pseudosTab[i])
                : pseudosTab[i] }{" "}
            </b>{" "}
            <b key={"keyChatMsg" + i}>
              : {messagesTab[i]}
            </b>
            
          </div>
        );
      }

      this.setState({ contenuChat: listMessages });


    });

    client.onclose = () => {

    };
  }

  postMessage = (event) => {
    //event.preventDefault();
    try {
      if (document.getElementById("inputChat").value !== "") {
        if (document.getElementById("inputChat").value.length > 300) {
          alert("Le message saisi est trop long !");
        } else {
          const message = {
            pseudo: this.props.param,
            message: document.getElementById("inputChat").value,
            type: "chat",
          };
          client.send(JSON.stringify(message));
          document.getElementById("inputChat").value = "";
          /*
                    var objDiv = document.getElementById("chat");
                    objDiv.scrollTop = objDiv.scrollHeight;*/
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //Fonction générant couleur random
  returnColor(pseudo) {
    //this.state.couleursTexte
    if (
      pseudo.charAt(0) === "a" ||
      pseudo.charAt(0) === "i" ||
      pseudo.charAt(0) === "q" ||
      pseudo.charAt(0) === "y"
    ) {
      return "#FF7A7A";
    } else if (
      pseudo.charAt(0) === "b" ||
      pseudo.charAt(0) === "j" ||
      pseudo.charAt(0) === "r" ||
      pseudo.charAt(0) === "z"
    ) {
      return "#5EA1D8";
    } else if (
      pseudo.charAt(0) === "c" ||
      pseudo.charAt(0) === "k" ||
      pseudo.charAt(0) === "s"
    ) {
      return "#3E86C2";
    } else if (
      pseudo.charAt(0) === "d" ||
      pseudo.charAt(0) === "l" ||
      pseudo.charAt(0) === "t"
    ) {
      return "#D8D357";
    } else if (
      pseudo.charAt(0) === "e" ||
      pseudo.charAt(0) === "m" ||
      pseudo.charAt(0) === "u"
    ) {
      return "#9F4496";
    } else if (
      pseudo.charAt(0) === "f" ||
      pseudo.charAt(0) === "n" ||
      pseudo.charAt(0) === "v"
    ) {
      return "#154A8F";
    } else if (
      pseudo.charAt(0) === "g" ||
      pseudo.charAt(0) === "o" ||
      pseudo.charAt(0) === "w"
    ) {
      return "#D87E57";
    } else if (
      pseudo.charAt(0) === "h" ||
      pseudo.charAt(0) === "p" ||
      pseudo.charAt(0) === "x"
    ) {
      return "#949494";
    }
  }

  postEvent() {
    this.postMessage();
  }

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.postEvent();
    }
  };

    sharePosition= (event) => {


    let pair = this.props.pair;
    let pari = this.props.pari;
    let levier = this.props.levier;
    let entree = this.props.entree;
    let soldeCrypto = this.props.soldeCrypto;

    let newPari = "";

    if(pari === "HAUSSE")
      newPari = "Long"

    if(pari === "BAISSE")
      newPari = "Short"


    var valuePair = this.props.asset;

    var pnlc =
        valuePair * soldeCrypto * levier -
        entree * soldeCrypto * levier;

    if (window.confirm("The current state of your active position will appear in chat and be visible by everybody !") === true) {

      let msgg = newPari+" position on "+pair+ " leverage "+levier+". Entry @"+entree+", PNL: "+Number.parseFloat(pnlc).toFixed(2)+"$";

      if(entree === 0) {
        alert("You don't have an open position !");
        return;
      }
      const message = {
        pseudo: this.props.param,
        message: msgg,
        type: "chat",
      };

      client.send(JSON.stringify(message));

      return;
    } else {
      return;
    }
  }

  render() {
    return (
      <section className="fondChat">
        <div className="containerChat">
          <div className="chat" id="chat">
            {this.state.contenuChat}
          </div>
          <input id="inputChat" type="text" onKeyDown={this._handleKeyDown} />
          <button className="butEnvoyer" onClick={this.postMessage}>
            {" "}
            Send{" "}
          </button>{" "}
          <button className="butShare" onClick={this.sharePosition}>
            {" "}
            Share{" "}
          </button>{" "}
          <br />
          <div className="alertmsg"> {this.state.alertmsg} </div>
        </div>
      </section>
    );
  }
}

export default Chat;
