import React, { Component } from "react";
import TradingViewWidget from "./TradingViewWidget";
import TradingViewWidgetMini from "./TradingViewWidgetMini";
import iconhisto from "./images/iconhisto.png";
import iconclass from "./images/iconclass.png";
import { getValFromCitation, truncateEthAddress, truncateRegex } from "../utils/function";
import { instanceAWS } from "../api/api_instance";
import { apiURL } from "../utils/const";


class Ranking extends Component {
  ///////////////////////////////////////////
  ////             Ranking.js            ////
  //// Fichier d'affichage des resultats ////
  ///////////////////////////////////////////

  state = {
    historique: [],
    nbparties: 0,
    resultats: 0,
    participants: 0,
    renderpseudos: 0,
    rendermultiple: 0,
    position: 0,
    gainTotal: 0,
    gainPourcent: 0,
    joueurligue: 0,
    joueurpseudo: "",
    joueurembleme: 0,
    joueurcadre: 0,
    joueurcitation: 0,
    joueurbordure: 0,
    joueurstat: 0,
    joueurstatvaleur: 0,
    joueurlvl: 0,
    joueurcr: 0,
    nbtotaljoueurs: 0,
    resultatranking: 0,
    resultatsEmeraude: 0,
    resultatsDiamant: 0,
    resultatsOr: 0,
    resultatsArgent: 0,
    resultatsAspirant: 0,
    activePairLeft: "BTC",
    ligueuser: 0,
    nbEmeraude: 0,
    nbDiamant: 0,
    nbOr: 0,
    nbArgent: 0,
    nbAspirant: 0,
    indiceJoueur: 0,
    altArray: [],
    stat1: 0,
    stat2: 0,
    stat3: 0,
    stat4: 0,
    stat5: 0,
    stat6: 0,
    stat7: 0,
    stat8: 0,
    stat9: 0,
    stat10: 0,
    stat11: 0,
    displaystat: 0,
    displaycitation: 0,
    ratingPerso: 0,
    evenement: "",
    podium1: [],
    podium2: [],
    podium3: [],
    logsArray: [],
    graphClicked: -1,
    rightContent: {
      logs: [],
      graphArray: [],
    },
    rightContentLogs: [],
    rightContentArray: [],
    leftContentArray1: 0,
    leftContentArray2: 0,
    leftContentArray3: 0,
    leftContentArray4: 0,
    leftContentArray5: 0,
    leftContentArray6: 0,
    leftContentArray7: 0,
    leftContentArray8: 0,
    histoBTC: [],
    histoETH: [],
    histoSOL: [],
    histoAVAX: [],
    histoXRP: [],
    histoDOGE: [],
    histoMATIC: [],
    histoLTC: [],
    options: {
      localization: {
        locale: "fr-FR",
      },
      lineWidth: 1,
      grid: {
        vertLines: {
          visible: false,
        },
        horzLines: {
          visible: false,
        },
      },
      crosshair: {
        vertLine: {
          color: "#696969",
          width: 0.5,
          style: 1,
          visible: true,
          labelVisible: false,
        },
        horzLine: {
          color: "#696969",
          width: 0.5,
          style: 0,
          visible: true,
          labelVisible: true,
        },
        mode: 1,
      },
      timeScale: {
        barSpacing: 3,
        fixLeftEdge: true,
        lockVisibleTimeRangeOnResize: true,
        rightBarStaysOnScroll: true,
        borderVisible: true,
        borderColor: "#7AFFD7",
        visible: true,
        timeVisible: true,
        secondsVisible: true,
      },
      layout: {
        backgroundColor: "#00000000",
        textColor: "#7AFFD7",
        fontSize: 12,
        fontFamily: "Calibri",
      },
      priceScale: {
        position: "left",
        borderColor: "#7AFFD7",
      },
      handleScroll: {
        mouseWheel: false,
        pressedMouseMove: true,
      },
      handleScale: {
        axisPressedMouseMove: true,
        mouseWheel: false,
      },
      priceRange: {
        minValue: 0,
        maxValue: 2000,
      },
      color: "#7AFFD7",
    },
    optionsLeft: {
      localization: {
        locale: "fr-FR",
      },
      lineWidth: 1,
      grid: {
        vertLines: {
          visible: false,
        },
        horzLines: {
          visible: false,
        },
      },
      timeScale: {
        barSpacing: 3,
        fixLeftEdge: true,
        lockVisibleTimeRangeOnResize: true,
        borderVisible: true,
        borderColor: "#7AFFD7",
        visible: true,
        timeVisible: true,
        secondsVisible: true,
      },
      layout: {
        backgroundColor: "#00000000",
        textColor: "#7AFFD7",
        fontSize: 12,
        fontFamily: "Calibri",
      },
      crosshair: {
        vertLine: {
          color: "#696969",
          width: 0.5,
          style: 1,
          visible: true,
          labelVisible: false,
        },
        horzLine: {
          color: "#696969",
          width: 0.5,
          style: 0,
          visible: true,
          labelVisible: true,
        },
        mode: 1,
      },
      handleScroll: {
        mouseWheel: false,
        pressedMouseMove: true,
      },
      handleScale: {
        axisPressedMouseMove: true,
        mouseWheel: false,
      },
      color: "#7AFFD7",
    },
  };

  async componentDidMount() {
    try {
      if (this.props.auth.isAuthenticated) {
        await this.getHistorique();
      } else {
        window.location.href = "/";
      }
    } catch (error) {
      console.log(error);
    }
  }

  //récupere l'historique complet du joueur et le stocke dans le state
  async getHistorique() {
    try {
      instanceAWS
        .post("/dev/gethistorique", { USERNAME: this.props.auth.user.username })
        .then((result) => {

          this.setState({ historique: result.data });
          this.setState({ nbparties: result.data.historique.length });

        })

        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error.message);
    }
  }

  async traitementShow(val) {
    document.getElementById("pseudoScoreSolo" + val).style.display = "none";
    document.getElementById("pseudoScore" + val).style.display = "block";

    document.getElementById("rankingRes" + val).style.height = "70vh";

    document.getElementById("pseudoScore" + val).style.height = "65vh";

    document.getElementById("posRes" + val).style.marginLeft = "13.5vw";
    document.getElementById("posRes" + val).style.marginRight = "5vw";
    document.getElementById("posRes" + val).style.marginTop = "5vh";

    document.getElementById("timesRes" + val).style.float = "right";
    document.getElementById("timesRes" + val).style.width = "10vw";
    document.getElementById("timesRes" + val).style.marginRight = "15vw";
    document.getElementById("timesRes" + val).style.marginTop = "10vh";
    //document.getElementById("timesRes"+val).style.marginLeft = "10vw";

    document.getElementById("resToShow" + val).style.display = "block";
    document.getElementById("resToHide" + val).style.display = "none";
  }

  async traitementHide(val) {
    document.getElementById("pseudoScoreSolo" + val).style.display = "block";
    document.getElementById("pseudoScore" + val).style.display = "none";

    document.getElementById("rankingRes" + val).style.height = "8.5vh";
    document.getElementById("pseudoScoreSolo" + val).style.height = "5";
    document.getElementById("rankingRes" + val).style.paddingBottom = "3vh";

    document.getElementById("posRes" + val).style.marginLeft = "12vw";
    document.getElementById("posRes" + val).style.marginRight = "5vw";
    document.getElementById("posRes" + val).style.marginTop = "1.5vh";

    document.getElementById("timesRes" + val).style.width = "100%";
    document.getElementById("timesRes" + val).style.float = "none";
    document.getElementById("timesRes" + val).style.marginLeft = "5vw";
    document.getElementById("timesRes" + val).style.marginTop = "1.5vh";

    document.getElementById("resToShow" + val).style.display = "none";
    document.getElementById("resToHide" + val).style.display = "block";
  }

  backRanking = (event) => {
    event.preventDefault();
    try {
      document.getElementById("rankingGeneral").style.display = "block";
      document.getElementById("playerView").style.display = "none";
    } catch (error) {
      console.log(error.message);
    }
  };

  goHisto = (event) => {
    event.preventDefault();
    try {
      document.getElementById("rankingGeneral").style.display = "block";
      document.getElementById("rankingC").style.display = "none";

      document.getElementById("tabRankClass").style.color = "grey";
      document.getElementById("tabRankHisto").style.color = "white";
    } catch (error) {
      console.log(error.message);
    }
  };

  goRanking = (event) => {
    event.preventDefault();
    try {
      this.getRanking();
      document.getElementById("rankingGeneral").style.display = "none";
      document.getElementById("rankingC").style.display = "block";
      document.getElementById("playerView").style.display = "none";

      document.getElementById("tabRankClass").style.color = "white";
      document.getElementById("tabRankHisto").style.color = "grey";
    } catch (error) {
      console.log(error.message);
    }
  };

  getRewards(pseudo) {
    try {
      instanceAWS
        .post("/dev/getrewards", { PSEUDO: pseudo })
        .then((response) => {
          const result = response.data;
          this.setState({ joueurembleme: result.SELECEMBLEME });
          this.setState({ joueurcadre: result.SELECCADRE });
          this.setState({ joueurcitation: result.SELECCITATION });
          this.setState({ joueurbordure: result.SELECBORDURE });
          this.setState({ joueurstat: result.SELECSTAT });
          this.setState({ joueurlvl: result.LVL });
          this.setState({ joueurcr: result.CR });

          var valRetour = getValFromCitation(this.state.joueurcitation);

          this.setState({ displaycitation: valRetour });
        })

        .catch((error) => console.log("error", error));

      instanceAWS
        .post("/dev/getstats", { PSEUDO: pseudo })
        .then((response) => {
          const result = response.data;
          this.setState({ stat1: result.NBTOURNOIS });
          this.setState({ stat2: result.MPLACE });
          this.setState({ stat3: result.MTRADE });
          this.setState({ stat4: result.MSCORE });
          this.setState({ stat5: result.WTRADE });
          this.setState({ stat6: result.NBPODIUM });
          this.setState({ stat7: result.NBTRADES });
          this.setState({ stat8: result.NBLIQUIDATIONS });
          this.setState({ stat9: result.BPLACE });
          this.setState({ stat10: result.NBVICTOIRE });
          this.setState({ stat11: result.MPALLIER });

          if (this.state.joueurstat !== 0) {
            var valRetour = "";
            if (this.state.joueurstat === "stat1") {
              valRetour = "Number of tournaments\n" + this.state.stat1;
            } else if (this.state.joueurstat === "stat2") {
              valRetour = "Best place\n" + this.state.stat2;
            } else if (this.state.joueurstat === "stat3") {
              valRetour = "Best trade\n" + this.state.stat3;
            } else if (this.state.joueurstat === "stat4") {
              valRetour = "Best score\n" + this.state.stat4;
            } else if (this.state.joueurstat === "stat5") {
              valRetour = "Worst trade\n" + this.state.stat5;
            } else if (this.state.joueurstat === "stat6") {
              valRetour = "Number of podiums\n" + this.state.stat6;
            } else if (this.state.joueurstat === "stat7") {
              valRetour = "Number of trades\n" + this.state.stat7;
            } else if (this.state.joueurstat === "stat8") {
              valRetour = "Number of liquidations\n" + this.state.stat8;
            } else if (this.state.joueurstat === "stat9") {
              valRetour = "Best leaderboard place\n" + this.state.stat9;
            } else if (this.state.joueurstat === "stat10") {
              valRetour = "Number of 1st places\n" + this.state.stat10;
            } else if (this.state.joueurstat === "stat11") {
              var retour = "";
              if(this.state.stat11 === 0) {
                retour = "nc"
              }
              if(this.state.stat11 === 5) {
                retour = "Aspirant"
              }
              if(this.state.stat11 === 4) {
                retour = "Silver"
              }
              if(this.state.stat11 === 3) {
                retour = "Gold"
              }
              if(this.state.stat11 === 2) {
                retour = "Diamond"
              }
              if(this.state.stat11 === 1) {
                retour = "Emerald"
              }
              valRetour = "Best league\n" + retour;
            }

            this.setState({ displaystat: valRetour });
          } else {
            this.getRewards(pseudo);
          }
        })

        .catch((error) => console.log("error", error));

      instanceAWS
        .post("/dev/getranking", { USERNAME: pseudo, MODE: "SOLO" })
        .then((result) => {
          //alert(ligue);joueurligue
          this.setState({ joueurligue: result.data.ligueUser });
        })

        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error.message);
    }
  }

  openProfile = (value) => (event) => {
    try {
      document.getElementById("rankingGeneral").style.display = "none";
      document.getElementById("rankingTab").style.display = "none";
      document.getElementById("partiesJouees").style.display = "none";
      document.getElementById("playerView").style.display = "block";

      this.setState({ joueurpseudo: value });
      this.getRewards(value);
    } catch (error) {
      console.log(error);
    }
  };

  openProfileRank = (value) => (event) => {
    try {
      document.getElementById("playerView").style.display = "block";

      this.setState({ joueurpseudo: value });
      this.getRewards(value);
    } catch (error) {
      console.log(error);
    }
  };

  async sleep(ms) {
    return new Promise((res) => setTimeout(res, ms));
  }

  handleClick = (value) => async (event) => {
    try {
      event.preventDefault();

      document.getElementById("ranking").style.cursor = "wait";
      var elemID = "swicherRanking"+value.id;
      document.getElementById(elemID).style.cursor = "wait";
      

      this.setState({ activePairLeft: "BTC" });

      var graphClicked = this.state.graphClicked;
      var rightID;
      var leftID

      if (graphClicked !== -1) {
        var rightIDClicked = "rankingGraphDroite" + graphClicked;
        var leftIDClicked = "rankingGraphGauche" + graphClicked;

        document.getElementById(rightIDClicked).style.height = "0vh";
        document.getElementById(leftIDClicked).style.height = "0vh";

        document.getElementById(rightIDClicked).style.display = "none";
        document.getElementById(leftIDClicked).style.display = "none";

        this.setState({ graphClicked: -1 });

        this.setState({ histoBTC: [] });
        this.setState({ histoETH: [] });
        this.setState({ histoSOL: [] });
        this.setState({ histoAVAX: [] });
        this.setState({ histoXRP: [] });
        this.setState({ histoDOGE: [] });
        this.setState({ histoMATIC: [] });
        this.setState({ histoLTC: [] });

        this.setState({ rightContentArray: [] });
      }
      if (graphClicked === value.id) {
        rightID = "rankingGraphDroite" + value.id;
        leftID = "rankingGraphGauche" + value.id;

        document.getElementById(rightID).style.height = "0vh";
        document.getElementById(leftID).style.height = "0vh";

        document.getElementById(rightID).style.display = "none";
        document.getElementById(leftID).style.display = "none";

        this.setState({ graphClicked: -1 });

        this.setState({ histoBTC: [] });
        this.setState({ histoETH: [] });
        this.setState({ histoSOL: [] });
        this.setState({ histoAVAX: [] });
        this.setState({ histoXRP: [] });
        this.setState({ histoDOGE: [] });
        this.setState({ histoMATIC: [] });
        this.setState({ histoLTC: [] });

        document.getElementById("ranking").style.cursor = "default";
        document.getElementById(elemID).style.cursor = "pointer";
        return;
      }

      rightID = "rankingGraphDroite" + value.id;
      leftID = "rankingGraphGauche" + value.id;

      var indice = value.id;
      var hDeb = value.hDeb;
      var duree = value.duree;
      var idTournament = value.idT;

      if (document.getElementById(rightID).style.height !== "40vh") {
        await this.returnLogsPlayer(
          this.props.auth.user.username,
          hDeb,
          duree,
          idTournament,
          indice
        );

        //Creation tableau (Long/Short)/Temps pour TW pour chaque asset.

        document.getElementById(rightID).style.height = "40vh";
        document.getElementById(leftID).style.height = "40vh";

        document.getElementById(rightID).style.display = "block";
        document.getElementById(leftID).style.display = "block";

        this.setState({ graphClicked: indice });

        
      }

      await this.sleep(1300);
      document.getElementById(elemID).style.cursor = "pointer";
      document.getElementById("ranking").style.cursor = "default";
      
    } catch (error) {
      console.log(error);
    }
  };

  displayGraphBTC(ind) {
    if (this.state.activePairLeft === "BTC") {
      if (
        JSON.stringify(this.state.histoBTC) !== "[]" &&
        this.state.leftContentArray1 !== 0
      ) {
        var markersArray = this.state.leftContentArray1;
        var historicalData = this.state.histoBTC;

        if (this.state.graphClicked === ind) {
          var width =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;

          var height =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight;

          width = (width * 39) / 100;
          height = (height * 33) / 100;

          for (var i = 0; i < historicalData.length; i++) {
            historicalData[i].value = Number(historicalData[i].value);
          }
          return (
            <TradingViewWidget
              markers={markersArray}
              data={historicalData}
              width={width}
              height={height}
            />
          );
        }
      }
    }
  }

  displayGraphETH(ind) {
    if (this.state.activePairLeft === "ETH") {
      if (
        JSON.stringify(this.state.histoETH) !== "[]" &&
        this.state.leftContentArray2 !== 0
      ) {
        var markersArray = this.state.leftContentArray2;
        var historicalData = this.state.histoETH;

        if (this.state.graphClicked === ind) {
          var width =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;

          var height =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight;

          width = (width * 39) / 100;
          height = (height * 33) / 100;

          for (var i = 0; i < historicalData.length; i++) {
            historicalData[i].value = Number(historicalData[i].value);
          }

          return (
            <TradingViewWidget
              markers={markersArray}
              data={historicalData}
              width={width}
              height={height}
            />
          );
        }
      }
    }
  }

  displayGraphSOL(ind) {
    if (this.state.activePairLeft === "SOL") {
      if (
        JSON.stringify(this.state.histoSOL) !== "[]" &&
        this.state.leftContentArray3 !== 0
      ) {
        var markersArray = this.state.leftContentArray3;
        var historicalData = this.state.histoSOL;

        if (this.state.graphClicked === ind) {
          var width =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;

          var height =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight;

          width = (width * 39) / 100;
          height = (height * 33) / 100;

          for (var i = 0; i < historicalData.length; i++) {
            historicalData[i].value = Number(historicalData[i].value);
          }

          return (
            <TradingViewWidget
              markers={markersArray}
              data={historicalData}
              width={width}
              height={height}
            />
          );
        }
      }
    }
  }

  displayGraphAVAX(ind) {
    if (this.state.activePairLeft === "AVAX") {
      if (
        JSON.stringify(this.state.histoAVAX) !== "[]" &&
        this.state.leftContentArray4 !== 0
      ) {
        var markersArray = this.state.leftContentArray4;
        var historicalData = this.state.histoAVAX;

        if (this.state.graphClicked === ind) {
          var width =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;

          var height =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight;

          width = (width * 39) / 100;
          height = (height * 33) / 100;

          for (var i = 0; i < historicalData.length; i++) {
            historicalData[i].value = Number(historicalData[i].value);
          }

          return (
            <TradingViewWidget
              markers={markersArray}
              data={historicalData}
              width={width}
              height={height}
            />
          );
        }
      }
    }
  }

  displayGraphXRP(ind) {
    if (this.state.activePairLeft === "XRP") {
      if (
        JSON.stringify(this.state.histoXRP) !== "[]" &&
        this.state.leftContentArray5 !== 0
      ) {
        var markersArray = this.state.leftContentArray5;
        var historicalData = this.state.histoXRP;

        if (this.state.graphClicked === ind) {
          var width =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;

          var height =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight;

          width = (width * 39) / 100;
          height = (height * 33) / 100;

          for (var i = 0; i < historicalData.length; i++) {
            historicalData[i].value = Number(historicalData[i].value);
          }

          return (
            <TradingViewWidget
              markers={markersArray}
              data={historicalData}
              width={width}
              height={height}
            />
          );
        }
      }
    }
  }

  displayGraphDOGE(ind) {
    if (this.state.activePairLeft === "DOGE") {
      if (
        JSON.stringify(this.state.histoDOGE) !== "[]" &&
        this.state.leftContentArray6 !== 0
      ) {
        var markersArray = this.state.leftContentArray6;
        var historicalData = this.state.histoDOGE;

        if (this.state.graphClicked === ind) {
          var width =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;

          var height =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight;

          width = (width * 39) / 100;
          height = (height * 33) / 100;

          for (var i = 0; i < historicalData.length; i++) {
            historicalData[i].value = Number(historicalData[i].value);
          }

          return (
            <TradingViewWidget
              markers={markersArray}
              data={historicalData}
              width={width}
              height={height}
            />
          );
        }
      }
    }
  }


  displayGraphMATIC(ind) {
    if (this.state.activePairLeft === "MATIC") {
      if (
        JSON.stringify(this.state.histoMATIC) !== "[]" &&
        this.state.leftContentArray7 !== 0
      ) {
        var markersArray = this.state.leftContentArray7;
        var historicalData = this.state.histoMATIC;

        if (this.state.graphClicked === ind) {
          var width =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;

          var height =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight;

          width = (width * 39) / 100;
          height = (height * 33) / 100;

          for (var i = 0; i < historicalData.length; i++) {
            historicalData[i].value = Number(historicalData[i].value);
          }

          return (
            <TradingViewWidget
              markers={markersArray}
              data={historicalData}
              width={width}
              height={height}
            />
          );
        }
      }
    }
  }

  displayGraphLTC(ind) {
    if (this.state.activePairLeft === "LTC") {
      if (
        JSON.stringify(this.state.histoLTC) !== "[]" &&
        this.state.leftContentArray8 !== 0
      ) {
        var markersArray = this.state.leftContentArray8;
        var historicalData = this.state.histoLTC;

        if (this.state.graphClicked === ind) {
          var width =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;

          var height =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight;

          width = (width * 39) / 100;
          height = (height * 33) / 100;

          for (var i = 0; i < historicalData.length; i++) {
            historicalData[i].value = Number(historicalData[i].value);
          }

          return (
            <TradingViewWidget
              markers={markersArray}
              data={historicalData}
              width={width}
              height={height}
            />
          );
        }
      }
    }
  }

  displayPNLGraph(indice) {
    if (
      JSON.stringify(this.state.rightContentArray) !== "[]" &&
      this.state.rightContentArray !== []
    ) {
      var width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      var height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

      width = (width * 25) / 100;
      height = (height * 33) / 100;

      var array = this.state.rightContentArray;

      for (var i = 0; i < array.length; i++) {
        array[i].value = Number(array[i].value);
      }

      /*
      return <span> {JSON.stringify(this.state.rightContentArray)} </span>;
      if(JSON.stringify(this.state.rightContentArray) !== "[]")*/

      //return <TradingViewWidget markers={[]}  data={this.state.rightContentArray} width={width} height={height}/>
      //return <Chart options={this.state.options} lineSeries={[{data: this.state.rightContentArray}]}  width={width} height={height}/>
      //alert(JSON.stringify(this.state.rightContentArray));
      return (
        <TradingViewWidgetMini
          indice={indice}
          data={this.state.rightContentArray}
          width={width}
          height={height}
        />
      );
      //return <span> ISOK </span>;
    }
  }

  //disply & color logs
  displayLogs() {
    var coloredLogs = [];
    if (this.state.rightContentLogs !== []) {
      for (var i = 0; i < this.state.rightContentLogs.length; i++) {
        var color = "white";

        if (this.state.rightContentLogs[i].includes("Long")) {
          color = "#5898f0";
        }
        if (this.state.rightContentLogs[i].includes("Short")) {
          color = "orange";
        }
        if (this.state.rightContentLogs[i].includes("Increase")) {
          for (var j = i; j >= 0; j--) {
            if(this.state.rightContentLogs[j].includes("Long")) {
              color = "#5898f0";
              break;
            }
            if(this.state.rightContentLogs[j].includes("Short")) {
              color = "orange";
              break;
            }
          }
        }
        if (this.state.rightContentLogs[i].includes("Decrease")) {
          for (var j = i; j >= 0; j--) {
            if(this.state.rightContentLogs[j].includes("Long")) {
              color = "orange";
              break;
            }
            if(this.state.rightContentLogs[j].includes("Short")) {
              color = "#5898f0";
              break;
            }
          }
        }
        if (this.state.rightContentLogs[i].includes("Close")) {
          for (var j = i; j >= 0; j--) {
            if(this.state.rightContentLogs[j].includes("Long")) {
              color = "orange";
              break;
            }
            if(this.state.rightContentLogs[j].includes("Short")) {
              color = "#5898f0";
              break;
            }
          }
        }
        if (this.state.rightContentLogs[i].includes("Liquidation")) {
          color = "#c060d6";
        }

        coloredLogs.push(
          <span style={{ color: color }} key={"dplog"+i}>
            {" "}
            {this.state.rightContentLogs[i]}{" "}
          </span>
        );
      }
    }

    return <span> {coloredLogs} </span>;
  }

  //graber des logs
  async returnLogsPlayer(pseudo, hDeb, duree, idTournament, indice) {
    this.getHistorical("BTCUSDT", hDeb, hDeb + duree, duree);
    this.getHistorical("ETHUSDT", hDeb, hDeb + duree, duree);
    this.getHistorical("SOLUSDT", hDeb, hDeb + duree, duree);
    this.getHistorical("AVAXUSDT", hDeb, hDeb + duree, duree);
    this.getHistorical("XRPUSDT", hDeb, hDeb + duree, duree);
    this.getHistorical("DOGEUSDT", hDeb, hDeb + duree, duree);
    this.getHistorical("MATICUSDT", hDeb, hDeb + duree, duree);
    this.getHistorical("LTCUSDT", hDeb, hDeb + duree, duree);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      pseudo: pseudo,
      hDeb: hDeb,
      duree: duree,
      idTournament: idTournament,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(apiURL + "/dev/getlogs", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        var parse = JSON.parse(result);

        if (parse.statusCode === 200 || parse.statusCode === "200") {
          this.setState({ rightContentLogs: parse.logs });
          this.setState({ rightContentArray: parse.pnlArray });

          this.setState({ leftContentArray1: parse.leftContentArrayBTC });
          this.setState({ leftContentArray2: parse.leftContentArrayETH });
          this.setState({ leftContentArray3: parse.leftContentArraySOL });
          this.setState({ leftContentArray4: parse.leftContentArrayAVAX });
          this.setState({ leftContentArray5: parse.leftContentArrayXRP });
          this.setState({ leftContentArray6: parse.leftContentArrayDOGE });
          this.setState({ leftContentArray7: parse.leftContentArrayMATIC });
          this.setState({ leftContentArray8: parse.leftContentArrayLTC });
        } else {
          this.setState({ rightContentLogs: [] });
          this.setState({ rightContentArray: [] });
        }

        //alert("upd");
      })

      .catch((error) => console.log("error", error));
  }

  closeProfile() {
    try {
      document.getElementById("playerView").style.display = "none";
    } catch (error) {
      console.log(error);
    }
  }

  getHistorical(pair, hDeb, hFin, duree) {
    var reso = "60";
    if(duree < 86400000) {
      reso = "1"
    }

    if(duree > 691200000) {
      reso = "120"
    }

    var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        PAIR: pair,
        RESOLUTION: reso,
        START: hDeb,
        END: hFin
      });


      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };


      fetch(apiURL + "/dev/getbars", requestOptions)
        .then((response) => response.text())
        .then((result) => {

          const data = JSON.parse(result);

          let bars = [];



          
          for (var i = 0; i < data.body.length; i++) {
            var tempBar = {
              time: data.body[i][0]/1000,
              value: data.body[i][4],
            };

            bars.push(tempBar);
          }



          if(pair === "BTCUSDT")
          {
            this.setState({ histoBTC: bars });
          }
          if(pair === "ETHUSDT")
          {
            this.setState({ histoETH: bars });
          }
          if(pair === "SOLUSDT")
          {
            this.setState({ histoSOL: bars });
          }
          if(pair === "AVAXUSDT")
          {
            this.setState({ histoAVAX: bars });
          }
          if(pair === "XRPUSDT")
          {
            this.setState({ histoXRP: bars });
          }
          if(pair === "DOGEUSDT")
          {
            this.setState({ histoDOGE: bars });
          }
          if(pair === "MATICUSDT")
          {
            this.setState({ histoMATIC: bars });
          }
          if(pair === "LTCUSDT")
          {
            this.setState({ histoLTC: bars });
          }
        });
  }

  goEmeraude = (event) => {
    try {
      event.preventDefault();
      document.getElementById("subEmeraude").style.display = "block";
      document.getElementById("subDiamant").style.display = "none";
      document.getElementById("subOr").style.display = "none";
      document.getElementById("subArgent").style.display = "none";
      document.getElementById("subAspirant").style.display = "none";

      document.getElementById("btEmeraude").style.backgroundColor = "grey";
      document.getElementById("btDiamant").style.backgroundColor = "lightGrey";
      document.getElementById("btOr").style.backgroundColor = "lightGrey";
      document.getElementById("btArgent").style.backgroundColor = "lightGrey";
      document.getElementById("btAspirant").style.backgroundColor = "lightGrey";
    } catch (error) {
      console.log(error);
    }
  };

  goDiamant = (event) => {
    try {
      event.preventDefault();
      document.getElementById("subEmeraude").style.display = "none";
      document.getElementById("subDiamant").style.display = "block";
      document.getElementById("subOr").style.display = "none";
      document.getElementById("subArgent").style.display = "none";
      document.getElementById("subAspirant").style.display = "none";

      document.getElementById("btEmeraude").style.backgroundColor = "lightGrey";
      document.getElementById("btDiamant").style.backgroundColor = "grey";
      document.getElementById("btOr").style.backgroundColor = "lightGrey";
      document.getElementById("btArgent").style.backgroundColor = "lightGrey";
      document.getElementById("btAspirant").style.backgroundColor = "lightGrey";
    } catch (error) {
      console.log(error);
    }
  };

  goOr = (event) => {
    try {
      event.preventDefault();
      document.getElementById("subEmeraude").style.display = "none";
      document.getElementById("subDiamant").style.display = "none";
      document.getElementById("subOr").style.display = "block";
      document.getElementById("subArgent").style.display = "none";
      document.getElementById("subAspirant").style.display = "none";

      document.getElementById("btEmeraude").style.backgroundColor = "lightGrey";
      document.getElementById("btDiamant").style.backgroundColor = "lightGrey";
      document.getElementById("btOr").style.backgroundColor = "grey";
      document.getElementById("btArgent").style.backgroundColor = "lightGrey";
      document.getElementById("btAspirant").style.backgroundColor = "lightGrey";
    } catch (error) {
      console.log(error);
    }
  };

  goArgent = (event) => {
    try {
      event.preventDefault();
      document.getElementById("subEmeraude").style.display = "none";
      document.getElementById("subDiamant").style.display = "none";
      document.getElementById("subOr").style.display = "none";
      document.getElementById("subArgent").style.display = "block";
      document.getElementById("subAspirant").style.display = "none";

      document.getElementById("btEmeraude").style.backgroundColor = "lightGrey";
      document.getElementById("btDiamant").style.backgroundColor = "lightGrey";
      document.getElementById("btOr").style.backgroundColor = "lightGrey";
      document.getElementById("btArgent").style.backgroundColor = "grey";
      document.getElementById("btAspirant").style.backgroundColor = "lightGrey";
    } catch (error) {
      console.log(error);
    }
  };

  goAspirant = (event) => {
    try {
      event.preventDefault();
      document.getElementById("subEmeraude").style.display = "none";
      document.getElementById("subDiamant").style.display = "none";
      document.getElementById("subOr").style.display = "none";
      document.getElementById("subArgent").style.display = "none";
      document.getElementById("subAspirant").style.display = "block";

      document.getElementById("btEmeraude").style.backgroundColor = "lightGrey";
      document.getElementById("btDiamant").style.backgroundColor = "lightGrey";
      document.getElementById("btOr").style.backgroundColor = "lightGrey";
      document.getElementById("btArgent").style.backgroundColor = "lightGrey";
      document.getElementById("btAspirant").style.backgroundColor = "grey";
    } catch (error) {
      console.log(error);
    }
  };
  getRanking() {
    try {
      instanceAWS
        .post("/dev/getranking", { USERNAME: this.props.auth.user.username })
        .then((response) => {
          const result = response.data;
          this.setState({ nbtotaljoueurs: result.nbJoueurs });
          this.setState({ resultatranking: result.resultats });

          this.setState({
            resultatsDiamant: result.resultatsDiamant,
          });
          this.setState({
            resultatsEmeraude: result.resultatsEmeraude,
          });
          this.setState({ resultatsOr: result.resultatsOr });
          this.setState({
            resultatsArgent: result.resultatsArgent,
          });
          this.setState({
            resultatsAspirant: result.resultatsAspirant,
          });

          this.setState({ nbEmeraude: this.state.resultatsEmeraude.length });

          this.setState({ nbDiamant: this.state.resultatsDiamant.length });
          this.setState({ nbOr: this.state.resultatsOr.length });
          this.setState({ nbArgent: this.state.resultatsArgent.length });
          this.setState({ nbAspirant: this.state.resultatsAspirant.length });

          this.setState({ ligueuser: result.ligueUser });

          if (this.state.ligueuser === "Emeraude") {
            document.getElementById("subEmeraude").style.display = "block";
            document.getElementById("btEmeraude").style.backgroundColor =
              "grey";
            document.getElementById("resumeACacher").style.display = "block";
          } else if (this.state.ligueuser === "Diamant") {
            document.getElementById("subDiamant").style.display = "block";
            document.getElementById("btDiamant").style.backgroundColor = "grey";
            document.getElementById("resumeACacher").style.display = "block";
            this.setState({
              evenement:
                "Cashprize perso.: " +
                Number.parseFloat(500 / this.state.nbDiamant).toFixed(2) +
                "$",
            });
            //document.getElementById("eventRanking").style.display = "block";
          } else if (this.state.ligueuser === "Or") {
            document.getElementById("subOr").style.display = "block";
            document.getElementById("btOr").style.backgroundColor = "grey";
            document.getElementById("resumeACacher").style.display = "block";
            this.setState({ evenement: "Passez Diamant pour remporter des $" });
          } else if (this.state.ligueuser === "Argent") {
            document.getElementById("subArgent").style.display = "block";
            document.getElementById("btArgent").style.backgroundColor = "grey";
            document.getElementById("resumeACacher").style.display = "block";
            this.setState({ evenement: "Passez Diamant pour remporter des $" });
          } else if (this.state.ligueuser === "Aspirant") {
            document.getElementById("subAspirant").style.display = "block";
            document.getElementById("btAspirant").style.backgroundColor =
              "grey";
            document.getElementById("resumeACacher").style.display = "block";
            this.setState({ evenement: "Passez Diamant pour remporter des $" });
          } else if (this.state.ligueuser === "") {
          }

          this.setState({ indiceJoueur: result.indiceUser });

          this.setState({ ratingPerso: result.coteUser });

          document.getElementById("chargement").style.display = "none";

          if (this.state.resultatsEmeraude !== 0) {
            var podium1 = [];
            var podium2 = [];
            var podium3 = [];

            instanceAWS
              .post("/dev/getrewards", {
                PSEUDO: this.state.resultatsEmeraude[0].joueur,
              })

              .then((result) => {
                podium1.push({
                  embleme: result.data.SELECEMBLEME,
                  cadre: result.data.SELECCADRE,
                  bordure: result.data.SELECBORDURE,
                });
                this.setState({ podium1: podium1 });
              })
              .catch((error) => console.log("error", error));

            instanceAWS
              .post("/dev/getrewards", {
                PSEUDO: this.state.resultatsEmeraude[1].joueur,
              })
              .then((result) => {
                podium2.push({
                  embleme: result.data.SELECEMBLEME,
                  cadre: result.data.SELECCADRE,
                  bordure: result.data.SELECBORDURE,
                });
                this.setState({ podium2: podium2 });
              })
              .catch((error) => console.log("error", error));

            instanceAWS
              .post("/dev/getrewards", {
                PSEUDO: this.state.resultatsEmeraude[2].joueur,
              })
              .then((result) => {
                podium3.push({
                  embleme: result.data.SELECEMBLEME,
                  cadre: result.data.SELECCADRE,
                  bordure: result.data.SELECBORDURE,
                });
                this.setState({ podium3: podium3 });
              })
              .catch((error) => console.log("error", error));
          }
        })

        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error.message);
    }
  }

  tpsRestant() {
    var end = Date.parse("30 Nov 2021 21:00:00 GMT+3:00");


    var heure = Date.now();

    var timer = new Date();
    var endTime = Number(end) - Number(heure);

    timer.setTime(endTime);


    return (
      <span>
        {" "}
        {timer.getDate() -
          1 +
          "j " +
          timer.getHours() +
          "h " +
          timer.getMinutes() +
          "min "}{" "}
      </span>
    );
  }

  /*
[{"hdeb":"999","duree":"666","score":[900,12],"pseudo":["bazoukiwi","luchap"],"id_tournament":"ID001"}]
*/
  //
  getAltArray(user) {
    var tab = this.state.altArray

    for (var i = 0; i < tab.length; i++) {
      if(tab[i].user === user) {
        return tab[i].alt
      }
    }
    return "";
  }
 
  getAlt(parse) {
    try {
      let usernameArray = [];

      for (let i = 0; i < parse.length; i++) {
        for (let j = 0; j < parse[i].pseudo.length; j++) {
          if(parse[i].pseudo[j].match(truncateRegex) && !usernameArray.includes(parse[i].pseudo[j])) {
            usernameArray.push(parse[i].pseudo[j]);
          }
        }
      }

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({ USERNAME: usernameArray, ISARRAY: true });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };


      fetch(apiURL + "/dev/getalt", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          let arrayTmp = this.state.altArray;

          for (var i = 0; i < JSON.parse(result).ALTS.length; i++) {
            arrayTmp.push(JSON.parse(result).ALTS[i]);
          }

          this.setState({ altArray: arrayTmp });
        });
    } catch (error) {
      console.log(error);
    }
  }

  //ok
  returnHistorique() {
    var ListElement = [];
    var ListScore = [];
    var scorePerso = 0;
    var placePerso = 0;

    var parse = this.state.historique.historique;

    if ( parse !== undefined) {
      parse = this.state.historique.historique;

      this.getAlt(parse);
      

      for (var i = 0; i < parse.length; i++) {
        ListScore = [];
        var dateDeb = new Date();
        dateDeb.setTime(parse[i].hdeb);
        var duree = parse[i].duree;



        for (var j = 0; j < parse[i].pseudo.length; j++) {
          var cssPlace = "";
          if (j === 0) {
            cssPlace = "classPrem";
          } else if (j === 1) {
            cssPlace = "classSec";
          } else if (j === 2) {
            cssPlace = "classTrois";
          }
          ListScore.push(
            <div className={cssPlace} key={"rankingJlevel"+j}>
              <span className="listScorePlace">{j + 1}.</span>
              <span
                className="listScorePseudo"
                onClick={this.openProfileRank(parse[i].pseudo[j])}
              >
                { this.getAltArray(parse[i].pseudo[j]) === ""
                    ? (parse[i].pseudo[j].match(truncateRegex)
                      ? truncateEthAddress(parse[i].pseudo[j])
                      : parse[i].pseudo[j] )
                    : this.getAltArray(parse[i].pseudo[j])
                }
              </span>
              <span className="listScoreScore">
                {Number.parseFloat(parse[i].score[j]).toFixed(2)}
              </span>
            </div>
          );
          if (parse[i].pseudo[j] === this.props.auth.user.username) {
            placePerso = j + 1;
            scorePerso = Number.parseFloat(parse[i].score[j]).toFixed(2);
          }
        }

        var dureeAffichee = duree / 1000 / 60 / 60 / 24; //en jours !
        if (dureeAffichee < 1) {
          dureeAffichee = dureeAffichee * 24 * 60;
          dureeAffichee = dureeAffichee + " mins";
        } else {
          dureeAffichee = Number.parseFloat(dureeAffichee).toFixed(0)  + " days";
        }

        ListElement.push(
          <div key={"rankingTopLevel"+i}>
            <div className="blocResultatsComplet">
              <div className="percent22">
                <div className="dateDureeResults">
                  {dateDeb.getDate() +
                    "." +
                    Number(dateDeb.getMonth() + 1) +
                    "." +
                    dateDeb.getFullYear()}{" "}
                  <br />
                  {dureeAffichee}
                </div>
                <div className="contenerScores">{ListScore}</div>
              </div>
              <div className="rankingGraphGauche" id={"rankingGraphGauche" + i}>
                <div className="bandeauRankingDroite">
                  <span
                    className="bandeauRankingLeftLogs"
                    onClick={this.handleLeftGraph({ id: i, mode: "BTC" })}
                  >
                    Bitcoin
                  </span>
                  <span
                    className="bandeauRankingLeftLogs"
                    onClick={this.handleLeftGraph({ id: i, mode: "ETH" })}
                  >
                    Ethereum
                  </span>
                  <span
                    className="bandeauRankingLeftLogs"
                    onClick={this.handleLeftGraph({ id: i, mode: "SOL" })}
                  >
                    Solana
                  </span>
                  <span
                    className="bandeauRankingLeftLogs"
                    onClick={this.handleLeftGraph({ id: i, mode: "AVAX" })}
                  >
                    Avalanche
                  </span>
                  <span
                    className="bandeauRankingLeftLogs"
                    onClick={this.handleLeftGraph({ id: i, mode: "XRP" })}
                  >
                    Ripple
                  </span>
                  <span
                    className="bandeauRankingLeftLogs"
                    onClick={this.handleLeftGraph({ id: i, mode: "DOGE" })}
                  >
                    Dogecoin
                  </span>
                  <span
                    className="bandeauRankingLeftLogs"
                    onClick={this.handleLeftGraph({ id: i, mode: "MATIC" })}
                  >
                    Matic
                  </span>
                  <span
                    className="bandeauRankingLeftLogs"
                    onClick={this.handleLeftGraph({ id: i, mode: "LTC" })}
                  >
                    Litecoin
                  </span>
                </div>
                <div
                  className="rankingGraphLeftGraph"
                  style={{ display: "block" }}
                  id={"rankingGraphLeftGraphBTC" + i}
                >
                  {this.displayGraphBTC(i)}
                </div>
                <div
                  className="rankingGraphLeftGraph"
                  id={"rankingGraphLeftGraphETH" + i}
                >
                  {this.displayGraphETH(i)}
                </div>
                <div
                  className="rankingGraphLeftGraph"
                  id={"rankingGraphLeftGraphSOL" + i}
                >
                  {this.displayGraphSOL(i)}
                </div>
                <div
                  className="rankingGraphLeftGraph"
                  id={"rankingGraphLeftGraphAVAX" + i}
                >
                  {this.displayGraphAVAX(i)}
                </div>
                <div
                  className="rankingGraphLeftGraph"
                  id={"rankingGraphLeftGraphXRP" + i}
                >
                  {this.displayGraphXRP(i)}
                </div>
                <div
                  className="rankingGraphLeftGraph"
                  id={"rankingGraphLeftGraphDOGE" + i}
                >
                  {this.displayGraphDOGE(i)}
                </div>
                <div
                  className="rankingGraphLeftGraph"
                  id={"rankingGraphLeftGraphMATIC" + i}
                >
                  {this.displayGraphMATIC(i)}
                </div>
                <div
                  className="rankingGraphLeftGraph"
                  id={"rankingGraphLeftGraphLTC" + i}
                >
                  {this.displayGraphLTC(i)}
                </div>
              </div>
            </div>
            <div
              className="blocResultatsCompletRg"
              id={"blocResultatsCompletRg" + i}
            >
              <div className="percent21">
                <div className="placePerso">You finished :</div>
                <div className="classPrem placePersoValue">{placePerso}.</div>
                <div className="ScorePerso">
                  Score :<b className="ScorePersoValue"> {scorePerso} </b>
                </div>
                <span
                  className="swicherRanking"
                  id={"swicherRanking"+i}
                  onClick={this.handleClick({
                    id: i,
                    hDeb: parse[i].hdeb,
                    duree: duree,
                    idT: parse[i].id_tournament,
                  })}
                >
                  More data
                </span>
              </div>
              <div className="rankingGraphDroite" id={"rankingGraphDroite" + i}>
                <div className="bandeauRankingDroite">
                  <span
                    className="bandeauRankingDroiteLogs"
                    onClick={this.handleRightGraph({ id: i, mode: "l" })}
                  >
                    Text
                  </span>
                  <span
                    className="bandeauRankingDroiteGraph"
                    onClick={this.handleRightGraph({ id: i, mode: "g" })}
                  >
                    Chart
                  </span>
                </div>
                <div
                  className="rankingGraphDroiteLogs"
                  id={"rankingGraphDroiteLogs" + i}
                >
                  {this.displayLogs()}
                </div>
                <div
                  className="rankingGraphDroiteGraph"
                  id={"rankingGraphDroiteGraph" + i}
                >
                  {this.displayPNLGraph(i)}
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    return <span> {ListElement.reverse()} </span>;
  }
  //
  handleRightGraph = (value) => async (event) => {
    try {
      event.preventDefault();

      var id = value.id;
      var mode = value.mode;
      var idGraph;
      var idLogs

      if (mode === "g") {
        idGraph = "rankingGraphDroiteGraph" + id;
        idLogs = "rankingGraphDroiteLogs" + id;

        document.getElementById(idGraph).style.display = "block";
        document.getElementById(idLogs).style.display = "none";
      }

      if (mode === "l") {
        idGraph = "rankingGraphDroiteGraph" + id;
        idLogs = "rankingGraphDroiteLogs" + id;

        document.getElementById(idGraph).style.display = "none";
        document.getElementById(idLogs).style.display = "block";
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleLeftGraph = (value) => async (event) => {
    try {
      event.preventDefault();

      var id = value.id;
      var mode = value.mode;

      var idBTC;
      var idETH;
      var idSOL;
      var idAVAX;
      var idXRP;
      var idDOGE;
      var idMATIC;
      var idLTC;


        idBTC = "rankingGraphLeftGraphBTC" + id;
        idETH = "rankingGraphLeftGraphETH" + id;
        idSOL = "rankingGraphLeftGraphSOL" + id;
        idAVAX = "rankingGraphLeftGraphAVAX" + id;
        idXRP = "rankingGraphLeftGraphXRP" + id;
        idDOGE = "rankingGraphLeftGraphDOGE" + id;
        idMATIC = "rankingGraphLeftGraphMATIC" + id;
        idLTC = "rankingGraphLeftGraphLTC" + id;

      if (mode === "BTC") {

        document.getElementById(idBTC).style.display = "block";
        document.getElementById(idETH).style.display = "none";
        document.getElementById(idSOL).style.display = "none";
        document.getElementById(idAVAX).style.display = "none";
        document.getElementById(idXRP).style.display = "none";
        document.getElementById(idDOGE).style.display = "none";
        document.getElementById(idMATIC).style.display = "none";
        document.getElementById(idLTC).style.display = "none";
      }

      if (mode === "ETH") {

        document.getElementById(idBTC).style.display = "none";
        document.getElementById(idETH).style.display = "block";
        document.getElementById(idSOL).style.display = "none";
        document.getElementById(idAVAX).style.display = "none";
        document.getElementById(idXRP).style.display = "none";
        document.getElementById(idDOGE).style.display = "none";
        document.getElementById(idMATIC).style.display = "none";
        document.getElementById(idLTC).style.display = "none";
      }

      if (mode === "SOL") {

        document.getElementById(idBTC).style.display = "none";
        document.getElementById(idETH).style.display = "none";
        document.getElementById(idSOL).style.display = "block";
        document.getElementById(idAVAX).style.display = "none";
        document.getElementById(idXRP).style.display = "none";
        document.getElementById(idDOGE).style.display = "none";
        document.getElementById(idMATIC).style.display = "none";
        document.getElementById(idLTC).style.display = "none";
      }

      if (mode === "AVAX") {

        document.getElementById(idBTC).style.display = "none";
        document.getElementById(idETH).style.display = "none";
        document.getElementById(idSOL).style.display = "none";
        document.getElementById(idAVAX).style.display = "block";
        document.getElementById(idXRP).style.display = "none";
        document.getElementById(idDOGE).style.display = "none";
        document.getElementById(idMATIC).style.display = "none";
        document.getElementById(idLTC).style.display = "none";
      }

      if (mode === "XRP") {

        document.getElementById(idBTC).style.display = "none";
        document.getElementById(idETH).style.display = "none";
        document.getElementById(idSOL).style.display = "none";
        document.getElementById(idAVAX).style.display = "none";
        document.getElementById(idXRP).style.display = "block";
        document.getElementById(idDOGE).style.display = "none";
        document.getElementById(idMATIC).style.display = "none";
        document.getElementById(idLTC).style.display = "none";
      }

      if (mode === "DOGE") {

        document.getElementById(idBTC).style.display = "none";
        document.getElementById(idETH).style.display = "none";
        document.getElementById(idSOL).style.display = "none";
        document.getElementById(idAVAX).style.display = "none";
        document.getElementById(idXRP).style.display = "none";
        document.getElementById(idDOGE).style.display = "block";
        document.getElementById(idMATIC).style.display = "none";
        document.getElementById(idLTC).style.display = "none";
      }

      if (mode === "MATIC") {

        document.getElementById(idBTC).style.display = "none";
        document.getElementById(idETH).style.display = "none";
        document.getElementById(idSOL).style.display = "none";
        document.getElementById(idAVAX).style.display = "none";
        document.getElementById(idXRP).style.display = "none";
        document.getElementById(idDOGE).style.display = "none";
        document.getElementById(idMATIC).style.display = "block";
        document.getElementById(idLTC).style.display = "none";
      }

      if (mode === "LTC") {

        document.getElementById(idBTC).style.display = "none";
        document.getElementById(idETH).style.display = "none";
        document.getElementById(idSOL).style.display = "none";
        document.getElementById(idAVAX).style.display = "none";
        document.getElementById(idXRP).style.display = "none";
        document.getElementById(idDOGE).style.display = "none";
        document.getElementById(idMATIC).style.display = "none";
        document.getElementById(idLTC).style.display = "block";
      }

      this.setState({ activePairLeft: mode });
    } catch (error) {
      console.log(error);
    }
  };

  //ok
  displayPodium() 
  {
    var displayer = [];

    if(this.state.resultatsEmeraude !== 0 && 
      this.state.podium1.length !== 0 &&
      this.state.podium2.length !== 0 &&
      this.state.podium3.length !== 0)
    {
      displayer.push(<> 
        <div className="podiumAnoucement">
          <div className="tiers preums">
            1st
          </div>
          <div className="tiers deus">
            2nd
          </div>
          <div className="tiers trois">
            3nd
          </div>
        </div>
        <div className="premierPodium"> 
          <img
            className="cadrePodium1"
            src={".././images/rewards/cadres/"+this.state.podium1[0].cadre+".png"}
            alt="cadre"
          />
          <img
            className="emblemePodium1"
            src={".././images/rewards/emblemes/"+this.state.podium1[0].embleme+".png"}
            alt="embleme"
          />
          <img
            className="bordurePodium1"
            src={".././images/rewards/bordures/"+this.state.podium1[0].bordure+".png"}
            alt=""
          />
          <div className="textePodium1"> 
            <span
              style={{cursor: 'pointer'}}
              onClick={this.openProfileRank(
                this.state.resultatsEmeraude[0].joueur
              )}
            >
              { this.getAltArray(this.state.resultatsEmeraude[0].joueur) === ""
                      ? (this.state.resultatsEmeraude[0].joueur.match(truncateRegex)
                        ? truncateEthAddress(this.state.resultatsEmeraude[0].joueur)
                        : this.state.resultatsEmeraude[0].joueur )
                      : this.getAltArray(this.state.resultatsEmeraude[0].joueur)
              }
            </span>
            <br/>
            {this.state.resultatsEmeraude[0].rating}  
           </div>
        </div>
        <div className="premierPodium"> 
          <img
            className="cadrePodium1"
            src={".././images/rewards/cadres/"+this.state.podium2[0].cadre+".png"}
            alt="cadre"
          />
          <img
            className="emblemePodium1"
            src={".././images/rewards/emblemes/"+this.state.podium2[0].embleme+".png"}
            alt="embleme"
          />
          <img
            className="bordurePodium1"
            src={".././images/rewards/bordures/"+this.state.podium2[0].bordure+".png"}
            alt=""
          />
          <div className="textePodium1"> 
            <span
              style={{cursor: 'pointer'}}
              onClick={this.openProfileRank(
                this.state.resultatsEmeraude[1].joueur
              )}
            >
              { this.getAltArray(this.state.resultatsEmeraude[1].joueur) === ""
                      ? (this.state.resultatsEmeraude[1].joueur.match(truncateRegex)
                        ? truncateEthAddress(this.state.resultatsEmeraude[1].joueur)
                        : this.state.resultatsEmeraude[1].joueur )
                      : this.getAltArray(this.state.resultatsEmeraude[1].joueur)
              }
            </span>
            <br/>
            {this.state.resultatsEmeraude[1].rating}  
           </div>
        </div>
        <div className="premierPodium"> 
          <img
            className="cadrePodium1"
            src={".././images/rewards/cadres/"+this.state.podium3[0].cadre+".png"}
            alt="cadre"
          />
          <img
            className="emblemePodium1"
            src={".././images/rewards/emblemes/"+this.state.podium3[0].embleme+".png"}
            alt="embleme"
          />
          <img
            className="bordurePodium1"
            src={".././images/rewards/bordures/"+this.state.podium3[0].bordure+".png"}
            alt=""
          />
          <div className="textePodium1"> 
            <span
              style={{cursor: 'pointer'}}
              onClick={this.openProfileRank(
                this.state.resultatsEmeraude[2].joueur
              )}
            >
              { this.getAltArray(this.state.resultatsEmeraude[2].joueur) === ""
                      ? (this.state.resultatsEmeraude[2].joueur.match(truncateRegex)
                        ? truncateEthAddress(this.state.resultatsEmeraude[2].joueur)
                        : this.state.resultatsEmeraude[2].joueur )
                      : this.getAltArray(this.state.resultatsEmeraude[2].joueur)
              }
            </span>
            <br/>
            {this.state.resultatsEmeraude[2].rating} 
           </div>
        </div>
      </>);

    }
 

    //
    return displayer
  }

  render() {
    var u = 0;

    if (this.state.resultatsDiamant != null) {
      var ListEmeraude = [];
      var ListDiamant = [];
      var ListOr = [];
      var ListArgent = [];
      var ListAspirant = [];

      for (u = 0; u < this.state.nbEmeraude; u++) {
        if (u < 3) {
        } else {
          ListEmeraude.push(
            <>
              <span className="ligneResultat" key={"lignres" + u}>
                <span className="placeResultat">{u + 1}.</span>
                <span
                  style={{cursor: "pointer"}}
                  onClick={this.openProfileRank(
                    this.state.resultatsEmeraude[u].joueur
                  )}
                >
                  <span className="ligneResultatPseudo">
                    <img
                      className="ppLink2"
                      src={".././images/profil.png"}
                      alt="-profil public"
                    />
                    { this.getAltArray(this.state.resultatsEmeraude[u].joueur) === ""
                      ? (this.state.resultatsEmeraude[u].joueur.match(truncateRegex)
                        ? truncateEthAddress(this.state.resultatsEmeraude[u].joueur)
                        : this.state.resultatsEmeraude[u].joueur )
                      : this.getAltArray(this.state.resultatsEmeraude[u].joueur)
                    }
                  </span>
                </span>
                <span className="ligneResultatScore">
                  {this.state.resultatsEmeraude[u].rating}
                </span>
              </span>
              <br />
            </>
          );
        }
      }

      for (u = 0; u < this.state.nbDiamant; u++) {
        ListDiamant.push(
          <>
            <span className="ligneResultat" key={"lignres" + u}>
              <span className="placeResultat">
                {this.state.nbEmeraude + u + 1}.
              </span>
              <span
                  style={{cursor: "pointer"}}
                onClick={this.openProfileRank(
                  this.state.resultatsDiamant[u].joueur
                )}
              >
                <span className="ligneResultatPseudo">
                  <img
                    className="ppLink2"
                    src={".././images/profil.png"}
                    alt="-profil public"
                  />
                  { this.getAltArray(this.state.resultatsDiamant[u].joueur) === ""
                      ? (this.state.resultatsDiamant[u].joueur.match(truncateRegex)
                        ? truncateEthAddress(this.state.resultatsDiamant[u].joueur)
                        : this.state.resultatsDiamant[u].joueur )
                      : this.getAltArray(this.state.resultatsDiamant[u].joueur)
                    }
                </span>
              </span>
              <span className="ligneResultatScore">
                {this.state.resultatsDiamant[u].rating}
              </span>
            </span>
            <br />
          </>
        );
      }

      for (u = 0; u < this.state.nbOr; u++) {
        ListOr.push(
          <>
            <span className="ligneResultat" key={"lignres" + u}>
              <span className="placeResultat">
                {this.state.nbDiamant + u + 1}
              </span>
              <span
                  style={{cursor: "pointer"}}
                onClick={this.openProfileRank(this.state.resultatsOr[u].joueur)}
              >
                <span className="ligneResultatPseudo">
                  <img
                    className="ppLink2"
                    src={".././images/profil.png"}
                    alt="-profil public"
                  />
                  { this.getAltArray(this.state.resultatsOr[u].joueur) === ""
                      ? (this.state.resultatsOr[u].joueur.match(truncateRegex)
                        ? truncateEthAddress(this.state.resultatsOr[u].joueur)
                        : this.state.resultatsOr[u].joueur )
                      : this.getAltArray(this.state.resultatsOr[u].joueur)
                    }
                </span>
              </span>
              <span className="ligneResultatScore">
                {this.state.resultatsOr[u].rating}
              </span>
            </span>
            <br />
          </>
        );
      }

      for (u = 0; u < this.state.nbArgent; u++) {
        ListArgent.push(
          <>
            <span className="ligneResultat" key={"lignres" + u}>
              <span className="placeResultat">
                {this.state.nbDiamant + this.state.nbOr + u + 1}
              </span>
              <span
                  style={{cursor: "pointer"}}
                onClick={this.openProfileRank(
                  this.state.resultatsArgent[u].joueur
                )}
              >
                <span className="ligneResultatPseudo">
                  <img
                    className="ppLink2"
                    src={".././images/profil.png"}
                    alt="-profil public"
                  />
                  { this.getAltArray(this.state.resultatsArgent[u].joueur) === ""
                      ? (this.state.resultatsArgent[u].joueur.match(truncateRegex)
                        ? truncateEthAddress(this.state.resultatsArgent[u].joueur)
                        : this.state.resultatsArgent[u].joueur )
                      : this.getAltArray(this.state.resultatsArgent[u].joueur)
                    }
                </span>
              </span>
              <span className="ligneResultatScore">
                {this.state.resultatsArgent[u].rating}
              </span>
            </span>
            <br />
          </>
        );
      }
      for (u = 0; u < this.state.nbAspirant; u++) {
        ListAspirant.push(
          <>
            <span className="ligneResultat" key={"lignres" + u}>
              <span className="placeResultat">
                {this.state.nbArgent +
                  this.state.nbDiamant +
                  this.state.nbOr +
                  u +
                  1}
              </span>
              <span
                  style={{cursor: "pointer"}}
                onClick={this.openProfileRank(
                  this.state.resultatsAspirant[u].joueur
                )}
              >
                <span className="ligneResultatPseudo">
                  <img
                    className="ppLink2"
                    src={".././images/profil.png"}
                    alt="-profil public"
                  />
                  { this.getAltArray(this.state.resultatsAspirant[u].joueur) === ""
                      ? (this.state.resultatsAspirant[u].joueur.match(truncateRegex)
                        ? truncateEthAddress(this.state.resultatsAspirant[u].joueur)
                        : this.state.resultatsAspirant[u].joueur )
                      : this.getAltArray(this.state.resultatsAspirant[u].joueur)
                    }
                </span>
              </span>
              <span className="ligneResultatScore">
                {this.state.resultatsAspirant[u].rating}
              </span>
            </span>
            <br />
          </>
        );
      }
    }

    return (
      <>
        <section className="ranking" id="ranking">
          <div className="rankingTab" id="rankingTab">
            <span onClick={this.goHisto} className="tabRank" id="tabRankHisto">
              <img src={iconhisto} className="histImg" alt="" />
              History
            </span>
            <span
              onClick={this.goRanking}
              className="tabRank"
              id="tabRankClass"
            >
              <img src={iconclass} className="histImg" alt="" />
              Leaderboard
            </span>
          </div>

          <div className="partiesJouees" id="partiesJouees">
            Games played:{this.state.nbparties}
          </div>

          <div id="rankingGeneral" className="rankingGeneral">
            <div className="renderRanking">{this.returnHistorique()}</div>
          </div>
          <div
            id="rankingC"
            className="rankingGeneral"
            style={{ display: "none" }}
          >
            <div className="classementResume">
              <div id="resumeACacher" style={{ display: "none" }}>
                <img
                  className="ligueRank"
                  src={".././images/" + this.state.ligueuser + ".png"}
                  alt="ligue"
                />
                You are: <br />
                <div
                  className="buttonsTournament"
                  style={{
                    width: "50%",
                    padding: "1px",
                    fontSize: "2vw",
                    marginLeft: "24%",
                  }}
                >
                  {" "}
                  {this.state.indiceJoueur + 1}{" "}
                </div>
                <div className="ofPlayer">
                  {" "}
                  Over {this.state.nbtotaljoueurs} players{" "}
                </div>
                Rating: {this.state.ratingPerso} <br />
              </div>
            </div>

            <div className="classementResultats">
              <div className="btsChoixLigue">
                <span id="btEmeraude" onClick={this.goEmeraude}>
                  {" "}
                  Emerald{" "}
                </span>
                <span id="btDiamant" onClick={this.goDiamant}>
                  {" "}
                  Diamond{" "}
                </span>
                <span id="btOr" onClick={this.goOr}>
                  {" "}
                  Gold{" "}
                </span>
                <span id="btArgent" onClick={this.goArgent}>
                  {" "}
                  Silver{" "}
                </span>
                <span id="btAspirant" onClick={this.goAspirant}>
                  {" "}
                  Aspirant{" "}
                </span>
                <span id="chargement">
                  {" "}
                  <br />
                  <br />
                  <br /> Chargement...{" "}
                </span>
              </div>
              <div
                id="subEmeraude"
                className="subLigue"
                style={{ display: "none" }}
              >
                <div className="podiumDisplayer">{this.displayPodium()}</div>
                {ListEmeraude}
              </div>
              <div
                id="subDiamant"
                className="subLigue"
                style={{ display: "none" }}
              >
                {ListDiamant}
              </div>

              <div id="subOr" className="subLigue" style={{ display: "none" }}>
                {ListOr}
              </div>

              <div
                id="subArgent"
                className="subLigue"
                style={{ display: "none" }}
              >
                {ListArgent}
              </div>

              <div
                id="subAspirant"
                className="subLigue"
                style={{ display: "none" }}
              >
                {ListAspirant}
              </div>
            </div>
          </div>

          <div id="playerView" className="playerView">
            <div className="pvContain">
              <div className="pvImages2">
                <div className="">
                  <img
                    className="imgCadreRank"
                    src={".././images/rewards/cadres/" + this.state.joueurcadre + ".png"}
                    alt="cadre"
                  />
                  <img
                    className="imgEmblemeRank"
                    src={".././images/rewards/emblemes/" + this.state.joueurembleme + ".png"}
                    alt="embleme"
                  />
                  <img
                    className="imgBordureRank"
                    src={".././images/rewards/bordures/" + this.state.joueurbordure + ".png"}
                    alt=""
                  />
                </div>
              </div>

              <div className="pvPseudo2">
                <img
                  className="ligueRankPP2"
                  src={".././images/" + this.state.joueurligue + ".png"}
                  alt="ligue"
                />
                <span className="jpsize2"> 
                  { this.getAltArray(this.state.joueurpseudo) === ""
                      ? (this.state.joueurpseudo.match(truncateRegex)
                        ? truncateEthAddress(this.state.joueurpseudo)
                        : this.state.joueurpseudo )
                      : this.getAltArray(this.state.joueurpseudo)
                  }
                </span>{" "}
                <br />
                <div className="pvCitation2">{this.state.displaycitation}</div>
                LVL {this.state.joueurlvl} <br />
                <div className="pvStat2">{this.state.displaystat}</div>
              </div>

              <div className="profileCloser" onClick={this.closeProfile}>
                <img
                  className="imgClose"
                  src={".././images/closer.png"}
                  alt="closer"
                />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Ranking;
